import { Toast } from '@bhb-frontend/lithe-ui/lib';
import isEquals from '@bhb-frontend/utils/lib/equals';
import { action, makeObservable, observable, observe } from 'mobx';
import * as Sentry from '@sentry/react';
import debounce from '@bhb-frontend/utils/lib/debounce';
import {
  getAppId,
  getTemplateId,
  isEditorAppData,
  isFromTemplate,
  searchParams,
} from '@/helpers/UrlParam';
import { getAppData, createAppData } from '@/api/app';
import AppStruc from '@/models/AppStruc';
import { ResErrType, SaveStatus } from '@/constants/SaveStatus';
import UserStore from './User';
import { DocDetail } from '@/types/ServerData';
import { buildAppData } from '@/core/FormatData';
import { saveAppData } from '@/actions/action';
import { SocketResponse } from '@/types/socket';
import socket from '@/core/socket';
import { transformData } from '@/core/FormatData/AppData/AppToData';
import { SOCKET_RESPONSE } from '@/constants/Socket';
import { ServeData } from '@/types/cteateVideo';
import { checkSingleSecene } from '@/utils/checkResources';
import { DOC_ERROR_CODE } from '@/constants/Createvideo';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';
import { DOC_EDTIOR_BLUR } from '@/constants/StoreCacheKeys';
import { event } from '@/utils/event';
import { noEnoughSpace } from '@/utils/storagePaidGuidance';
import { ERROR_CODE } from '@/constants/ErrorCode';

export default class AppStore extends AppStruc {
  saveStatus = SaveStatus.UN_SAVED;

  /** 原本的作品数据 */
  private rawAppModel: AppModel | null = null;

  /** 自动保存时间间隔：x分钟 */
  private autoSaveInterval = 5 * 60 * 1000;

  private autoSaveTimer = 0;

  /** 全局loading */
  public globalLoading = false;

  /** 资源错误 */
  public resourcesErrType?: ResErrType;

  /** 是否离线 */
  private isOffline = false;

  constructor(user: UserStore) {
    super();
    makeObservable(this, {
      saveStatus: observable,
      globalLoading: observable,
      setSaveStatus: action,
      setGlobalLoading: action,
    });
    // 登录之后 再开始请求接口
    observe(user, 'id', this.initAppData, false);

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOfflineStatus);
  }

  updateOnlineStatus = () => {
    this.isOffline = false;
    socket.reConnect();
    console.log('online');
  };

  updateOfflineStatus = () => {
    console.log('offline');
    this.isOffline = true;
    this.setSaveStatus(SaveStatus.FAIL_SAVED);
  };

  private initAppData = () => {
    /**
     * 获取templateId
     * 如果存在，则从模版初始化作品
     * 如果不存在，判断是否是再次编辑作品，否则创建空白作品
     */
    if (isEditorAppData()) {
      /** 初始化作品内容 */
      this.onUserChange();
    } else if (isFromTemplate()) {
      this.initialTemplate();
    } else {
      /** 初始化文档，文档构建好了之后再创建作品 */
      this.createApp();
    }
    this.onSocket();
  };

  /** 监听回调 */
  private onSocket() {
    socket.on((res: SocketResponse<any>) => {
      switch (res.event) {
        case SOCKET_RESPONSE.SAVE_THEME_SUCCESS:
          // console.log('---主题已经保存到云端---');
          this.setSaveStatus(SaveStatus.SAVED);
          break;
        case SOCKET_RESPONSE.SAVE_DOC_SUCCESS:
          // console.log('---文档已经保存到云端---');
          this.setDurationUS(res.data.durationUS);
          this.setSaveStatus(SaveStatus.SAVED);
          if (res.data) {
            const { durationUS, balanceStorage } = res.data;
            this.setDurationUS(durationUS);
            balanceStorage === 0 && noEnoughSpace();
          }
          break;
        case SOCKET_RESPONSE.SAVE_THEME_FAILED:
          // console.log('---主题保存失败---');
          this.setSaveStatus(SaveStatus.FAIL_SAVED);
          this.hendleError(+res.data.code);
          break;
        case SOCKET_RESPONSE.SAVE_DOC_FAILED:
          // console.log('---文档保存失败---');
          this.setSaveStatus(SaveStatus.FAIL_SAVED);
          this.hendleError(+res.data.code);
          if (Number(res.data.code) === ERROR_CODE.STORAGE_DEFICIENCY) {
            noEnoughSpace();
          }
          break;
        case SOCKET_RESPONSE.CAPTIONS_SPLIT_RESULT:
          // 正文拆分
          this.setCaptionsText(res.data.jsonText?.scenes);
          break;
        default:
          break;
      }
    });
  }

  hendleError(code: number) {
    if (DOC_ERROR_CODE.STORAGE_LIMIT_ERROR === code) {
      // 文档编辑器失焦，禁止再次编辑
      event.fire(DOC_EDTIOR_BLUR, null);
      WeChatQRcodeModal.show();
    }
  }

  /** 设置保存状态 */
  setSaveStatus(status: SaveStatus) {
    this.saveStatus = status;
  }

  /** 检测内容是否改变 */
  hasChange(data: AppModel) {
    return !isEquals(this.rawAppModel, data);
  }

  /** 是否正在保存中 */
  isSaveing() {
    return this.saveStatus === SaveStatus.SAVING;
  }

  /**
   * 保存
   */
  save() {
    // 离线不保存
    if (this.isOffline) {
      this.setSaveStatus(SaveStatus.FAIL_SAVED);
      return;
    }

    this.saveData();
  }

  /** 保存数据 */
  saveData = debounce(async () => {
    if (this.isSaveing() || !this.hasChange(this.model())) return;
    this.setSaveStatus(SaveStatus.SAVING);
    const { sceneThemeList, documentInfo, documentId } = this.getServeData();
    this.checkResources(documentInfo as DocDetail, false);
    /** 执行保存流程 */
    saveAppData(documentId, documentInfo, sceneThemeList);
    /** 重启自动保存 */
    this.restartAutoSave();
  }, 500);

  public getServeData() {
    const data = transformData(this);
    const documentId = this.id;
    const sceneThemeList = data.jsonText.scenes.map(scene => {
      const { id } = scene;
      return {
        ...scene.themeInfo,
        sceneId: id,
      };
    });
    const serveData = {
      documentId,
      sceneThemeList,
      documentInfo: {
        ...data,
        documentId,
      },
    } as ServeData;

    return serveData;
  }

  /** 自动拆分 */
  splitSave = debounce(async () => {
    await this.captionsSplit();
    this.save();
  }, 500);

  /**
   * 自动保存
   */
  private startAutoSave() {
    this.autoSaveTimer = window.setTimeout(() => {
      this.save();
    }, this.autoSaveInterval);
  }

  private restartAutoSave() {
    window.clearTimeout(this.autoSaveTimer);
    this.startAutoSave();
  }

  /** 用户信息改变 */
  private onUserChange = async () => {
    if (!isEditorAppData()) return;
    const appId = getAppId();
    if (appId) {
      this.initialAppData(appId);
    }
  };

  /** 创建作品 */
  private async createApp() {
    createAppData({
      title: '',
    })
      .then(res => {
        const documentId = res.data.documentId;
        // documentId放到URL上
        searchParams.set('id', documentId);
        const param = searchParams.toString();
        const prefixUrl = window.location.href.split('?')[0];
        window.history.replaceState({}, '', `${prefixUrl}?${param}`);
        // 重新初始化文档
        this.initialAppData(documentId);
      })
      .catch(err => {
        // console.log('error', err);
        // Toast.error('创建作品数据失败');
        if (err?.body?.error) {
          const error = err?.body?.error;
          if (
            [
              DOC_ERROR_CODE.STORAGE_LIMIT_ERROR,
              DOC_ERROR_CODE.EQUITY_DEFICIENCY,
            ].includes(error)
          ) {
            WeChatQRcodeModal.show();
          }
        }
      });
  }

  /**
   * 初始化作品数据
   * @param id
   */
  private async initialAppData(id: string) {
    try {
      const appData = await getAppData(id, { isGetTheme: true });
      this.checkResources(appData.data);
      const data = this.flatAppData(appData.data);
      this.buildSences(data);
    } catch (error) {
      console.log('error', error);
      Sentry.captureException(error);
      Toast.error('获取作品数据失败');
    }
  }

  /** 资源校验 */
  private checkResources(data: DocDetail, isInit = true) {
    if (this.resourcesErrType) return;
    const scenes = data.jsonText.scenes;
    const isCheck = scenes.every(scene => checkSingleSecene(scene.themeInfo));
    if (isCheck) return;
    if (isInit) {
      this.setResErrType(ResErrType.interfaceError);
      Sentry.captureException(`资源丢失,接口数据资源丢失,文档id${data.id}`);
      return;
    }
    this.setResErrType(ResErrType.webError);
    Sentry.captureException(
      `资源丢失,前端操作导致数据资源丢失,文档id${data.id}`
    );
  }

  public setResErrType(type: ResErrType) {
    this.resourcesErrType = type;
  }

  /**
   * 拉取模版内容并创建作品
   */
  private initialTemplate() {
    const templateId = getTemplateId();
    console.log(templateId, '拉取模版内容后创建作品');
    this.startAutoSave();
  }

  /**
   * 拍平后端返回的作品数据
   */
  private flatAppData(data: DocDetail) {
    const appData = buildAppData(data);

    return appData;
  }

  /** 设置全局loading */
  public setGlobalLoading(val: boolean) {
    this.globalLoading = val;
  }
}
