export enum MusicTabTypes {
  // 9and16表示9:16的，16and9表示16:9的  默认9and16
  Network = 'network',
  My = 'my',
}

export const MUSIC_TAB_TYPES = [
  {
    label: '网络音乐',
    key: MusicTabTypes.Network,
  },
  {
    label: '素材库',
    key: MusicTabTypes.My,
  },
];
