import AppStruc from '@/models/AppStruc';
import CmpStruc from '@/models/CmpStruc';
import SceneStruc from '@/models/SceneStruc';
import OSStore from '@/store/OS';
import { HistoryRecord } from '@/types/History';
import HistoryManager from '../Manager/History';

type ReverseAction<T> = (this: T, ...rest: any[]) => void;

type ReverseActionCreator<T> = (
  this: T,
  ...rest: any[]
) => ReverseAction<T> | null;

/**
 * 创建历史记录装饰器
 * 传入函数为当前动作的逆向动作：添加 --> 删除
 * 若记录的动作没有逆向动作，则不记录
 */
export function createDecorator<T>() {
  return function historyDecorator(reverseAction: ReverseActionCreator<T>) {
    return function decotatorRecord(
      _target: T,
      name: string,
      desc: PropertyDescriptor
    ) {
      const originalAction = desc.value;
      desc.value = function decotatorAction(this: T, ...rest: any[]) {
        // 获取逆向动作，若返回`null`，则不记录
        const reverse = reverseAction.apply(this, rest);
        if (typeof reverse === 'function') {
          // 重做动作，则取原始动作
          const obverse = () => originalAction.apply(this, rest);
          const record: HistoryRecord = {
            name,
            context: rest,
            reverse,
            obverse,
          };
          HistoryManager.push(record);
        }
        return originalAction.apply(this, rest);
      };
      return desc;
    };
  };
}

/**
 * 作品操作历史记录
 */
export const appHistoryDecorator = createDecorator<AppStruc>();

/**
 * 场景操作历史记录
 */
export const sceneHistoryDecorator = createDecorator<SceneStruc>();

/**
 * 组件操作历史记录
 */
export const cmpHistoryDecorator = createDecorator<CmpStruc>();

/**
 * 系统操作历史记录
 */
export const OSHistoryDecorator = createDecorator<OSStore>();
