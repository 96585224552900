import BackgroundStruc from '@/models/CmpStruc/BackgroundStruc';
import { Footage, ThemeDetailItem } from '@/types/ServerData';
import { MimeType } from '@/constants/formatData';
import { DEFAULT_CONFIGURE } from '@/constants/SceneSize';

/** 背景数据转换，特殊处理 */
export function transformBack(
  backModel: BackgroundStruc,
  originalThemeId: string,
  orientation: 'vertical' | 'horizontal',
  extensionTheme: Partial<ThemeDetailItem>,
  configure: Configure = DEFAULT_CONFIGURE
) {
  const { composition, optionalFields, ...themeDetail } = extensionTheme;
  const {
    fillType,
    sourceId,
    id,
    extra,
    url,
    key,
    fileName,
    color = '',
    imgExtensionFields,
  } = backModel.model();
  const config = JSON.parse(JSON.stringify(configure));
  const footage: Footage = {
    ...themeDetail.footage,
    // 文字图层
    texts: [],
    // 背景颜色
    colors: [],
    images: [],
    styles: [],
    videos: [],
    // 特效
    effects: [],
    fonts: [],
  };
  const themeInfo = {
    ...themeDetail,
    composition,
    backgroundId: id,
    originalThemeId,
    orientation,
    configure: {
      // todo 后续需要使用 orientation 来生成比较稳妥
      ...config,
      background: {
        mimeType: MimeType[fillType],
        sourceId,
      },
    },
    // 蒙层特效暂时未使用到
    maskEffect: '',
    titleBgColor: '',
    footage,
    optionalFields: {},
  };
  switch (fillType) {
    case 'Image':
      footage.images.push({
        ...imgExtensionFields,
        fileName,
        objectId: sourceId,
        extra,
        imageUrl: url,
        imageKey: key,
      });
      break;
    case 'Video':
      footage.videos.push({
        ...imgExtensionFields,
        fileName,
        objectId: sourceId,
        fileUrl: url,
        fileKey: key,
        extra: {
          web: {
            backgroundCoverUrl: extra?.cover,
          },
        },
      });
      break;
    default:
      footage.colors.push({
        objectId: sourceId,
        color,
      });
      break;
  }
  return themeInfo;
}
