/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/ae4d835ca9beab3f3ee0022fa88b67ca.png */
  'audio-icon-play.png': 'ae4d835ca9beab3f3ee0022fa88b67ca.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/af8ba11a29769a236417fd836966a3c5.png */
  'audio-icon-stop.png': 'af8ba11a29769a236417fd836966a3c5.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/4a0c162686d9a7a069a926113213785d.png */
  'material_content-close-icon_png': '4a0c162686d9a7a069a926113213785d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/82c64e28dd8766dee353ed3c6f471fe0.png */
  'empty.png': '82c64e28dd8766dee353ed3c6f471fe0.png',
};
