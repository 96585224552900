/**
 * URL基本属性
 */
export const { search, pathname, hostname, href, hash, host } = window.location;

/**
 * URL额外参数
 * 作品id使用resfull路径
 * 其他参数使用URL参数
 */
export const searchParams = new URLSearchParams(search);

/**
 * 获取作品id
 * @returns {string}
 */
export function getAppId(): string {
  return searchParams.get('id') || '';
}

/**
 * 是否是再次编辑作品
 * @returns {boolean}
 */
export function isEditorAppData(): boolean {
  return !!getAppId();
}

/**
 * 判断是否是从模版引入模版
 * @returns {boolean}
 */
export function isFromTemplate(): boolean {
  return searchParams.has('templateId');
}

/**
 * 获取模版id
 * @returns {string}
 */
export function getTemplateId(): string {
  return searchParams.get('templateId') || '';
}

/** 获取 search Obj */
export function getSearchObj(): Record<string, string> {
  return Object.fromEntries(searchParams.entries());
}
