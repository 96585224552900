import { ListQuery } from '@/types/api';
import { StickerDetail } from '@/types/material';
import { getTextAnimationList } from '@/api/material';

import GetListWrapper, {
  GetListWrapperProps,
} from '@/components/Material/GetListWrapper';
import TextAnimationList from './TextAnimationList';

import Style from './style.module.less';

function TextAnimation() {
  const parmas: GetListWrapperProps<ListQuery, StickerDetail> = {
    apiFn: getTextAnimationList,
    payload: {
      sid: '',
      pageSize: 20,
    },
  };

  return (
    <div className={Style.textAnimation}>
      <div className={Style.label}>文字动画</div>
      <GetListWrapper {...parmas}>
        <TextAnimationList />
      </GetListWrapper>
    </div>
  );
}

export default TextAnimation;
