import { Input } from '@bhb-frontend/lithe-ui';
import { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import Style from './SearchInput.module.less';
import image from '@/assets/images';

interface SearchInputProps {
  /** 是否加载 */
  isLoading: boolean;
  /** 获取焦点回调 */
  onFocus?: () => void;
  /** 搜索字段改变回调 */
  changeKeyWords?: (val: string) => void;
  /** 关键字 */
  keyWords?: string[];
}

function SearchInput(props: SearchInputProps) {
  const { onFocus, isLoading, keyWords, changeKeyWords } = props;
  const [detailShow, setDetailShow] = useState(false);
  /** 搜索框内容，用于搜索网络资源 */
  const [value, setValue] = useState('');
  /** 搜索结果节点 */
  const wrapperRef = useRef<HTMLDivElement>(null);

  /** input焦点事件 */
  const handleFocus = () => {
    onFocus?.();
    setDetailShow(true);
    if (keyWords?.length) {
      setDetailShow(true);
    }
  };
  const hiddenDropdown = () => {
    setDetailShow(false);
    // this.inputRef.blur();
  };

  /** 监听点击事件，隐藏下拉搜索结果 */
  const onClick = e => {
    if (wrapperRef?.current?.contains(e.target)) {
      return;
    }
    hiddenDropdown();
  };

  /** 下拉框展示时，监听鼠标点击事件 */
  useEffect(() => {
    if (detailShow) {
      document.body.addEventListener('click', onClick);
      return;
    }
    document.body.removeEventListener('click', onClick);
  }, [detailShow]);

  return (
    <div className={Style.search} ref={wrapperRef}>
      <Input
        className={Style.searchInput}
        contentClassName={Style.searcshInputEl}
        placeholder="请输入内容"
        onFocus={handleFocus}
        allowClear
        value={value}
        onChange={(_, value) => {
          setValue(value);
        }}
        onClear={() => {
          changeKeyWords?.('');
        }}
        onPressEnter={() => {
          changeKeyWords?.(value);
          hiddenDropdown();
        }}
        prefix={
          <i
            className={cs(
              'iconfont',
              'icon-a-common_icon_search2x',
              Style.searchInputIcon
            )}
          />
        }
      />
      {detailShow && (
        <div className={Style.searchViews}>
          {isLoading ? (
            <div className={Style.searchLoading}>
              <img src={image.doc['pic_loading.png']} alt="loading" />
            </div>
          ) : (
            <>
              <div className={Style.searchTitle}>关键词</div>
              <div className={Style.searchList}>
                {keyWords?.map((word, index) => (
                  <div
                    key={index}
                    className={Style.searchItem}
                    onClick={e => {
                      e.stopPropagation();
                      setValue(word);
                      hiddenDropdown();
                      changeKeyWords?.(word);
                    }}
                  >
                    {word}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchInput;
