import cx from 'classnames';
import { observer } from 'mobx-react';
import { NodeNameplate } from '@/constants/NodeNamePlate';
import SceneStruc from '@/models/SceneStruc';
import CreateCmp from '../Cmp';
import cssmodule from './Scene.module.less';

interface SceneProps {
  /**
   * 场景基础结构
   */
  struc: SceneStruc;

  /**
   * 是否可编辑
   */
  editable?: boolean;

  style?: React.CSSProperties;

  className?: string;

  zoomLevel: number;
}

function Scene(props: SceneProps) {
  const { struc, style, className, zoomLevel, editable } = props;

  return (
    <article
      className={cx(cssmodule.scene, className)}
      data-id={struc.id}
      data-nameplate={NodeNameplate.SCENE}
      style={style}
    >
      {struc.cmps.map((cmp, index) => (
        <CreateCmp
          model={cmp}
          key={`${cmp.id}-${index}`}
          editable={editable}
          zoomLevel={zoomLevel}
        />
      ))}
    </article>
  );
}

export default observer(Scene);
