import { useRef, useState } from 'react';
import { Input, Button } from '@bhb-frontend/lithe-ui';
import Style from './index.module.less';

export enum OverlayType {
  edit = 'edit',
  remove = 'remove',
}

interface PopupOverlayProps {
  type: OverlayType;
  height: number;
  onConfirm?: (tagName?: string) => void;
  tagName?: string;
}

export default function PopupOverlay({
  type,
  height,
  tagName,
  onConfirm,
}: PopupOverlayProps) {
  const cancelRef = useRef<HTMLSpanElement>(null);
  const confirmRef = useRef<HTMLSpanElement>(null);

  const [editTagName, setEditTagName] = useState(tagName);

  return (
    <div
      style={{
        height: `${height}px`,
      }}
      className={Style['popup-overlay-container']}
      onClick={e => {
        /** 只有点击取消和确定按钮才能关闭Popup，阻止Popup clickClosable 事件 */
        const isStopPropagation =
          !cancelRef.current?.contains(e.target as Node) &&
          !confirmRef.current?.contains(e.target as Node);
        if (isStopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      {type === OverlayType.remove && (
        <div>删除标签会一并移除关联文档的标签，确定删除吗？</div>
      )}

      {type === OverlayType.edit && (
        <>
          <div>修改标签</div>
          <div className={Style['popup-overlay-form']}>
            <span>名称:</span>
            <Input
              value={editTagName}
              onChange={(_, value) => {
                setEditTagName(value);
              }}
            />
          </div>
        </>
      )}

      <div className={Style['popup-overlay-btns']}>
        <span ref={cancelRef}>
          <Button size="small" className={Style['cancel-btn']}>
            取消
          </Button>
        </span>
        <span ref={confirmRef}>
          <Button
            onClick={() => {
              onConfirm?.(editTagName);
            }}
            size="small"
            className={Style['confirm-btn']}
          >
            确认
          </Button>
        </span>
      </div>
    </div>
  );
}
