/** 文件类型与后缀映射 */
export const typeSuffixMap: Record<string, string> = {
  // 图片
  // jpg: 'image/jpeg',
  'image/jpeg': 'jpg',
  // png: 'image/png',
  'image/png': 'png',
  // gif: 'image/gif',
  'image/gif': 'gif',
  // webp: 'image/webp',
  'image/webp': 'webp',

  // 视频
  // mp4: 'video/mp4',
  'video/mp4': 'mp4',
  // m4v: 'video/x-m4v',
  'video/x-m4v': 'm4v',
  // mov: 'video/quicktime',
  'video/quicktime': 'mov',
  // mxf: 'application/mxf',
  'application/mxf': 'mxf',
  // ts: 'video/MP2T',
  'video/MP2T': 'ts',
  // flv: 'video/x-flv',
  'video/x-flv': 'flv',
  // mkv: 'video/x-matroska',
  'video/x-matroska': 'mkv',
  // avi: 'video/x-msvideo',
  'video/x-msvideo': 'avi',
  // webm: 'video/webm',
  'video/webm': 'webm',

  // 音频
  // mp3: 'audio/mpeg',
  'audio/mpeg': 'mp3',
  // aac: 'audio/x-hx-aac-adts',
  'audio/x-hx-aac-adts': 'aac',
  // ogg: 'audio/ogg',
  'audio/ogg': 'ogg',
  // wav: 'audio/x-wav',
  'audio/x-wav': 'wav',
};
