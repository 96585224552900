import { makeImage } from '@/utils/image';

// canvas绘制.9图实现
export const toPoint9 = async (
  url: string,
  width: number,
  height: number,
  nX: number,
  nY: number,
  nW: number,
  nH: number
) => {
  const canvas = document.createElement('canvas');
  const content = canvas.getContext('2d');
  canvas.width = width as number;
  canvas.height = height as number;
  const img = await makeImage(url);
  // 把图片划成9份，字段_span中的属性为可伸缩区域，计算其他地方为固定区域。
  /* const _scale = 4
  const _spanWidth = (img.width - _scale) / 2
  const _spanHeight = (img.height - _scale) / 2 */

  // 计算九宫格关键点位置信息和宽高（9宫格图参照手机键盘，从左到右，从上到下）
  /* 1 2 3
     4 5 6
     7 8 9
  */
  // 计算九宫格每张图片的原始宽高
  // 左侧图片宽度一致，即：1，4，7
  const leftWidth = nX;
  // 中间（纵向）图片宽度一致，即：2，5，8
  const middleWidth = nW;
  // 右侧图片宽度一致，即：3，6，9
  const rightWidth = img.width - nW - nX;
  // 上侧图片高度一致，即：1，2，3
  const topHeight = nY;
  // 中间（横向）图片高度一致：即：4，5，6
  const centerHeight = nH;
  // 底侧图片的高度一致，即：7，8，9
  const bottomHeight = img.height - nH - nY;

  /*
    根据上面计算得知
    图1： leftWidth * topHeight
    图2： middleWidth * topHeight
    图3： rightWidth * topHeight
    图4： leftWidth * centerHeight
    图5： middleWidth * centerHeight
    图6： rightWidth * centerHeight
    图7： leftWidth * bottomHeight
    图8： middleWidth * bottomHeight
    图9： rightWidth * bottomHeight
   */

  let midWidthRes = width - leftWidth - rightWidth;
  let midHeightRes = height - topHeight - bottomHeight;
  let leftWidthRes = leftWidth;
  let rightWidthRes = rightWidth;
  let topHeightRes = topHeight;
  let bottomHeightRes = bottomHeight;
  // 中间部分区域小于0时不绘制，同时等比例缩小4个角的绘制宽高
  if (midWidthRes < 0) {
    midWidthRes = 0;
    leftWidthRes = (leftWidth / (leftWidth + rightWidth)) * width;
    rightWidthRes = (rightWidth / (leftWidth + rightWidth)) * width;
  }
  if (midHeightRes < 0) {
    midHeightRes = 0;
    topHeightRes = (topHeight / (topHeight + bottomHeight)) * height;
    bottomHeightRes = (bottomHeight / (topHeight + bottomHeight)) * height;
  }

  // 图5，center center：坐标不会发生变化，宽高会发生变化，宽度计算：总宽度 - 图4宽度 - 图6宽度，高度计算：总高度 - 图2高度 - 图8高度
  content?.drawImage(
    img,
    nX,
    nY,
    middleWidth,
    centerHeight,
    nX,
    nY,
    midWidthRes,
    midHeightRes
  );
  // 图2，center top：坐标不会发生变化，高度不会发生变化，宽度会发生变化，宽度计算：总宽度 - 图1宽度 - 图3宽度
  content?.drawImage(
    img,
    nX,
    0,
    middleWidth,
    topHeight,
    nX,
    0,
    midWidthRes,
    topHeightRes
  );
  // 图8，center bottom：横坐标不会发生变化，纵坐标会发生变化了，纵坐标计算：总高度 - 图8高度，高度不会发生变化，宽度发生变化，宽度计算：总宽度 - 图7宽度 - 图9宽度
  content?.drawImage(
    img,
    nX,
    nY + nH,
    middleWidth,
    bottomHeight,
    nX,
    height - bottomHeightRes,
    midWidthRes,
    bottomHeightRes
  );
  // 图4，left center：坐标不会发生变化，宽度不会发生变化，高度发生变化，高度计算：总高度 - 图1高度 - 图7高度
  content?.drawImage(
    img,
    0,
    nY,
    leftWidth,
    centerHeight,
    0,
    nY,
    leftWidthRes,
    midHeightRes
  );
  // 图6：right center：横坐标发生变化，横坐标计算：总宽度 - 图6宽度，纵坐标不会发生变化，宽度不会发生变化，高度发生变化：总高度 - 图3高度 - 图9高度
  content?.drawImage(
    img,
    nW + nX,
    nY,
    rightWidth,
    centerHeight,
    width - rightWidthRes,
    nY,
    rightWidthRes,
    midHeightRes
  );

  // 接下来的绘制四个角的图片，四个角的图片宽和高都是不变的
  // 图1：left top：横纵坐标不会发生变化
  content?.drawImage(
    img,
    0,
    0,
    leftWidth,
    topHeight,
    0,
    0,
    leftWidthRes,
    topHeightRes
  );
  // 图7：left bottom：横坐标不会发生变化，纵坐标发生变化，纵坐标计算：height - 图7高度；
  content?.drawImage(
    img,
    0,
    nY + nH,
    leftWidth,
    bottomHeight,
    0,
    height - bottomHeightRes,
    leftWidthRes,
    bottomHeightRes
  );
  // // 图3：right top：横坐标会发生变化，横坐标计算：width - 图3宽度;纵坐标不变
  content?.drawImage(
    img,
    nW + nX,
    0,
    rightWidth,
    topHeight,
    width - rightWidthRes,
    0,
    rightWidthRes,
    topHeightRes
  );
  // 图9：right bottom：横纵坐标都会发生变化：横坐标计算：width - 图9宽度；纵坐标计算：height - 图9高度
  content?.drawImage(
    img,
    nW + nX,
    nH + nY,
    rightWidth,
    bottomHeight,
    width - rightWidthRes,
    height - bottomHeightRes,
    rightWidthRes,
    bottomHeightRes
  );
  url = canvas.toDataURL('/image/png');
  return url;
};
