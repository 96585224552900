import { makeObservable, observable, action } from 'mobx';
import { Toast } from '@bhb-frontend/lithe-ui/lib';
import { EDubSpeed } from '@/types/dub';
import { app } from '@/store';
import { geDubInfo, getMusicMaterial, getMusicNetwork } from '@/api/app';

export default class AudioStruc {
  id?: string;

  tag!: 'music' | 'dubbing' | 'record';

  /** 语速 */
  speed?: EDubSpeed;

  /** 封面 */
  cover?: string;

  /** 名称 */
  name?: string;

  /** 音乐地址 */
  url?: string;

  /** 音乐来源 network-网络音乐(后台音乐) material-素材音乐(用户自己上传) */
  source?: 'network' | 'material';

  /** 音量 */
  volume = 0;

  key?: string;

  /** 时长 */
  duration?: number;

  /** 配音接口获取loading */
  dubLoading = false;

  constructor(data: Audio) {
    // super(data);
    makeObservable(this, {
      id: observable,
      tag: observable,
      speed: observable,
      url: observable,
      cover: observable,
      name: observable,
      volume: observable,
      update: action,
      initUpdate: action,
    });
    this.id = data.id;
    this.tag = data.tag;
    this.speed = (data?.speed as EDubSpeed) || EDubSpeed.medium;
    this.cover = data.cover;
    this.name = data.name;
    this.url = data?.url;
    this.source = data?.source;
    this.volume = data?.volume || 0;
    this.key = data?.key;
    this.duration = data?.duration;
  }

  /** 更新 */
  update(data: Partial<Audio>) {
    for (const key in data) {
      this[key] = data[key];
    }
    app.save();
  }

  /** 初始化更新, 不保存接口 */
  initUpdate(data: Partial<Audio>) {
    for (const key in data) {
      this[key] = data[key];
    }
  }

  /**  初始化音乐详情 */
  public async initMusic() {
    if (!this?.id) return;
    const api = this.source === 'network' ? getMusicNetwork : getMusicMaterial;
    const { data } = await api(this.id);
    const { coverUrl, musicUrl, materialUrl, name } = data;
    this.initUpdate({
      url: musicUrl || materialUrl,
      cover: coverUrl,
      name,
    });
  }

  /** 初始化配音 */
  public async initDub() {
    if (!this?.id) return;
    if (this.dubLoading || this?.url) return;
    this.dubLoading = true;
    this.getDubInfo();
  }

  /** 选择数字人处理语速 */
  public digitalManHandleSpeed() {
    const { tag, speed } = this;
    if (tag !== 'dubbing') return;
    // Toast.warning('为了视频效果，已切换为数字人推荐配音');
    if (speed === EDubSpeed.ultraQuick) {
      // 现在数字人如果为4x速度，将下调为1x速度
      Toast.success('速度已调整为1倍速');
      this.speed = EDubSpeed.medium;
    }
  }

  /** 获取配音详情 */
  getDubInfo = async () => {
    if (!this?.id) return;
    const { data } = await geDubInfo(this.id).finally(() => {
      this.dubLoading = false;
    });
    const { coverUrl, exampleUrl, name, id } = data;
    this.initUpdate({
      tag: 'dubbing',
      url: exampleUrl,
      cover: coverUrl,
      name,
      id,
    });
  };

  /** 清空 */
  clean(initValue: Partial<Audio> = {}) {
    this.update({
      key: '',
      id: '',
      url: '',
      cover: '',
      name: '',
      source: undefined,
      ...initValue,
    });
  }

  /** 获取tag */
  protected getTag() {
    return this.tag;
  }

  /**
   * 输出音乐组件model模型
   * @returns {Audio}
   */
  model(): Audio {
    return {
      id: this.id,
      tag: this.tag,
      speed: this.speed,
      cover: this.cover,
      url: this.url,
      name: this.name,
      duration: this.duration,
    };
  }

  /** 是否最快速度 */
  get isFastestSpeed() {
    return this.speed === EDubSpeed.ultraQuick;
  }

  /** 是否是导入的音频 */
  get isImportRecord() {
    return this.tag === 'record';
  }
}
