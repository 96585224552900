import { observer } from 'mobx-react';
import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import Style from './style.module.less';

interface Props {
  onDrop: (fileList: File[]) => void;
}
export interface DropRefFn {
  addDragEvent: (dom: HTMLDivElement) => void;
  removeDragEvent: (dom: HTMLDivElement) => void;
}

function Drop(props: Props, ref: Ref<DropRefFn>) {
  const { onDrop } = props;
  const [dropUploadImagesShow, setDropUploadImagesShow] = useState(false);

  useImperativeHandle(ref, () => ({
    addDragEvent,
    removeDragEvent,
  }));
  /** 控制遮罩层显示 */
  const dragMaskLayerShow = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDropUploadImagesShow(true);
  };
  const dragMaskLayerHidden = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDropUploadImagesShow(false);
  };

  /** 拖拽释放 数据处理 */
  const dragReleaseDataProcess = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDropUploadImagesShow(false);

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const fileList = Array.from(event.dataTransfer.files);
      console.log({ fileList });
      onDrop(fileList);
    }
  };

  /** 拖拽移动 阻止默认事件 */
  const dragMovePreventDefault = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDropUploadImagesShow(true);
  };

  /** 添加拖拽绑定事件 */
  const addDragEvent = (dom: HTMLDivElement) => {
    dom.addEventListener('dragenter', dragMaskLayerShow, true);
    dom.addEventListener('dragover', dragMovePreventDefault, true);
    dom.addEventListener('dragleave', dragMaskLayerHidden, true);
    dom.addEventListener('drop', dragReleaseDataProcess, true);
  };

  /** 移除拖拽绑定事件 */
  const removeDragEvent = (dom: HTMLDivElement) => {
    dom.removeEventListener('dragenter', dragMaskLayerShow, true);
    dom.removeEventListener('dragover', dragMovePreventDefault, true);
    dom.removeEventListener('dragleave', dragMaskLayerHidden, true);
    dom.removeEventListener('drop', dragReleaseDataProcess, true);
  };

  return (
    <div
      className={Style.drop}
      style={{ display: dropUploadImagesShow ? '' : 'none' }}
    >
      <p className={Style.dropTitle}>拖动到此处，添加至文档</p>
      <p className={Style.dropDesc}>
        支持上传MP4、MOV、PNG、JPG、JPEG、WEBP、GIF文件
      </p>
    </div>
  );
}

export default observer(forwardRef(Drop));
