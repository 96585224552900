import io, { Socket } from 'socket.io-client';
import cookie from 'js-cookie';
import { user } from '@/store';

const SOCKET_RECONNECT_MAX = 5;
type Data = any;
// TODO: 后续修改为枚举，约束event names边界
type EventName = string;
type Callback = (data: Data) => void;

export interface SocketRequestParams {
  emitterEventName: EventName;
  onEnventName: EventName;
  timeout?: number;
  data?: any;
}

export interface SocketRequestResult<T> {
  data: {
    message?: string;
    success?: boolean;
    results?: T;
  };
  event: EventName;
}

export interface CustomSocket {
  /**
   *  连接socket
   */
  connect(): void;
  /**
   * 发送消息
   * @param data Data
   * @param eventName string
   */
  emit(data: Data, eventName?: string): void;
  /**
   * 监听事件
   * @param cb Callback
   * @param eventName string
   */
  on(cb: Callback, eventName?: string): void;
  /**
   * 移除事件
   * @param cb Callback
   * @param eventName string
   */
  off(cb: Callback, eventName?: string): void;
  /**
   * socket错误监听
   * @param cb Callback
   */
  onErr(cb?: Callback): void;
  /**
   * 重新连接
   */
  reConnect(): void;
  /**
   * 关闭socket
   */
  close(): void;
  /**
   * 请求数据
   * @param params SocketRequestParams
   */
  request<T extends SocketRequestResult<T>>(
    params: SocketRequestParams
  ): Promise<T>;

  errFn(): void;
}

/**
 * 单例模式，避免多次初始化socket
 */
class SocketFn implements CustomSocket {
  // 重连次数
  private socketReconnectNum = 0;

  // 是否重新连接
  private reStart = false;

  // socket实例
  private static instance: SocketFn;

  // socket对象
  private socketObj: Socket | null = null;

  // 发送事件队列
  private socketEmitQueue: [Data, EventName][] = [];

  // 回调事件队列  第三个Callback 主要用于off 关闭事件
  private socketOnQueue: [Callback, EventName, Callback][] = [];

  static getInstance() {
    if (!this.instance) {
      this.instance = new SocketFn();
    }
    return this.instance;
  }

  // 建立连接
  public connect() {
    console.log('--------socketConnect---------');
    const socketLink = cookie.get('socketLink') || '';
    this.socketObj = io(socketLink, { transports: ['websocket'] });
    this.onErr();
  }

  // 发送事件
  public emit(data: Data, eventName = 'messageToServer') {
    if (!this.socketObj) {
      this.connect();
    }
    this.socketEmitQueue.push([data, eventName]);
    (this.socketObj as Socket).emit(eventName, data);
  }

  // 回调事件
  public on(cb: Callback, eventName = 'messageToClient') {
    if (!this.socketObj) {
      this.connect();
    }
    const handleCallback = (data: any) => {
      this.socketReconnectNum = 0;
      this.socketEmitQueue = [];
      cb(data);
    };
    this.socketOnQueue.push([cb, eventName, handleCallback]);

    (this.socketObj as Socket).on(eventName, handleCallback);
  }

  // 注销事件
  public off(cb: Callback, eventName = 'messageToClient') {
    this.socketOnQueue = this.socketOnQueue.filter(
      ([_cb, _eventName, socketCallback]) => {
        const isOff = cb === _cb && eventName === _eventName;
        if (isOff) {
          this.socketObj?.off(eventName, socketCallback);
        }
        return !isOff;
      }
    );
  }

  // 开始监听错误
  public onErr(cb?: Callback) {
    if (this.socketObj) {
      this.socketObj.on('connect_error', async (err: any) => {
        console.log('socketErr:', err);
        const errData = err.data;
        if (errData && errData.message === 'jwt expired') {
          console.log('socketToken过期');
          this.close();
          // 过期重新请求用户信息， 获取最新的链接
          await user.getUser();
          this.reConnect();
        } else {
          this.errFn(cb, err);
        }
      });
      this.socketObj.on('error', (err: any) => {
        console.log('---error---');
        this.errFn(cb, err);
      });
    }
  }

  // 重新连接
  public reConnect() {
    /* const socketLink = cookie.get('socketLink') || ''
    this.socketObj = io(socketLink, { transports: ['websocket'] }) */
    // 读取保存的数据，拷贝后进行重连
    const emitQueue = [...this.socketEmitQueue];
    this.socketEmitQueue = [];
    const onQueue = [...this.socketOnQueue];
    this.socketOnQueue = [];

    onQueue.forEach(([cb, eventName]) => {
      this.on(cb, eventName);
    });

    emitQueue.forEach(item => {
      this.emit(...item);
    });
  }

  // 关闭连接
  public close(): void {
    console.log('--socket_close--');
    this.socketObj && this.socketObj.close();
    this.socketObj = null;
  }

  /**
   * 绑定emit & on: 发送后同时接受对应res
   * @param params
   * @returns
   */
  public request<T extends SocketRequestResult<any>>(
    params: SocketRequestParams
  ): Promise<T> {
    const {
      data = null,
      emitterEventName,
      onEnventName,
      timeout = 12000,
    } = params;
    let timer: number;
    return new Promise((resolve, reject) => {
      // 计时之前，清空之前timer
      if (timer) {
        clearTimeout(timer);
      }
      timer = window.setTimeout(() => {
        reject(new Error('socket request timeout'));
      }, timeout);
      this.emit({ event: emitterEventName, data });
      this.on((data: T) => {
        if (data?.event === onEnventName) {
          if (data?.data) {
            resolve(data);
            clearTimeout(timer);
          } else {
            reject(data);
            clearTimeout(timer);
          }
        }
      });
    });
  }

  // 连接失败后的回调
  public errFn = (cb?: Callback, err?: Error) => {
    this.close();
    if (this.socketReconnectNum < SOCKET_RECONNECT_MAX) {
      if (this.reStart) {
        return;
      }
      this.reStart = true;
      // 等待1秒重连
      setTimeout(() => {
        this.socketObj = null;
        this.reStart = false;
        this.socketReconnectNum += 1;
        console.log(`开始第${this.socketReconnectNum}次重连...`);
        this.reConnect();
      }, 1000);
    } else {
      this.socketObj = null;
      cb && cb(err);
    }
  };
}

export default new SocketFn();
