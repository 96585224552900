import { makeObservable, observable, action, observe } from 'mobx';
import { randomString } from '@bhb-frontend/utils/lib/random';
import clone from '@bhb-frontend/utils/lib/clone';
import debounce from '@bhb-frontend/utils/lib/debounce';
import { CmpTypeEnum } from '@/constants/CmpType';
import { getTagsWithEnum } from '@/constants/Tags';
import CmpStruc from './CmpStruc';
import { app, fontStore } from '@/store';
import { textCounter } from '@/utils/textCounter';
import { SelectedStyle } from '@/types/selectedText';
import CreateCmpStruc from '../FactoryStruc/CmpFactory';

export default class TextStruc<
    T extends ComponentModel.Text = ComponentModel.Text
  >
  extends CmpStruc<T>
  implements ComponentModel.Text
{
  features?: number;

  content: string;

  contents?: string[];

  styleId?: string;

  charAttrs?: ComponentModel.CharAttr[];

  maxLines?: number;

  isEdit?: boolean;

  textAnimation?: ComponentModel.TextAnimation;

  textAlign!: 'left' | 'center' | 'right';

  verticalAlign!: 'top' | 'middle' | 'bottom';

  specialeEffect?: ComponentModel.SpecialeEffect;

  fontDesc!: ComponentModel.FontDesc;

  fill?: ComponentModel.Fill;

  /** 文字贴纸-图片名称 */
  fileName?: string;

  /** 文字贴纸-图片key */
  key?: string;

  /** 文字贴纸-图片url */
  url?: string;

  /** 文字贴纸-图片额外字段 */
  extra?: ImageExtra;

  /** 文字字段， optionalFields转换没用的数据存放在此 */
  textExtensionFields!: Record<string, any>;

  /** 文字选中区域的样式 */
  selectedStyle: SelectedStyle | null = null;

  constructor(data?: Partial<ComponentModel.Text>) {
    super(data);
    makeObservable(this, {
      content: observable,
      isEdit: observable,
      verticalAlign: observable,
      fontDesc: observable,
      fill: observable,
      maxLines: observable,
      url: observable,
      selectedStyle: observable,
      onEdit: action,
      unEdit: action,
    });

    observe(this, 'fontDesc', this.changeFontDesc);
    observe(fontStore, 'fontList', this.changeFontDesc);
    this.tag = data?.tag || getTagsWithEnum('Captions');
    this.content = data?.content || '';
    this.type = CmpTypeEnum.TEXT;
    this.isEdit = data?.isEdit ?? false;
    this.name = data?.name || '文本1';
    this.contents = data?.contents || [];
    this.styleId = data?.styleId || '';
    this.specialeEffect = data?.specialeEffect;
    this.textAnimation = data?.textAnimation;
    this.fontDesc = data?.fontDesc || {};
    this.fill = data?.fill;
    this.fileName = data?.fileName;
    this.key = data?.key;
    this.url = data?.url;
    this.extra = data?.extra;
    this.maxLines = data?.maxLines || 1;
    this.style = {
      ...this.style,
      // width: 120,
      // height: 'auto',
      ...data?.style,
    };
    this.textExtensionFields = data?.textExtensionFields || {};
    this.charAttrs = data?.charAttrs;
    // 初始化高度矫正
    this.changeTextHeight();
  }

  /** 保存前 */
  beforeSave() {
    // 字幕由接口拆分
    if (this.tag === 'Captions') {
      app.splitSave();
      return false;
    }
    this.setContents();
    return true;
  }

  /** 字体发生改变 */
  private changeFontDesc = () => {
    const { fontDesc } = this;
    if (!fontStore.fontList.length || !fontDesc?.faceName) return;
    // 加载过的将不在加载
    if (fontStore.fontCache.includes(fontDesc.faceName)) {
      return;
    }
    fontStore.setFontCache(fontDesc.faceName);
    this.createFontFace(fontDesc.faceName);
  };

  public createFontFace(faceName: string) {
    // 需要加载的字体的详情
    const font =
      fontStore.fontList.find(item => item.faceName === faceName) ||
      fontStore.fontList[0];

    if (!font) return;
    this.loading = true;
    const style = document.createElement('style');
    style.innerText = `@font-face{font-family:"${font.faceName}";src:url("${font.woff2Url}")}`;
    document.getElementsByTagName('head')[0].appendChild(style);
    // 兼容旧数据
    const oldStyle = document.createElement('style');
    oldStyle.innerText = `@font-face{font-family:"${font.fontName}";src:url("${font.woff2Url}")}`;
    document.getElementsByTagName('head')[0].appendChild(oldStyle);

    const fontPromise = new window.FontFace(
      font.faceName,
      `url(${font.woff2Url})`
    );
    fontPromise
      .load()
      .catch(err => {
        console.warn(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * 获取组件类型
   * @returns {CmpTypeEnum.TEXT}
   */
  protected getType(): CmpTypeEnum.TEXT {
    return CmpTypeEnum.TEXT;
  }

  /**
   * 获取tag
   * @returns
   */
  protected getTag() {
    return this.tag;
  }

  /**
   * 输出文本组件model模型
   * @returns {ComponentModel.Text}
   */
  model(): ComponentModel.Text {
    return {
      ...super.model(),
      tag: this.tag,
      features: this.features,
      content: this.content,
      contents: this.contents,
      styleId: this.styleId,
      charAttrs: this.charAttrs,
      maxLines: this.maxLines,
      isEdit: this.isEdit,
      textAnimation: this.textAnimation,
      type: this.type,
      name: this.name,
      style: this.style,
      specialeEffect: this.specialeEffect,
      fontDesc: this.fontDesc,
      fill: this.fill,
      fileName: this.fileName,
      key: this.key,
      url: this.url,
      extra: this.extra,
      textExtensionFields: this.textExtensionFields,
    };
  }

  /** 是否可copy */
  get isCanCopy() {
    if (['Captions', 'Title'].includes(this.tag)) return false;
    return true;
  }

  /** 复制一个组件 */
  clone(): CmpStruc {
    const model = clone(this.model(), true);
    // 所有的id 都需要重新生成
    const { id, styleId, sourceId, imgExtensionFields } = model;
    id && (model.id = `layer-${randomString()}-web`);
    styleId && (model.styleId = `style-${randomString()}-web`);
    sourceId && (model.sourceId = `text-${randomString()}-web`);
    imgExtensionFields?.objectId &&
      (imgExtensionFields.objectId = `image-${randomString()}-web`);

    return CreateCmpStruc(model.type, model, this.getParent());
  }

  /**
   * 开启编辑
   */
  onEdit() {
    this.isEdit = true;
  }

  /**
   * 关闭编辑
   */
  unEdit() {
    this.isEdit = false;
  }

  /**
   * 取消选中
   */
  inactive() {
    this.actived = false;
    this.unEdit();
  }

  /** 修改内容, 仅字幕需要，不需要保存ws */
  public setCaptionsText(texts: string[]) {
    this.content = texts?.join('\n') ?? '';
    this.contents = texts;
  }

  /**
   * 计算文字换行
   */
  setContents = debounce(() => {
    const {
      paddingBottom = 0,
      paddingLeft = 0,
      paddingRight = 0,
      paddingTop = 0,
      fontSize = 12,
      letterSpacing = 0,
      lineHeight = 0,
      width = 0,
    } = this.style;
    const { faceName = '' } = this.fontDesc;
    const option = {
      fontSize: +fontSize,
      padding: {
        top: +paddingTop,
        bottom: +paddingBottom,
        left: +paddingLeft,
        right: +paddingRight,
      },
      fontFamily: faceName,
      fontStyle: '',
      letterSpacing,
      lineHeight,
    };

    const textValue = this.isTitle ? app.titleContent : this.content;
    const textInfo = textCounter(textValue, option, +width);
    this.contents = textInfo.contents;
    this.maxLines = textInfo.text.split('\n').length;
  }, 300);

  /**
   * 矫正高度
   *  */
  rectifyHeight = () => {
    const {
      fontSize = 12,
      lineHeight = 1,
      paddingBottom = 0,
      paddingTop = 0,
      height = 0,
    } = this.style;

    const paddingHeight = paddingTop + paddingBottom;
    /** 单行高度  */
    const curHeight = lineHeight * +fontSize;
    /** 当前行数 */
    let lines = Math.floor((+height - paddingHeight) / curHeight);
    /** 是否有余数  */
    const remainder = (+height - paddingHeight) % curHeight;
    // 半行高度
    // eslint-disable-next-line no-bitwise
    const halfHeight = curHeight >> 1;
    // 是否超过半行
    if (remainder >= halfHeight) {
      lines += 1;
    }
    lines = Math.max(1, lines);
    const targetHeight = lines * curHeight + paddingHeight;
    this.updateStyle({ height: targetHeight });
    (this as TextStruc).update({ maxLines: lines });
  };

  /** 设置文字选中区域的样式 */
  setSelectedStyle(style: SelectedStyle) {
    this.selectedStyle = style;
  }

  clearSelectedStyle() {
    this.selectedStyle = null;
  }

  public changeTextHeight() {
    const {
      fontSize = 12,
      paddingBottom = 0,
      paddingTop = 0,
      lineHeight = 1,
    } = this.style;
    const { maxLines = 1 } = this;
    /** 单行高度  */
    const curHeight = lineHeight * +fontSize;
    /** 新的高度 */
    const newHeight = curHeight * maxLines + paddingBottom + paddingTop;
    this.updateStyle({ height: newHeight });
  }
}
