import cx from 'classnames';
import { Spin } from '@bhb-frontend/lithe-ui';
import { MouseEventProps } from '@bhb-frontend/lithe-ui/lib/core/props';
import { observer } from 'mobx-react';
import React from 'react';
import CmpsModel, { TextStruc } from '@/models/CmpStruc';
import Text from './Text';
import Sticker from './Image';
import Background from './Background';
import cssmodule from './Cmp.module.less';
import { CmpTypeEnum } from '@/constants/CmpType';
import { useStores } from '@/store';
import { NodeNameplate } from '@/constants/NodeNamePlate';
import {
  getCmpOuterStyles,
  getCmpInnerStyles,
  getTextFillStyle,
} from '@/helpers/Styles';
import Move from '@/core/Move';

export interface CmpsFactoryProps<M extends CmpsModel = CmpsModel> {
  /**
   * 组件样式，和`ComponentStyle`一致
   */
  style?: React.CSSProperties;

  model: M;

  /**
   * 在画布编辑模式或者场景缩略图不可编辑模式
   */
  editable?: boolean;
  /**
   * 画布缩放级别
   */
  zoomLevel?: number;
}

export interface InnerCmpProps<M extends CmpsModel = CmpsModel>
  extends CmpsFactoryProps<M>,
    MouseEventProps {
  className?: string;
  children?: React.ReactNode;
}

function getModelByType(type: CmpType) {
  const modelByType = {
    [CmpTypeEnum.TEXT]: Text,
    [CmpTypeEnum.IMAGE]: Sticker,
    [CmpTypeEnum.BACKGROUND]: Background,
  };
  return modelByType[type] as React.ComponentType<CmpsFactoryProps<CmpsModel>>;
}

function CreateCmp<M extends CmpsModel = CmpsModel>(props: InnerCmpProps<M>) {
  const {
    className,
    children,
    style,
    model,
    editable = true,
    zoomLevel = 1,
    ...otherProps
  } = props;

  const { app, OS } = useStores();

  const { activedCmps } = app;

  const isMulti = activedCmps.length > 1;

  const isActived = !isMulti && model.actived;

  if (!model.visible) return null;
  const Cmp = getModelByType(model.type);
  if (!Cmp) return null;

  const outerStyle = getCmpOuterStyles(model);
  const innerStyle = getCmpInnerStyles<M>(model);

  if (model.isText) {
    // Reflect.deleteProperty(outerStyle, 'height');
    Object.assign(
      outerStyle,
      getTextFillStyle((model as unknown as TextStruc).fill)
    );
  }

  /**
   *  鼠标进入时触发
   */
  const handleMouseEnter = () => {
    if (!editable || OS.isEditing) return;
    app.hoverCmp(model);
  };

  /**
   * 鼠标离开时触发
   */
  const handleMouseLeave = () => {
    if (!editable || OS.isEditing) return;
    app.hoverCmp(null);
  };

  /**
   * 鼠标按下时触发
   */
  const HandleMouseDown = (e: React.MouseEvent) => {
    if (!editable) return;
    e.stopPropagation();
    if (e.button !== 0 || isActived) return;
    app.activeCmp(model, e.shiftKey);
  };

  return (
    <Move model={model} zoomLevel={zoomLevel} editable={editable}>
      <div
        {...otherProps}
        className={cx(cssmodule.cmps, className, {
          [cssmodule['cursor-move']]: editable && !model.isLock,
        })}
        data-id={model.id}
        data-type={model.type}
        data-nameplate={NodeNameplate.COMPONENT}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={HandleMouseDown}
        style={{
          ...outerStyle,
        }}
      >
        {editable && (
          <Spin
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            spinning={model.loading ?? false}
            iconStyle={{
              width: 70,
              height: 70,
            }}
          >
            <div />
          </Spin>
        )}
        <Cmp {...props} style={innerStyle} editable={editable} />
      </div>
    </Move>
  );
}
export default observer(CreateCmp);
