import request from '@/api/init';
import {
  CustomListResponse,
  DetailResponse,
  UrlFunction,
  UrlString,
  ListQuery,
  CommonResponse,
} from '@/types/api';
import { Address } from '@/types/doc-materials';
import {
  Category,
  GetThemeParmas,
  ThemeDetail,
  GetDigitalManParmas,
  DigitalManDetail,
  DubbingDetail,
  SearchParams,
  PresetType,
  ThemeType,
  MusicDetail,
  MyMusicDetail,
  BackgroundDetail,
  StickerDetail,
  TextAnimationDetail,
  MaterialReqParams,
} from '@/types/material';
import { DigitalManRecommendDubQuery } from '@/types/dubbing';

const url: UrlString = {
  docs: '/clip/documents',
  docVideo: '/clip/documents/video',
  docMaterial: '/clip/material',
  background: '/clip/background',
  backgroundCategory: '/clip/background/category',
  themeCollap: '/theme/index',
  dubbing: '/clip/dubbing',
  dubRecommend: '/figure/intro/dubbings',
  music: '/clip/music/category',
  materialMusic: '/clip/material/audio',
  labels: '/clip/labels',
  removeDocs: '/clip/documents/many',
  videoDoc: '/clip/documents/video/link',
  themeCategory: '/theme/category',
  videoCover: '/clip/file/video/cover',
  speed: '/clip/dubbing/speeds',
  stickerClassify: '/clip/sticker/category',
  textSticker: '/clip/font_sticker',
  cutout: '/effects/segment/image',
  address: '/address',
  dubbingType: '/clip/dubbing/category',
  textAnimation: '/clip/text_animation',
  /**
   * 预设IP形象分类列表
   */
  presetIpProfileCategory: '/preset_ip_image/category',
  /**
   * ip形象
   */
  ipProfile: 'ip_images',
  /** 生成预览视频 */
  previewVideo: '/clip/documents/preview_video',
  /** 大头身体模板列表  */
  headFigure: '/clip/head_figure',
};

const urlFun: UrlFunction = {
  docDetail: id => `${url.docs}/${id}`,
  docMaterialType: type => `${url.docMaterial}/${type}`,
  docNetMusicList: id => `${url.music}/${id}`,
  docLabels: id => `${url.docs}/${id}/labels`,
  docRemoveTag: id => `${url.labels}/${id}`,
  docEditTag: id => `${url.labels}/${id}`,
  rename: id => `/clip/documents/${id}/production`,
  officialTheme: id => `/theme/category/${id}`,
  stickerList: id => `/clip/sticker/category/${id}`,
  stickerDetail: id => `/clip/font_sticker/${id}`,
  dubbingType: id => `/clip/dubbing/category/${id}`,
  presetIpProfileCategory: id => `/preset_ip_image/category/${id}`,
  ipProfile: id => `/ip_image/${id}`,
  /** 预设IP形象详情 */
  presetIpImageDetail: id => `/preset_ip_image/${id}`,
  backgroundCategoryList: id => `/clip/background/category/${id}`,
};

/**
 * 获取主题分类
 */
export function getThemeCategory(type: ThemeType = ThemeType.theme) {
  return request.get<CustomListResponse<Category>>('/theme/category', {
    type,
  });
}

/**
 * 获取分类主题
 */
export function getCategoryThemeById(data: GetThemeParmas, id?: string) {
  return request.get<CustomListResponse<ThemeDetail>>(
    `/theme/category/${id}`,
    data
  );
}

/**
 * 获取自己的主题
 */
export function getSelfTheme(data: GetThemeParmas) {
  return request.get<CustomListResponse<ThemeDetail>>('/theme/index', data);
}

/**
 * 删除主题
 */
export function removeTheme(id: string): Promise<CommonResponse> {
  return request.delete(`/theme/${id}`);
}

/**
 * 删除大头数字人
 */
export function removeBigHeadDigitalMan(id: string): Promise<CommonResponse> {
  return request.delete(`/clip/head_figure/${id}`);
}

/**
 * 获取数字人分类
 */
export function getDiagitalManCategory(
  type: PresetType = PresetType.presetFigure
) {
  return request.get<CustomListResponse<Category>>(
    '/preset_ip_image/category',
    {
      type,
    }
  );
}

/**
 * 获取数字人
 */
export function getDigitalManById(data: GetDigitalManParmas, id?: string) {
  return request.get<CustomListResponse<DigitalManDetail>>(
    `preset_ip_image/category/${id}`,
    data
  );
}

/** 获取筛选数字人列表 */
export function getDigitalManSearch(params: SearchParams) {
  return request.get('/preset_ip_image/search', params);
}

/**
 * 获取自己的数字人
 */
export function getSelfDigitalMan(data: GetDigitalManParmas) {
  return request.get<CustomListResponse<DigitalManDetail>>('/ip_images', data);
}

/**
 * 获取大头数字人列表
 * */
export function getBigHeadList(data: ListQuery) {
  return request.get<CustomListResponse<DigitalManDetail>>(
    '/clip/head_figure',
    data
  );
}

/**
 * 更新大头数字人名称
 */
export function updateBigHeadName(id: string, name: string) {
  return request.put<CustomListResponse<DigitalManDetail>>(
    `/clip/head_figure/${id}`,
    {
      name,
    }
  );
}

/**
 * 获取配音分类
 */
export function getDubbingCategory() {
  return request.get<CustomListResponse<Category>>('/clip/dubbing/category');
}

/**
 * 获取配音
 */
export function getCategoryDubbingById(data: ListQuery, id?: string) {
  return request.get<CustomListResponse<DubbingDetail>>(
    `/clip/dubbing/category/${id}`,
    data
  );
}

/**
 * 搜索配音
 */
export function getDubbingSearch(params: SearchParams) {
  return request.get<CustomListResponse<DubbingDetail>>(
    '/dubbing/search',
    params
  );
}

export function getMusicCategory() {
  return request.get<CustomListResponse<Category>>('/clip/music/category');
}

/**
 * 获取网络音乐
 * @param params
 * @returns
 */
export function getCategoryMusicById(params?: ListQuery, id?: string) {
  return request.get<CustomListResponse<MusicDetail>>(
    `/clip/music/category/${id}`,
    params
  );
}

/**
 * 获取素材库音乐列表
 */
export function getMaterialMusicList(params?: ListQuery) {
  return request.get<CustomListResponse<MyMusicDetail>>(
    url.materialMusic,
    params
  );
}

/**
 * 获取背景分类
 * @returns
 */
export function getBackgroundCategory() {
  return request.get<CustomListResponse<Category>>(url.backgroundCategory);
}

/**
 * 获取对应分类下面的列表
 * @returns
 */
export function getCategoryBackgroundById(params: ListQuery, id?: string) {
  return request.get<CustomListResponse<BackgroundDetail>>(
    `/clip/background/category/${id}`,
    params
  );
}

/**
 * 获取文字贴图列表
 */
export function getTextStickerList(params: ListQuery) {
  return request.get<CustomListResponse<StickerDetail>>(
    url.textSticker,
    params
  );
}

/**
 * 获取文字贴图详情
 */
export function getTextStickerDetailById(id: string) {
  return request.get<DetailResponse<StickerDetail>>(urlFun.stickerDetail(id));
}

/**
 * 获取文字动画列表
 */
export function getTextAnimationList() {
  return request.get<CustomListResponse<TextAnimationDetail>>(
    url.textAnimation
  );
}

/**
 * 获取贴图分类
 */
export function getStickerCategory() {
  return request.get<CustomListResponse<Category>>(url.stickerClassify);
}

/**
 * 按分类获取贴图列表
 * @param id 分类id
 */
export function getCategoryStickerById(params: ListQuery, id?: string) {
  return request.get<CustomListResponse<StickerDetail>>(
    `/clip/sticker/category/${id}`,
    params
  );
}

/**
 * 获取当前位置
 */
export function getAddress() {
  return request.get<DetailResponse<Address>>('/address');
}

/**
 * 获取数字人推荐配音
 */
export function getDigitalManRecommendDub(params: DigitalManRecommendDubQuery) {
  return request.get<CustomListResponse<DubbingDetail>>(
    url.dubRecommend,
    params
  );
}

/**
 * 添加音频
 * @param data
 * @returns
 */
export function addAudio(param: MaterialReqParams) {
  return request.post(urlFun.docMaterialType('audio'), param);
}

/**
 * 移除文档素材
 * @param params
 * @returns
 */
export function removeDocMaterial(data) {
  return request.delete<DetailResponse<void>>(url.docMaterial, data);
}
