import request from '@/api/init';
import { DetailResponse } from '@/types/api';

import { ConfigApp, ChannelAppConfig } from '@/types/config';

/** 获取客户端配置 */
export function getAppConfig(): Promise<DetailResponse<ConfigApp>> {
  return request.get('/config/app');
}

export function getChannelAppConfig() {
  return request.get<DetailResponse<ChannelAppConfig>>('/channel_app/single');
}
