import { makeAutoObservable } from 'mobx';
import { getDubSpeed } from '@/api/doc';
import { Speed } from '@/types/doc';

export default class SpeedStore {
  public speeds: Speed | null = null;

  constructor() {
    makeAutoObservable(this);
    this.getSpeeds();
  }

  private async getSpeeds() {
    try {
      const { data } = await getDubSpeed();
      this.speeds = data;
    } catch (err) {
      console.log(err);
    }
  }
}
