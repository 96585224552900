import request from '../init';

import { DetailResponse } from '@/types/api';

import {
  CreateVideoRequest,
  IOrder,
  OrderParams,
  PaymentGuidelines,
  PreviewVideoRes,
} from '@/types/cteateVideo';

/**
 * 创建预览视频
 * @param data
 * @returns
 */
export function createPreviewVideo(data: Partial<CreateVideoRequest>) {
  return request.post<DetailResponse<PreviewVideoRes>>(
    '/clip/documents/preview_video',
    data
  );
}

/**
 *  取消渲染视频
 *  错误码:1014-视频生成中，不能取消
 * @param renderTaskId 渲染任务id
 */
export function cancelVideo(data: {
  videoRecordId?: string;
  renderTaskId?: string;
}) {
  return request.post<DetailResponse<void>>('/clip/video/cancel', data);
}

/**
 * 创建视频
 * @param data
 * @returns
 */
export function createDocVideo(data: Partial<CreateVideoRequest>) {
  return request.post<DetailResponse<PaymentGuidelines>>(
    '/clip/documents/video',
    data
  );
}

/**
 * 获取订单信息
 * @param
 */
export function getOrderInfo(data: OrderParams) {
  return request.post<DetailResponse<IOrder>>('/order', data);
}
