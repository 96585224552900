import { TAGS_MAP, TAGS_TYPE } from '@/constants/Tags';

export function getDefaultContent(type: TAGS_TYPE) {
  switch (type) {
    case TAGS_MAP.Captions:
      return '请在文档区编辑您的正文';
    case TAGS_MAP.Title:
      return '请编辑您的标题';
    default:
      return '编辑文字';
  }
}
