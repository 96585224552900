import { RefObject, useEffect } from 'react';
import { observer } from 'mobx-react';
import ContextMenu, {
  ContextMenuProps,
} from '@bhb-frontend/lithe-ui/lib/ContextMenu';
import { getContextSource } from '@/config/stageContextMenu';
import { CANVAS_WRAP_REF, LAYER_LIST_REF } from '@/constants/Refs';
import { Stores, useStores } from '@/store';

type ContextSourceKeys = RefObject<HTMLElement>;
type ContextSourceValues = (store: Stores, e: MouseEvent) => ContextMenuProps;

const contextSourceMap = new Map<ContextSourceKeys, ContextSourceValues>();
contextSourceMap.set(CANVAS_WRAP_REF, getContextSource);
contextSourceMap.set(LAYER_LIST_REF, getContextSource);

function ContextMenuManager() {
  const store = useStores();

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    const node = e.target as HTMLElement;
    let options: ContextMenuProps | undefined;
    for (const ref of contextSourceMap.keys()) {
      if (ref.current?.contains(node)) {
        options = contextSourceMap.get(ref)?.(store, e);
        break;
      }
    }
    options && ContextMenu.show(options);
  };

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu, false);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  });

  return null;
}

export default observer(ContextMenuManager);
