import { usagetoTagMap, usagetoTypeMap } from '@/constants/formatData';
import { Address } from '@/types/doc-materials';
import {
  Footage,
  Image,
  Pattern,
  PatternStyle,
  Resources,
  VectorObj,
} from '@/types/ServerData';

/** usage 转换 Tag  */
export function transformUsageToTag(usage: number) {
  return usagetoTagMap[usage];
}

/** usage 转换 Type  */
export function transformUsageToType(usage: number) {
  return usagetoTypeMap[usage];
}

/** 向量转换对象 */
export function transformVectorToObj(vector: number[] = [0, 0, 0]) {
  const [x, y, z] = vector;
  return {
    x,
    y,
    z,
  };
}

/** 对象 转向量转*/
export function transformObjToVector(obj: VectorObj) {
  const { x, y, z } = obj;
  return [x, y, z];
}

const resourceKeys = ['images', 'videos', 'texts'];
export function getFootageMap(footage: Footage, keys: string[] = resourceKeys) {
  const footageMap: Record<string, Resources> = {};
  keys.forEach(key => {
    const resource = footage[key];
    resource.forEach((item: Resources) => {
      footageMap[item.objectId] = item;
    });
  });
  return footageMap;
}

export function getTextStickerImgMap(footage: Footage) {
  const images = footage.images;
  const imagesMap: Record<string, Image> = {};
  images.forEach(item => {
    const { objectId } = item;
    if (!objectId) return;
    imagesMap[objectId] = item;
  });
  return imagesMap;
}

/**
 * 格式化地址
 */
export function formatAddress(address: Address, format: string) {
  format = format || 'prov·city';
  switch (format) {
    case 'prov·city':
      return `${address.regionName}·${address.cityName}`;
    case 'province':
    case 'prov':
      return address.regionName;
    case 'city':
      return address.cityName;
    default:
      return '';
  }
}

/** 生成文字填充配置 */
export function transformPatternsToTextFill(
  patterns: Pattern[],
  textBackId?: string
): ComponentModel.Fill {
  const fillConfig: ComponentModel.Fill = {};
  const text = patterns.find(item => item.style === PatternStyle.text);
  const stroke = patterns.find(item => item.style === PatternStyle.stroke);
  const background = patterns.find(item => item.style === PatternStyle.bgColor);
  const image = patterns.find(item => item.style === PatternStyle.image);
  if (text) {
    // 文字填充
    Object.assign(fillConfig, {
      color: text.color,
      alpha: text.alpha,
    });
  }

  if (stroke) {
    // 描边
    Object.assign(fillConfig, {
      strokeColor: stroke.color,
      strokeAlpha: stroke.alpha,
      strokeWidth: stroke.width,
    });
  }

  if (background) {
    // 背景颜色填充
    Object.assign(fillConfig, {
      backgroundColor: background.color,
      backgroundAlpha: background.alpha,
    });
  }

  if (image) {
    // 文字填充
    Object.assign(fillConfig, {
      imageWidth: image.width,
      imageHeight: image.height,
      imageId: textBackId || image.sourceId,
      imageName: image.fileName,
      imageNinePath: image.ninePatch,
    });
  }

  return fillConfig;
}

/** 文字填充配置转为 patterns*/
export function transformTextFillToPatterns(
  textFill: ComponentModel.Fill
): Pattern[] {
  const {
    color,
    alpha,
    strokeColor,
    strokeAlpha,
    strokeWidth,
    backgroundColor,
    backgroundAlpha = 100,
    imageWidth,
    imageHeight,
    imageId,
    imageName,
    imageNinePath,
  } = textFill;
  const pattern: Pattern[] = [];
  if (color) {
    pattern.push({
      style: 0,
      alpha,
      color,
    });
  }
  if (strokeColor) {
    pattern.push({
      style: 1,
      alpha: strokeAlpha,
      width: strokeWidth,
      color: strokeColor,
    });
  }
  if (backgroundColor) {
    pattern.push({
      style: 6,
      alpha: backgroundAlpha,
      color: backgroundColor,
    });
  }
  if (imageId) {
    pattern.push({
      style: 8,
      width: imageWidth,
      height: imageHeight,
      sourceId: imageId,
      fileName: imageName,
      ninePatch: imageNinePath,
    });
  }
  return pattern;
}

/** px to number  */
export function pxToNumber(val: number | string): number {
  if (typeof val === 'number') return val;
  if (!val.includes('px')) return Number(val);
  return Number(val.substring(0, val.length - 2));
}

/**
 *  转换锚点
 * @param anchor 锚点
 * @param size 大小
 * @param isPxToPercentage 是否像素转百分比
 * @returns
 */
export function transformAnchor(
  anchor: Anchor,
  size: Size,
  isPxToPercentage = true
) {
  const { x, y, z } = anchor;
  const { width, height } = size;
  if (isPxToPercentage) {
    return { x: x / width, y: y / height, z };
  }
  return { x: x * width, y: y * height, z };
}
