export function makeImage(
  url: string,
  width?: number,
  height?: number
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image(width, height);
    image.crossOrigin = 'anonymous';
    image.src = url;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      reject(new Error('制作图片失败'));
    };
  });
}

/**
 * 解析图片信息
 */
export function getImageInfo(url: string) {
  if (!url)
    return {
      imageUrl: '',
      imageKey: '',
      fileName: '',
    };
  const fileName = url.split('?')[0]?.split('/').pop() || url;
  let imageKey = url;
  const startIdx = url.slice(8).indexOf('/');
  if (startIdx > -1) {
    imageKey = url.slice(startIdx + 9);
  }
  return {
    imageUrl: url,
    imageKey,
    fileName,
  };
}

/**
 * 获取图片key
 *  */
export function getImageKey(url: string) {
  return url.substring(url.indexOf('/', 9) + 1);
}

interface ScaleOption {
  /** 限制最大宽 限制最大高 限制最小宽 限制最小高 */
  type: 'max' | 'min';
  width?: number;
  height?: number;
}

/**
 * 根据最大或最小的宽度，将图片原始宽高等比例缩放
 * @param originWidth 原始宽
 * @param originHeight 原始高
 * @param scaleOptions 限制参数
 * @returns 返回限制参数下的 宽高值
 */
export function calcImgRatioScaleInfo(
  /** 原始宽 */
  originWidth: number,
  /** 原始高 */
  originHeight: number,
  scaleOptions: [ScaleOption] | [ScaleOption, ScaleOption]
) {
  const res = {
    width: originWidth,
    height: originHeight,
  };

  /** 图片比例 */
  const ratio = originWidth / originHeight;
  for (let i = 0; i < scaleOptions.length; i += 1) {
    const { type, width = res.width, height = res.height } = scaleOptions[i];

    /** 宽度是否需要处理 */
    const widthJudge = type === 'max' ? res.width > width : res.width < width;
    /** 高度是否需要处理 */
    const heightJudge =
      type === 'max' ? res.height > height : res.height < height;

    if (widthJudge) {
      res.width = width;
      res.height = Math.floor(res.width / ratio);
    }
    if (heightJudge) {
      res.height = height;
      res.width = Math.floor(res.height * ratio);
    }
  }

  return {
    ...res,
  };
}

/**
 * 等比压缩图片
 * @param img 图片
 * @param width 新的高
 * @param height 新的高
 * @param cb 回调，参数 blob
 * @param imgType 图片类型
 * @param quality 清晰度
 */
export function compressImg(
  img: HTMLImageElement,
  width: number,
  height: number,
  cb: (blob: Blob) => void,
  imgType = 'image/jpeg',
  quality = 1
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx?.drawImage(img, 0, 0, width, height);
  canvas.toBlob(
    blob => {
      blob && cb(blob);
    },
    imgType,
    quality
  );
}
