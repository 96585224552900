import { LoadMore, Toast } from '@bhb-frontend/lithe-ui';
import { useEffect, useState, useRef } from 'react';
import AudioPlayer, { AudioPlayerType } from '@bhb-frontend/audio-player';
import { DubbingDetail } from '@/types/material';
import DubbingListItem from './DubbingListItem';
import Style from './style.module.less';
import Confirm from '@/components/Confirm';
import { app } from '@/store';
import { EDubSpeed } from '@/types/dub';
import { CmpTypeEnum } from '@/constants/CmpType';

interface DubbingListProps {
  /** 资源列表 */
  list?: DubbingDetail[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;

  isSearch?: boolean;
}

function DubbingList(props: DubbingListProps) {
  const { list, loading, hasMore, isSearch = false, loadMore } = props;

  const [playingUrl, setPlayingUrl] = useState('');

  const [data, setData] = useState<DubbingDetail[]>([]);

  const [tipVisible, setTipVisible] = useState(false);

  const audioPlayer = useRef(AudioPlayer.getInstance());

  const dubbingDetail = useRef<DubbingDetail>();

  const { activedScene } = app;
  const { sound } = activedScene;

  useEffect(() => {
    setData(list?.map(item => ({ ...item, musicPlaying: false })) || []);
  }, [list]);

  useEffect(() => {
    const eventCB = (isPlaying: boolean) => {
      /** 暂停 */
      if (!isPlaying) setPlayingUrl('');
    };

    audioPlayer.current.onPlayEvent(AudioPlayerType.DUBBING_SOURCE, eventCB);
    return () => {
      audioPlayer.current.offPlayEvent(AudioPlayerType.DUBBING_SOURCE, eventCB);
    };
  }, []);

  // 切换配音时
  const switchMusic = (item: DubbingDetail) => {
    const url = item.exampleUrl || item.materialUrl;
    if (playingUrl === url) {
      audioPlayer.current.pause();
      setPlayingUrl('');
      return;
    }
    setPlayingUrl(url);
    handleAudioPlayer(AudioPlayerType.DUBBING_SOURCE, url);
  };

  const handleSelect = (item: DubbingDetail) => {
    const { coverUrl, exampleUrl, id, name, tipText } = item;

    if (sound?.speed === EDubSpeed.ultraQuick) {
      Toast.warning('超快速度不支持配音');
      return;
    }

    if (sound.tag !== 'dubbing') {
      setTipVisible(true);
      dubbingDetail.current = item;
      return;
    }
    if (sound?.id === id) return;

    if (tipText) Toast.success(tipText);

    activedScene.setSound({
      id,
      cover: coverUrl,
      url: exampleUrl,
      name,
    });
    handleAudioPlayer(AudioPlayerType.DUBBING, exampleUrl);
  };

  const handleAudioPlayer = (type: string, url: string) => {
    audioPlayer.current.play(type, url);
    app.setActiveMediaCmp(CmpTypeEnum.UNKNOWN);
    audioPlayer.current.setVolume(1);
  };

  const getPlaying = (item: DubbingDetail) => {
    if (!item.exampleUrl && !item.materialUrl) return false; // 没有音频默认暂停
    if (item.exampleUrl || item.materialUrl) {
      return [item.exampleUrl, item.materialUrl].includes(playingUrl);
    }
    return false;
  };

  return (
    <div className={`${Style.dubbingContent} ${isSearch && Style.search}`}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        className={Style['load-more']}
        finishedText=""
      >
        {data.map(item => (
          <DubbingListItem
            item={item}
            key={item.id}
            isPlaying={getPlaying(item)}
            switchMusic={switchMusic}
            onSelect={handleSelect}
          />
        ))}
        <Confirm
          visible={tipVisible}
          title="确定使用配音吗？"
          content="使用文字转语音将会替换现有音频"
          onOk={() => {
            const { coverUrl, exampleUrl, id, name } =
              dubbingDetail.current as DubbingDetail;
            activedScene.setSound({
              id,
              cover: coverUrl,
              url: exampleUrl,
              name,
              tag: 'dubbing',
            });
            audioPlayer.current.play(AudioPlayerType.DUBBING, exampleUrl);

            setTipVisible(false);
          }}
          onCancel={() => {
            setTipVisible(false);
          }}
        />
      </LoadMore>
    </div>
  );
}

export default DubbingList;
