/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/2af09e473705fcee8aeb7a7112539383.png */
  'card-dingyueship.png': '2af09e473705fcee8aeb7a7112539383.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2ee5a2e2c648926c10f6a3b8d50d0095.png */
  'card-miaotui.png': '2ee5a2e2c648926c10f6a3b8d50d0095.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/45a340dd99d318cf6f8791d33a436497.png */
  'card-tuwenkuaijian.png': '45a340dd99d318cf6f8791d33a436497.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/bd27ef386547f667d99752b0910ddd7d.png */
  'card-zhibokuaijian.png': 'bd27ef386547f667d99752b0910ddd7d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/487e192cbd2c7e05930d36af571937a2.png */
  'clip_vip_no.png': '487e192cbd2c7e05930d36af571937a2.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/1f0c47948e59fbc7d46c79e446a4c2c2.png */
  'clip_vip_no2.png': '1f0c47948e59fbc7d46c79e446a4c2c2.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/f3b4bb1541ee7c06e2a2c34dd928f198.png */
  'clip_vip_yes.png': 'f3b4bb1541ee7c06e2a2c34dd928f198.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/fe5a0ff18ea6897690ff98fcaf1f6fbe.png */
  'clip_vip_yes2.png': 'fe5a0ff18ea6897690ff98fcaf1f6fbe.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e32a7feaeb6fa7b728ccb2671278403f.png */
  'doc_vip_no.png': 'e32a7feaeb6fa7b728ccb2671278403f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/703b71268a03a217696acad44dd87fca.png */
  'doc_vip_no2.png': '703b71268a03a217696acad44dd87fca.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a9c6dbd971c867b90bc6e5c037cae06c.png */
  'doc_vip_yes.png': 'a9c6dbd971c867b90bc6e5c037cae06c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/90f869a94a864985bef38311328a3cd1.png */
  'doc_vip_yes2.png': '90f869a94a864985bef38311328a3cd1.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/249040749f77f15fc24c4b2744cea662.jpg */
  'gongzhonghao.jpg': '249040749f77f15fc24c4b2744cea662.jpg',

  /* https://bhb-frontend.bhbcdn.com/static/files/11551c113ee7dd1c457911cc770ec620.png */
  'img-tuwen-time.png': '11551c113ee7dd1c457911cc770ec620.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d6383c8131b941b1fac1078b3e4709fd.webp */
  'pro-gif.webp': 'd6383c8131b941b1fac1078b3e4709fd.webp',

  /* https://bhb-frontend.bhbcdn.com/static/files/c22229b001cc2a107dac2ffb6dc9d36a.png */
  'svip_bg.png': 'c22229b001cc2a107dac2ffb6dc9d36a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/224631442b8297a5266e8741b74f9392.png */
  'time-bg.png': '224631442b8297a5266e8741b74f9392.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/aa427a97e24c9a357a9db31407d8a9ca.png */
  'vip_bg.png': 'aa427a97e24c9a357a9db31407d8a9ca.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/53d4681681a57ae1638e080695726b9d.png */
  'vip_tab_bg.png': '53d4681681a57ae1638e080695726b9d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/7a1e4790dca9aa37097acbc465b02a70.png */
  'yuegongwang.png': '7a1e4790dca9aa37097acbc465b02a70.png',
};
