export const SOCKET_EVENTS = {
  /**
   * 抠像结果
   */
  IMAGE_SEGMENT_RESULT: 'imageSegmentResult',
  /**
   * 支付结果
   */
  PAY_RESULT: 'pay.result',
  /**
   * 生成视频成功
   */
  CREATE_VIDEO_SUCCESS: 'createVideoSuccess',
  /**
   * 生成视频失败
   */
  CREATE_VIDEO_FAILED: 'createVideoFailed',
  /**
   * 生成视频状态变更
   */
  VIDEO_STATUS_CHANGE: 'videoStatusChange',
  /**
   * 短视频发布结果
   */
  VIDEO_PUBLISH_RESULT: 'video.publish.result',
  /**
   * 提取视频/音频文字成功消息
   */
  ASR_SUCCESS: 'asrSuccess',
  /**
   * 提取视频/音频文字失败消息
   */
  ASR_FAILED: 'asrFailed',
  LIVE_STATUS_CHANGE: 'live.status.change',
  LIVE_DURATION_TIPS: 'live.duration.tips',
  LIVE_RECORD_UPDATE: 'live.record.update',
  SHORT_VIDEO_MONTAGE_INFO: 'shortVideo.montageInfo',
  /**
   * 文档保存成功
   */
  SAVE_DOCUMENTS_SUCCESS: 'saveDocumentsSuccess',
  /**
   * 保存文档失败
   */
  SAVE_DOCUMENTS_FAILED: 'saveDocumentsFailed',
  PARAMS_ERROR: 'paramsError',
  /**
   * 保存主题成功
   */
  SAVE_DOCUMENTS_THEME_SUCCESS: 'saveDocumentsThemeSuccess',
  /**
   * 保存主题失败
   */
  SAVE_DOCUMENTS_THEME_FAILED: 'saveDocumentsThemeFailed',

  /** 保存大头数字人 */
  SAVE_HEAD_FIGURE: 'saveHeadFigure',

  /** 保存大头数字人返回事件 */
  SAVE_HEAD_FIGURE_RESULT: 'saveHeadFigureResult',

  /** 特效任务结果 */
  EFFECT_TASK_RESULT: 'effectTaskResult',
};
