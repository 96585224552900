import { Percent } from '@/types/material';

export const PERCENT_TYPES = [
  {
    label: '竖版',
    key: Percent.Vertical,
  },
  {
    label: '横版',
    key: Percent.Horizontal,
  },
];
