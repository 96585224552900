import { action, makeObservable, observable, computed, observe } from 'mobx';
import { localUniqueid } from '@bhb-frontend/utils/lib/random';
import { Toast } from '@bhb-frontend/lithe-ui';
import CreateCmpStruc from '../FactoryStruc/CmpFactory';
import CmpStruc, { ImageStruc, TextStruc } from '../CmpStruc';
import { createScene } from '../FactoryStruc/SceneFactory';
// import { Material } from '@/types/doc-materials';
import {
  getIpProfileDetail,
  getPresetIpDetail,
  getThemeDetail,
} from '@/api/app';
import { buildCmps } from '@/core/FormatData';
import {
  DigitalManDetail,
  BackgroundDetail,
  StickerDetail,
} from '@/types/material';
import { DigitaiManType } from '@/types/DigitaiMan';
import { app, appConfig } from '@/store';
import { getImageInfo } from '@/utils/image';
import {
  createImage,
  createSticker,
  createTextSticker,
  createCmp,
} from '@/core/FormatData/Cmps/Cmp';
import { BackgroundType } from '@/constants/BackgroundType';
import {
  FontStyle,
  Style,
  TextDetail,
  ThemeDetailItem,
} from '@/types/ServerData';
import { ExtraType } from '@/constants/Extra';
import assets from '@/assets/images';
import AudioStruc from '../CmpStruc/Audio';
import { getFileNameFromPath } from '@/utils/file';
import socket from '@/core/socket';
import { SOCKET_EMIT } from '@/constants/Socket';
import { DEFAULT_CONFIGURE, getConfigure } from '@/constants/SceneSize';

export default class SceneStruc implements SceneModel {
  cmps: CmpStruc[] = [];

  configure: Configure = {
    ...DEFAULT_CONFIGURE,
  };

  id!: string;

  name!: string;

  cover?: string;

  actived?: boolean;

  /**  源主题id */
  originalThemeId = '';

  /** 文档相关 */
  allTexts: AllTexts[] = [];

  captions: any[] = [];

  /** 配音 | 录音 */
  sound: AudioStruc;

  /** 数字人相关配置 三选一 */
  /** 数字人 */
  presetIpImageId = '';

  /** ip形象 */
  ipImageId = '';

  /** 大头数字人 */
  avatarFigureId = '';

  /** 文档素材 */
  materials: Material[] = [];

  orientation: 'vertical' | 'horizontal' = 'vertical';

  /** 数字人详情 */
  digitalManDetail?: DigitalManDetail;

  /** 拓展字段， 转换没用的数据存放在此 */
  extensionFields!: Record<string, any>;

  /** 主题拓展字段 */
  extensionTheme!: Partial<ThemeDetailItem>;

  constructor(data?: Partial<SceneModel> | null) {
    makeObservable<this, 'handleUpdate'>(this, {
      cmps: observable,
      configure: observable,
      id: observable,
      name: observable,
      actived: observable,
      cover: observable,
      allTexts: observable,
      materials: observable,
      orientation: observable,
      presetIpImageId: observable,
      ipImageId: observable,
      digitalManDetail: observable,
      captions: observable,
      sound: observable,
      titleCmp: computed,
      captionsCmp: computed,
      hasDigitalMan: computed,
      imageCmp: computed,
      isHaveDigitalMan: computed,
      update: action,
      handleUpdate: action,
      clone: action,
      addCmp: action,
      removeCmp: action,
      setDigitaiMan: action,
    });

    this.configure = getConfigure(data?.configure);
    this.cover = data?.cover || '';
    this.actived = !!data?.actived;

    this.id = data?.id || localUniqueid();
    this.name = data?.name || '';
    this.originalThemeId = data?.originalThemeId || '';
    this.ipImageId = data?.ipImageId || '';
    this.presetIpImageId = data?.presetIpImageId || '';
    this.avatarFigureId = data?.avatarFigureId || '';
    this.materials = data?.materials || [];
    this.allTexts = data?.allTexts || [];
    this.captions = data?.captions || [];
    this.orientation = data?.orientation || 'vertical';
    this.extensionFields = data?.extensionFields || {};
    this.extensionTheme = data?.extensionTheme || {};

    this.sound = new AudioStruc(data?.sound || { tag: 'dubbing' });

    this.cmps = (data?.cmps || [])
      .filter(cmp => !!cmp.type)
      .map(cmp => CreateCmpStruc(cmp.type, cmp, this));

    // 登录之后 再开始请求接口
    observe(this, 'actived', this.activedInit, false);

    // 素材修改需要修改插图图层显示图片
    observe(this, 'materials', this.updatedImageCmp, false);

    // 数字人修改修改数字人详情
    observe(this, 'presetIpImageId', () => this.handleDigitalMan(false), false);

    // 定制数字人修改修改数字人详情
    observe(this, 'ipImageId', () => this.handleDigitalMan(true), false);
  }

  model(): SceneModel {
    return {
      cmps: this.cmps,
      configure: this.configure,
      id: this.id,
      name: this.name,
      actived: this.actived,
      cover: this.cover,
      originalThemeId: this.originalThemeId,
      ipImageId: this.ipImageId,
      presetIpImageId: this.presetIpImageId,
      avatarFigureId: this.avatarFigureId,
      materials: this.getMaterials(),
      allTexts: this.allTexts,
      captions: this.captions,
      orientation: this.orientation,
      sound: this.sound,
      extensionFields: this.extensionFields,
      extensionTheme: this.extensionTheme,
    };
  }

  /** 获取字幕 */
  public get getAllText(): string {
    return this.allTexts.map(item => item.text).join('');
  }

  /**
   * 标题组件
   */
  get titleCmp() {
    return this.cmps.find((cmp: CmpStruc) => cmp.isTitle);
  }

  /**
   * 字幕组件
   */
  get captionsCmp() {
    return this.cmps.find((cmp: CmpStruc) => cmp.isCaptions);
  }

  /**
   * 插图组件
   */
  get imageCmp() {
    return this.cmps.find((cmp: CmpStruc) => cmp.isImage);
  }

  /**
   * 是否存在数字人
   */
  get hasDigitalMan() {
    return this.cmps.some((cmp: CmpStruc) => {
      if (cmp.isSticker) {
        // console.log('cmp', cmp, (cmp as ImageStruc).extra?.scene?.type);
        return (cmp as ImageStruc).extra?.scene?.type === ExtraType.DIGITAL_MAN;
      }
      return false;
    });
  }

  /** 是否存在数字人 */
  get isHaveDigitalMan() {
    const { presetIpImageId, ipImageId } = this;
    return !!(presetIpImageId || ipImageId);
  }

  getMaterials() {
    return this.materials.filter(item => !item.placeholder);
  }

  /** 激活初始化：配音, 数字人 详情获取 */
  activedInit = change => {
    const { newValue } = change;
    if (!newValue) return;
    this.activedInitDub();
    this.activedInitDigitalMan();
  };

  /** 激活初始化配音 */
  activedInitDub() {
    this.sound?.initDub();
  }

  /** 激活初始化数字人 */
  activedInitDigitalMan() {
    const { digitalManDetail, presetIpImageId, ipImageId } = this;
    if (digitalManDetail) return;
    if (!presetIpImageId && !ipImageId) return;
    this.getDigitalMan(!!ipImageId);
  }

  /** 修改插图 图层 图片 */
  private updatedImageCmp = () => {
    const imageCmp = this.imageCmp as ImageStruc;
    if (!imageCmp) return;
    const {
      source = 'network',
      type = 'image',
      url = assets.material['empty.png'],
      cover,
    } = this.materials[0] || {};
    imageCmp.url = type === 'image' ? url : cover;
    imageCmp.fileName = type;
    imageCmp.extra = {
      ...imageCmp.extra,
      source,
    };
  };

  /**
   * 局部更新场景数据
   * @param data 当前场景数据
   */
  update(data: Partial<SceneModel>) {
    // 是否才分
    let isSplit = false;
    if (Reflect.has(data, 'allTexts')) {
      isSplit = true;
    }
    this.handleUpdate(data, isSplit);
  }

  /**
   * 复制一个场景
   * @param isRetainId 是否保持id不变
   */
  clone(isRetainId?: boolean) {
    const model = this.model();
    if (!isRetainId) {
      model.id = localUniqueid();
      model.name = this.getName();
    }
    return createScene(model);
  }

  addCmp(cmp: CmpStruc, index?: number) {
    index ? this.cmps.splice(index, 0, cmp) : this.cmps.push(cmp);
    app.save();
  }

  getName() {
    return this.name;
  }

  protected handleUpdate(data: Partial<SceneModel>, isSplit = false) {
    for (const key in data) {
      this[key] = data[key];
    }
    // 如果需要拆分，先拆分结束再保存
    if (isSplit) {
      app.splitSave();
      return;
    }
    app.save();
  }

  /** 文档区是否存在缩略图 */
  getIsPlaceholder() {
    return this.materials.findIndex(material => material.placeholder) !== -1;
  }

  /**
   * 删除组件
   * @param cmp 选中的组件
   */
  removeCmp(cmp: CmpStruc) {
    if (cmp.tag === 'Sticker') {
      // 数字人的特殊处理， 属于贴纸
      if ((cmp as ImageStruc).extra?.scene?.type === ExtraType.DIGITAL_MAN) {
        this.presetIpImageId = '';
        this.ipImageId = '';
        this.avatarFigureId = '';
      }
    }
    const index = this.getCmpIndex(cmp);
    if (index < 0) return;
    app.removeActivedCmp(cmp);
    this.cmps.splice(index, 1);
    cmp.scene = null;
    app.save();
  }

  /**
   * 获取组件的下标
   * @param cmp 选中的组件
   * @returns {number} 组件的位置
   */
  getCmpIndex(cmp: CmpStruc): number {
    return this.cmps.findIndex(item => item.id === cmp.id);
  }

  /**
   * 通过id集合获取组件
   * @param ids 组件id集合
   * @returns {CmpStruc[]}
   */
  getCmpsById(ids: string[]): CmpStruc[] {
    if (!ids.length) return [];
    return this.cmps.filter(cmp => ids.includes(cmp.id));
  }

  /** 替换场景主题 */
  public async replaceTheme(themeId: string) {
    const { data: themeInfo } = await getThemeDetail(themeId);
    const {
      id,
      coverUrl,
      configure,
      orientation,
      presetIpImageId,
      ipImageId,
      avatarFigureId,
      ...extensionTheme
    } = themeInfo;
    /** 数字人置位空, 自动清空相关数字人信息 */
    this.presetIpImageId = '';
    socket.emit({
      event: SOCKET_EMIT.CLICK_THEME,
      data: {
        documentId: app.id,
        themeId,
      },
    });
    const materials = this.materials;
    // 只需生成 cmps 即可
    const cmps = buildCmps({
      themeInfo,
      materials,
    });
    const newCmps = cmps.map(cmp => CreateCmpStruc(cmp.type, cmp, this));
    this.replaceFileds({
      originalThemeId: id,
      cover: coverUrl,
      configure,
      orientation,
      presetIpImageId,
      ipImageId,
      avatarFigureId,
      extensionTheme,
      cmps: newCmps,
    });

    app.splitSave();
  }

  /** 替换场景主题信息,不需要保存 */
  public replaceFileds(fileds) {
    for (const key in fileds) {
      this[key] = fileds[key];
    }
  }

  /** 初始化数字人 */
  handleDigitalMan = async (isIp = false) => {
    const detail = await this.getDigitalMan(isIp);
    if (detail) {
      this.DigitalManUpdateDub(detail);
    }
  };

  /** 获取数字人详情 */
  getDigitalMan = async (isIp = false) => {
    const { presetIpImageId, ipImageId } = this;
    if (!presetIpImageId && !ipImageId) {
      // 清空数字人详情
      this.digitalManDetail = undefined;
    }
    if (!presetIpImageId && !ipImageId) return false;
    const res = await this.getDigitalManApi(
      isIp ? ipImageId : presetIpImageId,
      isIp
    );
    return res;
  };

  /** 获取数字人详情接口 */
  async getDigitalManApi(id, isIp = false) {
    if (!id) return false;

    const api = isIp ? getIpProfileDetail : getPresetIpDetail;
    const res = await api(id);
    const detail = res.data;
    this.digitalManDetail = detail;
    this.updateDManLayers();
    this.sound.digitalManHandleSpeed();
    return detail;
  }

  /** 修改数字人图层数据 */
  updateDManLayers() {
    if (!this.digitalManDetail) return;
    const { avatar = '', previewImageUrl = '' } = this.digitalManDetail;
    const imageCmps = this.cmps.filter(
      cmp => cmp.type === 'Image'
    ) as ImageStruc[];
    const imageCmp = imageCmps.find(
      cmp => cmp.extra.scene?.type === ExtraType.DIGITAL_MAN
    );
    const { imageUrl, imageKey, fileName } = getImageInfo(
      avatar || previewImageUrl
    );
    imageCmp?.update({ url: imageUrl, key: imageKey, fileName });
  }

  /** 数字人详情修改配音 */
  DigitalManUpdateDub(item: DigitalManDetail) {
    const { dubbingInfo } = item;
    // 如果有配音，将不在替换配音
    if (dubbingInfo && this.sound.tag !== 'record') {
      const { coverUrl, exampleUrl, name, id } = dubbingInfo;
      Toast.warning('为了视频效果，已切换为数字人推荐配音');
      this.sound.initUpdate({
        id,
        cover: coverUrl,
        url: exampleUrl,
        name,
      });
    }
  }

  /** 设置数字人 */
  public setDigitaiMan(
    digitalMan: DigitalManDetail,
    typeId: Partial<Record<DigitaiManType, string>>
  ) {
    const { avatar, previewImageUrl } = digitalMan;
    const idObj = {
      ipImageId: '',
      presetIpImageId: '',
      avatarFigureId: '',
      ...typeId,
    };
    this.replaceFileds(idObj);
    const { imageUrl, imageKey, fileName } = getImageInfo(
      avatar || previewImageUrl
    );
    // 当前数字人cmp
    const digitaiManCmp = this.cmps.find(
      cmp => (cmp as ImageStruc).extra?.scene?.type === ExtraType.DIGITAL_MAN
    ) as ImageStruc;
    if (digitaiManCmp) {
      // 如果存在数字人图层，仅替换数据即可
      digitaiManCmp.update({ url: imageUrl, key: imageKey, fileName });
      app.activeCmp(digitaiManCmp);
      return;
    }
    // 新增
    const imageDefaultConfig =
      this.orientation === 'vertical'
        ? appConfig.IpImageFormats
        : appConfig.IpImageFormatsLandscapeScreen;
    if (!imageDefaultConfig) return;
    /** 从默认数据中解析所需要的图层信息和资源信息 */
    const { layerInfo, footageInfo } = imageDefaultConfig.avatar;
    const layer = {
      ...layerInfo,
    };
    const footage = {
      ...footageInfo,
      fileName,
      imageKey,
      imageUrl,
    };
    this.sound.digitalManHandleSpeed();
    const image = createImage(layer, footage);
    this.addCmpStruc(image);
  }

  /** 设置背景 */
  public setBackground(
    type: BackgroundType,
    backItem?: BackgroundDetail,
    color?: string,
    source: 'network' | 'material' = 'network'
  ) {
    const { backgroundKey, backgroundUrl, id = '', coverUrl } = backItem || {};
    this.extensionTheme.backgroundId = id;
    const backCmp = this.cmps.find(cmp => cmp.isBackground);
    const fileName = getFileNameFromPath(backgroundKey);
    const backOpt = {
      url: '',
      fileName,
      key: '',
      extra: undefined,
      fillType: type,
      color: '',
    };
    const commonOptions = {
      url: backgroundUrl,
      id,
      key: backgroundKey,
    };
    switch (type) {
      case BackgroundType.IMAGE:
        Object.assign(backOpt, {
          ...commonOptions,
          extra: {
            source,
          },
        });
        break;

      case BackgroundType.VIDEO:
        Object.assign(backOpt, {
          ...commonOptions,
          extra: {
            cover: coverUrl,
          },
        });
        break;

      default:
        Object.assign(backOpt, {
          ...commonOptions,
          color,
        });
        break;
    }
    backCmp?.update(backOpt);
    app.save();
  }

  /** 新增文字贴纸 */
  public addTextSticker(param: {
    textConfig: Partial<StickerDetail>;
    source: 'network' | 'material';
    optional?: TextDetail & FontStyle;
    text?: string;
  }) {
    const textSticker = createTextSticker({
      ...param,
      configure: this.configure,
    });
    this.addCmpStruc(textSticker);
  }

  /** 新增贴纸 */
  public addSticker(param: {
    stickerConfig: Partial<StickerDetail>;
    source: 'network' | 'material';
  }) {
    const sticker = createSticker({
      ...param,
      configure: this.configure,
    });
    this.addCmpStruc(sticker);
  }

  /** 新增 cmp */
  private addCmpStruc(modal: ComponentModel.Base | false) {
    if (modal === false) return;
    const cmp = CreateCmpStruc(modal.type, modal, this);
    this.addCmp(cmp);
    app.activeCmp(cmp);
  }

  /** 新增插图图层 */
  public addFigure() {
    const defaultFormats =
      this.orientation === 'vertical'
        ? appConfig.defaultFormats
        : appConfig.defaultFormatsLandscapeScreen;
    if (!defaultFormats) return;
    const { footageInfo, layerInfo } = defaultFormats.figure;
    const layer = {
      ...layerInfo,
    };
    const footage = {
      ...footageInfo,
    };
    const image = createImage(layer, footage, this.materials);
    this.addCmpStruc(image);
  }

  /** 新增文字图层 标题、正文 */
  private addTextLayer(key: 'title' | 'caption') {
    const defaultFormats =
      this.orientation === 'vertical'
        ? appConfig.defaultFormats
        : appConfig.defaultFormatsLandscapeScreen;
    if (!defaultFormats) return;
    const { footageInfo, layerInfo, styleInfo } = defaultFormats[key];
    const layer = {
      ...layerInfo,
    };
    const footage = {
      ...footageInfo,
    };
    const style = {
      ...styleInfo,
      styleId: styleInfo.objectId,
    };

    const resources = {
      [layer.sourceId]: footage,
    } as Record<string, TextDetail>;

    const optionalFields = {
      [footage.styleId]: style,
    } as Record<string, Style>;

    const text = createCmp({
      layer,
      resources,
      themeInfo: {
        optionalFields,
      } as ThemeDetailItem,
    });
    this.addCmpStruc(text);
  }

  /** 新增标题图层 */
  public addTitle() {
    this.addTextLayer('title');
  }

  /** 新增字幕图层 */
  public addCaption() {
    this.addTextLayer('caption');
    const captionsCmp = this.captionsCmp as TextStruc;
    if (!this.captions.length) return;
    captionsCmp?.setCaptionsText(this.captions.map(item => item.texts));
  }

  /** 设置声音 */
  public setSound(val: Partial<Audio>) {
    if (!(this.sound instanceof AudioStruc)) {
      this.update({
        sound: new AudioStruc({
          tag: 'dubbing',
          ...val,
        }),
      });
      return;
    }
    this.sound.update({
      id: '',
      ...val,
    });
  }

  /** 删除声音 */
  public removeSound() {
    // 默认配音模式
    this.sound?.clean({
      tag: 'dubbing',
    });
  }
}
