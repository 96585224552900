import { POINT_TYPE } from '@bhb-frontend/toolbox';

export interface Box {
  width: number;
  height: number;
}
export interface BoxPosition extends Box {
  x: number;
  y: number;
}
export interface Position {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

/** 图片wrapper 宽高初始化 */
export function initImg(
  width: number,
  height: number,
  boxWidth: number,
  boxHeight: number
) {
  // let width = 0;
  // let height = 0;

  if (width < boxWidth && height < boxHeight) {
    // 图片小于画布尺寸，则居中显示
    // width = width;
    // height = height;
  } else {
    // 图片大于画布尺寸，则居中显示
    const whRatio = width / height;

    // 竖屏
    if (width < height) {
      //  显示 图片的尺寸
      width = boxWidth;
      height = boxWidth / whRatio;
      if (height > boxHeight) {
        height = boxHeight;
        width = boxHeight * whRatio;
      }
    } else {
      // 横屏
      height = boxHeight;
      width = boxHeight * whRatio;
      if (width > boxWidth) {
        width = boxWidth;
        height = boxWidth / whRatio;
      }
    }
  }
  return { width, height };
}

/** 裁剪框尺寸初始化 */
export function initCropSize(
  defaultWidth: number,
  defaultHeight: number,
  imgWidth: number,
  imgHeight: number
) {
  let width = 0;
  let height = 0;
  const cropRatio = defaultWidth / defaultHeight;

  if (defaultWidth > imgWidth) {
    width = imgWidth;
    height = imgWidth / cropRatio;
    if (height > imgHeight) {
      height = imgHeight;
      width = imgHeight * cropRatio;
    }
  } else {
    width = defaultWidth;
    height = defaultWidth / cropRatio;
    if (height > imgHeight) {
      height = imgHeight;
      width = imgHeight * cropRatio;
    }
  }

  return { width, height };
}

/**
 * 边界检查，超出则返回 true,
 */
export function checkOutBorder(
  boxPositon: BoxPosition,
  borderPosition: Position
) {
  const { x, y, width, height } = boxPositon;
  const { left, top, right, bottom } = borderPosition;
  const dx = x + width;
  const dy = y + height;

  return !(x >= left && y >= top && dx <= right && dy <= bottom);
}

export const ALL_POINTS = [
  POINT_TYPE.LEFT_BOTTOM,
  POINT_TYPE.LEFT_TOP,
  POINT_TYPE.RIGHT_BOTTOM,
  POINT_TYPE.RIGHT_TOP,
];
