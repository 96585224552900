import { useStores } from '@/store';

import SidabarMenu from './Menu';
import { options } from './type';

interface MaterialProps {
  className: string;
}

function Material(props: MaterialProps) {
  const { className } = props;
  return (
    <div className={className}>
      <SidabarMenu MaterialStore={useStores().material} options={options} />
    </div>
  );
}

export default Material;
