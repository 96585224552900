export interface ModalVisible {
  /** 增加数字头像 */
  addBigHead?: boolean;
  /** 新创建数字头像 */
  createBigHead?: boolean;
  /** 创建数字头像失败提示 */
  createdBigHeadError?: boolean;
  /** 制作头像中 */
  makingBigHead?: boolean;
  /** 录制 */
  recordingBigHead?: boolean;
  /** 录制声明 */
  recordingStatement?: boolean;
}

export enum EFFECT_TASK_RESULT_ERROR {
  /** 特效处理异常 */
  aiError = 905,
  /** 913：服务端处理异常  */
  serverError = 913,
  /** 914: 识别不到人脸  */
  noFace = 914,
  /** 915: 讲话语音不符合要求  */
  audioError = 915,
  /** 916: 包含多个人脸 */
  moreFace = 916,
  /** 917: 人脸五官不完整 */
  incompleteFace = 917,
  /** 1500: 头像审核不通过 */
  avatarFailed = 1500,
}

export enum StatementStatus {
  enable = 'enable',
  disable = 'disable',
}
