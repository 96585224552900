import { makeObservable, observable } from 'mobx';
import { CmpTypeEnum } from '@/constants/CmpType';

import CmpStruc from './CmpStruc';

type Background = ComponentModel.Background;

export default class BackgroundStruc<T extends Background = Background>
  extends CmpStruc<T>
  implements Background
{
  url!: string;

  fileName!: string;

  key!: string;

  extra?: ImageExtra & VideoExtra;

  fillType!: 'Color' | 'Image' | 'Video';

  constructor(data?: Partial<ComponentModel.Background>) {
    super(data);
    makeObservable(this, {
      url: observable,
      fillType: observable,
      key: observable,
      extra: observable,
    });

    this.fillType = data?.fillType || 'Color';
    this.url = data?.url || '';
    this.fileName = data?.fileName || '';
    this.key = data?.key || '';
    this.extra = data?.extra;
  }

  /**
   * 获取组件类型
   * @returns {CmpTypeEnum.BACKGROUND}
   */
  protected getType(): CmpTypeEnum.BACKGROUND {
    return CmpTypeEnum.BACKGROUND;
  }

  /**
   * 获取tag
   * @returns
   */
  protected getTag() {
    return this.tag;
  }

  /** 是否可copy */
  get isCanCopy() {
    return false;
  }

  /** id兼容错误的旧数据 */
  getBackgroundId() {
    if (this.fillType === 'Color') return '';
    if (this.extra?.source === 'material') return '';
    return this.id;
  }

  /**
   * 输出文本组件model模型
   * @returns {ComponentModel.Background}
   */
  model(): ComponentModel.Background {
    return {
      ...super.model(),
      id: this.getBackgroundId(),
      name: this.name,
      tag: this.tag,
      fileName: this.fileName,
      key: this.key,
      url: this.url,
      extra: this.extra,
      type: this.type,
      position: this.position,
      anchor: this.anchor,
      scalar: this.scalar,
      rotation: this.rotation,
      flip: this.flip,
      fillType: this.fillType,
    };
  }
}
