import OSS from 'ali-oss';
import { cloneDeep } from 'lodash';
import { scene, fileType, module } from '@/types/file';
import { getOssToken } from '@/api/file';

enum TYPE_KEYS {
  IMAGE = 'image',
  VIDEO = 'video',
  RES = 'res',
  MUSIC = 'music',
}

type successFn = (key: string, obj: Record<string, any>) => void;
type progressFn = (percent: number) => void;

/**
 * 文件可用后缀类型
 */
export const RESOURCE_TYPE: Record<TYPE_KEYS, string[]> = {
  image: ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'],
  video: ['mp4', 'mov', 'webm'],
  res: ['zip'],
  music: ['mp3', 'wav', 'lrc'],
};

/**
 * 获取要上传的文件对象的类型信息
 * @param {File} file 文件对象
 */
function getFileInfo(file: File) {
  let fileType = 'res';
  const { name, type } = file;
  const nameArr = name.match(/.+\.([^.]+)$/);
  let suffix = '';
  // 文件后缀，转小写，避免大写后缀不被识别
  if (Array.isArray(nameArr) && nameArr.length > 1) {
    suffix = nameArr[1].toLowerCase();
  } else {
    suffix = type.split('/')[1].toLowerCase();
  }

  for (const type in RESOURCE_TYPE) {
    const typeList = RESOURCE_TYPE[type];
    if (typeList.includes(suffix)) {
      fileType = type;
      break;
    }
  }
  suffix === 'm4v' && (suffix = 'mp4');
  return [fileType, suffix];
}

function getImageSize(file: File) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.src = url;
    img.onerror = function () {
      URL.revokeObjectURL(url);
      reject(new Error('获取宽高失败'));
    };
    img.onload = function () {
      URL.revokeObjectURL(url);
      resolve({ width: img.width, height: img.height });
    };
  });
}

function getVideoSize(file: File) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const type = file.type || 'video/mp4';
    const blob = new Blob([file], { type });
    const url = URL.createObjectURL(blob);
    video.preload = 'metadata';
    video.src = url;
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(video.src);
      resolve({ width: video.videoWidth, height: video.videoHeight });
    };

    video.onerror = function () {
      reject(new Error('获取宽高失败'));
    };
  });
}

function getSize(file: File, type: string) {
  const isImageFile = RESOURCE_TYPE.image.includes(type);
  if (isImageFile) {
    return getImageSize(file);
  }
  return getVideoSize(file);
}

/**
 * 阿里OSS文件上传类
 */
export class Uploader {
  private fileType: fileType;

  private fileSuffix: string;

  private file: File;

  private scene: scene = 'material';

  private module: module = 'common';

  private uploadKey = '';

  /** 机器人新增切片视频长度 */
  private videoDuration = 0;

  public onProgress?: progressFn = undefined;

  public onSuccess?: successFn = undefined;

  /**
   * 阿里OSS文件上传
   * @param {File} file 要上传的文件对象
   */
  constructor(
    file: File,
    cb?: {
      success: successFn;
      uploadKey: string;
      videoDuration: number;
    }
  ) {
    const [fileType, fileSuffix] = getFileInfo(file);
    if (cb) {
      const { success, uploadKey, videoDuration } = cb;
      this.onSuccess = success;
      this.uploadKey = uploadKey;
      this.videoDuration = videoDuration;
    }
    this.fileType = fileType as fileType;
    this.fileSuffix = fileSuffix;
    this.file = file;

    // return this;
  }

  /**
   * 添加场景信息
   * @param {string} scene 场景
   */
  public addScene(scene: scene) {
    this.scene = scene;
  }

  /**
   * 添加模块信息
   * @param {string} module 模块名
   */
  public addModule(module: module) {
    this.module = module;
  }

  /** 添加视频时长 */
  addDuration(duration: number) {
    this.videoDuration = duration;
  }

  /**
   * 添加类型
   */
  public addFileType(type: fileType) {
    this.fileType = type;
  }

  /**
   * 增加进度回调监听
   * @param {Function} cb 进度回调处理函数
   */
  public progress(cb: progressFn) {
    this.onProgress = cb;
  }

  /**
   * 增加成功回调监听
   * @param {Function} cb 进度回调处理函数
   */
  success(cb: successFn) {
    this.onSuccess = cb;
  }

  /**
   * 开始上传
   */
  public async upload(): Promise<{
    coverUrl?: string;
    url: string;
    key?: string;
  }> {
    const payload = {
      type: this.fileType,
      extName: this.fileSuffix,
      scene: this.scene,
      module: this.module,
    };
    const isNeedSize = [
      ...RESOURCE_TYPE.image,
      ...RESOURCE_TYPE.video,
    ].includes(this.fileSuffix);
    if (isNeedSize) {
      const sizeInfo = await getSize(this.file, this.fileSuffix);
      Object.assign(payload, sizeInfo);
    }

    const { data } = await getOssToken(payload); // 获取上传token信息
    const result = data;
    const fileInfo = result.files[0];
    const client = new OSS({
      region: result.region,
      accessKeyId: result.accessKeyId,
      accessKeySecret: result.accessKeySecret,
      stsToken: result.stsToken,
      bucket: fileInfo.bucket,
    });

    return client
      .multipartUpload(fileInfo.key, this.file, {
        /**
         * 上传进度回调
         */
        progress: (percent: number) => {
          if (typeof this.onProgress === 'function') {
            this.onProgress(Math.floor(percent * 100)); // 将[0, 1]转为[0, 100]
          }
        },
        headers: {
          'Cache-Control': '', // 覆盖缓存设置
        },
      })
      .then(() => {
        const videoPayload: { [key: string]: any } = cloneDeep(payload);
        // 成功回调
        this.onSuccess?.(this.uploadKey, {
          name: this.file.name,
          videoKey: fileInfo.key,
          size: this.file.size,
          duration: this.videoDuration,
          width: videoPayload.width,
          height: videoPayload.height,
        });
        if (this.fileType === 'image') {
          return {
            url: fileInfo.url,
            key: fileInfo.key,
          }; // 上传成功后，返回文件的CDN地址
        }
        return {
          coverUrl: fileInfo.coverUrl,
          url: fileInfo.url,
        };
      });
  }
}

/**
 * 多张上传
 */
export class MultipleUpload {
  private len = 0;

  private curProgress = 0;

  private uploadQueue: Uploader[] = [];

  constructor(files: File[]) {
    this.len = files.length;
    this.curProgress = 0;
    this.uploadQueue = [];
    for (let i = 0; i < this.len; i += 1) {
      const UploadItem = new Uploader(files[i]);
      this.uploadQueue.push(UploadItem);
    }
  }

  addQueueScene(scene) {
    this.uploadQueue.forEach(item => {
      item.addScene(scene);
    });
  }

  addQueueModule(module) {
    this.uploadQueue.forEach(item => {
      item.addModule(module);
    });
  }

  queueProgress(callback) {
    this.queueProgress = callback;
  }

  upload() {
    const curQueue: Promise<Record<string, any>>[] = [];
    this.uploadQueue.forEach(item => {
      item.progress(percent => {
        this.queueProgress((this.curProgress = percent));
      });
      curQueue.push(
        new Promise((resolve, reject) => {
          item
            .upload()
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              reject(error);
            });
        })
      );
    });
    return Promise.all(curQueue);
  }
}

export default Uploader;
