import {
  action,
  makeObservable,
  observable,
  // runInAction,
  configure,
} from 'mobx';
import { Toast } from '@bhb-frontend/lithe-ui/lib';
import debounce from '@bhb-frontend/utils/lib/debounce';
import CmpStruc, { GroupStruc, TextStruc } from '../CmpStruc';
import SceneStruc from '../SceneStruc';
import { createScene } from '../FactoryStruc/SceneFactory';
import { getSceneSize } from '@/utils/getSceneSize';
import { ResolutionEnum } from '@/constants/SceneSize';
import { Orientation } from '@/constants/Orientation';
import { appHistoryDecorator } from '@/core/Decorator/History';
import { getCmpsMaxRect } from '@/helpers/Node';
import { Axis } from '@/types/canvas';
import { sortCmps } from '@/helpers/Cmps';
import { CmpTypeEnum } from '@/constants/CmpType';
import { copySceneApi, createSceneApi } from '@/api/app';
import { buildScene } from '@/core/FormatData/AppData/DataToApp';
import { transformData } from '@/core/FormatData/AppData/AppToData';
import socket from '@/core/socket';
import { ScenesNamespace } from '@/types/ServerData';
import { SOCKET_EMIT, SOCKET_RESPONSE } from '@/constants/Socket';
import { event } from '@/utils/event';
import { MATERIAL_CLOSE_MEUN } from '@/constants/StoreCacheKeys';
import { app, material, material as MaterialStore } from '@/store';
import AudioStruc from '../CmpStruc/Audio';
import { MaterialEnum } from '@/types/material';

// 忽略在动作外部修改状态
configure({ enforceActions: 'never' });

export default class AppStruc implements AppModel {
  id: string | null = null;

  name: string | null = null;

  scenes: SceneStruc[] = [];

  type = Orientation.VERTICAL;

  cover?: string = '';

  templateId?: string | null = null;

  userId?: string | null = null;

  orgId?: string | null = null;

  creteTime?: string | null = null;

  updateTime?: string | null = null;

  version?: string | null = null;

  resolution = ResolutionEnum.REGULAR;

  // speed?: number = 1;

  title?: string | null = null;

  titleContent = '';

  // showTitle?: boolean = true;

  // showIllustration?: boolean = true;

  // showSubtitles?: boolean = true;

  activedScene!: SceneStruc;

  /**
   * 当前被激活的组件或多个组件
   */
  activedCmps: CmpStruc[] = [];

  isDefaultScene = true;

  hoveredCmp: CmpStruc | null = null;

  showGlobalSetting = true;

  clipboard?: CmpStruc[] | null;

  /** 背景音乐 */
  music!: AudioStruc;

  /** 当前激活组件 */
  activeMediaCmp: CmpTypeEnum = CmpTypeEnum.UNKNOWN;

  /** 选中的tag id */
  labelId!: string[];

  /** 选中的tag name */
  labelName!: string[];

  durationUS = 0;

  /** 拓展字段， 转换没用的数据存放在此 */
  extensionFields!: Record<string, any>;

  constructor() {
    type ActionKeys =
      | 'handleAddScene'
      | 'handleAddCmp'
      | 'handleUpdate'
      | 'handleRemoveCmp'
      | 'handleRemoveScene';
    makeObservable<this, ActionKeys>(this, {
      id: observable,
      name: observable,
      scenes: observable,
      type: observable,
      cover: observable,
      templateId: observable,
      userId: observable,
      orgId: observable,
      creteTime: observable,
      updateTime: observable,
      version: observable,
      hoveredCmp: observable,
      activedCmps: observable,
      activedScene: observable,
      // speed: observable,
      title: observable,
      // showTitle: observable,
      // showIllustration: observable,
      // showSubtitles: observable,
      showGlobalSetting: observable,
      labelId: observable,
      durationUS: observable,
      titleContent: observable,
      music: observable,
      activeMediaCmp: observable,
      update: action,
      handleUpdate: action,
      addCmp: action,
      handleAddCmp: action,
      removeCmp: action,
      handleRemoveCmp: action,
      addScene: action,
      copyScene: action,
      handleAddScene: action,
      removeScene: action,
      handleRemoveScene: action,
      releaseAllCmps: action,
      checkStage: action,
      unCheckStage: action,
      copyCmps: action,
      cutCmps: action,
      pasteCmps: action,
      setScenes: action,
      activeScene: action,
      setTitleContent: action,
      setActiveMediaCmp: action,
    });

    const defaultScene = createScene({
      configure: { ...getSceneSize(this.resolution, this.type) },
    });
    // this.addScene(defaultScene);
    this.activeScene(defaultScene, !!this.isDefaultScene);
  }

  /**
   * 输出作品数据原型
   * @returns {AppModel}
   */
  model(): AppModel {
    return {
      id: this.id,
      name: this.name,
      scenes: this.scenes.map(scene => scene.model()),
      type: this.type,
      cover: this.cover,
      templateId: this.templateId,
      userId: this.userId,
      orgId: this.orgId,
      creteTime: this.creteTime,
      updateTime: this.updateTime,
      version: this.version,
      resolution: this.resolution,
      durationUS: this.durationUS,
      labelId: this.labelId,
      labelName: this.labelName,
      title: this.title,
      titleContent: this.titleContent,
      extensionFields: this.extensionFields,
      music: this.music,
    };
  }

  /**
   * 更新作品数据
   * @param data 当前的作品数据
   */
  /* @appHistoryDecorator(function () {
    const current = this.model();
    return () => this.handleUpdate(current);
  }) */
  update(data: Partial<AppModel>) {
    this.handleUpdate(data);
  }

  getCmpIndex(cmp: CmpStruc): number {
    const parent = cmp.getParent() || this.activedScene;
    return parent.getCmpIndex(cmp);
  }

  /**
   * 添加组件
   * @param cmp 新增的组件数据
   * @param parent 父级容器
   * @param index 插入位置
   */
  @appHistoryDecorator(function (cmp: CmpStruc | CmpStruc[]) {
    const cmps = Array.isArray(cmp) ? cmp : [cmp];
    return () => this.handleRemoveCmp(cmps);
  })
  addCmp(
    cmp: CmpStruc | CmpStruc[],
    parent?: SceneStruc | GroupStruc | null,
    index?: number
  ) {
    this.handleAddCmp(cmp, parent, index);
  }

  /**
   * 删除组件
   * @param cmp 选中的组件
   */
  @appHistoryDecorator(function (cmp: CmpStruc | CmpStruc[]) {
    const cmps = Array.isArray(cmp) ? cmp : [cmp];
    if (!cmps.length) return null;
    const indexs = cmps.map(item => this.getCmpIndex(item));
    // 逆向操作就是添加回原来的位置
    return () =>
      cmps.forEach((item, i) => {
        this.handleAddCmp(item, item.getParent(), indexs[i]);
      });
  })
  removeCmp(cmp: CmpStruc | CmpStruc[]) {
    this.handleRemoveCmp(cmp);
  }

  /**
   * 激活组件
   * @param cmp 被选中的组件，有可能是多个
   * @param isMulti 是否是多选
   */
  activeCmp(cmp: CmpStruc | CmpStruc[], isMulti?: boolean) {
    let cmps = Array.isArray(cmp) ? cmp : [cmp];

    cmps = cmps.filter(item => item.visible);

    if (cmps.every(item => item.actived)) return;
    const scene = cmps[0].getRootParent();
    if (scene && scene.id !== this.activedScene.id) {
      this.activeScene(scene);
    }

    /** 如果文字动画打开素材栏，切换的目标非文字，则关闭侧边栏 */
    if (
      cmps.some(item => !item.isText) &&
      MaterialStore.isTextAnimationActived
    ) {
      event.fire(MATERIAL_CLOSE_MEUN, null);
    }

    if (isMulti) {
      this.activedCmps = this.activedCmps.concat(cmps);
    } else {
      this.activedCmps.forEach(item => item.inactive());

      // 如果不用`runInAction`包裹，会出现警告提示，或者设置`enforceActions: 'never'`不强制使用动作处理
      // runInAction(() => {
      this.activedCmps = cmps;
      // });
    }

    this.activedCmps.forEach(item => item.active());
    this.unCheckStage();
  }

  /**
   * 是否是激活组件
   * @param cmp 组件或组件的id
   * @returns {boolean}
   */
  isActiveCmp(cmp: CmpStruc | string): boolean {
    const id = typeof cmp === 'string' ? cmp : cmp.id;
    return !!this.activedCmps.find(item => item.id === id);
  }

  /**
   * 释放所有活动组件
   */
  releaseAllCmps() {
    this.activedCmps.forEach(cmp => cmp.inactive());
    this.activedCmps = [];

    /** 如果侧边栏文字动画被打开，取消选择需要关闭 */
    if (MaterialStore.isTextAnimationActived)
      event.fire(MATERIAL_CLOSE_MEUN, null);
  }

  /**
   * 高亮组件
   */
  hoverCmp(cmp: CmpStruc | null) {
    this.hoveredCmp = cmp;
  }

  /**
   * 是否是高亮组件
   * @param cmp 组件或组件id
   */
  isHoveredCmp(cmp: CmpStruc | string) {
    const id = typeof cmp === 'string' ? cmp : cmp.id;
    return this.hoveredCmp?.id === id;
  }

  /**
   * 新增场景
   * @param scene 新增的场景数据
   * @param index 插入的位置
   */
  @appHistoryDecorator(function (scene: SceneStruc) {
    return () => this.handleRemoveScene(scene);
  })
  async addScene(scene: SceneStruc) {
    const { originalThemeId } = scene;
    const index = this.getSceneIndex(scene) + 1;
    const { error, data } = await createSceneApi(this.id, originalThemeId);
    if (error === 0) {
      const newScene = buildScene(data);
      this.handleAddScene(newScene, index);
      Toast.success('新增场景成功');
    }
  }

  /**
   * 复制场景
   * @param scene 新增的场景数据
   * @param index 插入的位置
   */
  @appHistoryDecorator(function (scene: SceneStruc) {
    return () => this.handleRemoveScene(scene);
  })
  async copyScene(scene: SceneStruc) {
    const { id } = scene;
    const index = this.getSceneIndex(scene) + 1;
    const { error, data } = await copySceneApi(this.id, id);
    if (error === 0) {
      const newScene = buildScene(data);
      this.handleAddScene(newScene, index);
      Toast.success('复制场景成功');
      // 复制场景需要重拆分组件
      this.captionsSplit();
    }
  }

  /**
   * 删除场景
   * @param scene 当前场景
   */
  @appHistoryDecorator(function (scene: SceneStruc) {
    const index = this.getSceneIndex(scene);
    if (index === -1) return null;
    const tmp = scene.clone(true);
    return () => this.handleAddScene(tmp, index);
  })
  removeScene(scene?: SceneStruc) {
    if (!scene) return;
    this.handleRemoveScene(scene);
  }

  /**
   * 激活场景
   * @param scene 当前场景
   */
  activeScene(scene: SceneStruc, isDefaultScene?: boolean) {
    if (!this.hasScene(scene) && !isDefaultScene) return;
    this.scenes = this.scenes.map(item => {
      item.actived = false;
      return item;
    });
    scene.actived = true;
    this.activedScene = scene;
    if (material.activeMenu === MaterialEnum.MATERIAL) {
      // 侧边栏为素材时，需要切换
      material.changeMenu(MaterialEnum.THEME);
    }
    this.releaseAllCmps();
  }

  /**
   * 检测场景是否存在
   * @param scene 选中的场景
   */
  hasScene(scene: SceneStruc) {
    return this.getSceneIndex(scene) >= 0;
  }

  /**
   * 返回场景位置
   * @param scene 选中的场景
   */
  getSceneIndex(scene: SceneStruc): number {
    return this.scenes.findIndex(sc => sc.id === scene.id);
  }

  /**
   * 通过id查询场景
   * @param id 场景id
   */
  getSceneById(id: string): SceneStruc | undefined {
    return this.scenes.find(scene => scene.id === id);
  }

  /**
   * 鼠标点击在舞台上画布外
   */
  checkStage() {
    this.showGlobalSetting = true;
    this.releaseAllCmps();
  }

  /**
   * 鼠标点击在画布内
   */
  unCheckStage() {
    this.showGlobalSetting = false;
  }

  /**
   * 复制组件，通过剪贴板实现跨作品复制
   */
  copyCmps() {
    this.clipboard = [];
    this.activedCmps.forEach(cmp => {
      if (!cmp.isCanCopy) return;
      const newCmp = cmp.clone();
      newCmp.resetParnth();
      newCmp.isLock = false;
      this.clipboard?.push(newCmp);
    });
  }

  /**
   * 剪切组件，通过剪贴板实现跨作品复制
   */
  cutCmps() {
    this.copyCmps();
    this.activedCmps.forEach(cmp => {
      if (!cmp.isCanCopy) return;
      if (cmp.isLock) return;
      this.removeCmp(cmp);
    });
  }

  /**
   * 粘贴组件，通过剪贴板实现跨作品复制
   */
  pasteCmps() {
    if (!this.clipboard || !this.clipboard.length) return;
    this.handlePasteCmps(this.clipboard);
  }

  /**
   * 粘贴组件
   * @param cmps 剪贴板的组件
   */
  handlePasteCmps(cmps: CmpStruc[]) {
    const newCmps = cmps.map(cmp => cmp.clone());
    const activedCmp =
      this.activedCmps.length === 1 ? this.activedCmps[0] : null;
    const parent = activedCmp?.isGroup() ? activedCmp : null;
    this.updatePastedCmpsPosition(newCmps);
    this.addCmp(newCmps, parent);
  }

  /**
   * 键盘移动组件
   * @param value 移动的量
   * @param axis 坐标轴
   */
  moveCmpBy(value: number, axis: Axis) {
    this.activedCmps.forEach(cmp => {
      if (cmp.isLock) return;
      cmp.addPixel(value, axis);
    });
  }

  /**
   * 更新粘贴后的组件位置
   * @param cmps 更新的组件
   */
  private updatePastedCmpsPosition(cmps: CmpStruc[]) {
    const maxRect = getCmpsMaxRect(cmps);
    if (!maxRect) return;
    const updatePosition = (axis: Axis) => {
      const min = cmps[0].getNumPixel(axis);
      const sorted = sortCmps(cmps, axis, min);
      const prop = axis === 'left' ? 'left' : 'bottom';
      const offset = maxRect[prop] - min;
      sorted.forEach(cmp => {
        if (cmp.style) cmp.style[axis] = cmp.toPixelBy(offset, axis);
      });
    };

    updatePosition('left');
    updatePosition('top');
  }

  protected handleUpdate(data: Partial<AppModel>) {
    for (const key in data) {
      this[key] = data[key];
    }
    app.save();
  }

  protected handleAddScene(scene: SceneStruc, index?: number) {
    index != null
      ? this.scenes.splice(index, 0, scene)
      : this.scenes.push(scene);

    app.save();
    /** 利用帧事件暂存激活场景 */
    window.requestAnimationFrame(() => this.activeScene(scene));
  }

  protected handleRemoveScene(scene: SceneStruc) {
    const index = this.scenes.findIndex(s => s.id === scene.id);
    if (index === -1) return;
    this.scenes.splice(index, 1);
    app.save();
    // 删除的恰好是选中的场景，则选中下一个场景
    if (scene.id === this.activedScene.id) {
      const nextScene =
        this.scenes[index] || this.scenes[this.scenes.length - 1];
      nextScene && this.activeScene(nextScene);
    }
    Toast.success('删除场景成功');
  }

  protected handleAddCmp(
    cmp: CmpStruc | CmpStruc[],
    parent?: SceneStruc | GroupStruc | null,
    index?: number
  ) {
    if (!parent) parent = this.activedScene;
    const cmps = Array.isArray(cmp) ? cmp : [cmp];
    for (const item of cmps) {
      parent.addCmp(item, index);
    }
  }

  protected handleRemoveCmp(cmp: CmpStruc | CmpStruc[]) {
    const cmps = Array.isArray(cmp) ? cmp : [cmp];
    cmps.forEach(item => {
      if (item.isLock) return;
      const parent = item.getParent() || this.activedScene;
      parent.removeCmp(item);
      if (this.isActiveCmp(item)) this.releaseAllCmps();
    });
  }

  /** 移除激活组件 */
  public removeActivedCmp(cmp: CmpStruc) {
    const index = this.activedCmps.findIndex(item => item.id === cmp.id);
    if (index < 0) return;
    this.activedCmps.splice(index, 1);
  }

  /** 创建场景（初始化） */
  protected buildSences(data: Partial<AppModel>) {
    this.update(data);
    // 激活第一个场景
    const curScenes = this.scenes[0];
    this.activeScene(curScenes);
    // 正文拆分交给组件处理
    this.captionsSplit();
    this.initMusic();
  }

  /** 初始化音乐详情 */
  public async initMusic() {
    this.music?.initMusic();
  }

  public setScenes(scenes: SceneStruc[]) {
    this.update({
      scenes,
    });
  }

  public setLabel(ids: string[], names: string[]) {
    this.labelId = ids;
    this.labelName = names;
  }

  /** 设置标题 */
  public setTitleContent(title: string) {
    if (this.titleContent === title) return;
    // 标题单独处理，因为需要修改其他场景的标题图层的拆分
    this.titleContent = title;
    this.setAllTitleContents();
  }

  /** 计算所有场景标题换行 */
  setAllTitleContents = debounce(() => {
    this.scenes.forEach(scene => {
      const titleCmp = scene.titleCmp as TextStruc;
      titleCmp?.setContents();
    });
    app.save();
  }, 500);

  /** 正文拆分 */
  public async captionsSplit() {
    const serverData = transformData(this);
    const { jsonText } = serverData;
    const sceneThemeList = jsonText.scenes.map(scene => {
      const { id } = scene;
      return {
        ...scene.themeInfo,
        sceneId: id,
      };
    });

    const { data } = await socket.request({
      data: {
        jsonText,
        sceneThemeList,
      },
      emitterEventName: SOCKET_EMIT.CAPTIONS_SPLIT,
      onEnventName: SOCKET_RESPONSE.CAPTIONS_SPLIT_RESULT,
    });
    return data;
  }

  /** 设置正文在画布显示的内容 */
  public setCaptionsText(scenes: ScenesNamespace[] = []) {
    scenes.forEach(item => {
      const { id, captions = [] } = item;
      if (!captions.length) return;
      const scene = this.scenes.find(scene => scene.id === id);
      if (!scene) return;
      scene.captions = captions;
      const textCmp = scene?.cmps.find(
        cmp => cmp.tag === 'Captions'
      ) as TextStruc;
      if (!textCmp) return;
      const { texts = [] } = captions[0];
      textCmp.setCaptionsText(texts);
    });
  }

  /** 设置视频预估时长， 无须保存 */
  public setDurationUS(durationUS: number) {
    this.durationUS = durationUS;
  }

  /** 设置音乐 */
  public setMusic(val: Partial<Audio>) {
    if (!this.music) {
      this.update({
        music: new AudioStruc({
          ...val,
          tag: 'music',
        }),
      });
      return;
    }
    this.music.update(val);
  }

  /** 删除音乐 */
  public removeMusic() {
    this.music.clean();
  }

  /** 修改当前激活组件 */
  public setActiveMediaCmp(type: CmpTypeEnum) {
    this.activeMediaCmp = type;
  }

  /** 是否选中画布区域的 音乐组件 */
  get isSelectedMediaMusic() {
    return this.activeMediaCmp === CmpTypeEnum.MUSIC;
  }
}
