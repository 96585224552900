import { Toast, Tooltips } from '@bhb-frontend/lithe-ui/lib';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { store } from '@bhb-frontend/store';
import AudioBox, { RefEditAudio } from '@/components/AudioBox';
import AudioText from './cmp/AudioText';
import Drop, { DropRefFn } from './cmp/Drop';
import PureText, { PureTextRefFn } from './cmp/PureText';
import Material from './cmp/Material';
import { event } from '@/utils/event';
import {
  DOC_EDTIOR_BLUR,
  SCENE_MATERIAL_DROP_ADD,
  SCENE_NAMESPACE,
  SCENE_MATERIAL_PASTE_ADD,
} from '@/constants/StoreCacheKeys';

import Style from './Doc.module.less';
import { useStores } from '@/store';
// import { storagePaidGuidance } from '@/utils/createVideo';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';

interface DocProps {
  className: string;
}

function Doc(props: DocProps) {
  const { user, app } = useStores();
  const { className } = props;
  const {
    presetIpImageId,
    sound: {
      cover = '',
      url = '',
      name = '请选择配音',
      tag = 'dubbing',
      id,
      isImportRecord,
    },
  } = app.activedScene;
  // 录音模式
  const isAudioMode = tag === 'record';

  const DocumentRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<DropRefFn>(null);
  const pureTextRef = useRef<PureTextRefFn>(null);
  const audioBoxRef = useRef<RefEditAudio>({} as RefEditAudio);

  useEffect(() => {
    if (!isAudioMode && pureTextRef.current && pureTextRef.current.init) {
      pureTextRef.current.init(
        app.activedScene.allTexts.length ? app.activedScene.allTexts : []
      );
    }
  }, [app.activedScene, isAudioMode]);

  useEffect(() => {
    if (DocumentRef.current && dropRef.current) {
      dropRef.current.addDragEvent(DocumentRef.current);
    }

    return () => {
      if (DocumentRef.current && dropRef.current) {
        dropRef.current.removeDragEvent(DocumentRef.current);
      }
    };
  }, []);

  /** 注册手动失焦事件 */
  useEffect(() => {
    const callback = () => {
      pureTextRef.current?.onBlur();
    };
    event.on(DOC_EDTIOR_BLUR, callback);
    return () => {
      event.off(DOC_EDTIOR_BLUR, callback);
    };
  }, []);

  /**
   * 当素材文件从文件浏览器拖进来时
   */
  const onDrop = (allfiles: File[]) => {
    const balanceStorage = user.videoClipRights?.balanceStorage || 0;
    if (balanceStorage <= 0) {
      // storagePaidGuidance();
      WeChatQRcodeModal.show();
      return;
    }
    // 限制文件类型
    const types = ['mp4', 'mov', 'png', 'jpeg', 'webp', 'gif'];
    const files = allfiles.filter(file =>
      types.some(type => file.type.includes(type))
    );

    if (!files.length) return;
    store.set(SCENE_NAMESPACE, SCENE_MATERIAL_DROP_ADD, files);
  };

  /** 配音文案编辑区获取焦点 */
  const pureTextFocus = () => {
    /** 取消图层激活状态 */
    app.releaseAllCmps();
  };

  /** 更新配音文案数据 */
  const pureTextUpdate = allTexts => {
    app.activedScene.update({ allTexts });
  };

  /** 打开新增配音别名弹窗 */
  const addAlias = () => {
    if (
      app.activedScene.sound.tag === 'dubbing' &&
      !!app.activedScene.sound.id
    ) {
      pureTextRef.current && pureTextRef.current.addAlias();
    } else {
      Toast.warning('别名功能需先选中配音');
    }
  };

  /** 粘贴富文本 */
  const pureTextPaste = fileList => {
    store.set(SCENE_NAMESPACE, SCENE_MATERIAL_PASTE_ADD, {
      arr: fileList,
      sceneId: app.activedScene.id,
    });
  };

  const handleRemoveMusic = () => {
    // if (isImportRecord) {
    //   app.activedScene.removeSound();
    // }
    audioBoxRef.current.pause?.();
    app.activedScene.removeSound();
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div className={className}>
        <div className={Style.title}>
          <div className={Style.titleText}>图文内容</div>
          <div className={Style.titleBtn}>
            <Tooltips placement="bottomRight" tips="滑选要修改的名词，输入别名">
              <div onClick={addAlias} className={Style.addAlias}>
                <i
                  className="iconfont icon-a-doc_icon_bieming2x alias"
                  style={{ userSelect: 'none', fontSize: '14px' }}
                />
                <span style={{ fontSize: 14, marginLeft: 5 }}>别名</span>
              </div>
            </Tooltips>
          </div>
        </div>
        <div className={Style.content}>
          <div className={Style.contentDocument}>
            <div ref={DocumentRef}>
              {isAudioMode ? (
                // !!! 此处为什么加key, 为了让每次切换场景的时候内部组件重新挂载一次
                <AudioText key={app.activedScene.id} />
              ) : (
                <PureText
                  ref={pureTextRef}
                  onFocus={pureTextFocus}
                  update={pureTextUpdate}
                  onPaste={pureTextPaste}
                />
              )}
            </div>
            <Material />
          </div>
          <Drop ref={dropRef} onDrop={onDrop} />
          <AudioBox
            ref={audioBoxRef}
            type="dubbing"
            volume={50}
            name={name}
            cover={cover}
            url={url}
            removeMusic={handleRemoveMusic}
            id={id}
            presetIpImageId={presetIpImageId}
            isImportRecord={isImportRecord}
          />
        </div>
      </div>
    </DndProvider>
  );
}

export default observer(Doc);
