/**
 * 使用mimeTypes判别浏览器种类 360浏览器
 * @param { option } 属性
 * @param { value } 值
 */
export function byMimeTypesCheckBrower(option: string, value: string) {
  const mimeTypes = navigator.mimeTypes;
  for (const mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true;
    }
  }
  return false;
}
