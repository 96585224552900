import { TRejectContent, IModaleText } from '@/types/videos';
/** 预览弹窗失败文案 */
export function getPrErrText(time: number): IModaleText {
  return {
    title: '无法预览',
    content: `您的视频时长超出当前权益单个视频最长时长${time}秒，请精简文档内容后重试`,
    butText: '去调整',
  };
}

export function getAuditErrText(text: string): IModaleText {
  return {
    title: '内容审核不通过',
    content: `文档模块中${text}存在违规内容。请先清除违规内容后重试`,
    butText: '好的',
  };
}

export const videoErrorConent = {
  title: '内容审核不通过',
  content: '视频可能存在违规内容，请先检查并清除违规内容后重试',
  butText: '好的',
};

/** 获取内容审核不通过文案 */
export function getRejectContent(rejectContent?: TRejectContent) {
  if (!Array.isArray(rejectContent)) return '';
  const objText = {
    text: '正文',
    picture: '图片',
    video: '视频',
    audio: '录音音频',
  };
  return rejectContent.map(item => objText[item]).join('、');
}

/**
 * 视频的合成状态
 */
export const VIDEO_STATUS = {
  /**
   * 生成中
   */
  PENDING: 'pending',
  /**
   * 生成成功
   */
  COMPLETED: 'completed',
  /**
   * 生成失败
   */
  FAIL: 'fail',
  /**
   * 取消
   */
  CANCEL: 'cancel',
  /**
   * 排队中
   */
  QUEUEING: 'queueing',
  /** 审核不通过 */
  REJECT: 'reject',
  /** 审核中 */
  REVIEW: 'review',
  /** 审核失败 */
  REVIEWFAIL: 'reviewFail',
  /** 申诉中 */
  APPEAL: 'appeal',
  /** 申诉不通过 */
  APPEALREJECT: 'appealReject',
};

export const MAX_AUDIO_NUMBER = 50; // 音频素材最大数量
