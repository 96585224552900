import { Tooltips, Tabs, Toast } from '@bhb-frontend/lithe-ui';
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useActivate } from 'react-activation';
import Style from './WebMaterial.module.less';
import SearchInput from '@/components/SearchInput';
import MeterialItems from './MeterialItems';
import GetListWrapper from '@/components/Material/GetListWrapper';
import { getMaterial } from '@/api/app';
import { MateriaResults } from '@/types/doc-materials';
import { SearchParams } from '@/types/api';
import { useStores } from '@/store';
import { createEffectTask } from '@/api/doc';
import { TaskType } from '@/types/doc';
import socket from '@/core/socket';
import { SocketResponse, IKeywordPick } from '@/types/socket';
import { SOCKET_EVENTS } from '@/constants/SocketEvents';

enum EnumMaterialTypes {
  ALL = 'all',
  VIDEO = 'video',
  IMAGE = 'image',
}

const MaterialTypes: { name: EnumMaterialTypes; title: string }[] = [
  {
    title: '全部',
    name: EnumMaterialTypes.ALL,
  },
  {
    title: '视频',
    name: EnumMaterialTypes.VIDEO,
  },
  {
    title: '照片',
    name: EnumMaterialTypes.IMAGE,
  },
];

const tips =
  '在线图片、视频素材资源系由合作第三方或基于互联网搜索、链接服务提供，不得用于任何商业目的。如您发现任何素材侵犯了您的合法权利，可通过【我的 - 帮助中心 - 联系我们】路径发起通知，我们将及时对您的投诉做出反馈。';

function WebMaterial() {
  /** 当前接口文本 */
  // const [curAllText, setCurAllText] = useState('');
  /** 是否在搜索 */
  const [loading, setLoading] = useState(false);
  /** 当前素材类型 */
  const [activeKey, setActiveKey] = useState<string>(EnumMaterialTypes.ALL);
  /** 关键字 */
  const [keyWords, setKeyWords] = useState<string[]>([]);
  /** 搜索值 */
  const [searchValue, setSearchValue] = useState('');
  /* appStore */
  const { app } = useStores();
  /** 当前接口的文本 */
  const curAllText = useRef('');
  /** 任务id 主要用户与服务端通信， 防止与ios 安卓那边事件冲突 */
  const task = useRef('');
  /** 请求参数 */
  /* const parmas = useRef({
    sid: '',
    pageSize: 20,
    type: activeKey,
    keyword: '',
  }); */

  /** 搜索框聚焦 */
  const handleFocus = async () => {
    const { allTexts } = app.activedScene;
    const list = allTexts.map(item => item.text);
    // 无文案时清空
    if (list.length === 0) {
      setKeyWords([]);
      return;
    }
    // 内容相同无须修改
    const joinAllTexts = list.join('');
    if (curAllText.current === joinAllTexts) return;
    setLoading(true);
    const text = joinAllTexts.replace(/[\n\r]+/g, '');
    curAllText.current = text;
    // this.searchRef.handleVisible(true);
    console.log('handleFocus', allTexts);
    const data = await createEffectTask({
      text,
      type: TaskType.keywordPick,
    }).catch(err => {
      console.log(err);
    });
    if (!data) return;
    const {
      data: { taskId },
    } = data;

    task.current = taskId;
  };

  /** 监听服务端返回的智能搜索 */
  const onlistenAllot = () => {
    socket.on((res: SocketResponse<IKeywordPick>) => {
      const { event, data } = res;
      if (event !== SOCKET_EVENTS.EFFECT_TASK_RESULT) return;

      setLoading(false);

      const { status, keyWords, message, taskId } = data;
      if (!task.current || task.current !== taskId) return;

      if (status === 'failed') {
        Toast.error(message);
      }
      setKeyWords(keyWords);
    });
  };

  /** 切换搜索值 */
  const changeKeyWords = (val: string) => {
    setSearchValue(val);
  };

  useEffect(() => {
    onlistenAllot();
  }, []);

  useActivate(() => {
    curAllText.current = '';
    setKeyWords([]);
  });

  return (
    <div className={Style.collapse}>
      <div className={Style.collapseTop}>
        网络素材
        <Tooltips tips={tips} placement="bottom" className={Style.collapseTips}>
          <i className="iconfont icon-a-common_icon_tips2x" />
        </Tooltips>
      </div>
      <SearchInput
        onFocus={handleFocus}
        isLoading={loading}
        keyWords={keyWords}
        changeKeyWords={changeKeyWords}
      />
      <Tabs
        tabBarStyle={{ width: '100%', color: '#7f8081' }}
        className={Style.collapseTabs}
        tabPosition="top"
        activeKey={activeKey}
        items={MaterialTypes.map(item => ({
          key: item.name,
          label: item.title,
          children: null,
        }))}
        onChange={menuKey => {
          setActiveKey(menuKey);
        }}
      />
      <GetListWrapper<SearchParams & { type: string }, MateriaResults>
        apiFn={getMaterial}
        payload={{
          sid: '',
          pageSize: 20,
          type: activeKey,
          keyword: searchValue,
        }}
      >
        <MeterialItems />
      </GetListWrapper>
    </div>
  );
}

export default observer(WebMaterial);
