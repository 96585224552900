import { observer } from 'mobx-react';
import { TextStruc } from '@/models/CmpStruc';
import { useStores } from '@/store';
// import { getCmpsMaxRect } from '@/helpers/Node';
// import Grid from '../Grid';
// import Ruler from '../Ruler';
// import CmpStruc from '@/models/CmpStruc';
import HoverWrap from '../Hover';
import Magnetic from '../Magnetic';
import Editor from '../Editor';
import RichText from '../RichText';
import Style from './EditWrapper.module.less';

interface EditWrapperProps {
  className: string;
  selectRect: Rect | null;
  zoomLevel: number;
  style?: React.CSSProperties;
}

function EditWrapper(props: EditWrapperProps) {
  const { className, selectRect, zoomLevel, style } = props;
  const { app } = useStores();
  const { activedCmps } = app;

  const isMulti = activedCmps.length > 1;

  const model = activedCmps[0] || null;

  return (
    <div
      style={style}
      className={className}
      onMouseDown={e => {
        e.stopPropagation();
      }}
    >
      {/* 绘制网格 */}
      {/* <Grid /> */}
      {/* 绘制横轴标尺 */}
      {/* <Ruler direction="x" /> */}
      {/* 绘制纵轴标尺 */}
      {/* <Ruler direction="y" /> */}
      {/* 绘制辅助线 */}
      {/* <Ruler.GuideLine /> */}
      {/* hover 框 */}
      <HoverWrap />
      {/* 编辑框 */}
      <Editor isMulti={isMulti} zoomLevel={zoomLevel} model={model} />
      {/* 多选 */}
      {selectRect && <div className={Style.selection} style={selectRect} />}
      {/* 磁力线 */}
      <Magnetic />

      {/* 富文本 */}
      {model && model.isText && (
        <RichText
          isMulti={isMulti}
          zoomLevel={zoomLevel}
          model={model as TextStruc}
        />
      )}
    </div>
  );
}

export default observer(EditWrapper);
