/**
 * 付费引导
 */

import { Member } from '@bhb-frontend/mtv-navbar';
import { Toast } from '@bhb-frontend/lithe-ui';
import VipDialog from '@/components/VipDialog';
import { user } from '@/store';

/**
 * 图文快剪存储空间付费引导
 */
export async function noEnoughSpace() {
  const { videoClipRights } = user;
  if (!videoClipRights) {
    return;
  }
  const { type = 'free', totalStorage = 0 } = videoClipRights;

  const {
    memberId,
    type: tab,
    isHave,
    goodsIds,
  } = await Member.getHigherMember({
    fields: 'storage',
    value: totalStorage,
    type,
    module: 'video',
  });

  if (!isHave) {
    Toast.error('空间已不足，请释放空间');
    return;
  }
  VipDialog?.show?.({
    title: '可用云空间已不足',
    content: '购买会员获取更多空间',
    module: 'video',
    vipParam: {
      tab,
      memberId,
      goodsIds,
    },
  });
}
