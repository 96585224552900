import { observer } from 'mobx-react';
import { GroupStruc } from '@/models/CmpStruc';
// import BaseProperites from '../Base/BaseProperites';
import style from './Group.module.less';

interface GroupSettingProps {
  model: GroupStruc;
}

function GroupSetting(_props: GroupSettingProps) {
  // const { model } = props;
  return (
    <div className={style.group}>
      组合设置面板
      {/* <BaseProperites model={model} /> */}
    </div>
  );
}

export default observer(GroupSetting);
