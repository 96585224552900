import React from 'react';
import { observer } from 'mobx-react';
import style from './Toolbar.module.less';
import { useStores } from '@/store';
import Settings from '@/settings/Settings';

function ToolBox() {
  const { app } = useStores();

  console.log(app);

  return (
    <div className={style.toolbar}>
      <Settings />
    </div>
  );
}

export default observer(ToolBox);
