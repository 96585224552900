let ctx: CanvasRenderingContext2D;

// 判断是否为 emoji 表情 (有的是两个字符)
function isEmojiCharacter(char: string) {
  const hs = char.charCodeAt(0);
  if (hs >= 0xd800 && hs <= 0xdbff) {
    if (char.length > 1) {
      const ls = char.charCodeAt(1);
      const uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000;
      if (uc >= 0x1d000 && uc <= 0x1f77f) {
        return true;
      }
    }
    return false;
  }
  if (char.length > 1) {
    const ls = char.charCodeAt(1);
    if (ls === 0x20e3) return true;
  }
  if (hs >= 0x2100 && hs <= 0x27ff) return true;
  if (hs >= 0x2b05 && hs <= 0x2b07) return true;
  if (hs >= 0x2934 && hs <= 0x2935) return true;
  if (hs >= 0x3297 && hs <= 0x3299) return true;
  if ([0xa9, 0xae, 0x303d, 0x3030, 0x2b55, 0x2b1c, 0x2b1b, 0x2b50].includes(hs))
    return true;
  return false;
}

type StyleParam = {
  fontSize: number;
  fontFamily: string;
  fontStyle: string;
  letterSpacing?: number;
  lineHeight?: number;
  padding?: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
};

type FontFootageCharParam = {
  char: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

const defaultFamily = '系统默认字体';

export function textCounter(content: string, style: StyleParam, width: number) {
  if (!ctx) {
    ctx = document
      .createElement('canvas')
      .getContext('2d') as CanvasRenderingContext2D;
  }
  const { letterSpacing = 0, fontSize, fontFamily, fontStyle, padding } = style;
  const lineHeight = style.lineHeight || fontSize;
  ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`;
  if (!ctx.font.includes(fontFamily)) {
    // 字体识别失败 设置浏览器默认字体
    ctx.font = `${fontStyle} ${fontSize}px ${defaultFamily}`;
  }
  if (padding) {
    width -= padding.left + padding.right;
  }

  const lineData: FontFootageCharParam[][] = [];

  lineData[0] = [];
  /** 计算自动换行 */
  const contents: string[] = [];
  let currentText = '';
  let line = 0;
  let currentX = 0;
  let text = '';
  const chars = content.split('');
  for (let i = 0; i < chars.length; i += 1) {
    let char = chars[i];

    // 判断 emoji 表情符号（有的表情符号是两个字符）
    if (chars[i + 1] && isEmojiCharacter(char + chars[i + 1])) {
      i += 1;
      char += chars[i];
    }
    const charSize = ctx.measureText(char).width;
    const size = letterSpacing + charSize;
    if (char === '\n') {
      lineData[line].push({
        char,
        x: currentX,
        y: (line + 0.5) * lineHeight,
        width: charSize,
        height: lineHeight,
      });
      lineData.push([]);

      text += '\n';
      currentX = 0;
      line += 1;

      currentText += '\n';
    } else if (width > 0 && width < currentX + charSize) {
      // 表示换行
      lineData.push([
        {
          char,
          x: 0,
          y: (line + 1.5) * lineHeight,
          width: charSize,
          height: lineHeight,
        },
      ]);

      text += `\n${char}`;
      line += 1;
      currentX = size;

      contents.push(currentText);
      currentText = char;
    } else {
      lineData[line].push({
        char,
        x: currentX,
        y: (line + 0.5) * lineHeight,
        width: charSize,
        height: lineHeight,
      });
      text += char;
      currentX += size;

      currentText += char;
    }
  }
  contents.push(currentText);
  return {
    lineData,
    text,
    contents,
  };
}
