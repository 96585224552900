import { observer } from 'mobx-react';
import { VideoStruc } from '@/models/CmpStruc';
// import BaseProperites from '../Base/BaseProperites';
import style from './Video.module.less';

interface VideoSettingProps {
  model: VideoStruc;
}

function VideoSetting(_props: VideoSettingProps) {
  // const { model } = props;
  return (
    <div className={style.video}>
      视频设置面板
      {/* <BaseProperites model={model} /> */}
    </div>
  );
}

export default observer(VideoSetting);
