import { blobToDataUrl } from './file';

/** 请求 blob 格式资源 */
export function fetchBlob(url: string): Promise<Blob> {
  return fetch(url).then(res => res.blob().then(blob => blob));
}

/** 请求 base64 格式资源 */
export function fetchDataUrl(url: string): Promise<string> {
  return fetchBlob(url).then(blob => blobToDataUrl(blob));
}

/** 请求 arrayBuffer 格式资源 */
export function fetchArrayBuffer(url: string): Promise<ArrayBuffer> {
  return fetch(url).then(res => res.arrayBuffer());
}
