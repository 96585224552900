import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Spin } from '@bhb-frontend/lithe-ui';
import Layout from '@/layout';
import { useStores } from '@/store';
import AppStore from './store/App';
import UserStore from './store/User';
import HistoryManager from './core/Manager/History';
import {
  registerAppActions,
  registerHistoryActions,
  registerOSSActions,
} from './core/Manager/Cmd';
import KeyboardManager from './core/Manager/Keyboard';
import ContextMenuManager from './core/Manager/ContextMenu';
import ClipboardManager from './core/Manager/Clipboard';
import { SOCKET_EVENTS } from './constants/SocketEvents';
import socket from './core/socket';
import BrowerTips from './components/BrowerTips/BrowerTips';

/**
 * 作品编辑后未保存拦截
 * @param app 当前最新数据
 * @param user 用户信息
 */
function interceptExit(app: AppStore, user: UserStore) {
  window.onbeforeunload = () => {
    /** 开发环境不做判断 */
    if (import.meta.env.DEV) {
      return null;
    }
    /** 用户登录且作品有修改才拦截 */
    if (user.isLogin() && app.hasChange(app.model())) {
      return '作品未保存，确认退出吗？';
    }
    return null;
  };
}

function App() {
  const { user, app, history, OS } = useStores();
  /**
   * 注册动作信息
   */
  const registerInfo = useCallback(() => {
    interceptExit(app, user);
    ClipboardManager.register(app);
    HistoryManager.register(history);

    registerAppActions(app);
    registerOSSActions(OS);
    registerHistoryActions();
  }, []);

  useEffect(() => {
    // 用户信息获取需要，可能需要唤起登录组件，所以在此获取。
    user.initUser();
    registerInfo();
  }, []);

  const listenMemberPay = (res: any) => {
    const { event, data } = res;
    const results = {
      // 支付成功
      [SOCKET_EVENTS.PAY_RESULT]() {
        if (data.success) {
          // Member.paySuccessDialog(data.orderNo);
        }
      },
    }[event];
    results?.();
  };

  useEffect(() => {
    if (user.socketLink) {
      socket.on(listenMemberPay);
    }
    return () => {
      socket.off(listenMemberPay);
    };
  }, [user.socketLink]);

  return (
    <>
      <BrowerTips />

      <Spin spinning={!user.isLogin() || app.globalLoading}>
        <Layout />
        <KeyboardManager />
        <ContextMenuManager />
      </Spin>
    </>
  );
}

export default observer(App);
