import { Tooltips } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { COLORS } from './constants';
import style from './ColorSelector.module.less';
import ColorPicker from '../ColorPicker';

interface ColorSelectorProps {
  children: React.ReactNode;
  prefixNode?: React.ReactNode;
  suffixNode?: React.ReactNode;
  changeColor?: (color: string) => void;
  color?: string;
}

export default function ColorSelector(props: ColorSelectorProps) {
  const { children, prefixNode, suffixNode, changeColor, color = '' } = props;

  const tips = (
    <div>
      {prefixNode}

      <div className={style['color-content']}>
        {COLORS.map(clr => (
          <div
            onClick={() => changeColor?.(clr)}
            key={clr}
            className={style.item}
          >
            <div
              style={{ background: clr }}
              className={cs(style.color, {
                [style['show-border']]: clr.toLowerCase() === '#ffffff',
                [style.checked]: clr.toLowerCase() === color.toLowerCase(),
              })}
            />
          </div>
        ))}
      </div>

      {suffixNode}

      <ColorPicker
        changeColor={changeColor}
        placement="rightTop"
        trigger="hover"
        clickClosable
        color={color}
      >
        <div className={style['custom-color']}>
          <div>
            <i
              className={cs(
                'iconfont icon-a-text_type_icon_color1x',
                style['custom-color-icon']
              )}
            />
            自定义颜色
          </div>
          <i className="iconfont icon-a-common_icon_arrow_right1x" />
        </div>
      </ColorPicker>
    </div>
  );

  return (
    <Tooltips
      className={style['tooltips-component']}
      tips={tips}
      trigger="click"
      placement="bottomLeft"
    >
      {children}
    </Tooltips>
  );
}
