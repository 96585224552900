import { Material } from './doc-materials';
import { EDubSpeed } from './dub';

export interface ICaptions {
  text: string;
  texts: [];
  /** 开始时间（毫秒），如果带有录音 */
  startMs?: number;
  /** 结束时间（毫秒） */
  endMs?: number;
}

// 部分字体属性
export interface CharAttr {
  influence: 0 | 1; // 作用类型: 0按字符序号, 1,按字符比例
  start: number; // 作用域起点
  endPos: number; // 作用域终点
  color?: string; // 字体颜色
  bgColor?: string; // 背景颜色
  stroke?: unknown;
  shadow?: unknown;
  gradient?: unknown;
  style: number;
}

// 图片配置
export interface Image {
  features?: number;
  base64Url?: string;
  objectId: string;
  // 当编辑主题背景时，下面3个字段需要替换掉成实际的图片信息
  fileName: string; // 文件名
  imageKey: string; // 该图片的地址key
  imageUrl: string; // 图片url地址
  maskName?: string;
  maskType?: 0;
  extra?: {
    source: 'network' | 'material'; // 来源
    stickerId?: string; // 贴图id
  };
}

// 样式属性资源配置
export interface Style {
  objectId: string; // style-697-1
  fileName: string; // style-697-1.json
}

export interface Video {
  objectId: string;
  // 当编辑模板背景时，下面3个字段需要替换掉成实际的图片信息
  fileName: string; // 文件名
  fileKey: string; // 该视频的地址key
  fileUrl: string; // url地址
  extra?: {
    web?: {
      backgroundCoverUrl?: string; // web端截图封面 url
    };
  };
}

// 画布比例 如 9:16
export interface Aspect {
  num: number;
  den: number;
}

// 视频背景
export interface Background {
  mimeType: 6 | 7 | 8; // 类型  7是图片 6是颜色 8是视频
  sourceId: string; // 如果是图片则引用自footage的images，是颜色则引用footage的colors
}

// 主题主体配置信息
export interface GlobalConfigure {
  width: number; // 画布宽
  height: number; // 画布高
  aspect: Aspect; // 画布比例 如 9:16
  background: Background; // 视频背景
}

export interface LayerExtra {
  scene?: {
    id: string;
    name: string;
    type: number;
  };
  hidden?: boolean;
  /** 插图图层是否关闭动画 */
  hiddenAnimation?: boolean;
}

export interface VectorObj {
  x: number;
  y: number;
  z: number;
}

// 标题/文本/图片配置
export interface Layer {
  /**
   * 1是背景
   * 2是图片
   * 3是标题
   * 4是正文
   * 8贴图
   * 6是水印（没用到）
   * 9是文字贴图
   * 10是时间日期文字贴图
   * 11是地理定位文字贴图
   */
  usage: 1 | 2 | 3 | 4 | 8 | 6 | 9 | 10 | 11;
  // 文本框的高度和宽度
  height: number; // 高
  width: number; // 宽
  originWidth?: number;
  originHeight?: number;
  originImageHeight?: number;
  originImageWidth?: number;
  transform: {
    anchor: number[];
    position: number[]; // 文本显示位置[360,253,0]
    scalar: number[];
    rotationX: number;
    rotationY: number;
    rotationZ: number;
  };
  flip?: VectorObj;
  scaleMode?: 0 | 1 | 2; // 缩放模式 0-不缩放，按照原图大小显示 1-把图像缩放到：宽或者高撑满屏幕，不足部分留黑边 2-把图像缩放到：把屏幕撑满，多余部分裁剪掉
  sourceId: string; // 图片来源id
  editFlags: number;
  zIndex?: number; // 层级关系，前端使用，根据数组顺序决定层级关系呢
  // 文字动画
  textAnimation?: {
    duration: number; // 动画时长，单位微秒
    sourceId: string; // 使用服务端返回的贴图的id，对应footage的 objectId
  };
  extra?: LayerExtra;
  /** 图片信息 */
  imageInfo: {
    imageUrl: string;
    fileName: string;
    imageKey: string;
  };
  objectId: string;
  usageName: string;
}

// 颜色配置
export interface Color {
  color: string;
  objectId: string;
  // 这两个无用字段，可能会返回，保存时要删掉
  width?: number;
  height?: number;
}

// 文本资源设置 包含标题和字幕
export interface TextDetail {
  usage: TextUsage; // 3是标题 ，4是文本，9是文字贴图
  features: number; // https://datouxiongdi.feishu.cn/wiki/wikcnGzda4wT0Qu6DSbaMWuP7Dh#eGAY9E
  content: string; // style-679-1里也有content; 底层渲染时以这里的footage.texts的content为准
  contents?: string[];
  styleId: string;
  objectId: string;
  charAttrs?: CharAttr[]; // (可选) 每个字独立属性; 继承自上面的属性  目前图文快剪时前端有用到
  maxLines?: number; // 最大行数
  // 以下属性覆盖OptionalField里的
  fontSize: number;
  width: number;
  height: number;
  kerning: number;
  leading: number;
  position: {
    x: number;
    y: number;
  };
  extra?: {
    stickerId: string;
  };
}

export type Resources = TextDetail | Color | Image | Style | Video;

interface FootageFont {
  faceName: string;
  fontName: string;
}

export interface Footage {
  // 主题素材信息
  texts: TextDetail[]; // 字幕 或 标题。 注意该数组即使只修改某一个对象，也需要数组整个值都传过来
  colors: Color[]; // 颜色配置
  images: Image[]; // 图片配置
  styles: Style[]; // 样式属性资源配置
  fonts: FootageFont[];
  // 视频配置
  videos: Video[];
  // 以下字段不一定存在，要看原始主题zip包里是否有该字段
  shapes?: unknown[];
  curves?: unknown[];
  effects: unknown[];
  targets?: unknown[];
  tracks?: unknown[];
  audios?: unknown[];
}

export enum PatternStyle {
  /** 文字 */
  text = 0,
  /** 描边 */
  stroke = 1,
  /** 背景颜色 */
  bgColor = 6,
  /** 背景图片 */
  image = 8,
}

// https://datouxiongdi.feishu.cn/wiki/wikcnDYnEKxYTcbdYPe5aDIU5pd
export interface Pattern {
  style?: PatternStyle;
  alpha?: number;
  width?: number;
  color?: string;
  angle?: number;
  distance?: number;
  softness?: number;
  point1?: number;
  point2?: number;
  color1?: string;
  color2?: string;
  height?: number;
  sourceId?: string;
  fileName?: string;
  ninePatch?: {
    // .9可伸缩区域
    h: number;
    w: number;
    x: number;
    y: number;
  };
}

export interface Padding {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export type TextUsage = 3 | 4 | 9 | 10 | 11;

export interface FontStyle {
  usage: TextUsage;
  width: number;
  height: number;
  degree?: number;
  hAlign: number;
  vAlign: number;
  justify: number;
  leading: number;
  kerning: number;
  content: string;
  contents?: string[];
  features: number; // https://datouxiongdi.feishu.cn/wiki/wikcnGzda4wT0Qu6DSbaMWuP7Dh#eGAY9E
  objectId?: string;
  fontSize: number;
  boxShift?: number;
  format?: string;
  fontDesc: {
    style?: string;
    family?: string;
    faceName?: string;
    fontName?: string;
    showName?: string;
    fileName?: string;
  };
  padding: Padding;
  // 字体的样式 底层文档 https://datouxiongdi.feishu.cn/wiki/wikcnDYnEKxYTcbdYPe5aDIU5pd
  patterns: Pattern[];
  originPadding?: Padding;
  fileName?: string;
}

export interface OptionalField {
  [key: string]: FontStyle;
}

export interface ThemeDetailItem {
  id: string; // 主题id
  /** 镜头id */
  sceneId: string;
  name: string; // 主题名
  coverUrl: string; // 主题封面
  coverKey: string; // 主题封面key
  backgroundId: string; // 官方背景id
  titleBgColor?: string; // 标题背景色，如果该值存在，需要设置全部标题背景色
  module: string; // 所属业务模块的主题 video-图文快剪  live-直播快剪
  maskEffect: string; // 蒙层图片地址
  footage: Footage;
  configure: GlobalConfigure; // 主题主体配置信息
  composition: {
    // 轨道信息
    layers: Layer[];
  };
  // 注意该字段里的属性名是不确定的
  optionalFields: OptionalField; // 主题一些额外的配置信息
  /** 模板描述 */
  brief: string;
  /** 预览视频 */
  preVideoUrl: string;
  /** 默认数字虚拟人 */
  presetIpImageId: string;
  /** 我的 ip 形象 */
  ipImageId: string;
  avatarFigureId?: string;
  originalThemeId: string;
  /** 主题方向 */
  orientation: 'vertical' | 'horizontal';
  /** 主题标题 */
  title?: string;
}

export interface ScenesNamespace {
  id: string;
  allTexts: AllTexts[];
  captions: Array<ICaptions>;
  /**
   * 图片或视频，要按顺序存放。多场景模式下使用，
   * 内部对象格式和原 documentJson figures 内一致
   */
  materials?: Material[];
  // 配音id
  dubId?: string;
  // 语速
  dubSpeed?: EDubSpeed;
  /** ip形象ID，与预设IP形象ID冲突，提交时只能选其一 */
  ipImageId: string;
  /** 预设IP形象ID，与IP形象ID冲突，提交时只能选其一 */
  presetIpImageId: string;
  /** 大头数字人 与上面两个冲突 */
  avatarFigureId: string;
  /** 录音文件url */
  recordUrl?: string;
  /** 录音时长（单位 秒） */
  recordDuration?: number;
  /** 封面地址 */
  coverUrl: string;
  /** 内部字段同原先模板模板详情 */
  themeInfo: ThemeDetailItem;
  /** 录音asr文本 */
  recordText?: string;
}

export interface DocDetail {
  /** 判断版本  normal 单场景  multiple 多场景 */
  sceneMode?: string;
  id: string;
  imageThumbnailUrl: string;
  imageUrl: string;
  labelId: string[];
  labelName: string[];
  musicId: string;
  musicSource?: 'network' | 'material';
  musicUrl: string;
  musicVolume: number;
  permission: string;
  showText: string;
  themeInfo: Record<string, unknown>;
  title: string;
  updatedTime: string;
  guideStatus: boolean;
  durationUS?: number; // new 文本时长
  production: string;
  documentId: string;
  musicKey?: string;
  imageKey?: string;
  duration?: number;
  text?: string;
  // 给底层渲染的正文数据
  jsonText: {
    // 文档标题
    title: string;
    // 镜头数据
    scenes: ScenesNamespace[];
  };
  /** 是否为旧文档 */
  isConvert?: boolean;
}
