import { Tooltips } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { TextStruc } from '@/models/CmpStruc';
import style from './FontAlign.module.less';

const AlignType = {
  center: 'center',
  left: 'left',
  right: 'right',
};

const alignList = [
  {
    label: '左对齐',
    icon: 'icon-a-text_icon_left2x',
    value: AlignType.left,
  },
  {
    label: '居中对齐',
    icon: 'icon-a-text_icon_center12x',
    value: AlignType.center,
  },
  {
    label: '右对齐',
    icon: 'icon-a-text_icon_right2x',
    value: AlignType.right,
  },
];

interface FontAlignProps {
  model: TextStruc;
  className?: string;
}

export default function FontAlign(props: FontAlignProps) {
  const { className, model } = props;

  const changeAlign = (textAlign: string) => {
    model.updateStyle({ textAlign });
  };

  const tips = (
    <div className={style['font-align-content']}>
      {alignList.map(item => (
        <div
          onClick={() => changeAlign(item.value)}
          key={item.icon}
          className={style['font-align-item']}
        >
          <i style={{ fontSize: 24 }} className={cs('iconfont', item.icon)} />
          <span className={style['font-align-label']}>{item.label}</span>
        </div>
      ))}
    </div>
  );

  return (
    <Tooltips
      trigger="click"
      className={style['tooltips-conponent']}
      tips={tips}
      placement="bottomLeft"
    >
      <div className={className}>
        <Tooltips tips="排版" placement="bottom" space={20}>
          <i
            className={cs(
              style['font-align-icon'],
              'iconfont icon-a-text_icon_center12x'
            )}
          />
        </Tooltips>
      </div>
    </Tooltips>
  );
}
