import { useState, useRef, useEffect } from 'react';
import { Member } from '@bhb-frontend/mtv-navbar';
import { Modal } from '@bhb-frontend/lithe-ui';
import { appConfig } from '@/store';
import { IOptions } from './type';
import './style.less';

type VipDialogType = React.FC & {
  show?: (payload: IOptions) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const VipDialog: VipDialogType = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    VipDialog.show = show;
    return () => {
      VipDialog.show = show;
    };
  }, []);

  /** 弹窗配置 */
  const optionsRef = useRef<IOptions>({
    title: '',
    content: '',
    module: 'video',
    isDigitalManGuide: false,
  });

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
    // todo 弹窗出现的时候 body回取消滚动条， 可能是两个弹窗切换的太快导致滚动出现 暂时这样处理
    setTimeout(() => {
      Member.show({
        module: optionsRef.current.module,
        vipParam: optionsRef.current?.vipParam,
      });
    }, 300);
  };

  /**
   * 处理数字人引导
   */
  const handleDigitalManGuide = () => {
    window.open(appConfig.config.figure2dContactLink, '_blank');
  };

  const show = (options: IOptions) => {
    Object.assign(optionsRef.current, options);
    setVisible(true);
  };

  return (
    <Modal
      visible={visible}
      className="vip-guidance-dialog"
      width={335}
      height={180}
      closable={false}
    >
      <div className="vip-guidance-dialog-title">
        {optionsRef.current.title}
      </div>
      <div className="vip-guidance-dialog-content">
        {optionsRef.current.content}
      </div>
      <div className="vip-guidance-dialog-footer">
        <div onClick={handleCancel}>取消</div>
        {optionsRef.current.isDigitalManGuide ? (
          <div onClick={handleDigitalManGuide}>联系商务</div>
        ) : (
          <div onClick={handleOk}>购买会员</div>
        )}
      </div>
    </Modal>
  );
};

export default VipDialog;
