import { Tooltips } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { observer } from 'mobx-react';
import { TextStruc } from '@/models/CmpStruc';
import ColorSelector from '../ColorSelector';
import style from './FontStroke.module.less';

interface FontStrokeProps {
  className?: string;
  model: TextStruc;
}

// 默认描边宽度
const DEFAULT_STROKE_WIDTH = 8;
// 默认描边透明度
const DEFAULT_STROKE_ALPHA = 100;

function FontStroke(props: FontStrokeProps) {
  const { className, model } = props;
  const { fill } = model;

  const changeColor = (strokeColor: string) => {
    const fillData = { ...fill, strokeColor };
    if (!fill?.strokeWidth) {
      Object.assign(fillData, { strokeWidth: DEFAULT_STROKE_WIDTH });
    }
    if (!fill?.strokeAlpha) {
      Object.assign(fillData, { strokeAlpha: DEFAULT_STROKE_ALPHA });
    }

    model.update({ fill: fillData });
  };

  const updateStrokeWidth = (strokeWidth: number) => {
    if (strokeWidth >= 0 && strokeWidth <= 20) {
      model.update({ fill: { ...fill, strokeWidth } });
    }
  };

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const width = Number(e.target.value);
    updateStrokeWidth(width);
  };

  const onChangeWidth = (num: number) => {
    const strokeWidth = (fill?.strokeWidth || 0) + num;
    updateStrokeWidth(strokeWidth);
  };

  const clearStroke = () => {
    const fillData = { ...fill, strokeWidth: 0 };
    Reflect.deleteProperty(fillData, 'strokeColor');
    model.update({ fill: fillData });
  };

  const clearStrokeBtn = (
    <div onClick={clearStroke} className={style['clear-stroke-btn']}>
      清除描边
    </div>
  );

  const strokeSize = (
    <div className={style['stroke-size-container']}>
      <div className={style['stroke-size-title']}>描边大小</div>
      <div className={style['stroke-size-btn']}>
        <input
          className={style.input}
          min="0"
          max="20"
          type="number"
          onInput={onInput}
        />
        <div className={style.view}>{fill?.strokeWidth || 0}</div>
        <div
          className={cs(style.btn, style['btn-add'])}
          onClick={() => onChangeWidth(1)}
        >
          <i
            className={cs(
              'iconfont',
              'icon-a-live_icon_pullup',
              style['btn-icon']
            )}
          />
        </div>
        <div
          className={cs(style.btn, style['btn-sub'])}
          onClick={() => onChangeWidth(-1)}
        >
          <i
            className={cs(
              'iconfont',
              'icon-live_icon_pull-down',
              style['btn-icon']
            )}
          />
        </div>
      </div>
    </div>
  );

  return (
    <ColorSelector
      changeColor={changeColor}
      color={fill?.strokeColor}
      prefixNode={clearStrokeBtn}
      suffixNode={strokeSize}
    >
      <span>
        <Tooltips tips="字体描边" placement="bottom" space={15}>
          <div className={className}>
            <i
              className={cs(
                style['font-stroke-icon'],
                'font-stroke iconfont icon-text_icon_border'
              )}
            />
            <div
              style={{
                background: fill?.strokeColor,
              }}
              className={cs(style['color-panel'], {
                [style.border]:
                  !fill?.strokeColor ||
                  fill?.strokeColor?.toLowerCase() === '#ffffff',
              })}
            />
          </div>
        </Tooltips>
      </span>
    </ColorSelector>
  );
}
export default observer(FontStroke);
