import { calcRotatedPoint } from '@bhb-frontend/toolbox';
import CmpStruc from '@/models/CmpStruc';
import { getRectCenter } from '@/utils/math';
import { Axis } from '@/types/canvas';

/**
 * 返回排序后的组件
 * @param cmps 组件集合
 * @param axis 坐标轴
 */
export function sortCmps(
  cmps: CmpStruc[],
  axis: Axis,
  min: number
): CmpStruc[] {
  const sorted = cmps.sort((a, b) => {
    const ax1 = a.getNumPixel(axis);
    const ax2 = b.getNumPixel(axis);
    min = Math.min(min, ax1, ax2);
    return ax1 - ax2;
  });

  return sorted;
}

/**
 * 获取坐标点下面的组件
 * @param point 坐标点
 */
export function getCmpsByPoint(cmps: CmpStruc[], point: Point): CmpStruc[] {
  return cmps.reduce((cpms: CmpStruc[], curCpm: CmpStruc) => {
    if (!curCpm.isLock) {
      const { width = 0, height = 0, left = 0, top = 0 } = curCpm.style;
      const { z: rotate } = curCpm.rotation;
      const center = getRectCenter(+width, +height, +left, +top);
      const p = calcRotatedPoint(point, center, -rotate);
      const isPointInRect =
        p.x > left && p.x < +left + +width && p.y > top && p.y < +top + +height;
      if (isPointInRect) {
        cpms.push(curCpm);
      }
    }
    return cpms;
  }, []);
}
