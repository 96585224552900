import { ImageStruc } from '@/models/CmpStruc';
import { makeImage } from './image';

let canvas: HTMLCanvasElement | null = null;
let ctx: CanvasRenderingContext2D | null = null;

/**
 * 获取图片下鼠标点击的不透明度
 * @param point 坐标点，在图片内部的坐标
 * @param cmp 组件
 * @result 透明度 0 - 1
 */
export async function getMousePointOpacityInImage(
  point: Point,
  ImageCmp: ImageStruc
): Promise<number> {
  if (!canvas || !ctx) {
    canvas = document.createElement('canvas');
    ctx = canvas.getContext('2d');
  }
  ctx?.clearRect(0, 0, canvas.width, canvas.height);
  const { url, style } = ImageCmp;
  const { width = 0, height = 0 } = style;
  const image = await makeImage(url, +width, +height);
  canvas.width = +width;
  canvas.height = +height;
  ctx?.drawImage(image, 0, 0, +width, +height);
  const data = ctx?.getImageData(point.x, point.y, 1, 1).data;
  if (!data) return 1;
  /** 透明度的值为0 - 255， 除以255得到 0 - 1 */
  return data[3] / 255;
}
