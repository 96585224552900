import { Toast } from '@bhb-frontend/lithe-ui/lib';
import { FileType } from '@bhb-frontend/wasm/lib/fileType';
import { typeSuffixMap } from '@/constants/file';

/**
 * file to base64 url
 */
export function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader?.result);
    };
    // is err
    reader.onerror = error => {
      reject(error);
    };
  });
}

/**
 * 获取图片 file 的信息及图片 blob 类型的图片地址
 * @param file File
 * @param autoDestory 是否自动销毁生成的 url，默认 true
 * @return img 图片及其信息
 * @return destoryFn 销毁内存中的生成的 图片 blob url
 */
export function fileToImg(
  file: File,
  autoDestory = true
): Promise<{ img: HTMLImageElement; destoryFn: () => void }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.src = url;
    img.crossOrigin = '*';
    img.onerror = function () {
      URL.revokeObjectURL(url);
      reject(new Error('获取宽高失败'));
    };
    img.onload = function () {
      const destoryFn = () => {
        URL.revokeObjectURL(url);
      };
      autoDestory && destoryFn();
      resolve({ img, destoryFn });
    };
  });
}

/**
 * 压缩图片分辨率
 * @param imgFile 图片文件
 * @param newWidth 自定义宽度/宽度 || 默认：200
 * @param imageType 文件类型 || 默认：'image/jpeg'
 * @param imageArguments 质量参数 || 默认: 0.7
 */
export function handleCompressImage(imgFile: File) {
  return (newWidth = 200, newHeight = 200, imageType = 'image/jpeg') =>
    (imageArguments = 0.7): Promise<Blob> =>
      new Promise(resolve => {
        fileToImg(imgFile).then(({ img }) => {
          if (img) {
            // 创建一个临时画布来绘制缩小后的图像
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            // 在画布上绘制缩小后的图像并返回新的数据URL
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(img, 0, 0, newWidth, newHeight);
              canvas.toBlob(
                blob => {
                  blob && resolve(blob);
                },
                imageType,
                imageArguments
              );
            }
          }
        });
      });
}

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  if (arr.length) {
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  return null;
}

/** 根据图片url转为png文件对象 */
export function getImageFileFromUrl(
  url: string,
  imageName: string
): Promise<File> {
  return new Promise((resolve, reject) => {
    // let blob = null;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.setRequestHeader('Accept', 'image/png');
    xhr.responseType = 'blob';
    // 加载时处理
    xhr.onload = () => {
      if (xhr.status >= 400) {
        reject(url);
      }
      // 获取返回结果
      const blob = xhr.response;
      const imgFile = new File([blob], imageName, { type: 'image/png' });
      // 返回结果
      resolve(imgFile);
    };
    xhr.onerror = () => {
      reject(url);
    };
    // 发送
    xhr.send();
  });
}

/**
 * 压缩图片分辨率
 * 以宽高最大的为基准
 */
export function compressImage(imgFile: File) {
  return (boundary = 200, imageType = 'image/jpeg') =>
    (imageArguments = 0.7): Promise<string | File> =>
      new Promise((resolve, reject) => {
        if (['gif', 'webp'].some(item => imageType.includes(item))) {
          // 动态图直接返回
          resolve(imgFile);
          return;
        }
        toBase64(imgFile).then((res: any) => {
          if (res) {
            // 构建一个image元素
            const image: HTMLImageElement = new Image();
            image.src = `${res}`;
            // img元素加载
            image.onload = () => {
              // 初始化变量
              let [oldWidth, oldHeight, newHeight, newWidth, newDataUrl] = [
                0,
                0,
                0,
                0,
                '',
              ];

              // 创建一个临时图像，计算缩小后的图像的高度
              oldWidth = image.width;
              oldHeight = image.height;

              if (oldWidth <= boundary && oldHeight <= boundary) {
                // 宽高都小于边界值， 无需压缩直接返回原文件
                resolve(imgFile);
                return;
              }

              // 需要压缩的场景
              if (oldWidth >= oldHeight) {
                // 以宽为基准
                newHeight = Math.floor((oldHeight / oldWidth) * boundary) || 0;
                newWidth = boundary;
              }

              if (oldWidth < oldHeight) {
                // 以宽为基准
                newHeight = boundary;
                newWidth = Math.floor((oldWidth / oldHeight) * boundary) || 0;
              }

              // 创建一个临时画布来绘制缩小后的图像
              const canvas = document.createElement('canvas');
              canvas.width = newWidth;
              canvas.height = newHeight;

              // 在画布上绘制缩小后的图像并返回新的数据URL
              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.drawImage(image, 0, 0, newWidth, newHeight);
                newDataUrl = canvas.toDataURL(imageType, imageArguments);
                resolve(newDataUrl);
              }
            };
            image.onerror = reject;
          }
        });
      });
}

/** blob 转 base64 */
export function blobToDataUrl(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const content = (reader.result as string).split(/,/)[1];
      if (content) resolve(reader.result as string);
      else reject(new Error('DataUrl 为空'));
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

/** 通过路径获取文件名 */
export function getFileNameFromPath(path?: string) {
  if (!path) return '';
  const arr = path.split('/');
  return arr[arr.length - 1];
}

/**
 * file to ArrayBuffer
 */
export function toArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader?.result as ArrayBuffer);
    };
    // is err
    reader.onerror = error => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

/** 文件类型校验 */
export function validateFileType(type: string, accept: string[]) {
  const [, suffix] = type.split('/');
  return accept.includes(suffix);
}

const wavPattern = ['audio/x-wav', 'audio/wav'];

/** 对单个文件 真是的类型进行校验 */
export async function validateFile(file: File, accept: string[]) {
  if (!file) return null;
  const type = file.type;
  try {
    const arrayBuffer = await toArrayBuffer(file);
    const { code, data } = await FileType.checkFileType(arrayBuffer);
    const strData = data as string;
    // 获取类型失败
    if (code !== 0) return null;
    // 特殊处理 wav
    if (wavPattern.includes(strData) && wavPattern.includes(type)) {
      return file;
    }
    // 上传类型与真实类型一致
    if (type === strData) return file;

    // 文件类型不一致，但文件真实的类型符合，生成一个新的文件
    const dataSuffix = typeSuffixMap[strData];
    if (accept.includes(dataSuffix)) {
      const newFileName = file.name.replace(/\.[^/.]+$/, `.${dataSuffix}`);
      const newFile = new File([file], newFileName, { type: strData });
      return newFile;
    }
  } catch (error) {
    console.log('上传类型处理失败，上传原文件', error);
    return file;
  }

  return null;
}

/** 文件过滤 */
export async function validateArrayFile(
  oriFiles: File[],
  accept: string[],
  errorMsg = '已过滤不符合的文件'
): Promise<File[]> {
  const promiseArr = oriFiles.map(file => validateFile(file, accept));

  const allSettle = await Promise.allSettled(promiseArr);
  // 获取符合条件的file
  const files = allSettle.reduce((res, item) => {
    const { status } = item;
    if (status === 'fulfilled' && item.value !== null) {
      res.push(item.value);
    }
    return res;
  }, [] as Array<File>);

  if (files.length !== oriFiles.length) {
    Toast.error(errorMsg);
  }
  return files;
}

/** 获取可上传的图片 */
export async function getUploadImage(
  orgFile: File,
  accept: string[] = ['jpg', 'jpeg', 'png', 'gif', 'webp']
) {
  const file = await validateFile(orgFile, accept);
  if (!file) {
    Toast.error('文件类型错误');
    return null;
  }

  return file;
}
