import request from '@/api/init';
import { DetailResponse } from '@/types/api';

import { InterUserItems, StatusStatement } from '@/types/user';

// const host = '';

/** 获取用户信息（自己） */
export function getUserInfo(): Promise<DetailResponse<InterUserItems>> {
  return request.get('/users/info');
}

/**
 * 修改配音合规提示开关
 */
export function updateDubbingTips(data: {
  dubbingLegalTip: StatusStatement;
}): Promise<DetailResponse<InterUserItems>> {
  return request.put('/users/info/dubbing_legal_tip', data);
}
