import { makeAutoObservable } from 'mobx';
import { ChannelAppConfig } from '@/types/config';
import { getChannelAppConfig } from '@/api/configuration';
import { getWindow } from '@/utils/addEnv';

export default class ChannelAppStore {
  public config: Partial<ChannelAppConfig> = {};

  constructor() {
    makeAutoObservable(this);
    this.getChannelConfig();
  }

  /** 设置窗口信息 */
  private setWindowInfo(config: ChannelAppConfig) {
    const win = getWindow();
    // TODO: 下个版本不需要使用这个name字段，使用最新跟服务端约定的字段
    const { name, logo } = config;
    if (win && name) {
      win.document.title = name;
    }
    if (logo) {
      const favicon = document.createElement('link');
      favicon.rel = 'shortcut icon';
      favicon.type = 'image/x-icon';
      favicon.href = logo;
      document.head.appendChild(favicon);
    }
  }

  public setConfig(config: ChannelAppConfig) {
    this.config = config;
  }

  async getChannelConfig() {
    try {
      const { data } = await getChannelAppConfig();
      this.setConfig(data);
      this.setWindowInfo(data);
    } catch (err) {
      console.log(err);
    }
  }
}
