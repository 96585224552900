import { observer } from 'mobx-react';
import { ListQuery } from '@/types/api';
import { StickerDetail } from '@/types/material';
import { getTextStickerList } from '@/api/material';

import GetListWrapper, {
  GetListWrapperProps,
} from '@/components/Material/GetListWrapper';
import TextList from './TextList';

import Style from './style.module.less';
import { useStores } from '@/store';

function Sticker() {
  const { app } = useStores();

  const parmas: GetListWrapperProps<ListQuery, StickerDetail> = {
    apiFn: getTextStickerList,
    payload: {
      sid: '',
      pageSize: 20,
    },
  };

  const addText = () => {
    app.activedScene.addTextSticker({
      source: 'network',
      textConfig: {},
    });
  };

  return (
    <>
      <div className={Style.addText} onClick={addText}>
        添加文字
      </div>
      <div className={Style.tab}>气泡文字</div>
      <GetListWrapper {...parmas}>
        <TextList />
      </GetListWrapper>
    </>
  );
}

export default observer(Sticker);
