/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/4adab16af5431509c23eff6e185b396a.png */
  'add.png': '4adab16af5431509c23eff6e185b396a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/56cfadc4d7f3dd2748cfebe589ad3e01.png */
  'menu-add.png': '56cfadc4d7f3dd2748cfebe589ad3e01.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d333076d5acd9e7a290eb820c229889d.png */
  'menu-copy.png': 'd333076d5acd9e7a290eb820c229889d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/b90e1c9a889d4425348549f83f8aa8c0.png */
  'icon_speed.png': 'b90e1c9a889d4425348549f83f8aa8c0.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/dba408f3365360d2a8d6f8c56af402c1.png */
  'weixin.png': 'dba408f3365360d2a8d6f8c56af402c1.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/adf334978a1418fc27d9a32986afb291.png */
  'zhifubao.png': 'adf334978a1418fc27d9a32986afb291.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/aef8cb718d893653a3c82aeb36cbbe9d.png */
  'icon_time.png': 'aef8cb718d893653a3c82aeb36cbbe9d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2e738add23180bb65f746e5bbaee0e13.png */
  'icon_video.png': '2e738add23180bb65f746e5bbaee0e13.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/15b44622377433978619bc2c5d620088.png */
  'big-head.png': '15b44622377433978619bc2c5d620088.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/efbbc1066d83720a4e530dc6ac7fcd59.png */
  'recording_icon_play.png': 'efbbc1066d83720a4e530dc6ac7fcd59.png',
};
