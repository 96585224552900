import screenshot from '@/core/Screenshot';
import { fontStore, app } from '@/store';
import { CANVAS_REF } from '@/constants/Refs';
import Uploaders from './upload';

function getFont(): Record<string, string> {
  const { fontList } = fontStore;
  return fontList.reduce((fontMap, fontInfo) => {
    const { fontName, faceName, woff2Url } = fontInfo;
    return {
      ...fontMap,
      [fontName]: woff2Url,
      [faceName]: woff2Url,
    };
  }, {});
}

/** 获取封面信息 */
export async function getCoverInfo() {
  const fontMap = getFont();
  const node = CANVAS_REF.current?.firstChild as HTMLElement;

  const { configure } = app.activedScene;

  const blob = await screenshot(node, {
    fontMap,
    style: { transform: 'none' },
    width: configure.width,
    height: configure.height,
  });
  const suffix = blob.type.split('/')[1];
  const file = new File([blob], `${new Date().getTime}.${suffix}`);
  const uploader = new Uploaders(file);
  uploader.addModule('video');
  uploader.addScene('document');

  return new Promise<{ url: string; key?: string; coverUrl?: string }>(
    (resolve, reject) => {
      uploader.upload().then(res => {
        if (res.url && res.key) {
          resolve(res);
        }
        reject(new Error('图片key不存在'));
      });
    }
  );
}
