/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/4c7279c7de0400cc46ccceccc870d899.png */
  'icon-live-stop.png': '4c7279c7de0400cc46ccceccc870d899.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/1e8f46488a370e30cb48743446e6b9df.png */
  'icon-music-random.png': '1e8f46488a370e30cb48743446e6b9df.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/006695cd78e87f445e4a3f4af79c8940.png */
  'icon-video-mute.png': '006695cd78e87f445e4a3f4af79c8940.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/6d78f228b5ab916c7eb223b31969bc55.png */
  'icon-video-start.png': '6d78f228b5ab916c7eb223b31969bc55.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/618aa041314d33a4b8687c96a7c3a2df.png */
  'icon-video-stop.png': '618aa041314d33a4b8687c96a7c3a2df.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/60b8983c3f2c638ddcd3d590b2025585.png */
  'icon-vip-grey-big.png': '60b8983c3f2c638ddcd3d590b2025585.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/1e2f833f5952bdc548631149d3839463.png */
  'icon-vip-grey-small.png': '1e2f833f5952bdc548631149d3839463.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2de204f315c39b257f460d8ada7d45b6.png */
  'icon-vip-red-big.png': '2de204f315c39b257f460d8ada7d45b6.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/37262802d7ee66017d4834548b2f1673.png */
  'icon-vip-red-small.png': '37262802d7ee66017d4834548b2f1673.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5b59d21f9500a0c37408d42efef7d306.png */
  'img-clip-bg.png': '5b59d21f9500a0c37408d42efef7d306.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/aa0b5f6c9a4d0503e5216efbff7f8ddb.png */
  'img-video-generafailed.png': 'aa0b5f6c9a4d0503e5216efbff7f8ddb.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/6d58f20c724bc5f78c09e6f9d4373d04.png */
  'img-video-renderfailed.png': '6d58f20c724bc5f78c09e6f9d4373d04.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/23bfbf9bc5d12b5f9ce5d102506e1bbd.png */
  'img-video-rendering.png': '23bfbf9bc5d12b5f9ce5d102506e1bbd.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e513f30929efe2edb2aae193426c5288.png */
  'img-vip-bg.png': 'e513f30929efe2edb2aae193426c5288.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/42b845fb1fd267ee45cd5520783161ba.png */
  'live_icon_empty.png': '42b845fb1fd267ee45cd5520783161ba.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/6ebd10ea5b2cc357026f1f3e21ef9da4.svg */
  'live_icon_filter.svg': '6ebd10ea5b2cc357026f1f3e21ef9da4.svg',

  /* https://bhb-frontend.bhbcdn.com/static/files/474adc37de70902ad257d8e1be3bbb58.png */
  'zhibo_icon_loading.png': '474adc37de70902ad257d8e1be3bbb58.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/b6aff01e76b37b5e1ac0701606b9422a.png */
  'icon-cut-slices.png': 'b6aff01e76b37b5e1ac0701606b9422a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ac9516cd262d211ea351443112df31e3.png */
  'privilege_icon_cut_slices.png': 'ac9516cd262d211ea351443112df31e3.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/29010860483f1ed0b8bab4e6b60b07d5.png */
  'live_image_AIcut.png': '29010860483f1ed0b8bab4e6b60b07d5.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/3969ee94f0d83820fb6143161637d4a1.png */
  'live_icon_slice_left.png': '3969ee94f0d83820fb6143161637d4a1.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/438ce703de9364d7639cf823c9e3849a.png */
  'live_icon-slice_right.png': '438ce703de9364d7639cf823c9e3849a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d9a9dfe8595fbebb472cce758038cc05.png */
  'live_icon_point.png': 'd9a9dfe8595fbebb472cce758038cc05.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/915e315f74237a64af21518fa8834928.png */
  'live_icon_refresh.png': '915e315f74237a64af21518fa8834928.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/619ced36aebe93e943f5cf721748ae43.png */
  'live_icon_volume.png': '619ced36aebe93e943f5cf721748ae43.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e02317276f64cc32d0c8c7527d9cdcab.png */
  'live_icon_cut@2x.png': 'e02317276f64cc32d0c8c7527d9cdcab.png',
};
