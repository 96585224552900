import cs from 'classnames';
import { observer } from 'mobx-react';
import { MusicDetail } from '@/types/material';
import Style from './style.module.less';
import image from '@/assets/images';
import formatDuration from '@/utils/formatDuration';
import { MusicTabTypes } from './const';
import { useStores } from '@/store';

interface MusicListItemProps {
  item: MusicDetail;
  switchMusic: () => void;
  /** 选中 */
  onSelect?: (val: MusicDetail) => void;
  /** 删除 */
  onRemove?: (val: MusicDetail) => void;

  isPlaying: boolean;
  /** 倒计时播放时间 */
  time: number;

  /** 音乐资源类型 */
  type?: MusicTabTypes;
}

function MusicListItem(props: MusicListItemProps) {
  const { item, time, switchMusic, onSelect, isPlaying, onRemove, type } =
    props;

  const { app } = useStores();
  const currentTime = isPlaying && time ? time : item.duration;

  const handleSelect = () => {
    onSelect?.(item);
  };

  return (
    <div className={`${Style.musicItem}`} onClick={handleSelect}>
      <div
        className={Style.musicItemCover}
        onClick={e => {
          e.stopPropagation();
          switchMusic();
        }}
      >
        {item.coverUrl ? (
          <img className={Style.musicItemCoverImg} src={item.coverUrl} alt="" />
        ) : (
          <div
            className={cs(
              'iconfont',
              'icon-doc_icon_music_pre',
              Style.musicItemCoverIcon
            )}
          />
        )}
        <img
          alt=""
          src={
            isPlaying
              ? image.doc['music_icon_stop.png']
              : image.doc['music_icon_play.png']
          }
          className={cs(Style.musicItemCoverPlayBtn, {
            pause: !isPlaying,
          })}
        />
      </div>
      <div className={Style.musicItemNameWrapper}>
        <div className={Style.musicItemNameWrapperName}>{item.name}</div>
        <div
          className={Style.musicItemNameWrapperDuration}
          data-t={currentTime}
          data-d={item.duration}
        >
          {currentTime
            ? formatDuration(currentTime * 1000, true)
            : formatDuration(item.duration * 1000, true)}
        </div>
      </div>
      <div className={Style.musicItemSelectWrapper}>
        {type === MusicTabTypes.My && (
          <i
            className={cs(
              'iconfont',
              'icon-a-music_icon_delete12x',
              Style.musicItemSelectWrapperDelete
            )}
            onClick={e => {
              e.stopPropagation();
              onRemove?.(item);
            }}
          />
        )}
        <div
          className={cs(Style.musicItemSelectWrapperIcon, {
            [Style.musicItemSelectWrapperSelect]: app.music?.id === item.id,
          })}
        >
          <i className="iconfont icon-a-music_icon_choose_succeed2x" />
        </div>
        {/* <div className={Style.musicItemSelectWrapperIcon}>
          <i
            className="iconfont icon-a-music_icon_delete12x"
            onClick={() => {
              // @click.stop="removeMusic(item)"
              console.log(1);
            }}
          />
        </div> */}
      </div>
    </div>
  );
}

export default observer(MusicListItem);
