import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';

import { dragAction } from '@bhb-frontend/toolbox';
import { Toast } from '@bhb-frontend/lithe-ui/lib';
import { images } from '@/assets';

import Style from './style.module.less';
import { showTime } from './helper';
import { useRefState } from '@/hooks/useRefState';
import { useStores } from '@/store';

interface ImagePreview {
  type: 'image';
  url: string;
}
interface VideoPreview {
  type: 'video';
  url: string;
  start: number;
  end: number;
  duration: number;
}

interface PreviewControl {
  close: () => void;
}

type PointType = 'right' | 'left' | 'pointer' | '';

export type PreviewType = ImagePreview | VideoPreview;
export type PreviewProps = PreviewType & PreviewControl;

function Preview(props: PreviewProps) {
  const { type, close: closeFn } = props;
  const { app } = useStores();

  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const [hiddenAni, setHiddenAni] = useState(false);
  // props 传入的起止时间，微秒
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const [videoCurTime, setVideoCurTime] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  // 拖动相关 state
  // const [curPointType, setCurPointType] = useState<PointType>('');
  // const curPointType = useRef<PointType>('left');
  const [isMoving, setIsMoving] = useState(false);
  const [isPointerMoving, setIsPointerMoving] = useState(false);

  const [range, setRange] = useRefState(() => ({
    start: 0,
    end: 0,
    pointer: 0,
    maxWidth: 0,
  }));
  const base = useRef(0);

  const [aniInfo, setAniInfo] = useState<{
    state: 'paused' | 'running';
    duration: number;
  }>(() => ({
    state: 'paused',
    duration: 1,
  }));

  const close = () => {
    setHiddenAni(true);
    setTimeout(() => {
      setHiddenAni(false);
      closeFn();
    }, 300);
  };

  const handleEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      close();
    }
  };

  useEffect(() => {
    if (type === 'video') {
      let { start, end } = props;
      start = start / 1000 / 1000 || 0;
      end = end / 1000 / 1000 || 0;

      setStart(start);
      setEnd(end);
    }
    document.addEventListener('keyup', handleEsc);

    return () => {
      console.log({ isWaiting, isLoaded });

      document.removeEventListener('keyup', handleEsc);
    };
  }, []);

  // 判断是否可以剪辑
  useEffect(() => {
    if (type === 'video') {
      const edited = range.start !== start || range.end !== end;
      setHasEdited(edited);

      // 拖拽区间需要更新当前时间
      setVideoCurTime(() => {
        const time = px2seconds(range.pointer);
        videoRef.current && (videoRef.current.currentTime = time);
        return time;
      });
    }
  }, [range]);

  const previewBoxModal = (content: JSX.Element, boxClassName?: string) => (
    <div
      className={cls(Style.previewBox, boxClassName, {
        [Style.hiddenModal]: hiddenAni,
      })}
    >
      <img
        className={Style.closeBtnImg}
        onClick={close}
        src={images.doc['common_icon_close.png']}
        alt=""
      />
      <div
        className={cls({
          [Style.hiddenContent]: hiddenAni,
        })}
      >
        {content}
      </div>
    </div>
  );

  // 图片时
  if (type === 'image') {
    const { url } = props;
    const imgContent = (
      <div className={Style.imageContent} onClick={close}>
        <img src={url} alt="" />
      </div>
    );
    return previewBoxModal(imgContent);
  }

  const { url, duration } = props;

  const px2seconds = (px: number) => (base.current ? px * base.current : 0);
  const seconds2px = (s: number) => (base.current ? s / base.current : 0);

  const play = () => {
    if (!videoRef.current) return;
    if (isEnd) {
      videoRef.current.currentTime = px2seconds(range.start);
      setRange({ ...range, pointer: range.start });
    }
    videoRef.current.play();
    setIsPlay(true);
    setIsEnd(false);
    // 动画时长
    setAniInfo({
      ...aniInfo,
      duration: px2seconds(range.end) - videoRef.current.currentTime,
    });
  };
  const pause = (isEditPause = false) => {
    if (!videoRef.current) return;
    videoRef.current.pause();
    setIsPlay(false);

    if (isEditPause) return;

    setRange({ ...range, pointer: seconds2px(videoRef.current.currentTime) });
  };

  /** 初始化进度条 */
  const onLoadedData = () => {
    if (!videoRef.current || !progressRef.current) return;
    setIsLoaded(true);
    setShowProgress(true);

    const maxWidth = progressRef.current.clientWidth;

    if (duration) {
      // 初始化 1px 转时长的倍率
      base.current = duration / maxWidth;

      const rangeStart = start / base.current;
      const rangeEnd = end / base.current;

      setRange({
        start: rangeStart,
        end: rangeEnd,
        pointer: rangeStart,
        maxWidth,
      });

      setAniInfo({
        ...aniInfo,
        duration: px2seconds(rangeEnd - rangeStart),
      });
      videoRef.current.currentTime = px2seconds(rangeStart);
    }
  };
  /** 播放区间限制 */
  const onTimeUpdate = () => {
    if (!videoRef.current) return;
    const time = videoRef.current.currentTime || 0;
    const endTime = px2seconds(range.end);
    if (time > endTime) {
      videoRef.current.currentTime = endTime;
      pause();
      setIsEnd(true);
    }
  };

  // 播放结束
  const onEnded = () => {
    setRange({ ...range, pointer: range.end });
    setIsPlay(false);
    setIsEnd(true);
  };
  // 暂停时
  const onWaiting = () => {
    setIsWaiting(true);
    setAniInfo({
      ...aniInfo,
      state: 'paused',
    });
  };
  // 继续播放
  const onCanPlay = () => {
    setIsWaiting(false);
    setAniInfo({
      ...aniInfo,
      state: 'running',
    });
  };

  const handleDrag = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    type: PointType
  ) => {
    pause(true);
    if (type === 'pointer') {
      const info = (e.target as HTMLElement).getBoundingClientRect();
      const offsetX = e.clientX - info.x;

      videoRef.current && (videoRef.current.currentTime = px2seconds(offsetX));
      setRange(old => {
        if (offsetX < old.start) {
          old.start = offsetX;
        }
        if (offsetX > old.end) {
          old.end = offsetX;
        }
        return { ...old, pointer: offsetX };
      });
    }
    dragAction(e as unknown as MouseEvent, {
      move: (e: MouseEvent) => {
        setIsMoving(true);
        if (type === 'left') {
          setRange(old => {
            const val = Math.max(0, Math.min(old.end, old.start + e.movementX));
            return { ...old, start: val, pointer: val };
          });
        } else if (type === 'right') {
          setRange(old => {
            const val = Math.min(
              old.maxWidth,
              Math.max(old.start, old.end + e.movementX)
            );
            return { ...old, end: val, pointer: val };
          });
        } else {
          setIsPointerMoving(true);
          setRange(old => {
            const pointer = Math.max(
              range.start,
              Math.min(range.end, old.pointer + e.movementX)
            );
            return { ...old, pointer };
          });
        }
      },
      end: () => {
        setIsMoving(false);
        setIsPointerMoving(false);
      },
    });
  };

  const confirm = () => {
    // px2seconds start、end
    const newList = app.activedScene.materials.map(item => {
      if (item.url === url) {
        const start = px2seconds(range.start) * 1000 * 1000;
        const end = px2seconds(range.end) * 1000 * 1000;
        return { ...item, clipRange: [start, end] };
      }
      return item;
    });
    app.activedScene.update({ materials: newList });
    Toast.success('裁剪成功！');
    close();
  };
  const style = {
    '--animation-duration': `${aniInfo.duration}s`,
    '--animation-from': `translateX(${range.pointer}px)`,
    '--animation-to': `translateX(${range.end}px)`,
  };
  const tipTimeStyle = {
    '--pointer-left': `${range.pointer - range.start}px`,
  };

  const videoContent = (
    <div className={Style.videoContent}>
      {/* 视频 */}
      <video
        ref={videoRef}
        src={url}
        onTimeUpdate={onTimeUpdate}
        onLoadedData={onLoadedData}
        onEnded={onEnded}
        onWaiting={onWaiting}
        onCanPlay={onCanPlay}
      />

      <div
        className={cls(Style.progressContainer, {
          [Style.moving]: isMoving,
        })}
        style={{ opacity: showProgress ? '1' : '0' }}
      >
        <div className={Style.playBtn}>
          <img
            src={
              isPlay
                ? images.doc['cut-icon-stop.png']
                : images.doc['cut-icon-play.png']
            }
            onClick={isPlay ? () => pause() : play}
            alt=""
          />
        </div>
        <div className={Style.progress}>
          {/* 底部进度条 */}
          <div
            ref={progressRef}
            className={Style.progressTotal}
            onMouseDown={e => handleDrag(e, 'pointer')}
          />
          {/* 控制组件 */}
          <div
            style={{ left: range.start, width: range.end - range.start }}
            className={Style.progressOuter}
          >
            <img
              className={Style.leftBtn}
              src={images.doc['cut-icon-left.png']}
              alt=""
              draggable={false}
              onMouseDown={e => handleDrag(e, 'left')}
            />
            <div className={Style.progressClip} />
            <img
              className={Style.rightBtn}
              src={images.doc['cut-icon-right.png']}
              draggable={false}
              onMouseDown={e => handleDrag(e, 'right')}
              alt=""
            />
            {/* 时间 */}
            <div
              style={{ display: isMoving ? 'block' : 'none', ...tipTimeStyle }}
              className={cls(Style.tooltip, Style.pointer)}
            >
              {showTime(videoCurTime)}
            </div>
          </div>
          {/* 指针 */}
          <i
            style={{
              display: !isMoving || isPointerMoving ? 'block' : 'none',
              transform: `translateX(${range.pointer}px)`,
              animationPlayState: aniInfo.state,
              ...style,
            }}
            className={cls(Style.timePointer, isPlay && Style.move)}
          />
        </div>
        <div className={Style.confirmBtn}>
          <div
            className={hasEdited ? Style.cutBtn : Style.disabled}
            onClick={() => hasEdited && confirm()}
          >
            修剪
          </div>
        </div>
      </div>
    </div>
  );

  return previewBoxModal(videoContent, Style.videoBox);
}

export default observer(Preview);
