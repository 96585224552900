import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useActivate } from 'react-activation';

import {
  BackgroundDetail,
  GetBackgroundParams,
  Percent,
  TabsCategory,
} from '@/types/material';
import {
  getCategoryBackgroundById,
  getBackgroundCategory,
} from '@/api/material';

import GetListWrapper from '@/components/Material/GetListWrapper';
import MaterialContent from '@/components/Material/MaterialContent';

import BackgroundList from './BackgroundList';
import AddBackground from './AddBackground';
import AddColor from './AddColor';
import { useStores } from '@/store';

function Background() {
  const { app } = useStores();

  const [percent, setPercent] = useState(Percent.Vertical);
  /** 配音分类 */
  const [backgroundCategorys, setBackgroundCategorys] = useState<
    TabsCategory[]
  >([]);
  /** 默认选择的分类 */
  const [activeCategory, setActiveCategory] = useState('');
  /** 获取分类 */
  const getCategory = async () => {
    const { data } = await getBackgroundCategory();
    const reuslts = data.results.map(({ id, name }) => ({
      label: name,
      key: id,
    }));

    setActiveCategory(reuslts[0]?.key || '');
    setBackgroundCategorys(reuslts);
  };

  const getPercent = () => {
    const aspect = app.activedScene.configure.aspect || { num: 9, den: 16 };
    const isV = aspect.den === 16;

    setPercent(isV ? Percent.Vertical : Percent.Horizontal);
  };

  useEffect(() => {
    getPercent();
    getCategory();
  }, []);

  useActivate(() => {
    getPercent();
  });

  return (
    <>
      <AddBackground />

      <AddColor />

      <MaterialContent
        categorys={backgroundCategorys}
        activeTab={activeCategory}
      >
        <GetListWrapper<GetBackgroundParams, BackgroundDetail>
          apiFn={getCategoryBackgroundById}
          payload={{ percent }}
        >
          <BackgroundList percent={percent} />
        </GetListWrapper>
      </MaterialContent>
    </>
  );
}

export default observer(Background);
