import { makeAutoObservable } from 'mobx';

import { getFontList } from '@/api/app';
import { FontItem } from '@/types/Font';

export default class FontStore {
  public fontList: FontItem[] = [];

  /** 字体缓存 */
  public fontCache: string[] = [];

  constructor() {
    makeAutoObservable(this);
    this.getFontList();
  }

  public setFontList(fontList: FontItem[]) {
    this.fontList = fontList;
  }

  public setFontCache(fontName: string) {
    this.fontCache.push(fontName);
  }

  public async getFontList() {
    try {
      const { data } = await getFontList({ pageSize: 200 });
      this.setFontList(data.results);
    } catch (error) {
      console.log(error);
    }
  }
}
