import { useEffect, useRef } from 'react';
import Style from './UploadProgress.module.less';

interface UploadProgressProps {
  complete: boolean;
  uploadEnd?: () => void;
}

function UploadProgress(props: UploadProgressProps) {
  const { complete, uploadEnd } = props;
  let timer: any = null;
  const width = useRef(70);
  /** 监听动画 */
  const onAnimationEnd = () => {
    width.current += 2;
    timer = setInterval(() => {
      if (width.current < 94) {
        width.current += 2;
      } else {
        clearInterval(timer);
      }
    }, 1200);
  };

  useEffect(() => {
    if (complete) {
      clearInterval(timer);
      width.current = 100;
      setTimeout(() => {
        uploadEnd?.();
      }, 1000);
    }
  }, [complete]);

  return (
    <div className={Style.progress}>
      <div className={Style.progressBar}>
        <div
          className={Style.progressActive}
          onAnimationEnd={onAnimationEnd}
          style={{ width: `${width.current}%` }}
          // @animationend="animationend"
        />
      </div>
    </div>
  );
}

export default UploadProgress;
