import { LoadMore } from '@bhb-frontend/lithe-ui';
import { StickerDetail } from '@/types/material';
import ImageWrapper from '@/components/ImageWrapper';
import Style from './style.module.less';
import { getAddress, getTextStickerDetailById } from '@/api/material';
import { TagsEnum } from '@/constants/Tags';
import { formatDate } from '@/utils/formatDuration';
import { formatAddress } from '@/utils/formatData';
import { useStores } from '@/store';

interface BackgroundListProps {
  /** 资源列表 */
  list?: StickerDetail[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;
}

function TextList(props: BackgroundListProps) {
  const { list, loading, hasMore, loadMore } = props;

  const { app } = useStores();

  const addText = async (item: StickerDetail) => {
    const { data } = await getTextStickerDetailById(item.id);
    const { config } = data;
    if (config.usage === TagsEnum.DATE_TEXT_STICKER) {
      // 10是日期
      config.contents = [formatDate(config.format)];
    }
    if (config.usage === TagsEnum.MAP_TEXT_STICKER) {
      // 11是地址
      const res = await getAddress();
      config.contents = [formatAddress(res.data, config.format)];
    }
    app.activedScene.addTextSticker({
      source: 'network',
      optional: config,
      textConfig: data,
    });
  };
  return (
    <div className={Style['template-content']}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        className={Style['load-more']}
        finishedText=""
      >
        <div className={Style.textList}>
          {list?.map(item => (
            <ImageWrapper
              key={item.id}
              className={Style.textListItem}
              urls={[item.coverUrl]}
              onClick={() => addText(item)}
            />
          ))}
        </div>
      </LoadMore>
    </div>
  );
}

export default TextList;
