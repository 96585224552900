/**
 * 记录一些系统设置
 */

import { makeAutoObservable } from 'mobx';
import { localUniqueid } from '@bhb-frontend/utils/lib/random';
import {
  CANVAS_GUIDE_ARRAY,
  CANVAS_SHOW_GRIDLINES,
  CANVAS_SHOW_GUIDE,
  CANVAS_SHOW_RULER,
  CANVAS_ZOOM_LEVEL,
  CANVAS_SHOW_MAGNETIC,
} from '@/constants/CacheKeys';
import LocalCache from '@/core/Manager/LocalCache';
// import AppStore from './App';
import {
  CANVAS_MAX_ZOOM_LEVEL,
  CANVAS_MIN_ZOOM_LEVEL,
} from '@/constants/ZoomLevel';
import { AxleDirection, GuideLine, MagneticLine } from '@/types/OSModel';
import { OSHistoryDecorator } from '@/core/Decorator/History';

export default class OSStore {
  /** 是否显示标尺 */
  showRuler = !!LocalCache.get(CANVAS_SHOW_RULER, 'boolean');

  /** 是否显示网格线 */
  showGridlines = !!LocalCache.get(CANVAS_SHOW_GRIDLINES, 'boolean');

  /** 是否显示辅助线 */
  showGuide = !!LocalCache.get(CANVAS_SHOW_GUIDE, 'boolean');

  /** 是否显示磁力线 */
  showMagnetic = !!LocalCache.get(CANVAS_SHOW_MAGNETIC, 'boolean');

  /** 画布缩放等级 */
  zoomLevel = LocalCache.get(CANVAS_ZOOM_LEVEL, 'number') ?? 1;

  /** 辅助线集合 */
  guidelines: GuideLine[] = LocalCache.get(CANVAS_GUIDE_ARRAY, 'object') ?? [];

  /** 激活的辅助线 */
  activedGuideLine: GuideLine | null = null;

  /** 磁力线集合 */
  magneticLines: MagneticLine[] | null = null;

  /** 是否在移动中 */
  isMoveing = false;

  /** 是否旋转中 */
  isRotateing = false;

  /** 是否拉伸中 */
  isScaleing = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 是否在编辑中
   * @readonly
   * @memberof OSStore
   */
  get isEditing() {
    return this.isMoveing || this.isRotateing || this.isScaleing;
  }

  /**
   * 是否可以放大
   * @readonly
   * @memberof OSStore
   */
  get canZoomIn() {
    return this.zoomLevel < CANVAS_MAX_ZOOM_LEVEL;
  }

  /**
   * 是否可以缩小
   * @readonly
   * @memberof OSStore
   */
  get canZoomOut() {
    return this.zoomLevel > CANVAS_MIN_ZOOM_LEVEL;
  }

  /**
   * 设置标尺状态
   * @param toogle 切换状态
   */
  setRulerVisible(toogle: boolean) {
    this.showRuler = toogle;
    LocalCache.set(CANVAS_SHOW_RULER, toogle);
  }

  /**
   * 设置网格状态
   * @param toogle 切换状态
   */
  setGridlineVisible(toogle: boolean) {
    this.showGridlines = toogle;
    LocalCache.set(CANVAS_SHOW_GRIDLINES, toogle);
  }

  /**
   * 设置辅助线状态
   * @param toogle 切换状态
   */
  setGuideVisible(toogle: boolean) {
    this.showRuler = toogle;
    LocalCache.set(CANVAS_SHOW_GUIDE, toogle);
  }

  /**
   * 添加辅助线
   */
  addGuideLine(direction: AxleDirection, value: number) {
    const id = localUniqueid();
    const line: GuideLine = { id, direction, value };
    this.guidelines.push(line);
    this.syncGuideLineCache();
    this.activeGuideLine(line);
  }

  /**
   * 更新辅助线属性
   */
  updateGuideLine(id: string, value: number) {
    const line = this.guidelines.find(item => item.id === id);
    if (!line) return;
    line.value = value;
    if (this.activedGuideLine) {
      this.activedGuideLine.value = value;
    }
    this.syncGuideLineCache();
  }

  /**
   * 删除辅助线
   */
  removeGuideLine(id: string) {
    const index = this.guidelines.findIndex(line => line.id === id);
    if (index < 0) return;
    this.guidelines.splice(index, 1);
    this.refreshGuideLine();
  }

  /**
   * 清楚所有辅助线
   */
  clearGuideLine() {
    this.guidelines = [];
    this.refreshGuideLine();
  }

  /**
   * 激活辅助线
   */
  activeGuideLine(line: GuideLine) {
    this.activedGuideLine = line;
  }

  /**
   * 激活辅助线
   */
  unActiveGuideLine() {
    this.activedGuideLine = null;
  }

  /**
   * 刷新辅助线状态
   */
  refreshGuideLine() {
    this.syncGuideLineCache();
    this.unActiveGuideLine();
  }

  /**
   * 设置磁力线
   */
  setMagneticLine(lines: MagneticLine[]) {
    this.magneticLines = lines;
  }

  /**
   * 清除磁力线
   */
  clearMagneticLines() {
    this.magneticLines = null;
  }

  /**
   * 设置移动状态
   */
  setMoveState(isMoveing: boolean) {
    this.isMoveing = isMoveing;
  }

  /**
   * 设置旋转状态
   */
  setRotateState(isRotateing: boolean) {
    this.isRotateing = isRotateing;
  }

  /**
   * 设置移动状态
   */
  setScaleState(isScaleing: boolean) {
    this.isScaleing = isScaleing;
  }

  /**
   * 设置画布缩放登记
   * @param level 传入的等级
   */
  @OSHistoryDecorator(function () {
    const level = this.zoomLevel;
    return () => this.handleSetZoomLevel(level);
  })
  setZoomLevel(level: number) {
    this.handleSetZoomLevel(level);
  }

  /**
   * 放大画布
   */
  zoomIn() {
    this.setZoomLevel(this.zoomLevel + 0.1);
  }

  /**
   * 缩小画布
   */
  zoomOut() {
    this.setZoomLevel(this.zoomLevel - 0.1);
  }

  /**
   * 缩到最小
   */
  zoomMin() {
    this.setZoomLevel(CANVAS_MIN_ZOOM_LEVEL);
  }

  /**
   * 缩到最大
   */
  zoomMax() {
    this.setZoomLevel(CANVAS_MAX_ZOOM_LEVEL);
  }

  /**
   * 恢复默认
   */
  zoomReset() {
    this.setZoomLevel(1);
  }

  protected handleSetZoomLevel(level: number) {
    level = +level.toFixed(2);
    this.zoomLevel = Math.max(
      CANVAS_MIN_ZOOM_LEVEL,
      Math.min(CANVAS_MAX_ZOOM_LEVEL, level)
    );
    LocalCache.set(CANVAS_ZOOM_LEVEL, this.zoomLevel);
  }

  /**
   * 缓存辅助线
   */
  private syncGuideLineCache() {
    LocalCache.set(CANVAS_GUIDE_ARRAY, this.guidelines);
  }
}
