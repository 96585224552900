import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Tooltips } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { TextStruc } from '@/models/CmpStruc';
import FontFamily from '../Base/FontFamily';
import FontSize from '../Base/FontSize';
import FontColor from '../Base/FontColor';
import FontStroke from '../Base/FontStroke';
import FontFill from '../Base/FontFill';
import FontAlign from '../Base/FontAlign';
import FontAnimation from '../Base/FontAnimation';
import CmpDelete from '../Base/CmpDelete';
import CmpLayer from '../Base/CmpLayer';
import style from './Text.module.less';

interface TextSettingProps {
  model: TextStruc;
}

const SettingPropsMap = {
  FontFamily: 'FontFamily',
  FontSize: 'FontSize',
  FontColor: 'FontColor',
  FontStroke: 'FontStroke',
  FontFill: 'FontFill',
  FontAlign: 'FontAlign',
  CmpLayer: 'CmpLayer',
  FontAnimation: 'FontAnimation',
};

const componentMap = {
  [SettingPropsMap.FontFamily]: FontFamily,
  [SettingPropsMap.FontSize]: FontSize,
  [SettingPropsMap.FontColor]: FontColor,
  [SettingPropsMap.FontStroke]: FontStroke,
  [SettingPropsMap.FontFill]: FontFill,
  [SettingPropsMap.FontAlign]: FontAlign,
  [SettingPropsMap.CmpLayer]: CmpLayer,
  [SettingPropsMap.FontAnimation]: FontAnimation,
};

const propsList = [
  {
    componentKey: SettingPropsMap.FontFamily,
    className: style['item-margin'],
  },
  {
    componentKey: SettingPropsMap.FontSize,
    className: style['item-margin'],
  },
  {
    componentKey: SettingPropsMap.FontColor,
    className: style.item,
  },
  {
    componentKey: SettingPropsMap.FontStroke,
    className: style.item,
  },
  {
    componentKey: SettingPropsMap.FontFill,
    className: style.item,
  },
  {
    componentKey: SettingPropsMap.FontAlign,
    className: style.item,
  },
  {
    componentKey: SettingPropsMap.CmpLayer,
    className: style.item,
  },
  {
    componentKey: SettingPropsMap.FontAnimation,
  },
];

export interface Offset {
  right: number;
  key: string;
}

function TextSetting(props: TextSettingProps) {
  const { model } = props;

  const [hideSettingProps, setHideSettingProps] = useState<string[]>([]);

  const propsSettingsRef = useRef<HTMLDivElement>(null);
  const offsetRef = useRef<Offset[]>([]);

  const listeningSize = (): ResizeObserver | null => {
    if (!propsSettingsRef.current || !offsetRef.current) return null;
    const resizeObserver = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        const { right: contentRight } =
          entries[0].target.getBoundingClientRect();

        const result = offsetRef.current
          .map(({ right, key }) => {
            /** 更多图标的宽度为 32 */
            if (contentRight - 32 < right) return key;
            return null;
          })
          .filter(item => item);
        setHideSettingProps(result as string[]);
      });
    });
    resizeObserver.observe(propsSettingsRef.current);
    return resizeObserver;
  };

  const unListeningSize = (resizeObserver: ResizeObserver) => {
    if (!propsSettingsRef.current) return;
    resizeObserver.unobserve(propsSettingsRef.current);
  };

  useEffect(() => {
    const resizeObserver = listeningSize();
    return () => {
      if (resizeObserver) unListeningSize(resizeObserver);
    };
  }, []);

  useEffect(() => {
    const propsNodes = Array.from(propsSettingsRef.current?.children || []);
    if (propsNodes) {
      offsetRef.current = propsNodes.map(node => {
        const key = (node as HTMLElement).dataset.name || '';
        const { right } = node.getBoundingClientRect();
        return {
          key,
          right,
        };
      });
    }
  }, []);

  const tips = (
    <div className={style['more-tips']}>
      {hideSettingProps.map((key: string) => {
        const item = propsList.find(({ componentKey }) => componentKey === key);
        if (!item) return null;
        const Component = componentMap[item.componentKey];
        return (
          <div style={{ marginRight: 10 }} key={item.componentKey}>
            <Component model={model} className={item.className} />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={style.text}>
      <div ref={propsSettingsRef} className={style['props-setting']}>
        {propsList.map(item => {
          if (hideSettingProps.includes(item.componentKey)) return null;
          const Component = componentMap[item.componentKey];
          return (
            <div
              style={{ marginRight: 10 }}
              key={item.componentKey}
              data-name={item.componentKey}
            >
              <Component model={model} className={item.className} />
            </div>
          );
        })}
      </div>

      {!!hideSettingProps.length && (
        <Tooltips
          className={style['tooltips-component']}
          placement="bottomRight"
          trigger="click"
          tips={tips}
        >
          <div className={cs(style.item, style.more)}>
            {Array.from({ length: 3 }, (_item, index) => (
              <div className={style.point} key={index} />
            ))}
          </div>
        </Tooltips>
      )}

      <CmpDelete onClick={() => model.remove()} className={style.item} />
    </div>
  );
}

export default observer(TextSetting);
