import { useState, useEffect, useRef } from 'react';
import {
  EditorBox,
  POINT_TYPE,
  RectData,
  calcRotatedPoint,
} from '@bhb-frontend/toolbox';
import { observer } from 'mobx-react';
import cs from 'classnames';
import { FileType } from '@bhb-frontend/wasm/lib/fileType';
import { useStores } from '@/store';
import CmpStruc, { TextStruc, ImageStruc } from '@/models/CmpStruc';
import { checkCmpInVisibleArea, moveHandle } from '@/utils/move';
import { getPreviewSizePosition, isCenterPoint } from './tools';
import { ALL_POINTS, TEXT_POINTS, TEXT_LIST } from './constants';
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from '@/constants/FontSize';
import { fetchArrayBuffer } from '@/utils/fetch';
import { toCanvasPoint } from '@/helpers/Node';
import { getCmpsByPoint } from '@/helpers/Cmps';
import { getMousePointOpacityInImage } from '@/utils/getOpacity';
import { TRANSPARENT_PICTURE } from '@/constants/Mimes';
import style from './Editor.module.less';

interface EditorProps {
  isMulti: boolean;
  zoomLevel: number;
  model: CmpStruc | null;
}

function Editor(props: EditorProps) {
  const { isMulti, zoomLevel, model } = props;

  const { OS, app } = useStores();
  const { cmps } = app.activedScene;

  const [previewSizePosition, setPreviewSizePosition] = useState(() => ({
    x: 0,
    y: 0,
  }));

  const [points, setPoints] = useState(ALL_POINTS);

  const previewSizeRef = useRef<HTMLDivElement>(null);
  /** 是否在当前编辑组件触发的鼠标事件 */
  const isMouseEventFormEditor = useRef(false);

  /**
   * 根据不同组件类型，设置拉伸点
   * */
  const setPointsByCmpTag = () => {
    if (!model) return;

    if (TEXT_LIST.includes(model.tag)) {
      setPoints(TEXT_POINTS);
      return;
    }
    setPoints(ALL_POINTS);
  };

  useEffect(() => {
    setPointsByCmpTag();
  }, [model]);

  /**
   * 初始化矫正高度
   *  */
  useEffect(() => {
    if (model?.isCaptions) (model as TextStruc).rectifyHeight();
  }, []);

  /**
   * 获取矩形的信息
   */
  const getRectInfo = () => {
    if (!model) return null;
    const { height = 0, width = 0, left = 0, top = 0 } = model.style;
    const { z: rotate } = model.rotation;
    return {
      width: +width * zoomLevel,
      height: +height * zoomLevel,
      x: +left * zoomLevel,
      y: +top * zoomLevel,
      rotate,
    };
  };

  const rectInfo = getRectInfo();

  if (!model || isMulti || !rectInfo) return null;

  /**
   * 更新格外一些属性
   */
  const updateOtherProps = (rate: number) => {
    if (!model.style) return {};
    const keys = [
      'paddingTop',
      'paddingRight',
      'paddingLeft',
      'paddingBottom',
      'padding',
    ];
    return keys.reduce((css: React.CSSProperties, key) => {
      const value = model.style[key];
      if (key in model.style) {
        css[key] = Number(value) * rate;
      }
      return css;
    }, {});
  };

  /**
   * 拉伸开始
   */
  const onStartScale = (_point: POINT_TYPE, e: MouseEvent) => {
    const position = getPreviewSizePosition(
      { x: e.clientX, y: e.clientY },
      previewSizeRef.current
    );
    setPreviewSizePosition(position);
    OS.setScaleState(true);
  };

  /**
   *  拉伸
   */
  const onScale = (
    { width, height, x, y }: RectData,
    point: POINT_TYPE,
    e: MouseEvent
  ) => {
    const position = getPreviewSizePosition(
      { x: e.clientX, y: e.clientY },
      previewSizeRef.current
    );
    setPreviewSizePosition(position);

    const updateData = {
      width: width / zoomLevel,
      left: x / zoomLevel,
      top: y / zoomLevel,
      height: height / zoomLevel,
    };

    const preWidth = +(model.style?.width ?? 0);
    const rate = updateData.width / preWidth;

    /** 如果是文字组件，并且拉伸的是顶点，一些属性需要做同比例缩放 */
    if (model.isText && !isCenterPoint(point)) {
      const { fontSize = 12 } = model.style;
      const newFontSize = Math.min(
        MAX_FONT_SIZE,
        Math.max(MIN_FONT_SIZE, +fontSize * rate)
      );
      // 如果字体大小到了最小值，不允许缩小了
      if (newFontSize === MIN_FONT_SIZE) return;
      Object.assign(updateData, { fontSize: newFontSize });
      Object.assign(updateData, updateOtherProps(rate));
    }

    /**
     * 如果是文字，并拉伸的是左右改变宽度时，这里不改变文字的高度，富文本会改变文字的高度
     * */
    if (
      model.isText &&
      [POINT_TYPE.RIGHT_CENTER, POINT_TYPE.LEFT_CENTER].includes(point)
    ) {
      Reflect.deleteProperty(updateData, 'height');
    }
    model.updateStyle(updateData, false);
  };

  /**
   * 拉伸结束
   */
  const onEndScale = (point: POINT_TYPE) => {
    OS.setScaleState(false);
    // 只有在拉伸高度的情况下才做高度矫正
    if (
      [POINT_TYPE.TOP_CENTER, POINT_TYPE.BOTTOM_CENTER].includes(point) &&
      model.isCaptions
    ) {
      (model as TextStruc).rectifyHeight();
    }
    checkCmpInVisibleArea(model);
  };

  /**
   * 旋转开始
   */
  const onRotateStart = () => {
    OS.setRotateState(true);
  };

  /**
   * 旋转
   */
  const onRotate = (rotate: number) => {
    /** 接近于0 直接设置0，方便调整为未旋转状态 */
    if (Math.floor(Math.abs(rotate)) === 0) rotate = 0;
    model.update({ rotation: { ...model.rotation, z: rotate } });
  };

  /**
   * 旋转结束
   */
  const onRotateEnd = () => {
    OS.setRotateState(false);
    checkCmpInVisibleArea(model);
  };

  /**
   * 鼠标按下事件
   */
  const handleMouseDown = (e: React.MouseEvent) => {
    isMouseEventFormEditor.current = true;
    if (model.isLock) return;
    moveHandle(e.nativeEvent, model, zoomLevel);
  };

  const onDoubleClick = () => {
    if (model.isText && !model.isCaptions) (model as TextStruc).onEdit();
  };

  /**
   * 获取需要切换的组件
   *  */
  const getSwitchCmp = async (
    mousePointInCanvas: Point,
    cmps: CmpStruc<ComponentModel.Base>[]
  ) => {
    const getCmp = async (cmpList: CmpStruc<ComponentModel.Base>[]) => {
      const cmp = cmpList.pop();
      if (!cmp) return null;
      /** 文字不需要透传 */
      if (cmp?.isText) return cmp;
      /** 检测图片类型 */
      const buffer = await fetchArrayBuffer((cmp as ImageStruc).url);
      const { code, data: imageType } = await FileType.checkFileType(buffer);

      if (
        code !== 0 ||
        !imageType ||
        !TRANSPARENT_PICTURE.includes(imageType as string)
      )
        return cmp;

      const { left = 0, top = 0, width = 0, height = 0 } = cmp.style;
      const { z: rotate } = cmp.rotation;

      let pointInCmp = {
        x: mousePointInCanvas.x - +left,
        y: mousePointInCanvas.y - +top,
      };
      if (rotate !== 0) {
        // 处理旋转情况
        const center = {
          x: +left + +width / 2,
          y: +top + +height / 2,
        };
        const { x, y } = calcRotatedPoint(mousePointInCanvas, center, -rotate);
        pointInCmp = {
          x: x - +left,
          y: y - +top,
        };
      }
      const opacity = await getMousePointOpacityInImage(
        pointInCmp,
        cmp as ImageStruc
      );
      if (opacity === 0) return getCmp(cmpList);
      return cmp;
    };

    const cmp = await getCmp([...cmps]);
    /** 销毁worker，释放内存 */
    FileType.destory();
    return cmp || cmps.pop();
  };

  const onMouseUp = async (e: React.MouseEvent) => {
    if (OS.isEditing || !isMouseEventFormEditor.current) return;
    isMouseEventFormEditor.current = false;
    /**
     *  如果没有发生过移动，判断是否需要切换活动组件
     *  需要对透明图片做透传选择
     * */
    const pointInCanvas = toCanvasPoint({
      x: e.clientX,
      y: e.clientY,
    });

    pointInCanvas.x /= zoomLevel;
    pointInCanvas.y /= zoomLevel;

    const cmpsInPoint = getCmpsByPoint(cmps, {
      x: pointInCanvas.x,
      y: pointInCanvas.y,
    });
    if (!cmpsInPoint.length) return;
    const cmp = await getSwitchCmp(pointInCanvas, cmpsInPoint);
    app.activeCmp(cmp, e.shiftKey);
  };

  const previewSize = `宽度:${Math.round(
    rectInfo.width / zoomLevel
  )} 高度:${Math.round(rectInfo.height / zoomLevel)}`;

  return (
    <>
      <EditorBox
        className={cs({ [style['pointer-events']]: model.isBackground })}
        points={points}
        isShowPoint={!OS.isMoveing && !model.isLock}
        rectInfo={rectInfo}
        minHeight={12}
        minWidth={12}
        onStartScale={onStartScale}
        onScale={onScale}
        onEndScale={onEndScale}
        onRotateStart={onRotateStart}
        onRotate={onRotate}
        onRotateEnd={onRotateEnd}
        onMouseDown={handleMouseDown}
        onDoubleClick={onDoubleClick}
        onMouseUp={onMouseUp}
      />

      {/* 矩形大小预览 */}
      <div
        ref={previewSizeRef}
        style={{
          transform: `translate(${previewSizePosition.x}px,${previewSizePosition.y}px)`,
          visibility: OS.isScaleing ? 'visible' : 'hidden',
        }}
        className={style['preview-size']}
      >
        {previewSize}
      </div>
    </>
  );
}

export default observer(Editor);
