import { POINT_TYPE } from '@bhb-frontend/toolbox';
import { getCanvasWrapRectInfo, getCanvasRectInfo } from '@/helpers/Node';
import { PREVIEW_SIZE_OFFSET_X, PREVIEW_SIZE_OFFSET_Y } from './constants';

/**
 * 获取预览宽高信息框的位置
 * @param point 鼠标以浏览器为基准的坐标点
 * @param previewSizeRef 预览信息框Ref
 * @returns
 */
export function getPreviewSizePosition(
  point: Point,
  previewSizeDom: HTMLDivElement | null
): Point {
  const canvasWrapRectInfo: DOMRect | null = getCanvasWrapRectInfo();
  const canvasRectInfo: DOMRect | null = getCanvasRectInfo();

  if (!canvasWrapRectInfo || !canvasRectInfo || !previewSizeDom) return point;
  const mousePositionInCanvas = {
    x: point.x - canvasRectInfo.left,
    y: point.y - canvasRectInfo.top,
  };

  const result = {
    x: mousePositionInCanvas.x + PREVIEW_SIZE_OFFSET_X,
    y: mousePositionInCanvas.y + PREVIEW_SIZE_OFFSET_Y,
  };

  // canvas 与 canvas wrap 横轴、纵轴 位置的偏移量
  const horizontaOffet = canvasRectInfo.x - canvasWrapRectInfo.x;
  const verticalOffet = canvasRectInfo.y - canvasWrapRectInfo.y;

  // =============== 判断是否超出了四个方向 ===============
  // left
  if (point.x + PREVIEW_SIZE_OFFSET_X < canvasWrapRectInfo.x) {
    result.x = -horizontaOffet;
  }

  // right
  const previewDomWidth = previewSizeDom.offsetWidth;
  if (point.x + previewDomWidth > canvasWrapRectInfo.right) {
    result.x = canvasRectInfo.width + horizontaOffet - previewDomWidth;
  }

  // top
  if (point.y + PREVIEW_SIZE_OFFSET_Y < canvasWrapRectInfo.y) {
    result.y = -verticalOffet;
  }

  // bottom
  const previewDomHeight = previewSizeDom.offsetHeight;
  if (point.y + previewDomHeight > canvasWrapRectInfo.bottom) {
    result.y = canvasRectInfo.height + verticalOffet - previewDomHeight;
  }

  return result;
}

/**
 * 判断当前的拉动点是否是中心点
 * @param {POINT_TYPE} pointType
 * @return {Boolean} 否是中心点
 */
export function isCenterPoint(pointType: POINT_TYPE): boolean {
  const centerPonitList = [
    POINT_TYPE.TOP_CENTER,
    POINT_TYPE.LEFT_CENTER,
    POINT_TYPE.RIGHT_CENTER,
    POINT_TYPE.BOTTOM_CENTER,
  ];
  return centerPonitList.includes(pointType);
}
