import { PropsWithChildren, ReactNode, useRef, useCallback } from 'react';
import { useGlobalClick } from '@/hooks/useGlobalClick';
import style from './style.module.less';

interface ExportPopoverProps {
  visiable: boolean;
  content: ReactNode;
  onClose: () => void;
}
function ExportPopover(props: PropsWithChildren<ExportPopoverProps>) {
  const { children, visiable, content, onClose } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onClick = useCallback((e: MouseEvent) => {
    if (wrapperRef.current?.contains(e.target as Node)) return;
    onClose();
  }, []);

  useGlobalClick(onClick, visiable);

  return (
    <div className={style.popover} ref={wrapperRef}>
      {children}
      {visiable && <div className={style.content}>{content}</div>}
    </div>
  );
}

export default ExportPopover;
