import { makeAutoObservable } from 'mobx';
import { MaterialEnum } from '@/types/material';

// Model the application state.
export default class MaterialStore {
  /** 当前激活 */
  public activeMenu: MaterialEnum = MaterialEnum.THEME;

  constructor() {
    makeAutoObservable(this);
  }

  changeMenu = (value: MaterialEnum) => {
    this.activeMenu = value;
  };

  /** 是否文字动画被激活 */
  get isTextAnimationActived() {
    return this.activeMenu === MaterialEnum.TEXT_ANIMATION;
  }

  // reset() {
  //   this.secondsPassed = 0;
  // }
}
