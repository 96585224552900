import { observer } from 'mobx-react';
import clssNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { Tooltips } from '@bhb-frontend/lithe-ui';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStores } from '@/store';
import SceneThumbnail from '@/components/Scene/Scene';
import style from './Scene.module.less';
import SceneStruc from '@/models/SceneStruc';
import Menu, { Coord } from './Menu/Menu';
import assets from '@/assets';
import { getItemStyle, getListStyle, reorder } from '@/utils/reactDnd';

interface SceneProps {
  className?: string;
}

/**
 * 预览场景大小
 */
const SIZE = 130;
function SceneList(props: SceneProps) {
  const { className } = props;
  const { app } = useStores();
  const { scenes } = app;

  /** 菜单显隐 */
  const [open, setOpen] = useState<boolean>(false);
  /** 菜单坐标 */
  const [coord, setCoord] = useState<Coord>({});
  /** 当前操作的场景ID */
  const curScene = useRef<SceneStruc>();

  /** 是否需要滚动到最后 */
  const isScrollLast = useRef(false);

  /** 激活场景 */
  const handleActive = (scene: SceneStruc) => {
    app.activeScene(scene, false);
  };

  /** 右键 */
  const rightClick = (e, sence) => {
    const { clientX, clientY } = e;
    setOpen(true);
    setCoord({
      x: clientX,
      y: clientY,
    });
    curScene.current = sence;
  };

  /** 闭关菜单 */
  const handleCloseMenu = () => {
    setOpen(false);
  };

  /** 新增 */
  const addScene = () => {
    if (!curScene.current) return;
    isHandleScroll();
    app.addScene(curScene.current);
    handleCloseMenu();
  };

  /** 复制 */
  const copyScene = () => {
    if (!curScene.current) return;
    isHandleScroll();
    app.copyScene(curScene.current);
    handleCloseMenu();
  };

  /** 删除 */
  const deleteScene = () => {
    app.removeScene(curScene.current);
    handleCloseMenu();
  };

  /** 是否需要操作滚动 */
  const isHandleScroll = () => {
    const tragetIndex = scenes.findIndex(
      item => item.id === curScene.current?.id
    );
    if (tragetIndex === scenes.length - 1) {
      // 最后一位的新增需要滚动
      isScrollLast.current = true;
    }
  };

  /** 新增最后一个 */
  const handleAdd = () => {
    isScrollLast.current = true;
    app.addScene(scenes[scenes.length - 1]);
  };

  const handleMenu = (e, scene) => {
    e.stopPropagation();
    rightClick(e, scene);
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      scenes,
      result.source.index,
      result.destination.index
    );
    app.setScenes(items as SceneStruc[]);
  };

  useEffect(() => {
    if (isScrollLast.current) {
      isScrollLast.current = false;

      const dom = document.querySelector(`.${style.scene}`);
      if (!dom) return;
      const width = dom.scrollWidth;
      dom.scrollTo(width, 0);
    }
  }, [scenes.length]);

  const renderScene = (scene, index) => {
    const { configure, actived } = scene;
    const { width = 1, height = 1 } = configure;
    const rate = SIZE / Math.max(width, height);
    const sceneStyle = {
      width,
      height,
      transform: ` scale(${rate})`,
    };
    return (
      <Draggable key={scene.id} draggableId={scene.id} index={index}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...getItemStyle(provided.draggableProps.style),
            }}
          >
            <div
              className={clssNames(style.thumbnail, {
                [style.actived]: actived,
                [style.item]: true,
              })}
              style={{
                width: rate * width + 8,
                height: rate * height + 8,
              }}
              onClick={() => handleActive(scene)}
              onContextMenu={e => rightClick(e, scene)}
            >
              <div className={style['thumbnail-border']}>
                <SceneThumbnail
                  style={sceneStyle}
                  className={`${style.preview} ${
                    index === 0 ? 'doc-canvas' : ''
                  }`}
                  editable={false}
                  struc={scene}
                  zoomLevel={rate}
                />
                <div
                  className={style['item-menu']}
                  onClick={e => handleMenu(e, scene)}
                >
                  ...
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };
  const renderScenes = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {provided => (
          <div
            ref={provided.innerRef}
            style={getListStyle()}
            className={style.list}
            {...provided.droppableProps}
          >
            {scenes.map((scene, index) => renderScene(scene, index))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <>
      <div
        className={clssNames(
          className,
          style['scene-wrapper'],
          app.activedScene.orientation === 'vertical'
            ? style.vertical
            : style.horizontal
        )}
      >
        <div className={style.scene}>
          {renderScenes()}
          <Tooltips tips="添加页面">
            <div className={style['scene-add']} onClick={handleAdd}>
              <img src={assets.images.theme['add.png']} alt="新增" />
            </div>
          </Tooltips>
        </div>
      </div>
      <Menu
        onClose={handleCloseMenu}
        open={open}
        coord={coord}
        onAddScene={addScene}
        onCopyScene={copyScene}
        onDeleteScene={deleteScene}
      />
    </>
  );
}
export default observer(SceneList);
