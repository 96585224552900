import { useState, useRef, useEffect, cloneElement } from 'react';
import classnames from 'classnames';
import { Tabs } from '@bhb-frontend/lithe-ui';
import AudioPlayer from '@bhb-frontend/audio-player';
import Style from './style.module.less';
import { TabsCategory } from '@/types/material';

// 收放箭头的宽度为 24
const ARROW_WIDTH = 24;

interface MaterialContentProps {
  /** 分类 */
  categorys: TabsCategory[];
  /** 活动tab */
  activeTab?: string;
  /** 内容 */
  children: React.ReactElement;
  /** 切换tab */
  toggleTag?: (key: string) => void;
}

function MaterialContent(props: MaterialContentProps) {
  const { categorys, activeTab, children, toggleTag } = props;
  const [isWrap, setIsWrap] = useState(false);
  const [activeKey, setActiveKey] = useState(activeTab || categorys[0]?.key);
  const [isHiddenArrow, setIsHiddenArrow] = useState(true);

  const tabsNavWrapRef = useRef<HTMLDivElement>(null);
  const tabsNavListRef = useRef<HTMLDivElement>(null);
  const audioPlayer = useRef(AudioPlayer.getInstance());

  const setArrowDisplay = () => {
    const tabsNavWrapNode = tabsNavWrapRef.current;
    const tabsNavListNode = tabsNavListRef.current;
    if (!tabsNavWrapNode || !tabsNavListNode) return;

    const st = setTimeout(() => {
      const tabsNavWrapWidth = tabsNavWrapNode.offsetWidth;
      const tabsNavListWidht = tabsNavListNode.offsetWidth;

      const isHidden = tabsNavWrapWidth > tabsNavListWidht - ARROW_WIDTH;
      setIsHiddenArrow(isHidden);
      window.clearTimeout(st);
    });
  };

  const selectTag = (key: string) => {
    toggleTag?.(key);
    setActiveKey(key);
    audioPlayer.current?.pause();
    setIsWrap(false);
  };

  useEffect(() => {
    setArrowDisplay();
  }, [categorys]);

  useEffect(() => {
    setActiveKey(activeTab || categorys[0]?.key);
  }, [activeTab]);

  const renderTabBar = () => (
    <div className={Style['tabs-nav-wrap']} ref={tabsNavWrapRef}>
      {/* 展开/收起 箭头 */}
      <span
        onClick={() => {
          setIsWrap(!isWrap);
        }}
        className={classnames(
          Style['tabs-nav-arrow'],
          'iconfont icon icon-a-common_icon_arrow_left1x',
          {
            [Style['tabs-nav-arrow-rotate']]: isWrap,
            [Style['tabs-nav-arrow-hidden']]: isHiddenArrow,
          }
        )}
      />
      {/* tabs */}
      <div
        ref={tabsNavListRef}
        className={classnames(Style['tabs-nav-list'], {
          [Style.wrap]: isWrap,
        })}
      >
        {categorys.map(category => (
          <div
            key={category.key}
            onClick={() => selectTag(category.key)}
            className={classnames(Style['tabs-tab'], {
              [Style.acitveTabsTab]: category.key === activeKey,
            })}
          >
            {category.label}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={Style['material-content']}>
      <Tabs
        tabPosition="top"
        activeKey={activeKey}
        renderTabBar={renderTabBar}
        items={categorys.map(({ key, label, ...otherProps }) => ({
          key,
          label,
          children: cloneElement(children, {
            id: key,
            ...otherProps,
          }),
        }))}
      />
    </div>
  );
}

export default MaterialContent;
