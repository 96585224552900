import { useMemo, useState } from 'react';
import { Checkbox } from '@bhb-frontend/lithe-ui';
import assets from '@/assets';
import { PaidContent } from '@/types/cteateVideo';
import GoodsItem from '../ExportVideo/PayContent/GoodsItem';
import { formerTime } from '@/utils/createVideo';
import style from './style.module.less';

interface Props {
  paidContent: Partial<PaidContent>;
  visible?: boolean;
  onConfirm: (val: boolean) => void;
}

const ConfirmConsumption = (props: Props) => {
  const { paidContent, onConfirm } = props;

  const [isAgainNextTip, setIsAgainNextTip] = useState<boolean>(false);

  // 生成视频的剩余次数
  const videoMergeNum = useMemo(() => {
    const { balanceVideoMergeNum, videoMergeNum } = paidContent;
    return {
      goodsCover: assets.images.theme['icon_video.png'],
      title: `生成视频剩余次数：${balanceVideoMergeNum}`,
      desc: `本次导出消耗：${videoMergeNum}次`,
    };
  }, [paidContent]);

  /** 数字的剩余时间 */
  const digitalPeopleTime = useMemo(() => {
    const { balanceFigureDuration = 0, estimateFigureDuration = 0 } =
      paidContent;
    return {
      goodsCover: assets.images.theme['icon_time.png'],
      title: `数字人剩余时长：${formerTime(balanceFigureDuration)}`,
      desc: `本次导出预估消耗：${formerTime(estimateFigureDuration)}`,
    };
  }, [paidContent]);

  const tipInfoList = useMemo(() => {
    const list = [videoMergeNum];
    const { estimateFigureDuration = 0 } = paidContent;
    // 有数字人，才显示数字人提示
    if (estimateFigureDuration > 0) {
      list.unshift(digitalPeopleTime);
    }
    return list;
  }, [videoMergeNum, paidContent]);

  const handleChange = (e, value) => {
    setIsAgainNextTip(value);
  };

  const handleConfirm = () => {
    onConfirm(isAgainNextTip);
  };

  return (
    <div>
      <div className={style.title}>权益消耗确认</div>
      {tipInfoList.map(item => (
        <GoodsItem
          key={item.title}
          className={style['goods-item']}
          goodsCover={item.goodsCover}
          title={item.title}
          desc={item.desc}
        />
      ))}

      <div className={style['export-video-box']}>
        <Checkbox value={isAgainNextTip as any} onChange={handleChange}>
          下次不再提示
        </Checkbox>
        <div className={style['export-video-btn']} onClick={handleConfirm}>
          导出视频
        </div>
      </div>
    </div>
  );
};

export default ConfirmConsumption;
