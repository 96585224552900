import { Tooltips } from '@bhb-frontend/lithe-ui';
import { observer } from 'mobx-react';
import cs from 'classnames';
import CmpStruc from '@/models/CmpStruc';
import style from './CmpLayer.module.less';

/** 改变层级 */
export enum ChangeLayerType {
  /** 上一级 */
  UP = 'up',
  /** 下一级 */
  DOWN = 'down',
  /** 置顶 */
  TOP = 'top',
  /** 置底 */
  BOTTOM = 'bottom',
}

export const layerOrderList = [
  {
    label: '前移',
    iconfont: 'icon-a-cengji_icon_up2x',
    value: ChangeLayerType.UP,
  },
  {
    label: '移至最前',
    iconfont: 'icon-a-cengji_icon_top2x',
    value: ChangeLayerType.TOP,
  },
  {
    label: '后移',
    iconfont: 'icon-a-cengji_icon_down2x',
    value: ChangeLayerType.DOWN,
  },
  {
    label: '移至最后',
    iconfont: 'icon-a-cengji_icon_bottom2x',
    value: ChangeLayerType.BOTTOM,
  },
];

interface CmpLayerProps {
  model: CmpStruc;
  className?: string;
}

function CmpLayer(props: CmpLayerProps) {
  const { model, className } = props;
  const { isFirstCmp, isLastCmp } = model;

  /** 改变层级 */
  const changeLayer = (type: ChangeLayerType) => {
    switch (type) {
      case ChangeLayerType.DOWN:
        !isFirstCmp && model.toDown();
        break;
      case ChangeLayerType.BOTTOM:
        !isFirstCmp && model.toBottom();
        break;
      case ChangeLayerType.UP:
        !isLastCmp && model.toUp();
        break;
      default:
        !isLastCmp && model.toTop();
        break;
    }
  };

  const isDisableLayer = (type: ChangeLayerType) => {
    if (
      [ChangeLayerType.BOTTOM, ChangeLayerType.DOWN].includes(type) &&
      isFirstCmp
    )
      return true;
    if ([ChangeLayerType.TOP, ChangeLayerType.UP].includes(type) && isLastCmp)
      return true;
    return false;
  };

  /** 层级控制器 */
  const layerControl = (
    <div className={style['layer-control']}>
      {layerOrderList.map(layer => (
        <div
          className={cs(style['layer-control-item'], {
            [style['layer-disabled']]: isDisableLayer(layer.value),
          })}
          key={layer.label}
          onClick={() => {
            changeLayer(layer.value);
          }}
        >
          <i className={cs('iconfont', layer.iconfont)} />
          <br />
          {layer.label}
        </div>
      ))}
    </div>
  );

  return (
    <Tooltips
      placement="bottomLeft"
      className={style['layer-tooltips']}
      tips={layerControl}
      trigger="click"
    >
      <span className={className}>
        <Tooltips tips="层级" space={20} placement="bottom">
          <i className="iconfont icon-a-common_icon_cengji2x" />
        </Tooltips>
      </span>
    </Tooltips>
  );
}

export default observer(CmpLayer);
