import { LoadMore } from '@bhb-frontend/lithe-ui';
import { StickerDetail } from '@/types/material';
import ImageWrapper from '@/components/ImageWrapper';
import Style from './style.module.less';
import { useStores } from '@/store';

interface BackgroundListProps {
  /** 资源列表 */
  list?: StickerDetail[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;
}

function BackgroundList(props: BackgroundListProps) {
  const { list, loading, hasMore, loadMore } = props;
  const { app } = useStores();
  const addSticker = (item: StickerDetail) => {
    app.activedScene.addSticker({
      stickerConfig: item,
      source: 'network',
    });
  };

  return (
    <div className={Style['template-content']}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        finishedText=""
        className={Style['load-more']}
      >
        <div className={Style.stickerList}>
          {list?.map(item => (
            <ImageWrapper
              key={item.id}
              className={Style.stickerListItem}
              urls={[item.coverUrl]}
              onClick={() => {
                addSticker(item);
              }}
            />
          ))}
        </div>
      </LoadMore>
    </div>
  );
}

export default BackgroundList;
