import cx from 'classnames';
import type { AudioType } from '../AudioBox';
import { AudioEnums } from '../AudioBox';
import Style from './EmptyRender.module.less';

interface EmptyRenderProps {
  type: AudioType;
}

/** 空音频渲染 */
export default function EmptyRender(props: EmptyRenderProps) {
  const { type } = props;

  return (
    <div className={Style.audioEmpty}>
      <i
        className={cx(
          'iconfont',
          type === AudioEnums.MUSIC
            ? 'icon-theme_icon_muscic'
            : 'icon-theme_icon_voice',
          Style.audioEmptyImg
        )}
      />
      <span className={Style.audioEmpytTitle}>
        {type === AudioEnums.MUSIC ? '选择音乐' : '选择配音'}
      </span>
    </div>
  );
}
