import { CANVAS_ZOOM_LEVEL } from '@/constants/CacheKeys';
import { NodeNameplate } from '@/constants/NodeNamePlate';
import LocalCache from '@/core/Manager/LocalCache';
import CmpStruc from '@/models/CmpStruc';
import { useStores } from '@/store';

/**
 * 返回画布节点
 * @returns {HTMLElement | null} `HTMLElement` 画布真实节点
 */
export function getCanvasNode(): HTMLElement | null {
  return document.querySelector<HTMLElement>(
    `[data-nameplate="${NodeNameplate.CANVAS}"]`
  );
}

/**
 * 返回场景节点
 * @returns {HTMLElement | null} `HTMLElement` 当前场景真实节点
 */
export function getSceneNode(): HTMLElement | null {
  const $canvas = getCanvasNode();
  if (!$canvas) return null;
  return $canvas.querySelector<HTMLElement>(
    `[data-nameplate="${NodeNameplate.SCENE}"]`
  );
}

/**
 * 返回当前场景内的所有组件节点
 * @returns {NodeListOf<HTMLElement> | null} `NodeListOf<HTMLElement>` 组件节点集合
 */
export function getAllCmpNode(): NodeListOf<HTMLElement> | null {
  const $scene = getSceneNode();
  if (!$scene) return null;
  return $scene.querySelectorAll<HTMLElement>(
    `[data-nameplate="${NodeNameplate.COMPONENT}"]`
  );
}

/**
 * 返回指定组件节点
 * @param cmp 当前选中的组件
 * @param parent 当前选中的组件的父级节点
 * @returns {HTMLElement | null} `HTMLElement` 当前选中的组件的真实节点
 */
export function getCmpNode(
  cmp: CmpStruc,
  parent?: HTMLElement | null
): HTMLElement | null {
  if (!parent) return null;
  return parent.querySelector<HTMLElement>(`[data-id="${cmp.id}"]`);
}

/**
 * 返回画布的真实尺寸
 * @returns {DOMRect | null } `DOMRect` dom的矩形信息
 */
export function getCanvasRectInfo(): DOMRect | null {
  const $canvas = getCanvasNode();
  if (!$canvas) return null;
  return $canvas.getBoundingClientRect();
}

/**
 * 获取画布缩放比例
 * @returns {number} 缩放比例，默认：`1`
 */
export function getCanvasZoomLevel(): number {
  const {
    app: { activedScene },
  } = useStores();
  const canvasSize = getCanvasRectInfo()?.height || 0;

  const {
    configure: { height = 0 },
  } = activedScene;

  return LocalCache.get(CANVAS_ZOOM_LEVEL, 'number') ?? canvasSize / height;
}

/**
 * 返回组合中场景中的最大矩形
 */
export function getCmpsMaxRect(cmps: CmpStruc[]): Rect | null {
  let rect: Rect | null = null;
  cmps.forEach(cmp => {
    const { width, height } = cmp.getRectSize();
    const { left, top } = cmp.getAbsPosition(cmp.group);

    const right = left + width;
    const bottom = top + height;

    if (!rect) {
      rect = { left, top, right, bottom, width: 0, height: 0 };
    } else {
      rect.left = Math.min(rect.left, left);
      rect.top = Math.min(rect.top, top);
      rect.right = Math.max(rect.right, right);
      rect.bottom = Math.max(rect.bottom, bottom);
    }

    rect.width = rect.right - rect.left;
    rect.height = rect.bottom - rect.top;
  });

  return rect;
}

/**
 * 获取画布中心点
 */
export function getCanvasCenter(): Point | null {
  const $canvas = getCanvasNode();
  const $scene = getSceneNode();
  if (!$canvas || !$scene) return null;
  const zoomLevel = getCanvasZoomLevel();
  const canvasRect = $canvas.getBoundingClientRect();
  const sceneRect = $scene.getBoundingClientRect();
  const canvasX = canvasRect.width / 2 - (sceneRect.left - canvasRect.left);
  const canvasY = canvasRect.height / 2 - (sceneRect.top - canvasRect.top);
  return {
    x: Math.round(canvasX / zoomLevel),
    y: Math.round(canvasY / zoomLevel),
  };
}

/**
 * 返回相对于画布的坐标
 * @param point 相对的可视坐标
 * @returns {Point}
 */
export function toCanvasPoint(point: Point): Point {
  const sceneNode = getSceneNode();
  if (!sceneNode) return point;
  const { left, top } = sceneNode.getBoundingClientRect();
  return { x: point.x - left, y: point.y - top };
}

/**
 * 返回组件的中心点
 * @param cmp 选中的组件
 * @returns { Point | null}
 */
export function getCmpCenter(cmp: CmpStruc): Point | null {
  const cmpNode = getCmpNode(cmp, getCanvasNode());
  if (!cmpNode) return null;
  const { left, top, width, height } = cmpNode.getBoundingClientRect();
  return { x: left + width / 2, y: top + height / 2 };
}

/**
 * 返回组件实际dom尺寸
 * @param cmp 组件
 */
export function getCmpNodeSize(cmp: CmpStruc): Size | null {
  const cmpNode = getCmpNode(cmp, getSceneNode());
  if (!cmpNode) return null;
  const zoomLevel = getCanvasZoomLevel();
  const { width, height } = cmpNode.getBoundingClientRect();
  return {
    width: Math.round(width / zoomLevel),
    height: Math.round(height / zoomLevel),
  };
}

/**
 * 返回组件实际dom位置
 * @param cmp 组件
 */
export function getCmpNodePosition(cmp: CmpStruc): CmpPosition | null {
  const cmpNode = getCmpNode(cmp, getSceneNode());
  if (!cmpNode) return null;
  const zoomLevel = getCanvasZoomLevel();
  const { left, top } = cmpNode.getBoundingClientRect();
  const { x, y } = toCanvasPoint({ x: left, y: top });
  return {
    left: Math.round(x / zoomLevel),
    top: Math.round(y / zoomLevel),
  };
}

/**
 * 返回组件实际矩形
 * @param cmp 组件
 * @returns
 */
export function getCmpNodeRect(cmp: CmpStruc): (Size | CmpPosition) | null {
  const size = getCmpNodeSize(cmp);
  const position = getCmpNodePosition(cmp);
  if (!size || !position) return null;
  return Object.assign(size, position);
}

/**
 * 返回画布包装器节点
 * @returns {HTMLElement | null} `HTMLElement` 画布包装器真实节点
 */
export function getCanvasWrapNode(): HTMLElement | null {
  return document.querySelector<HTMLElement>(
    `[data-nameplate="${NodeNameplate.CANVAS_WRAP}"]`
  );
}

/**
 * 返回画布包装器盒子信息
 * @returns {DOMRect | null} `DOMRect` 画布包装器盒子信息
 */
export function getCanvasWrapRectInfo(): DOMRect | null {
  const node = getCanvasWrapNode();
  if (!node) return null;
  return node.getBoundingClientRect();
}
