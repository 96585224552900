import { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Modal } from '@bhb-frontend/lithe-ui';
import WeChatQRCode, { WeChatQRCodeProps } from './WeChatQRCode';

interface WeChatQRcodeModalProps extends WeChatQRCodeProps {
  defaultVisible?: boolean;
}

function WeChatQRcodeModal(props: WeChatQRcodeModalProps) {
  const { defaultVisible = true, ...otherProps } = props;

  const [visible, setVisible] = useState(defaultVisible);

  return (
    <Modal
      visible={visible}
      onClose={() => setVisible(false)}
      timeout={0}
      width={330}
      height={330}
    >
      <WeChatQRCode {...otherProps} />
    </Modal>
  );
}

WeChatQRcodeModal.show = function (props?: WeChatQRcodeModalProps) {
  const node = document.createElement('div');
  const root = createRoot(node);
  root.render(<WeChatQRcodeModal {...props} />);
};

export default WeChatQRcodeModal;
