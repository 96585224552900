import request from '@/api/init';
import { DetailResponse } from '@/types/api';
import {
  InterFileData,
  OssCopyParams,
  OssCopyResult,
  OssTokenParams,
} from '@/types/file';

/**
 * 获取上传文件的token
 */
export function getOssToken(
  params: OssTokenParams
): Promise<DetailResponse<InterFileData>> {
  return request.post('/file/oss/sts', {
    fileConfig: [{ ...params }],
  });
}

/**
 * 文件拷贝
 */
export function getOssCopy(
  data: OssCopyParams
): Promise<DetailResponse<OssCopyResult>> {
  return request.post('/file/oss/copy', data);
}
