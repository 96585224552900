import { useEffect, useMemo, useRef, useState } from 'react';
import { Toast } from '@bhb-frontend/lithe-ui/lib';

import { observer } from 'mobx-react';
import {
  DigitalManRecommendDubQuery,
  ExtractAudioResponse,
} from '@/types/dubbing';
import { DubbingDetail, TabsCategory } from '@/types/material';
import {
  getDubbingCategory,
  getDubbingSearch,
  getDigitalManRecommendDub,
  getCategoryDubbingById,
} from '@/api/material';

import GetListWrapper from '@/components/Material/GetListWrapper';
import MaterialContent from '@/components/Material/MaterialContent';
import SearchList from '../../components/SearchList';
import UploadVideo, { UploadRefFn } from '../../components/UploadVideo';
import DubbingList from './DubbingList';

import { useStores } from '@/store';
import Style from './style.module.less';
import assets from '@/assets';
import { audio2Text } from '@/api/doc';
import { SOCKET_EVENTS } from '@/constants/SocketEvents';
import socket from '@/core/socket';
import { EDubSpeed } from '@/types/dub';
import { INTRO_CATEGORY_ID } from '../../const';

function Dubbing() {
  const { app } = useStores();
  const {
    activedScene: { presetIpImageId, digitalManDetail },
  } = app;

  /** 配音分类 */
  const [dubbingCategorys, setDubbingCategorys] = useState<TabsCategory[]>([]);
  /** 默认选择的分类 */
  const [activeCategory, setActiveCategory] = useState('');

  const [hasSocketLink, setHasScoketLink] = useState(false);

  const recordUrlRef = useRef('');
  const durationRef = useRef(0);
  const dubbingCategorysRef = useRef<TabsCategory[]>([]);

  const uploadRef = useRef<UploadRefFn>(null);

  const payloadMemo = useMemo(
    () => ({
      id: presetIpImageId,
      source: digitalManDetail?.expire ? 'ipImage' : 'presetIpImage',
    }),
    [presetIpImageId, digitalManDetail]
  );

  const getCategory = async () => {
    const { data } = await getDubbingCategory();
    const results = data.results.map(({ id, name }) => ({
      label: name,
      key: id,
    }));

    dubbingCategorysRef.current = results;
  };

  useEffect(() => {
    (async function () {
      let category: TabsCategory[];
      !dubbingCategorys.length && (await getCategory());

      if (presetIpImageId) {
        category = [
          {
            key: INTRO_CATEGORY_ID,
            label: '数字人推荐',
          },
          ...dubbingCategorysRef.current,
        ];
      } else {
        category = dubbingCategorysRef.current;
      }
      setActiveCategory(category[0]?.key || '');
      setDubbingCategorys(category);
    })();
  }, [presetIpImageId]);

  const resetRecordInfo = () => {
    durationRef.current = 0;
    recordUrlRef.current = '';
  };

  /** 该错误有可能连续触发多次 */
  const socketErr = (msg: string) => {
    Toast.error(msg);
  };

  const onSocketVideoToDocMessage = (_taskId: string) => {
    // 防止重复连接socket
    // 初始化为 true，后面代码不会执行
    if (hasSocketLink) {
      return;
    }

    socket.on((res: any) => {
      if (res.event === SOCKET_EVENTS.ASR_SUCCESS) {
        const extractResult: ExtractAudioResponse = res.data;
        console.log({
          extractResult,
          duration: durationRef.current,
          recordUrl: recordUrlRef.current,
        });

        const allTexts: AllTexts[] = [
          {
            text: extractResult.subtitle.map(item => item.text).join('\n'),
          },
        ];
        app.activedScene.update({
          allTexts,
          captions: extractResult.subtitle,
        });
        app.activedScene.setSound({
          speed: EDubSpeed.medium,
          url: recordUrlRef.current,
          tag: 'record',
          duration: durationRef.current,
        });
      }
      if (res.event === SOCKET_EVENTS.ASR_FAILED) {
        resetRecordInfo();
        socketErr('录音中未识别到内容');
      }
      uploadRef.current?.closeLoading();
    });
    socket.onErr(() => {
      resetRecordInfo();
      uploadRef.current?.closeLoading();
      setHasScoketLink(false);
    });
    setHasScoketLink(true);
  };

  return (
    <>
      <SearchList<DubbingDetail>
        placeholder="搜索配音"
        apiFn={getDubbingSearch}
      >
        <DubbingList isSearch />
      </SearchList>

      <UploadVideo
        ref={uploadRef}
        autoUpload
        transform
        accept=".wav,.mp3,.mp4"
        acceptType={['wav', 'mp3', 'mp4', 'mpeg']}
        uploadChange={res => {
          if (!res.length) return;

          uploadRef.current?.openLoading('识别中......');
          const linkVal = res[0].url;
          console.log(linkVal);
          recordUrlRef.current = linkVal;

          audio2Text(linkVal).then(({ data }) => {
            onSocketVideoToDocMessage(data.taskId);
          });
        }}
        onAudioDurationChange={d => {
          durationRef.current = d;
        }}
      >
        <div className={Style.extract}>
          <img
            src={assets.images.doc['voice_icon_import.png']}
            className={Style.extractImg}
            alt=""
          />
          导入音频
        </div>
      </UploadVideo>

      <MaterialContent
        categorys={dubbingCategorys.map(dubbing => ({
          ...dubbing,
          apiFn:
            dubbing.key === INTRO_CATEGORY_ID
              ? (parmas: DigitalManRecommendDubQuery) => {
                  if (presetIpImageId) {
                    return getDigitalManRecommendDub(parmas);
                  }
                  return { data: {} }; // 为了不报错
                }
              : getCategoryDubbingById,
          payload: dubbing.key === INTRO_CATEGORY_ID ? payloadMemo : undefined,
        }))}
        activeTab={activeCategory}
      >
        <GetListWrapper<DigitalManRecommendDubQuery, DubbingDetail>>
          <DubbingList />
        </GetListWrapper>
      </MaterialContent>
    </>
  );
}

export default observer(Dubbing);
