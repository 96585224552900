import { useEffect, useMemo, useState } from 'react';
import { LoadMore, Slider } from '@bhb-frontend/lithe-ui';
import debounce from '@bhb-frontend/utils/lib/debounce';
import classNames from 'classnames';

import { observer } from 'mobx-react-lite';
import { TextAnimationItem } from '@/types/material';
import ImageWrapper from '@/components/ImageWrapper';
import Style from './style.module.less';
import { useStores } from '@/store';
import { TextStruc } from '@/models/CmpStruc';

interface BackgroundListProps {
  /** 资源列表 */
  list?: TextAnimationItem[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;
}

function TextAnimationList(props: BackgroundListProps) {
  const { list, loading, hasMore, loadMore } = props;

  const {
    app: { activedCmps },
  } = useStores();
  const [activedCmp] = activedCmps;

  /* 当前文字动画索引 */
  const [selectIndex, setSelectIndex] = useState(-1);

  /* 滑动条的值 */
  const [sliderValue, setSliderValue] = useState(1);

  useEffect(() => {
    const activeText = (activedCmp as TextStruc) || {};
    const sourceId = activeText?.textAnimation?.sourceId;
    const index = list && list?.findIndex(item => item.id === sourceId);
    setSelectIndex(index as number);
  }, [list, activedCmps]);

  /**
   * 当选中的文本框为 图文快剪（订阅）的正文、直播快剪 的字幕时，不展示时长滑块
   * 当选择无文字动画时，不展示时长滑块
   */
  const sliderVisible = useMemo(
    () => activedCmp?.tag !== 'Captions' && selectIndex !== -1,
    [activedCmps, selectIndex]
  );

  useEffect(() => {
    const activeText = (activedCmp as TextStruc) || {};
    const duration = activeText.textAnimation?.duration;
    if (duration) {
      setSliderValue(duration / 1000 / 1000);
    } else {
      setSliderValue(1);
    }
  }, [activedCmps]);

  const onSelect = (index: number) => {
    const sliderValue = 1;
    setSliderValue(sliderValue);
    setSelectIndex(index);
    debounceUpdate(index, sliderValue);
  };

  const onSliderChange = (value: number | number[]) => {
    if (Array.isArray(value)) return;
    const sliderValue = Number(value);
    setSliderValue(sliderValue);
    debounceUpdate(selectIndex, sliderValue);
  };

  const debounceUpdate = debounce((index: number, sliderValue?: number) => {
    if (!sliderValue || !list) return;

    const data =
      index > -1
        ? list[index]
        : ({ name: '无效果', resourceUrl: '' } as TextAnimationItem);
    const textAnimation =
      index > -1
        ? {
            sourceId: data.id,
            duration: sliderValue * 1000 * 1000,
          }
        : undefined;
    const specialeEffect = {
      accessMode: 0,
      features: 0,
      fileName: data.name,
      footageType: 10, // 特效的 footageType 为10 ，参考底层定义
      matchId: '',
      matchType: 0,
      objectId: data.id,
      showName: '',
      uri: data.resourceUrl, // 资源包地址
      usage: 0,
    };
    const activeText = (activedCmp as TextStruc) || {};
    activeText.update?.({
      textAnimation, // 无效果传undefined
      specialeEffect,
    });
  }, 500);

  return (
    <div className={Style['template-content']}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        className={Style['load-more']}
        finishedText=""
      >
        <div className={Style.list}>
          <div className={Style.item}>
            <div
              className={classNames(Style.image, {
                [Style.select]: selectIndex === -1,
              })}
              onClick={() => onSelect(-1)}
            >
              <i
                className={classNames(
                  'iconfont',
                  'icon-text_icon_animation_wu'
                )}
                style={{ fontSize: 24, color: '#bdbbbb' }}
              />
            </div>
            <p>无效果</p>
          </div>
          {list?.map((item, index) => (
            <div className={Style.item} key={item.id}>
              <ImageWrapper
                className={classNames(Style.image, {
                  [Style.select]: selectIndex === index,
                })}
                urls={[item.coverUrl]}
                onClick={() => onSelect(index)}
              />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </LoadMore>

      <div
        style={{ display: sliderVisible ? 'block' : 'none' }}
        className={Style.slider}
      >
        <p>{sliderValue}s</p>
        <div className={Style.block}>
          <span className={Style.label}>动画时长</span>
          <Slider
            value={sliderValue}
            onChange={onSliderChange}
            min={0.5}
            max={3}
            step={0.1}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(TextAnimationList);
