import { useMemo } from 'react';
import { Tooltips } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { observer } from 'mobx-react';
import { event } from '@/utils/event';
import { TextStruc } from '@/models/CmpStruc';
import { combineColor } from '@/helpers/Styles';
import ColorSelector from '../ColorSelector';
import { SETTING_TEXT_COLOR_KEY } from '@/constants/StoreCacheKeys';

import assets from '@/assets';
import style from './FontColor.module.less';

interface FontColorProps {
  className?: string;
  model: TextStruc;
}

function FontColor(props: FontColorProps) {
  const { className, model } = props;
  const { fill, selectedStyle } = model;

  const changeColor = (color: string) => {
    event.fire(SETTING_TEXT_COLOR_KEY, color);
  };

  /** 展示的颜色 */
  const showColor = useMemo(() => {
    if (!selectedStyle || !selectedStyle.color) return fill?.color;
    if (typeof selectedStyle.color === 'string') return selectedStyle.color;
    return combineColor(selectedStyle.color);
  }, [selectedStyle, fill?.color]);

  return (
    <ColorSelector changeColor={changeColor} color={showColor}>
      <span>
        <Tooltips tips="字体颜色" placement="bottom" space={15}>
          <div className={className}>
            <img
              className={style['font-color-icon']}
              src={assets.images.doc['make_icon_color.png']}
              alt=""
            />
            <div
              style={{
                background: showColor,
              }}
              className={cs(style['color-panel'], {
                [style.border]: showColor?.toLowerCase() === '#ffffff',
              })}
            />
          </div>
        </Tooltips>
      </span>
    </ColorSelector>
  );
}

export default observer(FontColor);
