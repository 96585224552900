/** 组件类型 */
export enum TagsEnum {
  /** 背景 */
  BACKGROUND = 1,

  /** 插图 */
  IMAGE = 2,

  /** 标题 */
  TITLE = 3,

  /** 字幕 */
  CAPTIONS = 4,

  /** 水印 */
  WATERMARK = 6,

  /** 贴纸 */
  STICKER = 8,

  /** 文字贴图 */
  TEXT_STICKER = 9,

  /** 时间日期文字贴图 */
  DATE_TEXT_STICKER = 10,

  /** 地理定位文字贴图 */
  MAP_TEXT_STICKER = 11,
  /** 未知 */
  UNKNOWN = 0,
}

export const TAGS_MAP = {
  Background: 'Background',
  Image: 'Image',
  Title: 'Title',
  Captions: 'Captions',
  Sticker: 'Sticker',
  Watermark: 'Watermark',
  TextSticker: 'TextSticker',
  DateTextSticker: 'DateTextSticker',
  MapTextSticker: 'MapTextSticker',
  Unknown: 'Unknown',
} as const;

export type TAGS_TYPE = typeof TAGS_MAP[keyof typeof TAGS_MAP];

export function getTagsWithEnum(tags: TAGS_TYPE): TAGS_TYPE {
  const CmpTagsWithEnum: typeof TAGS_MAP = {
    [TAGS_MAP.Background]: 'Background',
    [TAGS_MAP.Image]: 'Image',
    [TAGS_MAP.Title]: 'Title',
    [TAGS_MAP.Captions]: 'Captions',
    [TAGS_MAP.Sticker]: 'Sticker',
    [TAGS_MAP.Watermark]: 'Watermark',
    [TAGS_MAP.TextSticker]: 'TextSticker',
    [TAGS_MAP.DateTextSticker]: 'DateTextSticker',
    [TAGS_MAP.MapTextSticker]: 'MapTextSticker',
    [TAGS_MAP.Unknown]: 'Unknown',
  };
  return CmpTagsWithEnum[tags];
}
// 文字类型
export const TEXT_TAG: TAGS_TYPE[] = [
  TAGS_MAP.TextSticker,
  TAGS_MAP.DateTextSticker,
  TAGS_MAP.MapTextSticker,
];
