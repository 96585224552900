import { Modal, Spin } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import style from './MakingBigHeadModal.module.less';

interface MakingBigHeadModalProps extends ModalProps {
  /** 取消 */
  onCancel: () => void;
}
export default function MakingBigHeadModal(props: MakingBigHeadModalProps) {
  const { onClose, onCancel, ...otherProps } = props;
  return (
    <Modal
      {...otherProps}
      closable={false}
      contentClassName={style['making-big-head-modal']}
      width={209}
      height={224}
    >
      <div className={style.container}>
        <Spin iconStyle={{ width: 30, height: 30 }} spinning />
        <p className={style['createing-text']}>AI生成中</p>
        <p className={style['waiting-time']}>大概需要2分钟</p>

        <div onClick={onCancel} className={style['cancel-btn']}>
          取消
        </div>
      </div>
    </Modal>
  );
}
