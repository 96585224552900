/**
 * 图文快剪文档相关错误码
 */
export const DOC_ERROR_CODE = {
  /**
   * 渲染视频个数超过限制
   */
  RENDER_LIMIT: 1002,
  /**
   * 包含基础会员收费素材
   */
  // CONTAINS_BASIC_MEMBERSHIP_FEE: 1018,
  /**
   * 包含专业会员收费素材
   */
  // CONTAINS_PROFESSIONAL_MEMBERSHIP_FEE: 1019,
  /**
   * 数字人输出时长不足
   */
  // FIGURE2D_DURATION_LIMIT: 1024,
  /**
   * 时长太长
   */
  // DURATION_LIMIT: 1012,
  /**
   * 生成视频次数限制
   */
  CREATE_VIDEO_NUM_LIMIT: 1011,
  /**
   * 数字人权限未开通
   */
  FIGURE2D_PERMISSION_LIMIT: 1023,
  /**
   * 定制2D数字人到期
   */
  FIGURE2D_EXPIRED: 1020,
  /**
   * 云空间不足，请先前往“图文快剪”清理
   */
  STORAGE_LIMIT_ERROR: 1009,

  /**
   * 触发付费指引
   */
  PAYMENT_GUIDELINES: 1050,

  /** 资源错误，资源缺省 */
  RESOURCE_ERROR: 1120,

  /** 审核失败 */
  AUDIT_FAILURE: 1016,

  /** 权益不足 */
  EQUITY_DEFICIENCY: 413,
};

export enum CreateVideoStatus {
  pending = 'pending', // 生成中
  completed = 'completed', // 生成成功
  fail = 'fail', // 生成失败
  queueing = 'queueing', // 排队中
  reject = 'reject', // 审核不通过
  review = 'review', // 审核中
  reviewFail = 'reviewFail', // 审核失败
}

export enum guideType {
  /** 消费 */
  consume = 'consume',
  /** 购买 */
  buy = 'buy',
  /** 无法使用 */
  outUse = 'outUse',
}

export enum ConsumeType {
  once = 'once',
  memberRights = 'memberRights',
}

export enum PayType {
  alipay = 'alipay',
  wechat = 'wechat',
}

export const PayTypeMap = {
  [PayType.wechat]: '微信',
  [PayType.alipay]: '支付宝',
};
