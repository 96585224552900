import { useState, useLayoutEffect } from 'react';
import { Input, Toast, Tooltips, Popup } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import classnames from 'classnames';
import ModalWrapper from '@/components/Modal';
import PopupOverlay, { OverlayType } from './PopupOverlay';
import Style from './index.module.less';
import { Tag } from './types';

interface AddTagProps extends ModalProps {
  /** 文档名称 */
  productionName: string;
  /** tag 列表 */
  tagList: Tag[];
  /** 文档的 tagName */
  labelId: string;
  /** 关闭事件 */
  onClose: () => void;
  /** 设置tag事件 */
  onSetTag: (tagIds: string[], names: string[]) => void;
  /** 添加tag */
  onAddTag: (name: string) => void;
  /** 编辑tag */
  onEditTag: (id: string, name: string) => void;
  /** 删除tag */
  onRemoveTag: (id: string) => void;
}

export default function TagModal(props: AddTagProps) {
  const {
    productionName,
    labelId,
    tagList,
    onSetTag,
    onAddTag,
    onEditTag,
    onRemoveTag,
    visible,
    ...otherProps
  } = props;

  const [isEditing, setEditing] = useState<boolean>(false);
  const [tagName, setTagName] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<Tag>();

  /** 设置默认值 */
  function setDefaultValue() {
    const tag = tagList.find(tag => tag.id === labelId);
    setSelectedTag(tag);
    setEditing(false);
    setTagName('');
  }
  useLayoutEffect(() => {
    /** 弹出开启时，设置默认值 */
    if (visible) {
      setDefaultValue();
    }
  }, [visible, labelId]);

  const isRepeatTag = (newTagName: string): boolean =>
    tagList.some(tag => tag.name === newTagName);

  /** 回车 */
  const onPressEnter = () => {
    if (!tagName) return;
    const curName = tagName.trim();
    if (!curName) return;
    if (tagList.length >= 10) {
      Toast.warning('标签上限，请删除后再添加！');
      return;
    }

    if (isRepeatTag(curName)) {
      Toast.warning('标签名重复！');
      return;
    }

    onAddTag(curName);
    setTagName('');
  };

  const selectTags = tagList.map(tag => (
    <div
      className={classnames(Style['select-item'], Style['tag-item'], {
        [Style['selected-item']]: selectedTag?.id === tag.id,
      })}
      key={tag.id}
      onClick={() => {
        setSelectedTag(tag);
      }}
    >
      {tag.name}
    </div>
  ));

  const editTagHandler = (newTagName: string, id: string) => {
    if (isRepeatTag(newTagName)) {
      Toast.warning('标签名重复！');
      return;
    }
    onEditTag(id, newTagName);
  };

  const editTags = tagList.map(tag => (
    <div className={Style['edit-tag-item']} key={tag.id}>
      <span className={Style['edit-tag-name']}>{tag.name}</span>
      <Popup
        trigger="click"
        placement="bottom"
        clickClosable
        overlay={
          <PopupOverlay
            height={138}
            tagName={tag.name}
            type={OverlayType.edit}
            onConfirm={newTagName => {
              editTagHandler(newTagName as string, tag.id);
            }}
          />
        }
      >
        <span>
          <Tooltips tips="编辑">
            <i
              className={classnames(
                'iconfont icon-a-document_icon_mingming2x',
                Style.btn
              )}
            />
          </Tooltips>
        </span>
      </Popup>

      <Popup
        trigger="click"
        placement="bottom"
        clickClosable
        overlay={
          <PopupOverlay
            height={110}
            type={OverlayType.remove}
            onConfirm={() => {
              onRemoveTag(tag.id);
            }}
          />
        }
      >
        <span>
          <Tooltips tips="删除">
            <i
              className={classnames(
                'iconfont icon-video_icon_delete',
                Style.btn
              )}
            />
          </Tooltips>
        </span>
      </Popup>
    </div>
  ));

  return (
    <ModalWrapper
      visible={visible}
      {...otherProps}
      title={`将”${productionName}“添加内容标签`}
      width={520}
      height="auto"
      onConfirm={() => {
        onSetTag([selectedTag?.id ?? ''], [selectedTag?.name || '']);
      }}
    >
      <div className={Style['tag-content']}>
        <div className={Style['tag-selected']}>
          <span className={Style['tag-selected-title']}>当前应用标签：</span>
          {selectedTag && (
            <div
              className={classnames(Style['tag-item'], Style['selected-item'])}
            >
              {selectedTag.name}
              <i
                onClick={() => {
                  setSelectedTag(undefined);
                }}
                className={classnames(
                  'iconfont icon-a-preview_icon_close_white2x',
                  Style['remove-tag-icon']
                )}
              />
            </div>
          )}
        </div>

        <div className={Style['tag-body']}>
          <Input
            value={tagName}
            placeholder="输入标签按回车即可为文档添加标签"
            onPressEnter={onPressEnter}
            onChange={(_, value) => {
              setTagName(value);
            }}
          />
          <div className={Style['tags-body-title']}>
            <span>选择标签</span>
            <span
              className={Style['management-btn']}
              onClick={() => {
                setEditing(!isEditing);
              }}
            >
              {isEditing ? '完成' : '标签管理'}
            </span>
          </div>

          <div>{isEditing ? editTags : selectTags}</div>
        </div>
      </div>
    </ModalWrapper>
  );
}
