import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ListQuery } from '@/types/api';
import { StickerDetail, TabsCategory } from '@/types/material';
import { getCategoryStickerById, getStickerCategory } from '@/api/material';

import GetListWrapper from '@/components/Material/GetListWrapper';
import MaterialContent from '@/components/Material/MaterialContent';

import StickerList from './StickerList';

import AddSticker from './AddSticker';

function Sticker() {
  /** 配音分类 */
  const [stickerCategorys, setStickerCategorys] = useState<TabsCategory[]>([]);
  /** 默认选择的分类 */
  const [activeCategory, setActiveCategory] = useState('');
  /** 获取分类 */
  const getCategory = async () => {
    const { data } = await getStickerCategory();
    const reuslts = data.results.map(({ id, name }) => ({
      label: name,
      key: id,
    }));

    setActiveCategory(reuslts[0]?.key || '');

    setStickerCategorys(reuslts);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <AddSticker />
      <MaterialContent categorys={stickerCategorys} activeTab={activeCategory}>
        <GetListWrapper<ListQuery, StickerDetail>
          apiFn={getCategoryStickerById}
          payload={{ sid: '', pageSize: 20 }}
        >
          <StickerList />
        </GetListWrapper>
      </MaterialContent>
    </>
  );
}

export default observer(Sticker);
