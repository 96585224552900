import { Slider } from '@bhb-frontend/lithe-ui';
import { observer } from 'mobx-react';
import cs from 'classnames';
import CmpDelete from '../Base/CmpDelete';
import { app } from '@/store';
import style from './Music.module.less';

function AudioSetting() {
  const { music } = app;
  const { volume } = music || {};
  const handlerChange = (value: unknown) => {
    app.setMusic({
      volume: value as number,
    });
  };

  const handleRemoveMusic = () => {
    app.removeMusic();
  };

  return (
    <div className={style.audio}>
      <div className={style['props-setting']}>
        <i className={cs('iconfont icon-zbkj_yl', style['volume-icon'])} />
        <Slider
          onChange={handlerChange}
          value={volume}
          min={0}
          max={100}
          className={style['slider-cmp']}
        />
        <span>{volume}</span>
      </div>

      <CmpDelete onClick={handleRemoveMusic} className={style['delete-icon']} />
    </div>
  );
}

export default observer(AudioSetting);
