/** 使用chrome内核的其他浏览器 ua区别 */
export const MARK_LIST = [
  'ubrowser', // UC
  'taobrowser', // 淘宝
  'lbbrowser', // 猎豹
  'qqbrowser', // QQ
  'maxthon', // 遨游
  'bidubrowser', // 百度
  'edg', // edge
  'metasr', // 搜狗
  'opera', // 欧朋
  'msie', // IE
  'trident', // IE
];

/** 使用chrome内核的360浏览器 需要mimeTypes区别 */
export const MIME_TYPE_LIST_360 = 'application/vnd.chromium.remoting-viewer'; // 360

/** 7天时间戳 */
export const SEVEN_TIME_STAMP = 604800000;
