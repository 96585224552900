import { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { LoadMore, Toast } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { removeBigHeadDigitalMan, updateBigHeadName } from '@/api/material';
import { DigitalManDetail, ItemMenuType } from '@/types/material';
import ConfirmMoal from '@/components/Confirm';
import RenameBigHeadModal from './modal/RenameBigHeadModal';
import Style from './style.module.less';
import { useStores } from '@/store';
import { DigitalManType, SELF_CATEGORY } from '../../const';
import ContentItem from './ContentItem';

interface DigitaiManContentProps {
  /** 资源列表 */
  list?: DigitalManDetail[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;
  className?: string;
  /** 添加项的名称 */
  addItemName?: string;
  /** tabID */
  tabId?: string;
  /** 初始化列表 */
  initList?: () => void;
  /** 添加操作 */
  onAdd?: () => void;
  activeDigitalMan?: DigitalManType;
  /** 是否是搜索 */
  isSearch?: boolean;
  /** 否展示更多操作 */
  isShowMore?: boolean;
}

export interface DigitaiManContentRef {
  initList?: () => void;
}

function DigitaiManContent(
  props: DigitaiManContentProps,
  ref: React.Ref<DigitaiManContentRef | null>
) {
  const {
    list,
    loading,
    hasMore,
    loadMore,
    className,
    addItemName,
    tabId,
    initList,
    onAdd,
    activeDigitalMan,
    isSearch = false,
    isShowMore = false,
  } = props;

  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [renameModalVisible, setRenameModalVisible] = useState<boolean>(false);
  const [renameValue, setRenameValue] = useState<string>('');

  const operationId = useRef<string>('');
  const { app } = useStores();

  useImperativeHandle(ref, () => ({ initList }));

  const handleClick = (item: DigitalManDetail) => {
    // 数字人-我的 使用 ipImageId
    // 数字人使用 presetIpImageId
    // 大头使用 avatarFigureId
    const typeIdObj =
      activeDigitalMan === DigitalManType.bigHead
        ? {
            avatarFigureId: item.id,
          }
        : tabId === 'self'
        ? { ipImageId: item.id }
        : {
            presetIpImageId: item.id,
          };
    app.activedScene.setDigitaiMan(item, typeIdObj);
  };

  const onOperation = async (value: ItemMenuType, item: DigitalManDetail) => {
    operationId.current = item.id;
    if (value === ItemMenuType.delete) {
      setRemoveModalVisible(true);
      return;
    }
    setRenameModalVisible(true);
    setRenameValue(item.name);
  };

  const onRemove = async () => {
    await removeBigHeadDigitalMan(operationId.current);
    initList?.();
    setRemoveModalVisible(false);
  };

  const onChangeName = (value: string) => {
    setRenameValue(value);
  };

  const onRename = async () => {
    if (renameValue.length > 10) {
      Toast.error('数字头像名称不能大于10个字符');
      return;
    }
    if (renameValue.length === 0) {
      Toast.error('数字头像名称不能为空');
      return;
    }
    const { error } = await updateBigHeadName(operationId.current, renameValue);
    if (error === 0) {
      Toast.success('操作成功');
      setRenameModalVisible(false);
      initList?.();
    }
  };

  /** 是否展示自定义item */
  const isShowCustomItem = (!tabId || tabId === SELF_CATEGORY.id) && !isSearch;

  return (
    <div
      className={cs(Style['digital-man-content'], className, {
        [Style.search]: isSearch,
      })}
    >
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        finishedText=""
        className={Style['load-more']}
      >
        {/* 添加item 项 */}
        {isShowCustomItem && (
          <div className={Style['content-item']}>
            <div
              onClick={onAdd}
              className={cs(Style['image-item'], Style['add-item'])}
            >
              <i
                className={cs(
                  'iconfont icon-a-theme_icon_add3x',
                  Style['add-icon']
                )}
              />
            </div>
            <div className={Style['image-name']}>{addItemName}</div>
          </div>
        )}

        {/* item 列表 */}
        {list?.map(item => (
          <ContentItem
            handleClick={handleClick}
            onOperation={onOperation}
            data={item}
            isShowMore={isShowMore}
            key={item.id}
          />
        ))}
      </LoadMore>

      {/* 弹窗 */}
      <ConfirmMoal
        visible={removeModalVisible}
        onCancel={() => {
          setRemoveModalVisible(false);
        }}
        onOk={onRemove}
        title="提示"
        content="确认删除数字头像吗？"
      />

      <RenameBigHeadModal
        visible={renameModalVisible}
        name={renameValue}
        onChange={onChangeName}
        onClose={() => {
          setRenameModalVisible(false);
        }}
        onOk={onRename}
      />
    </div>
  );
}

export default forwardRef(DigitaiManContent);
