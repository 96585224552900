import { makeObservable, observable } from 'mobx';
import { CmpTypeEnum } from '@/constants/CmpType';
import CmpStruc from './CmpStruc';

export default class VideoStruc<
    T extends ComponentModel.Video = ComponentModel.Video
  >
  extends CmpStruc<T>
  implements ComponentModel.Video
{
  fileName!: string;

  key!: string;

  url!: string;

  extra!: ImageExtra & VideoExtra;

  constructor(data?: Partial<ComponentModel.Video>) {
    super(data);
    makeObservable(this, {
      fileName: observable,
      key: observable,
      url: observable,
      extra: observable,
    });
  }

  /**
   * 获取组件类型
   * @returns {CmpTypeEnum.VIDEO}
   */
  protected getType(): CmpTypeEnum.VIDEO {
    return CmpTypeEnum.VIDEO;
  }

  /**
   * 获取tag
   * @returns
   */
  protected getTag() {
    return this.tag;
  }

  /**
   * 输出文本组件model模型
   * @returns {ComponentModel.Video}
   */
  model(): ComponentModel.Video {
    const model = super.model();
    return {
      ...model,
      tag: this.tag,
      fileName: this.fileName,
      key: this.key,
      url: this.url,
      extra: this.extra,
    };
  }
}
