import { observer } from 'mobx-react';
import Header from './Header';
import Doc from './Doc';
import Stage from './Stage';
import Scene from './Scene';
import Material from './Material';
import Style from './style.module.less';

function Layout() {
  return (
    <div className={Style.baseContainer}>
      <Header className={Style.header} />
      <div className={Style.main}>
        <Material className={Style.material} />

        <div className={Style.contentContainer}>
          <div className={Style.editContainer}>
            <Stage className={Style.stage} />
            <Doc className={Style.doc} />
          </div>

          <Scene />
        </div>
      </div>
    </div>
  );
}

export default observer(Layout);
