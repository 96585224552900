import {
  cloneElement,
  useState,
  useRef,
  useEffect,
  ReactElement,
  Ref,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { CustomListResponse } from '@/types/api';

// Redecalare forwardRef
declare module 'react' {
  function forwardRef<T, P>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export interface ListWrapperRefFn {
  handleInitList: () => void;
}

export interface GetListWrapperProps<T = any, P = any> {
  /** 请求api 方法 */
  apiFn?: (args: T, id?: string) => Promise<CustomListResponse<P>>;
  /** 请求参数 */
  payload?: T | ((id: string) => T);
  /** id */
  id?: string;
  /**  */
  children?: ReactElement;
  /** 回调函数特殊处理 */
  getSuccess?: (data) => void;
}

function GetListWrapper<T, P>(
  props: GetListWrapperProps<T, P>,
  _ref: Ref<ListWrapperRefFn>
) {
  const { apiFn, id, payload, children, getSuccess } = props;

  useImperativeHandle(_ref, () => ({
    handleInitList,
  }));

  const [list, setList] = useState<P[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const parmas = useRef({ sid: '', pageSize: 20 });
  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      if (!apiFn) return null;
      const { data } = await apiFn(parmas.current as T, id);
      parmas.current = {
        ...parmas.current,
        sid: data.sid,
      };

      setHasMore(!!data.sid);
      return data;
    } catch {
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleInitList = () => {
    initList();
  };

  /** 初始化列表 */
  const initList = async () => {
    parmas.current = { sid: '', pageSize: 20, ...payload };
    const data = await getList();
    if (data) {
      setList(data.results);
      getSuccess?.(data);
    }
  };

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    if (data) {
      setList([...list, ...data.results]);
    }
  };

  useEffect(() => {
    initList();
  }, [payload]);

  if (!children) return null;

  return cloneElement(children, {
    list,
    initList,
    loading,
    hasMore,
    loadMore,
    tabId: id,
  });
}

export default forwardRef(GetListWrapper);
