import { observer } from 'mobx-react';
import { useStores } from '@/store';
import { getRectDisplayStyle } from '@/helpers/Styles';
import { TAGS_MAP } from '@/constants/Tags';
import style from './Hover.module.less';

function HoverWrap() {
  const { app, OS } = useStores();
  const { activedCmps, hoveredCmp } = app;
  const zoomLevel = OS.zoomLevel;

  const hasSame = activedCmps.some(cmp => cmp.id === hoveredCmp?.id);

  if (!hoveredCmp || hasSame) {
    return null;
  }
  const hoverWrapStyle = getRectDisplayStyle(hoveredCmp, zoomLevel);

  /**
   * 类型提示
   */
  const renderTypeTip = () => {
    const tag = hoveredCmp.tag;
    const tipMap = {
      [TAGS_MAP.Captions]: '字幕',
      [TAGS_MAP.Title]: '标题',
    };

    if (!tipMap[tag]) return null;
    return <div className={style['hover-tip']}>{tipMap[tag]}</div>;
  };

  return (
    <div className={style['hover-wrap']} style={hoverWrapStyle}>
      {renderTypeTip()}
    </div>
  );
}

export default observer(HoverWrap);
