import { dragAction } from '@bhb-frontend/toolbox';
import MagneticLineHandler from '@/core/Manager/MagneticLine';
import { app, OS } from '@/store';
import CmpStruc from '@/models/CmpStruc';
import { Vector2d, OBB, isCollision } from './obb';

/**
 * 检测是否在可视区域，如果位置移除画布，进行删除
 *  */
export const checkCmpInVisibleArea = (model: CmpStruc) => {
  const { width: templateWidth = 0, height: templateHeight = 0 } =
    app.activedScene.configure;
  const { height = 0, width = 0, left = 0, top = 0 } = model.style;
  const { z: rotate } = model.rotation;

  const canvas = new OBB(
    new Vector2d(templateWidth / 2, templateHeight / 2),
    templateWidth,
    templateHeight
  );
  const currentCmp = new OBB(
    new Vector2d(+left + +width / 2, +top + +height / 2),
    +width,
    +height,
    rotate
  );
  const visible = isCollision(canvas, currentCmp);
  if (!visible) {
    model.remove();
  }
};

/**
 * cmp 移动
 *  */
export const moveHandle = (
  e: MouseEvent,
  model: CmpStruc,
  zoomLevel: number
) => {
  const { activedScene } = app;

  const magneticLineHandler = new MagneticLineHandler(
    model,
    activedScene.cmps,
    {
      width: Number(activedScene.configure?.width),
      height: Number(activedScene.configure?.height),
    },
    zoomLevel
  );
  const startPosition = {
    x: e.clientX,
    y: e.clientY,
  };
  dragAction(e, {
    move: e => {
      OS.setMoveState(true);
      const moveDistance = {
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      };
      const { x, y, magneticLines } =
        magneticLineHandler.calcAlignmentLine(moveDistance);
      model.updateStyle({ top: y / zoomLevel, left: x / zoomLevel });
      OS.setMagneticLine(magneticLines);
    },
    end: () => {
      OS.clearMagneticLines();
      OS.setMoveState(false);
      checkCmpInVisibleArea(model);
    },
  });
};
