import chineseLunar from 'chinese-lunar';
/**
 * 毫秒转化
 * @param milliseconds  毫秒
 * @returns
 */
function translateMs(milliseconds: number) {
  return {
    days: Math.trunc(milliseconds / 86400000),
    hours: Math.trunc(milliseconds / 3600000) % 24,
    minutes: Math.trunc(milliseconds / 60000) % 60,
    seconds: Math.trunc(milliseconds / 1000) % 60,
    milliseconds: Math.trunc(milliseconds) % 1000,
  };
}

/**
 *  补 0
 * @param value
 * @param digits 补的位数
 * @returns
 */
function addZero(value: number, digits = 2): string {
  return String(value).padStart(digits, '0');
}

/**
 * 毫秒转时间
 * @param ms 毫秒
 * @param isAddZero 是否补 0
 * @returns
 */
function formatDuration(ms: number, isAddZero = false): string {
  const sign = ms <= -1000 ? '-' : '';
  const t = translateMs(Math.abs(ms));
  const seconds = addZero(t.seconds);
  if (t.days)
    return `${sign + t.days}:${addZero(t.hours)}:${addZero(
      t.minutes
    )}:${seconds}`;
  if (t.hours)
    return `${sign + (isAddZero ? addZero(t.hours) : t.hours)}:${addZero(
      t.minutes
    )}:${seconds}`;
  return `${sign + (isAddZero ? addZero(t.minutes) : t.minutes)}:${seconds}`;
}

export default formatDuration;

/**
 * 格式化日期
 */
export function formatDate(format: string) {
  format = format || 'yyyy.MM.dd';
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const day = new Date().getDate();
  const week = new Date().getDay();
  switch (format) {
    case 'yyyy.MM.dd':
      return `${year}.${month}.${day}`;
    case 'yyyy-MM-dd':
      return `${year}-${month}-${day}`;
    case 'yyyy/MM/dd':
      return `${year}/${month}/${day}`;
    case 'yyyy年MM月dd日':
      return `${year}年${month}月${day}日`;
    case 'yyyy.MM':
      return `${year}.${month}`;
    case 'yyyy年':
      return `${year}年`;
    case 'yyyy':
      return `${year}`;
    case 'MM.dd':
      return `${month}.${day}`;
    case 'MM月dd日':
      return `${month}月${day}日`;
    case 'MM月':
      return `${month}月`;
    case 'MM':
      return `${month}`;
    case 'dd':
      return `${day}`;
    case '_MM.':
      return `${new Date().toDateString().split(' ')[1]}`;
    case '_week':
      return `${
        [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ][week]
      }`;
    case 'week':
      return `${
        ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][
          week
        ]
      }`;
    case '农历MMdd': {
      // 支持1900-2100年
      let lunar = '';
      try {
        lunar = chineseLunar.solarToLunar(new Date(year, month - 1, day), 'mD');
      } catch (e) {
        console.log(e);
        return `${year}.${month}.${day}`;
      }
      return `农历${lunar}`;
    }
    default:
      return `${year}.${month}.${day}`;
  }
}
