import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  Ref,
} from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import AudioPlayer from '@bhb-frontend/audio-player';
import Style from './AudioBox.module.less';
import { Speed } from '@/types/dubbing';
import { useStores } from '@/store';
import { MaterialEnum } from '@/types/material';
import { CmpTypeEnum } from '@/constants/CmpType';
import EmptyRender from './EmptyRender';
import AudioRender from './AudioRender';

export type AudioType = 'music' | 'dubbing';

export enum AudioEnums {
  MUSIC = 'music',
  DUBBING = 'dubbing',
}

export interface AudioProps {
  /** 音频类型 */
  type: AudioType;
  /** 语速 */
  speed?: keyof Speed;
  /** 音量 */
  volume: number;
  /** 封面 */
  cover: string;
  /** 名称 */
  name: string;
  /** 音频地址 */
  url: string;
  /** 是否隐藏工具栏 */
  isHideToolBar?: boolean;
  /** 移除音乐 */
  removeMusic: () => void;
  /* id */
  id?: string;
  /* 数字人id */
  presetIpImageId?: string;
  /* 导入录音 */
  isImportRecord?: boolean;
  /* 配音不需要选中 */
  isAudioActive?: boolean;
}

export interface RefEditAudio {
  play: () => void;
  pause: () => void;
  isPlay: boolean;
}

/**
 * 音乐播放盒子
 */
function AudioBox(props: AudioProps, ref: Ref<RefEditAudio>) {
  const { type, url, volume, id, isImportRecord, isAudioActive } = props;
  const {
    material: { changeMenu },
    app,
  } = useStores();

  const {
    isSelectedMediaMusic,
    activedScene: { isHaveDigitalMan },
  } = app;
  /* 是否在播放 */
  const [isPlay, setIsPlay] = useState(false);
  /* 单例模式 - 音频 */
  const audioPlayer = useRef(AudioPlayer.getInstance());

  useEffect(() => {
    const eventCB = (isPlay: boolean) => {
      setIsPlay(isPlay);
    };
    audioPlayer.current.onPlayEvent(type, eventCB);
    return () => {
      audioPlayer.current.offPlayEvent(type, eventCB);
    };
  }, []);

  /** 播放 */
  const play = () => {
    audioPlayer.current.play(type, url);
    setIsPlay(true);
  };

  /** 暂停 */
  const pause = () => {
    audioPlayer.current.pause(false);
    setIsPlay(false);
  };

  /**
   * 处理音频状态
   */
  const handleAudio = () => {
    isPlay ? pause() : play();
  };

  /** 调整音量 */
  useEffect(() => {
    audioPlayer.current.setVolume(volume / 100);
  }, [volume]);

  useImperativeHandle(ref, () => ({
    play,
    pause,
    isPlay,
  }));

  const handleClick = () => {
    switch (type) {
      case AudioEnums.MUSIC: {
        if (url && isSelectedMediaMusic) {
          handleAudio();
          break;
        }
        if (url && !isSelectedMediaMusic) {
          app.setActiveMediaCmp(CmpTypeEnum.MUSIC);
          audioPlayer.current.setVolume(volume / 100);
          break;
        }
        break;
      }
      case AudioEnums.DUBBING: {
        if (url) {
          handleAudio();
          app.setActiveMediaCmp(CmpTypeEnum.UNKNOWN);
          audioPlayer.current.setVolume(1);
          break;
        }
        break;
      }
      default:
        break;
    }

    // 打开对应的资源区侧边栏
    changeMenu(type as MaterialEnum);
  };

  return (
    <div
      className={cx(Style.audio, {
        [Style.audioActive]: isAudioActive,
        [Style.audioPlaying]: isPlay,
      })}
      onClick={handleClick}
    >
      {/* 存在id则渲染配音或者音乐 - 存在isImportRecord则渲染导入配音 */}
      {id || isImportRecord ? (
        <AudioRender
          {...props}
          isPlay={isPlay}
          isHaveDigitalMan={isHaveDigitalMan}
        />
      ) : (
        <EmptyRender type={type} />
      )}
    </div>
  );
}

export default observer(forwardRef(AudioBox));
