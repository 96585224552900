import Assets from '@/assets';

export const SELF_CATEGORY = {
  name: '我的',
  id: 'self',
};

/**
 * 会员等级/会员类型
 * 客户端看免费不展示，基础版 = VIP，专业版 = SVIP
 */
export const MEMBER_TYPE = {
  /**
   * 免费版
   */
  FREE: 'free',
  /**
   * 基础版
   */
  BASIC: 'basic',
  /**
   * 专业版
   */
  PROFESSIONAL: 'professional',
};

export const VIP_IMAGE = {
  clip: {
    vip_yes: Assets.images.components['doc_vip_yes.png'],
    vip_yes2: Assets.images.components['clip_vip_yes2.png'],
    vip_no: Assets.images.components['clip_vip_no.png'],
    vip_no2: Assets.images.components['clip_vip_no2.png'],
  },
  doc: {
    vip_yes: Assets.images.components['doc_vip_yes.png'],
    vip_yes2: Assets.images.components['doc_vip_yes2.png'],
    vip_no: Assets.images.components['doc_vip_no.png'],
    vip_no2: Assets.images.components['doc_vip_no2.png'],
    /**
     * 普通会员素材标识图标
     */
    VIP: Assets.images.doc['common_icon_vip1@2x.png'],
    /**
     * 专业会员素材标识图标
     */
    SVIP: Assets.images.doc['common_icon_golden.png'],
  },
};

/** 该列表里的域名不需要再上传cdn */
export const UPLOAD_IMG_DOMAIN = ['img.miaotui.com', 'vod.miaotui.com'];
/** 允许上传图片的最大容量 */
export const IMG_UPLOAD_MAX = 1024 * 1024 * 10; // 10MB
export const IMG_UPLOAD_MAX_TEXT = '10MB';
/** 允许上传视频的最大容量 */
export const VIDEO_UPLOAD_MAX = 1024 * 1024 * 100; // 100MB
export const VIDEO_UPLOAD_TEXT = '100MB';
/** 允许上传视频的最大时长 5minutes */
export const MAX_VIDEO_UPLOAD_DURATION = 5 * 60;

/**
 * 背景裁剪相关常量
 */
export const BACKGROUND_CROP = {
  /**
   * 裁剪框的宽度
   */
  CROP_WIDTH: 183,
  /**
   * 裁剪框的高度
   */
  CROP_HEIGHT: 325,
  /**
   * 裁剪框的宽高比例,9:16
   */
  VERTICAL: [9, 16] as [number, number],
  /**
   * 裁剪框的宽高比例,16:9
   */
  HORIZONTAL: [16, 9] as [number, number],
};

/**
 * 图文快剪侧边栏固定背景颜色常量
 */
export const BACKGROUND_COLOR = [
  '#FFFFFF',
  '#000000',
  '#5F5F5F',
  '#00FF00',
  '#773ED6',
  '#6CEBFF',
  '#FA0404',
  '#FA9904',
  '#FFFE00',
  '#1B55FD',
  '#ED41FD',
];

/**
 * 数字人类型
 */
export enum DigitalManType {
  /**
   * 数字克隆人
   * */
  cloneMan = 'cloneMan',
  /**
   * 大头数字人
   *  */
  bigHead = 'bigHead',
}

/**
 * 数字人一级标签页
 *  */
export const DIGITAL_MAN_TABS = [
  {
    label: '数字克隆人',
    key: DigitalManType.cloneMan,
  },
  {
    label: '大头数字人',
    key: DigitalManType.bigHead,
  },
];

/* 数字人推荐 */
export const INTRO_CATEGORY_ID = 'INTRO_CATEGORY_ID';
