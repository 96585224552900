/**
 * 组件基础类型
 */
export enum CmpTypeEnum {
  BACKGROUND = 'Background',
  TEXT = 'Text',
  IMAGE = 'Image',
  VIDEO = 'Video',
  GROUP = 'Group',
  MUSIC = 'Music',
  DUBBING = 'Dubbing',
  UNKNOWN = 'Unknown',
}
