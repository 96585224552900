import { action, makeObservable, observable, computed } from 'mobx';
import { randomString } from '@bhb-frontend/utils/lib/random';
import clone from '@bhb-frontend/utils/lib/clone';
import { CmpTypeEnum } from '@/constants/CmpType';
import TextStruc from './TextStruc';
import SceneStruc from '../SceneStruc';
import GroupStruc from './GroupStruc';
import { ALL_ANCHORS, AnchorPoint } from '@/constants/AnchorPoint';
import { TagsEnum, TAGS_MAP, TAGS_TYPE } from '@/constants/Tags';
import { PixelKey } from '@/constants/pixelKeys';
import { getCmpNodeRect } from '@/helpers/Node';
import { cmpHistoryDecorator } from '@/core/Decorator/History';
import CreateCmpStruc from '../FactoryStruc/CmpFactory';
import { app } from '@/store';
import { filterData } from '@/utils/filterData';

const origin = { x: 0, y: 0, z: 0 };

/**
 * 连续的操作记录集合
 */
const continuousMaps = new Map<string, any>();

export interface CmpTypeMap {
  [CmpTypeEnum.TEXT]: TextStruc;
  // ...
}

export default class CmpStruc<
  T extends ComponentModel.Base = ComponentModel.Base
> implements ComponentModel.Base
{
  id!: string;

  name!: string;

  type!: CmpType;

  sourceId!: string;

  // width: number;

  // height!: number;

  position!: Position;

  anchor!: Anchor;

  scalar!: Scalar;

  rotation!: Rotation;

  flip!: Flip;

  tag!: TAGS_TYPE;

  style: ComponentStyle;

  color?: string;

  // 功能点
  actived?: boolean;

  visible?: boolean;

  isLock?: boolean;

  loading?: boolean;

  parent?: SceneStruc | ComponentModel.Group;

  disabled?: boolean;

  isLockScale?: boolean;

  isLockFlip?: boolean;

  isLockRotation?: boolean;

  /** 1是背景 2是图片 3是标题 4是正文 8贴图 6是水印（没用到） 9是文字贴图 10是时间日期文字贴图 11是地理定位文字贴图 */
  usage?: 1 | 2 | 3 | 4 | 8 | 6 | 9 | 10 | 11;

  /** 所在的组合 */
  group?: GroupStruc | null = null;

  /** 所在的场景 */
  scene?: SceneStruc | null = null;

  /** 拓展字段， 转换没用的数据存放在此 */
  extensionFields!: Record<string, any>;

  /** 文字图片拓展字段， 转换没用的数据存放在此 */
  imgExtensionFields?: Record<string, any>;

  scaleMode?: 0 | 1 | 2;

  constructor(data?: Partial<ComponentModel.Base> & Record<string, any>) {
    makeObservable<this, 'handleUpdate' | 'handleUpdateStyle'>(this, {
      name: observable,
      visible: observable,
      isLock: observable,
      isLockScale: observable,
      isLockRotation: observable,
      isLockFlip: observable,
      loading: observable,
      style: observable,
      actived: observable,
      flip: observable,
      disabled: observable,
      color: observable,
      scaleMode: observable,
      rotation: observable,
      isLastCmp: computed,
      isFirstCmp: computed,
      isTitle: computed,
      isImage: computed,
      isBackground: computed,
      isCaptions: computed,
      isDateTextSticker: computed,
      isMapTextSticker: computed,
      isSticker: computed,
      isTextSticker: computed,
      isWatermark: computed,
      isText: computed,

      update: action,
      handleUpdate: action,
      active: action,
      inactive: action,
      updateStyle: action,
      handleUpdateStyle: action,
      // other
    });
    this.id = data?.id || '';
    this.type = data?.type || this.getType();
    this.tag = data?.tag || TagsEnum[TAGS_MAP.Unknown];
    // this.width = data?.width || 120;
    // this.height = data?.height || 30;
    this.disabled = !!data?.disabled;
    this.isLock = !!data?.isLock;
    this.isLockFlip = !!data?.isLockFlip;
    this.isLockRotation = !!data?.isLockRotation;
    this.isLockScale = !!data?.isLockScale;
    this.visible = data?.visible ?? true;
    this.position = data?.position || origin;
    this.anchor = data?.anchor || origin;
    this.scalar = data?.scalar || origin;
    this.rotation = data?.rotation || origin;
    this.flip = data?.flip || origin;
    this.color = data?.color;
    this.sourceId = data?.sourceId || '';
    this.extensionFields = data?.extensionFields;
    this.style = {
      backgroundColor: 'transparent',
      rotate: 0,
      ...data?.style,
    };
    this.imgExtensionFields = data?.imgExtensionFields || {};
    this.scaleMode = data?.scaleMode;
  }

  /**
   * 复制一个组件
   */
  clone(): CmpStruc {
    // 通用复制方法， 子类将会具体实现
    const model = clone(this.model(), true);
    model.id = `copy-${randomString()}-web`;
    return CreateCmpStruc(model.type, model, this.getParent());
  }

  /**
   * 输出组件model结构
   */
  model(): ComponentModel.Base & Record<string, any> {
    return {
      id: this.id,
      type: this.type,
      tag: this.tag,
      name: this.name,
      sourceId: this.sourceId,
      position: this.position,
      anchor: this.anchor,
      scalar: this.scalar,
      rotation: this.rotation,
      flip: this.flip,
      actived: this.actived,
      visible: this.visible,
      isLock: this.isLock,
      loading: this.loading,
      color: this.color,
      style: { ...this.style },
      extensionFields: this.extensionFields,
      imgExtensionFields: this.imgExtensionFields,
      scaleMode: this.scaleMode,
    };
  }

  isGroup(): this is GroupStruc {
    return 'cmps' in this;
  }

  /**
   * 返回父级：场景或者组合
   */
  getParent() {
    return this.scene || this.group;
  }

  getRootParent() {
    let parent = this.getParent();
    while (parent && !(parent instanceof SceneStruc)) {
      parent = parent.getParent();
    }
    return parent;
  }

  /**
   * 获取组件在dom中的矩形尺寸
   */
  getRectSize(): Size {
    const width = this.getNumPixel('width');
    const height = this.getNumPixel('height');
    return { width, height };
  }

  /**
   * 返回组件绝对位置，暂未实现
   * @returns
   */
  getAbsPosition(group?: GroupStruc | null): CmpPosition {
    if (!group) return this.getNumPosition();
    return {
      left: this.getNumPixel('left') + group.getNumPixel('left'),
      top: this.getNumPixel('top') + group.getNumPixel('top'),
    };
  }

  /**
   * 更新数据
   * @param data 当前数据
   */
  @cmpHistoryDecorator(function (_data: Partial<T>, isContinuous?: boolean) {
    if (isContinuous) {
      if (!continuousMaps.get(this.id)) {
        continuousMaps.set(this.id, this.model());
      }
      return null;
    }
    const last = continuousMaps.get(this.id) || this.model();
    continuousMaps.delete(this.id);

    return () => this.handleUpdate(last);
  })
  update(data: Partial<T>) {
    this.handleUpdate(data);
  }

  /** 替换数据,不保存WS */
  public replaceFileds(fileds) {
    for (const key in fileds) {
      this[key] = fileds[key];
    }
  }

  /**
   * 更新样式
   * @param data 当前数据
   */
  @cmpHistoryDecorator(function (_data: Partial<T>, isContinuous?: boolean) {
    if (isContinuous) {
      if (!continuousMaps.get(this.id)) {
        continuousMaps.set(this.id, { ...this.style });
      }
      return null;
    }
    const lastStyle = continuousMaps.get(this.id) || { ...this.style };
    continuousMaps.delete(this.id);

    return () => this.handleUpdateStyle(lastStyle);
  })
  updateStyle(data: Partial<ComponentStyle>, _isContinuous?: boolean) {
    this.handleUpdateStyle(data);
  }

  /**
   * 证明是否是该类型
   * @param type 当前需要判断的类型
   */
  proveType<T extends keyof CmpTypeMap>(type: T) {
    return this.type === type;
  }

  /**
   * 返回组件锚点
   */
  getAnchors(): AnchorPoint[] {
    // 锁定后不可操作，锚点空
    if (this.isLock) return [];

    // 锁定缩放后只返回四边锚点
    if (this.isLockScale) {
      return [
        AnchorPoint.TOP,
        AnchorPoint.BOTTOM,
        AnchorPoint.LEFT,
        AnchorPoint.RIGHT,
      ];
    }

    // 无锁定条件返回八个方向锚点
    return ALL_ANCHORS;
  }

  /**
   * 激活/选中组件
   */
  active() {
    this.actived = true;
  }

  /**
   * 取消选中
   */
  inactive() {
    this.actived = false;
  }

  /**
   * 删除自身
   */
  remove() {
    const parent = this.getParent();
    parent && parent.removeCmp(this);
  }

  /**
   * 解除父级所属
   */
  resetParnth() {
    this.scene = null;
    this.group = null;
  }

  /**
   * 获取父级像素大小
   */
  getParentNumSize(): Size | null {
    let size: Size | null = null;
    if (this.group) {
      size = this.group.getNumSize();
    } else if (this.scene) {
      size = {
        width: this.scene.configure.width || 0,
        height: this.scene.configure.height || 0,
      };
    }

    return size;
  }

  /**
   * 返回真实尺寸的值
   * @returns {Size}
   */
  getNumSize(): Size {
    return {
      width: this.getNumPixel('width'),
      height: this.getNumPixel('height'),
    };
  }

  /**
   * 获取组件转换为绝对像素值的坐标位置
   * @returns {CmpPosition}
   */
  getNumPosition(): CmpPosition {
    return {
      left: this.getNumPixel('left'),
      top: this.getNumPixel('top'),
    };
  }

  /**
   * 转换为百分比或者像素
   * @param value 数值
   * @param styleKey 属性key
   */
  toPixel(value: number, styleKey: PixelKey) {
    if (!this.style) return value;
    const pixel = this.style[styleKey];
    if (pixel === 'auto') return 'auto';
    if (typeof pixel === 'string' && /%$/.test(pixel)) {
      const parentSize = this.getParentNumSize();
      if (!parentSize) return value;
      const determine = ['width', 'left'].includes(styleKey)
        ? parentSize.width
        : parentSize.height;

      return `${((value / determine) * 100).toFixed(2)}%`;
    }
    return value;
  }

  /**
   * 根据偏移量转换像素值
   * @param value 偏移量
   * @param styleKey
   * @returns
   */
  toPixelBy(value: number, styleKey: PixelKey) {
    const pixel = this.getNumPixel(styleKey);
    return this.toPixel(pixel + value, styleKey);
  }

  /**
   * 转换为数字像素
   * @param styleKey 属性
   * @returns {number}
   */
  getNumPixel(styleKey: PixelKey): number {
    if (!this.style) return 0;
    const pixel = this.style[styleKey];
    if (pixel == null) return 0;
    if (pixel === 'auto') {
      const rect = getCmpNodeRect(this);
      return rect?.[styleKey] ?? 0;
    }
    if (typeof pixel === 'number') return pixel;
    if (/px$/.test(pixel)) return parseFloat(pixel);
    if (/%$/.test(pixel)) {
      const parentSize = this.getParentNumSize();
      if (!parentSize) return 0;
      const determine = ['width', 'left'].includes(styleKey)
        ? parentSize.width
        : parentSize.height;

      return (determine * parseFloat(pixel)) / 100;
    }
    return 0;
  }

  /**
   * 设置方位的具体数值
   * @param value 偏离值
   * @param styleKey 方向属性key
   */
  setNumPixel(value: number, styleKey: PixelKey) {
    const px = this.toPixel(value, styleKey);
    this.updateStyle({ [styleKey]: px });
  }

  /**
   * 在当前位置上增加偏移
   * @param value 偏移值
   * @param styleKey 方向属性key
   */
  addPixel(value: number, styleKey: PixelKey) {
    const px = this.getNumPixel(styleKey);
    this.setNumPixel(px + value, styleKey);
  }

  /** 保存前 */
  beforeSave(): boolean {
    // 保存前的特殊处理，由子类实现
    return true;
  }

  /**
   * 更新组件数据
   * @param data 组件当前数据
   */
  protected handleUpdate(data: Partial<T>) {
    const updateData = filterData(this.model(), data);
    if (Object.keys(updateData).length === 0) return;

    Object.keys(updateData).forEach(key => {
      // 背景特处理
      if (this.tag !== 'Background' && key !== 'isLock' && this.isLock) return;
      this[key] = updateData[key];
    });
    const isSave = this.beforeSave();
    isSave && app.save();
  }

  /**
   * 更新组件样式
   * @param data 组件当前样式数据
   */
  protected handleUpdateStyle(data: Partial<ComponentStyle>) {
    const updateData = filterData(this.style, data);
    if (Object.keys(updateData).length === 0) return;

    for (const key in updateData) {
      if (this.isLock || !this.style) continue;
      this.style[key] = updateData[key];
    }
    const isSave = this.beforeSave();
    isSave && app.save();
  }

  /**
   * 返回组件类型，未构造成功前是 `unkomwn`
   */
  protected getType(): CmpType {
    return CmpTypeEnum.UNKNOWN;
  }

  protected getTypeName() {
    return '新组件';
  }

  /** 层级是否是最后一个 */
  get isLastCmp() {
    const { activedScene } = app;
    const index = this.getIndex();
    return index === activedScene.cmps.length - 1;
  }

  /** 层级是否第一个 */
  get isFirstCmp() {
    const index = this.getIndex();
    /** 背景应该是在最下面，背景永远是在数组的第一个 */
    return index <= 1;
  }

  getIndex(): number {
    const { activedScene } = app;
    return activedScene.cmps.findIndex(cmps => cmps.id === this.id);
  }

  /** 下一级 */
  toDown() {
    const { activedScene } = app;
    const currentIndex = this.getIndex();
    /** 背景应该是在最下面，背景永远是在数组的第一个 */
    if (this.isFirstCmp) return;
    const cmps = [...activedScene.cmps];
    const flag = cmps[currentIndex];
    cmps[currentIndex] = cmps[currentIndex - 1];
    cmps[currentIndex - 1] = flag;
    activedScene.update({ cmps });
  }

  /** 置底 */
  toBottom() {
    const { activedScene } = app;
    const currentIndex = this.getIndex();
    if (this.isFirstCmp) return;
    const cmps = [...activedScene.cmps];
    cmps.splice(1, 0, ...cmps.splice(currentIndex, 1));
    activedScene.update({ cmps });
  }

  /** 上一级 */
  toUp() {
    const { activedScene } = app;
    const currentIndex = this.getIndex();
    if (this.isLastCmp) return;
    const cmps = [...activedScene.cmps];
    const flag = cmps[currentIndex];
    cmps[currentIndex] = cmps[currentIndex + 1];
    cmps[currentIndex + 1] = flag;
    activedScene.update({ cmps });
  }

  /** 置顶 */
  toTop() {
    const { activedScene } = app;
    const currentIndex = this.getIndex();
    if (this.isLastCmp) return;
    const cmps = [...activedScene.cmps];
    cmps.push(...cmps.splice(currentIndex, 1));
    activedScene.update({ cmps });
  }

  /**
   * 是否是Title
   */
  get isTitle() {
    return this && this.tag === TAGS_MAP.Title;
  }

  /**
   * 是否是Background
   */
  get isBackground() {
    return this && this.tag === TAGS_MAP.Background;
  }

  /**
   * 是否是Captions
   */
  get isCaptions() {
    return this && this.tag === TAGS_MAP.Captions;
  }

  /**
   * 是否是DateTextSticker
   */
  get isDateTextSticker() {
    return this && this.tag === TAGS_MAP.DateTextSticker;
  }

  /**
   * 是否是Image（插图）
   */
  get isImage() {
    return this && this.tag === TAGS_MAP.Image;
  }

  /**
   * 是否是MapTextSticker
   */
  get isMapTextSticker() {
    return this && this.tag === TAGS_MAP.MapTextSticker;
  }

  /**
   * 是否是Sticker
   */
  get isSticker() {
    return this && this.tag === TAGS_MAP.Sticker;
  }

  /**
   * 是否是TextSticker
   */
  get isTextSticker() {
    return this && this.tag === TAGS_MAP.TextSticker;
  }

  /**
   * 是否是Watermark
   */
  get isWatermark() {
    return this && this.tag === TAGS_MAP.Watermark;
  }

  /**
   * 是否是文本
   * */
  get isText() {
    return this && this.type === 'Text';
  }

  /**
   * 是否是图片
   */
  get isImg() {
    return this && this.type === 'Image';
  }

  /** 是否可copy, 默认可copy，再由子类重写 */
  get isCanCopy() {
    return true;
  }
}
