import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef } from 'react';
import Canvas from './Canvas';
import Toolbox from './Toolbar/Toolbar';

import style from './Stage.module.less';
import { useStores } from '@/store';
import AudioBox, { RefEditAudio } from '@/components/AudioBox';

interface StageProps {
  className: string;
}

function Stage(props: StageProps) {
  const { className } = props;
  const { app } = useStores();
  const { checkStage, music, activedScene, isSelectedMediaMusic } = app;
  const { presetIpImageId } = activedScene || {};
  const {
    cover = '',
    url = '',
    name = '选择音乐',
    volume = 0,
    id,
  } = music || {};
  const audioBoxRef = useRef<RefEditAudio>({} as RefEditAudio);
  /**
   * 点到画布之外，显示全局设置
   */
  const handleFloorClick = () => {
    checkStage();
  };

  const handleRemoveMusic = () => {
    audioBoxRef.current.pause?.();
    app.removeMusic();
  };

  /** 配音不需要选中 */
  const isAudioActive = useMemo((): boolean => {
    if (isSelectedMediaMusic && url) return true;
    return false;
  }, [isSelectedMediaMusic, url]);

  /**
   * 设置选中状态
   *  music 才能被选中
   */
  useEffect(() => {
    if (isSelectedMediaMusic) {
      audioBoxRef.current.pause?.();
    }
  }, [isSelectedMediaMusic]);

  return (
    <div className={className}>
      <div className={style.floor} onClick={handleFloorClick} />
      <Toolbox />
      <Canvas />
      <AudioBox
        ref={audioBoxRef}
        type="music"
        url={url}
        cover={cover}
        name={name}
        volume={volume}
        removeMusic={handleRemoveMusic}
        presetIpImageId={presetIpImageId}
        id={id}
        isAudioActive={isAudioActive}
      />
    </div>
  );
}

export default observer(Stage);
