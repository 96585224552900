import { useState, useRef, useEffect } from 'react';

import { Modal, Tabs, Button, Toast, Spin } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import cx from 'classnames';
import { formatTime } from '@bhb-frontend/utils/lib/format';
import { useRefState } from '@/hooks/useRefState';
import style from './AddBigHeadModal.module.less';
import ResourceContent from './ResourceContent';
import { UserBigHeadItem, BodyStyleItem, HeadStyleItem } from '@/types/doc';
import { SocketResponse, BigHeadEffectsResult } from '@/types/socket';
import { cancelEffectTaskById, createEffectTask } from '@/api/doc';
import { makeImage } from '@/utils/image';
import { SOCKET_EVENTS } from '@/constants/SocketEvents';
import Uploader from '@/utils/upload';
import {
  Item,
  TBA_ITEMS,
  TabType,
  PREVIEW_HEIGHT,
  PREVIEW_WIDTH,
  HeadImageInfo,
  DESIGN_INFO,
  LOWER_JAW_INFO_IN_DESIGN,
  SAVE_HEAD_FIGURE_RESULT_ERROR_TIP,
} from '../../config';
import socket from '@/core/socket';

interface SelectedInfo {
  [TabType.head]: UserBigHeadItem | null;
  [TabType.bodyStyle]: BodyStyleItem | null;
  [TabType.headStyle]: HeadStyleItem | null;
}

const getDefaultSelectedData = () => ({
  [TabType.head]: null, // 选择的用户头像
  [TabType.bodyStyle]: null, // 选择的头像风格
  [TabType.headStyle]: null, // 选择的身体风格
});

interface AddBigHeadModalProps extends ModalProps {
  /** 打开创建 */
  openCreate: () => void;
  /** 添加数字人成功 */
  addSuccess: () => void;
}

export default function AddBigHeadModal(props: AddBigHeadModalProps) {
  const { onClose, addSuccess, openCreate, ...otherProps } = props;
  /** 活动tab */
  const [activeTab, setActiveTab] = useState<string>(TabType.head);
  /** 是否在创建风格头像中 */
  const [isCreateingStyleHead, setIsCreateingStyleHead] = useState(false);
  /** 是否提交中 */
  const [submiting, setSubmiting] = useState<boolean>(false);
  /** 选中的信息 */
  const [selectedInfo, setSelectedInfo, getSelectedInfoRef] =
    useRefState<SelectedInfo>(getDefaultSelectedData());
  /** 上一次选择的信息 用于创建特效失败，返回上一次的状态 */
  const preSelectedInfoRef = useRef<SelectedInfo>(getDefaultSelectedData());
  /** 用户头像和风格头像合成头像地址 */
  const styleHeadUrl = useRef<string>('');
  /** 预览canvas */
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  /** 创建风格特效id */
  const taskIdRef = useRef('');

  /** 计算头部位置 */
  const calcHeadPosition = (
    targetWidth: number,
    targetHeight: number,
    imageInfo: HeadImageInfo
  ) => {
    const rateWidth = targetWidth / DESIGN_INFO.width;
    const rateHeight = targetHeight / DESIGN_INFO.height;
    const { headWidth, headHidht, headX, headY } = DESIGN_INFO;

    // 在目标容器中的头部信息
    const headInfoInTarget = {
      width: headWidth * rateWidth,
      height: headHidht * rateHeight,
      x: headX * rateWidth,
      y: headY * rateHeight,
    };

    // 在目标容器头部框中的下颚坐标
    const lowerJawPositionInHeadBox = {
      x: LOWER_JAW_INFO_IN_DESIGN.x * rateWidth,
      y: LOWER_JAW_INFO_IN_DESIGN.y * rateHeight,
    };

    const {
      chinPointX,
      chinPointY,
      width: imageWidth,
      height: imageHeight,
    } = imageInfo;
    // 容器中的头像大小和真实头像（图片）尺寸的比例
    const rateW = headInfoInTarget.width / imageWidth;
    const rateH = headInfoInTarget.height / imageHeight;
    // 下颚在目标容器中的实际位置
    const lowerJawPositionInTarget = {
      x: chinPointX * rateW + headInfoInTarget.x,
      y: chinPointY * rateH + headInfoInTarget.y,
    };

    /** 下颚在画布中的实际位置 与 目标位置的偏移  */
    const offsetX = lowerJawPositionInHeadBox.x - lowerJawPositionInTarget.x;
    const offsetY = lowerJawPositionInHeadBox.y - lowerJawPositionInTarget.y;

    headInfoInTarget.x += offsetX;
    headInfoInTarget.y += offsetY;
    return headInfoInTarget;
  };

  /** 绘制 */
  const draw = async (
    headUrl: string,
    bodyUrl: string,
    imageInfo?: HeadImageInfo
  ) => {
    if (!previewCanvasRef.current) return;
    const ctx = previewCanvasRef.current.getContext('2d');
    ctx?.clearRect(0, 0, PREVIEW_WIDTH, PREVIEW_HEIGHT);

    if (bodyUrl) {
      const img = await makeImage(bodyUrl, PREVIEW_WIDTH, PREVIEW_HEIGHT);
      ctx?.drawImage(img, 0, 0, PREVIEW_WIDTH, PREVIEW_HEIGHT);
    }

    const selectedInfo = getSelectedInfoRef();
    if (headUrl && selectedInfo.head) {
      // 合成后的特效图片，有自己的图片坐标信息
      const { width: w, height: h, chinPointX, chinPointY } = selectedInfo.head;
      const imageData = imageInfo || {
        width: w,
        height: h,
        chinPointX,
        chinPointY,
      };
      const { x, y, width, height } = calcHeadPosition(
        PREVIEW_WIDTH,
        PREVIEW_HEIGHT,
        imageData
      );
      const img = await makeImage(headUrl, width, height);
      ctx?.drawImage(img, x, y, width, height);
    }
  };

  /** 更新画布 */
  const updateCanvas = (imageInfo?: HeadImageInfo) => {
    const selectedInfo = getSelectedInfoRef();

    let headUrl = styleHeadUrl.current;
    /** 如果没有生成的风格头像 或者没有选中风格头像 就使用用户的头像 */
    if (!headUrl || !selectedInfo[TabType.headStyle]) {
      headUrl = selectedInfo[TabType.head]?.previewImageUrl || '';
    }
    const bodyUrl = selectedInfo[TabType.bodyStyle]?.preImageUrl || '';
    draw(headUrl, bodyUrl, imageInfo);
  };

  /** 选择 */
  const onSelect = async (item: Item | null, key: TabType) => {
    setSelectedInfo(oldInfo => {
      preSelectedInfoRef.current = oldInfo;
      return {
        ...oldInfo,
        [key]: item,
      };
    });
  };

  /** 取消合成头像人物 */
  const cancelEffectTask = () => {
    cancelEffectTaskById([taskIdRef.current]);
    taskIdRef.current = '';
  };

  const selectedInfoChange = async () => {
    /** 去合成特效头像 */
    if (
      selectedInfo.headStyle &&
      (activeTab === TabType.headStyle || activeTab === TabType.head)
    ) {
      try {
        /** 有id说明在创建中，需要取消上一个任务 */
        taskIdRef.current && cancelEffectTask();
        if (selectedInfo.head && selectedInfo.headStyle) {
          setIsCreateingStyleHead(true);
          const { type, id } = selectedInfo.headStyle;
          const { chinPointX, chinPointY, previewImageUrl } = selectedInfo.head;
          const { data } = await createEffectTask({
            type,
            fileUrl: previewImageUrl,
            effectMaterialId: id,
            customExtra: {
              chinPointX,
              chinPointY,
            },
          });
          taskIdRef.current = data.taskId;
        }
        return;
      } catch (err) {
        console.log(err);
        Toast.error('合成头像失败');
        setIsCreateingStyleHead(false);
        return;
      }
    }

    updateCanvas();
  };

  useEffect(() => {
    selectedInfoChange();
  }, [selectedInfo]);

  const sockerCallback = (res: SocketResponse<BigHeadEffectsResult>) => {
    const { event, data } = res;
    const {
      status,
      message,
      taskId,
      fileUrl,
      width,
      height,
      chinPointX,
      chinPointY,
      code,
    } = data;
    /** 合成头像事件 */
    if (event === SOCKET_EVENTS.EFFECT_TASK_RESULT) {
      if (!taskIdRef.current || taskIdRef.current !== taskId) return;
      taskIdRef.current = '';
      if (status === 'failed') {
        // 风格头像生成失败回到上一次选中状态
        setSelectedInfo({ ...preSelectedInfoRef.current });
        Toast.error(message);
        return;
      }
      styleHeadUrl.current = fileUrl || '';
      updateCanvas({ width, height, chinPointX, chinPointY });
      setIsCreateingStyleHead(false);
    }
    /** 提交大头数字人事件 */
    if (event === SOCKET_EVENTS.SAVE_HEAD_FIGURE_RESULT) {
      setSubmiting(false);
      if (status === 'failed') {
        const message = SAVE_HEAD_FIGURE_RESULT_ERROR_TIP[code];
        Toast.error(message);
        return;
      }
      Toast.success('添加大头数字人成功');
      addSuccess();
    }
  };

  useEffect(() => {
    socket.on(sockerCallback);
    return () => {
      socket.off(sockerCallback);
    };
  }, []);

  /** 下一步 */
  const nextStep = () => {
    const currentIndex: number = TBA_ITEMS.findIndex(
      ({ key }) => key === activeTab
    );
    const nextKey = TBA_ITEMS[currentIndex + 1]?.key;
    nextKey && setActiveTab(nextKey);
  };

  /** canvas 转文件 */
  const canvasToFile = (): Promise<File> =>
    new Promise((resolve, reject) => {
      previewCanvasRef.current?.toBlob((blob: Blob | null) => {
        if (!blob) {
          return reject(new Error('导出blob失败'));
        }
        const file = new File([blob], `${new Date()}.png`);
        return resolve(file);
      });
    });

  /** 完成提交 */
  const onSubmit = async () => {
    if (!selectedInfo.head) {
      Toast.warning('请选择头像');
      return;
    }
    setSubmiting(true);

    /** 如果有合成头像人物，需要取消*/
    taskIdRef.current && cancelEffectTask();

    const imageFile = await canvasToFile();
    const uploader = new Uploader(imageFile);
    const { url } = await uploader.upload();
    const name = formatTime(new Date(), 'yyyy.mm.dd hh:MM');
    const data = {
      name, // 数字头像名称
      headId: selectedInfo.head?.id || '', // 头像id
      bodyId: selectedInfo.bodyStyle?.id || '', // 身体id
      headEffectId: selectedInfo.headStyle?.id || '', // 头像作用的特效id
      previewKey: url, // 预览图key
    };
    socket.emit({
      event: SOCKET_EVENTS.SAVE_HEAD_FIGURE,
      data,
    });
  };

  return (
    <Modal
      {...otherProps}
      width={904}
      height={600}
      contentClassName={style['add-big-head-modal']}
      closable={false}
    >
      <Spin
        spinning={submiting}
        className={style['spin-component']}
        iconStyle={{ width: 50, height: 50 }}
      >
        <div className={style.container}>
          <div className={style['container-item']}>
            <Tabs
              isLazy={false}
              activeKey={activeTab}
              onChange={setActiveTab}
              className={style['tabs-component']}
              tabPosition="top"
              tabBarStyle={{ fontWeight: 500 }}
              items={TBA_ITEMS.map(({ label, key, ...otherProps }) => ({
                label,
                key,
                children: (
                  <ResourceContent
                    toCreateDigitalMan={openCreate}
                    key={key}
                    {...otherProps}
                    selectedData={selectedInfo[key]}
                    onSelect={onSelect}
                    tabName={key}
                    loading={isCreateingStyleHead}
                  />
                ),
              }))}
            />
          </div>

          {/* 右侧 */}
          <div className={cx(style['container-item'])}>
            {/* 右侧头部 */}
            <div className={cx(style['display-header'])}>
              <i
                onClick={onClose}
                className={cx(
                  'iconfont',
                  'icon-a-common_icon_close_grey2x1',
                  style['icon-close']
                )}
              />
            </div>
            {/* 右侧预览 */}
            <div className={style['display-area']}>
              <canvas
                ref={previewCanvasRef}
                width={PREVIEW_WIDTH}
                height={PREVIEW_HEIGHT}
                className={style['display-canvas']}
              />
              <div className={style['btn-box']}>
                <Button onClick={onSubmit} className={style.btn}>
                  完成
                </Button>

                {activeTab !== TabType.bodyStyle && (
                  <Button onClick={nextStep} className={style.btn}>
                    下一步
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}
