import { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import cx from 'classnames';

import { observer } from 'mobx-react';
import { reorder } from '@/utils/reactDnd';
import { useStores } from '@/store';
import Preview, { PreviewType } from './Preview';
import UploadResource from './UploadResource';
import MaterIalItem from './MaterialItem';

import Style from './style.module.less';

function Mater() {
  const { app } = useStores();
  const { materials } = app.activedScene;

  const [uploading, setUploading] = useState(false);
  // 预览素材的数据
  const [previewTypeData, setPreviewTypeData] = useState<PreviewType>({
    type: 'image',
    url: '',
  });
  // 预览素材的显示隐藏
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    const r = app.activedScene.materials.some(item => !!item.placeholder);
    setUploading(r);
  }, [app.activedScene.materials]);

  /**
   * 预览素材
   */
  const handleItemClick = (item: Material) => {
    const { type, url, duration, clipRange } = item;
    if (type === 'image') {
      setPreviewTypeData({ type, url });
    }
    if (type === 'video') {
      const [start = 0, end = Number(duration) * 1000 * 1000] = clipRange || [];
      setPreviewTypeData({ type, url, start, end, duration });
    }
    setPreviewVisible(true);
  };

  const findItem = useCallback(
    (id: string) => {
      const item = materials.filter(m => m.url === id)[0];

      return {
        item,
        index: materials.findIndex(m => m.url === id),
      };
    },
    [materials]
  );
  const moveItem = useCallback(
    (id: string, atIndex: number) => {
      const { index } = findItem(id);
      const newList: any[] = reorder(materials, index, atIndex);
      app.activedScene.update({ materials: newList });
    },
    [findItem, materials]
  );

  const [, drop] = useDrop(() => ({ accept: 'ITEM' }));

  return (
    <div className={cx(Style.material, Style.materialDrag)} ref={drop}>
      <UploadResource />
      {materials.map((item, index) => (
        <MaterIalItem
          key={item.url + index}
          item={item}
          id={item.url}
          index={index}
          uploading={uploading}
          findItem={findItem}
          moveItem={moveItem}
          previewItem={handleItemClick}
        />
      ))}

      {previewVisible && (
        <Preview {...previewTypeData} close={() => setPreviewVisible(false)} />
      )}
    </div>
  );
}

export default observer(Mater);
