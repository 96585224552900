import { CmpTypeEnum } from '@/constants/CmpType';
import CmpStruc, {
  TextStruc,
  GroupStruc,
  ImageStruc,
  VideoStruc,
  BackgroundStruc,
} from '../CmpStruc';
import SceneStruc from '../SceneStruc';

const cmpTypeMapStruc: Record<CmpType, typeof CmpStruc | null> = {
  [CmpTypeEnum.TEXT]: TextStruc,
  /** 一下模型暂未实现 */
  [CmpTypeEnum.MUSIC]: null,
  [CmpTypeEnum.DUBBING]: null,
  [CmpTypeEnum.BACKGROUND]: BackgroundStruc,
  [CmpTypeEnum.IMAGE]: ImageStruc,
  [CmpTypeEnum.GROUP]: GroupStruc,
  [CmpTypeEnum.VIDEO]: VideoStruc,
  [CmpTypeEnum.UNKNOWN]: TextStruc,
};

export default function CreateCmpStruc<T extends keyof typeof cmpTypeMapStruc>(
  type: T,
  data?: Partial<ComponentModel.Base>,
  parent?: SceneStruc | GroupStruc | null
) {
  const Structure = cmpTypeMapStruc[type];
  if (!Structure) throw new Error(`${type}组件暂未实现`);

  const structure = new Structure(data);

  if (parent instanceof SceneStruc) {
    structure.scene = parent;
  } else if (parent instanceof GroupStruc) {
    structure.group = parent;
  } else {
    structure.scene = null;
    structure.group = null;
  }

  return structure;
}
