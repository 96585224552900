import { Button, Toast } from '@bhb-frontend/lithe-ui';
import { useRef, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react';
import AudioPlayer from '@bhb-frontend/audio-player';
import { getCoverInfo } from '@/utils/getCoverInfo';
import { useStores } from '@/store';
import {
  CreateVideoRequest,
  CreateVideoResult,
  // CreateVideoStatus,
  PaymentGuidelines,
  PayOrderResult,
  // TipDialogOptionMapKey,
} from '@/types/cteateVideo';
import {
  // commonGuide,
  // getNoConsumptionTips,
  setNoConsumptionTips,
  // storagePaidGuidance,
} from '@/utils/createVideo';
import { createDocVideo } from '@/api/app/video';
import HintModal from '../View/HintModal';
import { IModaleText, TRejectContent } from '@/types/videos';
import { getAuditErrText, getRejectContent } from '@/constants/Audio';
import { ErrorResponse } from '@/types/api';
import {
  ConsumeType,
  CreateVideoStatus,
  DOC_ERROR_CODE,
} from '@/constants/Createvideo';
import socket from '@/core/socket';
import { SocketResponse } from '@/types/socket';
import { SOCKET_RESPONSE } from '@/constants/Socket';
import PayContent from './PayContent';
import ExportPopover from './ExportPopover';
import ConfirmConsumption from '../ConfirmConsumption';
import DigitalMaturity from './DigitalMaturity';
import { goToWorkbench } from '@/utils/workbench';
import { ResErrType } from '@/constants/SaveStatus';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';

const ExportVideo = () => {
  const { app, user } = useStores();
  /** 任务ID */
  const taskId = useRef<string>('');

  /** 付费指引数据 */
  const paymentGuidelinesData = useRef<PaymentGuidelines>();

  /** 付费指引开关 */
  const [paymentGuidelinesVisible, setGuidelinesVisible] =
    useState<boolean>(false);

  /** 会员消耗确认框开关 */
  const [confirmConsumptionVisible, setConsumptionVisible] =
    useState<boolean>(false);

  /** 数字人到期开关 */
  const [digitalMaturityVisible, setDigitalMaturityVisible] = useState(false);

  /** 提示弹窗展示 */
  const [hintModalVisible, setHintModalVisible] = useState<boolean>(false);

  /** 提示弹窗文案 */
  const hintModaleText = useRef<Partial<IModaleText>>({});

  /** 提示框开关 */
  // const [tipVisiable, setTipsiable] = useState<boolean>(false);

  // const tipDialogInfo = useRef<Partial<PayDialogPorp>>({});

  const audioPlayer = useRef(AudioPlayer.getInstance());

  /** 订单ID */
  const onceOrderNo = useRef<string>('');

  useEffect(() => {
    if (!user.socketLink) return;
    socket.on((res: SocketResponse<CreateVideoResult | PayOrderResult>) => {
      switch (res.event) {
        case SOCKET_RESPONSE.VIDEO_STATUS_CHANGE: {
          const { renderTaskId, status } = res.data as CreateVideoResult;
          if (renderTaskId !== taskId.current) return;
          if (
            status === CreateVideoStatus.queueing ||
            status === CreateVideoStatus.pending
          ) {
            toVideoList();
          }
          break;
        }

        case SOCKET_RESPONSE.PAY_RESULT: {
          /** 判断订单是否是当前单次支付的订单 */
          const { orderNo } = res.data as PayOrderResult;
          if (onceOrderNo.current !== orderNo) return;
          /** 判断支付成功，重新调接口 */
          equityExportVideo(ConsumeType.once);
          break;
        }
        default:
      }
    });
  }, [user.socketLink]);

  // const contactBusiness = () => {
  //   window.open(appConfig.config.figure2dContactLink, '_blank');
  // };

  // const handleCancel = () => {
  //   setTipsiable(false);
  // };

  // 提示框的数集合
  // const tipDialogOptionMap = {
  //   figure2d_expired: {
  //     title: '无法导出',
  //     loading: false,
  //     content: '你的数字克隆人使用时间已到期，请联系商务续费',
  //     okText: '联系商务',
  //     onOk: contactBusiness,
  //     onCancel: handleCancel,
  //   },
  // };

  /** 点击导出 */
  const handleExport = async () => {
    if (app.globalLoading) return;
    // 文档区是否存在占位图
    const isPlaceholder = app.scenes.some(scene => scene.getIsPlaceholder());
    if (isPlaceholder) {
      Toast.warning('资源上传中，请稍后重试');
      return;
    }

    audioPlayer.current?.pause();

    app.setGlobalLoading(true);
    // todo 关闭音乐之类的操作
    const param = await getParams();
    createVideo(param);
  };

  /** 获取参数 */
  const getParams = async (): Promise<CreateVideoRequest> => {
    let imageKey;
    try {
      imageKey = (await getCoverInfo()).key;
    } catch (error) {
      console.log('获取主题预览作为视频封面错误：', error);
      /** 异常关闭loading */
      app.setGlobalLoading(false);
    }
    const { documentId, documentInfo, sceneThemeList } = app.getServeData();
    // console.log('serveData', serveData);
    const params: CreateVideoRequest = {
      documentId,
      isPreview: false, // 是否是预览 true-是 false-否
      duration: app.durationUS || 0, // 预估时长 单位秒
      documentInfo,
      sceneThemeList,
      // isNoConsumptionTips: getNoConsumptionTips(),
      // 不去要提示消费权益
      isNoConsumptionTips: true,
      imageKey,
    };
    return params;
  };

  /** 创建视频 */
  const createVideo = async (params: Partial<CreateVideoRequest>) => {
    try {
      const { data } = await createDocVideo(params);
      taskId.current = data.renderTaskId;
      paymentGuidelinesData.current = data;
      if (data.isPaidGuides) {
        setGuidelinesVisible(true);
        return;
      }
      // if (data.isConsumptionTips) {
      //   setConsumptionVisible(true);
      //   return;
      // }
      /** 审核不通过弹框提示 */
      if (data.rejectContent) {
        onError(data.rejectContent);
        return;
      }
      toVideoList();
    } catch (error) {
      const apiError = error as ErrorResponse;

      if (apiError?.body?.error) {
        const body = apiError?.body;
        // const { type = 'free', videoMergeNum = 0 } = user.videoClipRights || {};
        // console.log(user.videoClipRights);
        switch (body.error) {
          // 视频导出数量
          case DOC_ERROR_CODE.CREATE_VIDEO_NUM_LIMIT:
            // commonGuide(
            //   {
            //     fields: 'videoMergeNum',
            //     value: videoMergeNum,
            //     type,
            //   },
            //   {
            //     title: '可用生成视频次数已不足',
            //     content: '购买会员获取更多生成次数',
            //   },
            //   '生成视频次数已达上限'
            // );
            WeChatQRcodeModal.show();
            break;
          // 定制2D数字人使用权限检查
          case DOC_ERROR_CODE.FIGURE2D_PERMISSION_LIMIT:
            // commonGuide(
            //   {
            //     fields: 'madeFigure',
            //     value: 0,
            //     type,
            //   },
            //   {
            //     title: '视频无法合成',
            //     content:
            //       '当前会员无法使用定制数字人，购买会员即可使用定制数字人',
            //   },
            //   '包含定制数字人，请移除'
            // );
            WeChatQRcodeModal.show();
            break;
          // 检查定制数字人是否到期
          case DOC_ERROR_CODE.FIGURE2D_EXPIRED:
            setDigitalMaturityVisible(true);
            // openTipDialog('figure2d_expired');
            break;
          // 空间不足付费引导
          case DOC_ERROR_CODE.STORAGE_LIMIT_ERROR:
            // storagePaidGuidance();
            WeChatQRcodeModal.show();

            break;

          case DOC_ERROR_CODE.RENDER_LIMIT: {
            const msg = body?.message || '排队视频过多，请稍后重试';
            Toast.error(msg);
            break;
          }

          // 资源错误，
          case DOC_ERROR_CODE.RESOURCE_ERROR:
            resourceLoseExport();
            break;

          // 权限不足
          case DOC_ERROR_CODE.EQUITY_DEFICIENCY:
            WeChatQRcodeModal.show();
            break;
          default:
            console.log('其他类型错误');
            break;
        }
      }
    } finally {
      app.setGlobalLoading(false);
    }
  };

  const resourceLoseExport = () => {
    if (app.resourcesErrType === ResErrType.exportError) return;
    if (!app.resourcesErrType) {
      app.setResErrType(ResErrType.exportError);
      Sentry.captureException(`资源丢失,导出接口数据资源丢失,文档id${app.id}`);
    }
  };

  const toVideoList = () => {
    goToWorkbench('/videos');
  };

  /** 审核失败弹窗 */
  const onError = (rejectContent: TRejectContent) => {
    const text = getRejectContent(rejectContent);
    hintModaleText.current = getAuditErrText(text);
    setHintModalVisible(true);
  };

  /** 打开提示弹窗 */
  // const openTipDialog = (key: TipDialogOptionMapKey) => {
  //   setTipsiable(true);
  //   tipDialogInfo.current = tipDialogOptionMap[key];
  // };

  /** 操作关闭PayContent */
  const handleClosePayContent = () => {
    setGuidelinesVisible(false);
    setConsumptionVisible(false);
    setDigitalMaturityVisible(false);
  };

  const equityExportVideo = (type: ConsumeType) => {
    /**
     * 导出条件：
     * 1、类型为单次付费
     * 2、类型为使用会员，并且不提示消费提示框
     *   */
    const isCreateVideo =
      type === ConsumeType.once ||
      (type === ConsumeType.memberRights &&
        !paymentGuidelinesData.current?.isConsumptionTips);

    if (isCreateVideo) {
      createVideo({
        consumeType: type,
        documentId: app.id,
        renderTaskId: taskId.current,
      });
    }
    setConsumptionVisible(
      paymentGuidelinesData.current?.isConsumptionTips ?? false
    );
    setGuidelinesVisible(val => !val);
  };

  /** 修改订单号 */
  const handleOrder = id => {
    onceOrderNo.current = id;
  };

  const handleConfirm = (val: boolean) => {
    createVideo({
      consumeType: ConsumeType.memberRights,
      documentId: app.id,
      renderTaskId: taskId.current,
    });
    setConsumptionVisible(false);

    /** 用户是否勾选了不再提示 */
    if (val) {
      setNoConsumptionTips();
    }
  };

  const renderContent = () => {
    if (paymentGuidelinesVisible) {
      return (
        <PayContent
          paymentGuidelinesData={paymentGuidelinesData.current}
          onClose={handleClosePayContent}
          equityExportVideo={equityExportVideo}
          visiable={paymentGuidelinesVisible}
          onOrderChange={handleOrder}
        />
      );
    }

    if (confirmConsumptionVisible) {
      return (
        <ConfirmConsumption
          paidContent={
            paymentGuidelinesData.current
              ? paymentGuidelinesData.current.paidContent
              : {}
          }
          onConfirm={handleConfirm}
        />
      );
    }

    if (digitalMaturityVisible) {
      return <DigitalMaturity />;
    }

    return null;
  };

  return (
    <>
      <ExportPopover
        visiable={
          paymentGuidelinesVisible ||
          confirmConsumptionVisible ||
          digitalMaturityVisible
        }
        onClose={handleClosePayContent}
        content={renderContent()}
      >
        <Button className="opt-btn" type="primary" onClick={handleExport}>
          导出视频
        </Button>
      </ExportPopover>

      <HintModal
        visible={hintModalVisible}
        onOk={() => setHintModalVisible(false)}
        modaleText={hintModaleText.current}
      />

      {/* <TipDialog visible={tipVisiable} option={tipDialogInfo.current} /> */}
    </>
  );
};

export default observer(ExportVideo);
