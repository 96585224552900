/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/72caedc60ca91420d90529ade7323e60.png */
  'color_icon_sepan.png': '72caedc60ca91420d90529ade7323e60.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e0658072af845b8ed6b6917e5fc78fb0.png */
  'common_icon_add.png': 'e0658072af845b8ed6b6917e5fc78fb0.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ec8c182f3986c49f8d2d63b72f356242.png */
  'common_icon_close_white.png': 'ec8c182f3986c49f8d2d63b72f356242.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a2c631c15ffc3357d18c04988a84027c.png */
  'common_icon_close.png': 'a2c631c15ffc3357d18c04988a84027c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/79a6340e40c084570724277c02d9f2f7.png */
  'common_icon_golden.png': '79a6340e40c084570724277c02d9f2f7.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9fc4e3313c83fa7443a93b003ae497b9.png */
  'common_icon_loading.png': '9fc4e3313c83fa7443a93b003ae497b9.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2b5ef8d509bead9a5452d65ea6c08e17.png */
  'common_icon_reduce.png': '2b5ef8d509bead9a5452d65ea6c08e17.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/88249057ce76c53f5f9b1e07c222f5c5.png */
  'common_icon_vip1@2x.png': '88249057ce76c53f5f9b1e07c222f5c5.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/8993952837fb7d4002010aa44ab083e4.png */
  'cut-icon-left.png': '8993952837fb7d4002010aa44ab083e4.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c3a52e70bdebeacf60527d85aebcde00.png */
  'cut-icon-play.png': 'c3a52e70bdebeacf60527d85aebcde00.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/28dde04ee3d0006b3d6398ce4ffd8a34.png */
  'cut-icon-right.png': '28dde04ee3d0006b3d6398ce4ffd8a34.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/45a9f56412b0757e47ce47b8a1eda138.png */
  'cut-icon-stop.png': '45a9f56412b0757e47ce47b8a1eda138.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c59014e4cc147088e54dd7c24153b1ba.png */
  'doc_icon_add.png': 'c59014e4cc147088e54dd7c24153b1ba.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d99b0192b797ba11b550cb477d66b582.png */
  'doc_icon_delete.png': 'd99b0192b797ba11b550cb477d66b582.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/33d76ba1dc7c12e31a43f0d265257034.png */
  'doc_icon_empty.png': '33d76ba1dc7c12e31a43f0d265257034.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/704a75c5775a49e4ecbf7b8305347b70.png */
  'doc_icon_loading.png': '704a75c5775a49e4ecbf7b8305347b70.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/b61ad2dc9d8913f506b0e0a49ecb3336.png */
  'doc_icon_pic.png': 'b61ad2dc9d8913f506b0e0a49ecb3336.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ca2d95f608d6630bb30279fc20cfd924.png */
  'doc_icon_video.png': 'ca2d95f608d6630bb30279fc20cfd924.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/19cb4d21b89bb8fbb2cad24adc6c5004.png */
  'document_cover_blank.png': '19cb4d21b89bb8fbb2cad24adc6c5004.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ea04381600fce883a6c8ae53df6c4d30.png */
  'document_cover_default.png': 'ea04381600fce883a6c8ae53df6c4d30.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/34bcdefe20c06729cecb1d13fd8435a3.png */
  'document_icon_delete.png': '34bcdefe20c06729cecb1d13fd8435a3.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/6d845b5899563f4e8dde089273de32ad.png */
  'document_icon_link.png': '6d845b5899563f4e8dde089273de32ad.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/10036f0e908352990674a3f135a0be5c.png */
  'document_icon_more.png': '10036f0e908352990674a3f135a0be5c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/f8ae57189884b25e255ae814faf72386.png */
  'document_icon_play.png': 'f8ae57189884b25e255ae814faf72386.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/cbb4d374ada5e0ca0bdffbf5b29440a6.png */
  'document_icon_search.png': 'cbb4d374ada5e0ca0bdffbf5b29440a6.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/3a5c39b257e3cfdc6ad59fa5f94fe074.png */
  'document_icon_share.png': '3a5c39b257e3cfdc6ad59fa5f94fe074.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e33cf2c383ba35cb5c2886c0b14b84e0.png */
  'document_icon_tips.png': 'e33cf2c383ba35cb5c2886c0b14b84e0.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/342fb3ef27379fcc8d74d0891d6c4fe3.png */
  'edit_icon_play.png': '342fb3ef27379fcc8d74d0891d6c4fe3.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/82c64e28dd8766dee353ed3c6f471fe0.png */
  'empty.png': '82c64e28dd8766dee353ed3c6f471fe0.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/bc4f32149b1f64677599ffadaf556b0c.webp */
  'figureSpt.webp': 'bc4f32149b1f64677599ffadaf556b0c.webp',

  /* https://bhb-frontend.bhbcdn.com/static/files/ca9e40b06466844448b8eaa8f9858359.png */
  'font-logo.png': 'ca9e40b06466844448b8eaa8f9858359.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/fe4ccad1f2d940c9b65c3ef675971916.png */
  'img-doc-adlum-empty.png': 'fe4ccad1f2d940c9b65c3ef675971916.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/024aac3889a8a1a543b36756e9c9767c.png */
  'make_icon_color.png': '024aac3889a8a1a543b36756e9c9767c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/3f846d997a47888fb908a479d9bfbe1d.png */
  'make_icon_confirm.png': '3f846d997a47888fb908a479d9bfbe1d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d1c74c6aae8bdef37ffbfc43fdff8543.png */
  'make_icon_fill.png': 'd1c74c6aae8bdef37ffbfc43fdff8543.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e25d6f222544bd06e7daa2b46426a0d9.png */
  'make_icon_next.png': 'e25d6f222544bd06e7daa2b46426a0d9.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ae4d835ca9beab3f3ee0022fa88b67ca.png */
  'music_icon_play.png': 'ae4d835ca9beab3f3ee0022fa88b67ca.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/af8ba11a29769a236417fd836966a3c5.png */
  'music_icon_stop.png': 'af8ba11a29769a236417fd836966a3c5.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5cffcafa1a64bb40a4b5878a878706a7.png */
  'pic_icon_close.png': '5cffcafa1a64bb40a4b5878a878706a7.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/16a99cfa354279bde033d986d8e7e302.png */
  'pup_icon_link.png': '16a99cfa354279bde033d986d8e7e302.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/64fa33b5dcb2cc80c7f25ac26ba719db.png */
  'pup_icon_lock.png': '64fa33b5dcb2cc80c7f25ac26ba719db.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9b1be912c244fb3a75367c326ae8c501.png */
  'scence_icon_add.png': '9b1be912c244fb3a75367c326ae8c501.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/4a0c162686d9a7a069a926113213785d.png */
  'side-toggle.png': '4a0c162686d9a7a069a926113213785d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/422dac41be3639caedba63d80985664b.png */
  'theme_icon_animation.png': '422dac41be3639caedba63d80985664b.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/bf0a6bb8644eb7cad1d5e98d56d09408.png */
  'theme_icon_choose.png': 'bf0a6bb8644eb7cad1d5e98d56d09408.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9845fc2b4c292f706d6e0e6317f55be7.png */
  'theme_icon_more.png': '9845fc2b4c292f706d6e0e6317f55be7.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e9e167e434551ff25931c043ba0224fc.png */
  'tuwen_icon_pic_add.png': 'e9e167e434551ff25931c043ba0224fc.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/eaccc333fa2eb189405cffb724ae43b4.png */
  'tuwen_icon_volume.png': 'eaccc333fa2eb189405cffb724ae43b4.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/6fd8a0ceabde835be1a974fa39a3ff6f.png */
  'tuwen_template_icon_loading.png': '6fd8a0ceabde835be1a974fa39a3ff6f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c5155cdb68434658525e730b116b55f3.png */
  'video_icon_volume_def.png': 'c5155cdb68434658525e730b116b55f3.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/7d07226288d264ee594e2bb16e508708.png */
  'video_icon_volume_mute.png': '7d07226288d264ee594e2bb16e508708.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/aa0290ca78525524d68174da3a5cfc44.png */
  'voice_icon_import.png': 'aa0290ca78525524d68174da3a5cfc44.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5026c98556b36b3d1d8e19f2b300fa55.svg */
  'audio-container-content.svg': '5026c98556b36b3d1d8e19f2b300fa55.svg',

  /* https://bhb-frontend.bhbcdn.com/static/files/9a5d8fb4b9062e39578d66fb8827f574.png */
  'pic_doc.png': '9a5d8fb4b9062e39578d66fb8827f574.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/0f98a2dc4cc19956f34566f3bde1a6fe.png */
  'pic_search.png': '0f98a2dc4cc19956f34566f3bde1a6fe.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/951caec01a8e2f0d43768dbbe81b850e.png */
  'pic_loading.png': '951caec01a8e2f0d43768dbbe81b850e.png',
};
