import { makeAutoObservable, computed } from 'mobx';
import { getAppConfig } from '@/api/configuration';

import { ConfigApp } from '@/types/config';

export default class AppConfigStore {
  public config: Partial<ConfigApp> = {};

  constructor() {
    makeAutoObservable(this);
    this.getAppConfig();
  }

  /** 设置用户信息 */
  public setAppConfig(config: ConfigApp) {
    this.config = config;
  }

  public async getAppConfig() {
    try {
      const { data } = await getAppConfig();
      this.setAppConfig(data);
    } catch (error) {
      console.log(error);
    }
  }

  // 竖屏默认图层数据
  @computed get IpImageFormats() {
    return this.config.videoConfig?.formats.IpImageFormats;
  }

  // 横屏默认图层数据
  @computed get IpImageFormatsLandscapeScreen() {
    return this.config.videoConfig?.formats.IpImageFormatsLandscapeScreen;
  }

  /** 默认竖屏插图、标题、字幕图层数据 */
  @computed get defaultFormats() {
    return this.config.videoConfig?.formats.defaultFormats;
  }

  /** 默认横屏插图、标题、字幕图层数据 */
  @computed get defaultFormatsLandscapeScreen() {
    return this.config.videoConfig?.formats.defaultFormatsLandscapeScreen;
  }
}
