import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Movable } from '@bhb-frontend/lithe-ui';
import { useStores } from '@/store';
import Scene from '@/components/Scene/Scene';
import { NodeNameplate } from '@/constants/NodeNamePlate';
import { CmpTypeEnum } from '@/constants/CmpType';

import style from './Canvas.module.less';
import { CANVAS_WRAP_REF, CANVAS_REF } from '@/constants/Refs';
import { X_SPACING, Y_SPACING } from '@/constants/Canvas';
import EditWrapper from '../EditWrapper';

export interface CanvasProps {
  model: Partial<ComponentModel.Base>;
  start?: Point;
}

function Canvas() {
  const { app, OS } = useStores();
  const { activedScene, activedCmps } = app;
  const { zoomLevel } = OS;
  const { width: templateWidth = 0, height: templateHeight = 0 } =
    activedScene.configure;

  const startPointRef = useRef<Point | null>();

  const [selectRect, setSelectRect] = useState<Rect | null>(null);

  /**
   * 自适应大小
   * */
  const adaptSize = (wrapWidth: number, wrapHeight: number) => {
    const { width: templateWidth = 0, height: templateHeight = 0 } =
      app.activedScene.configure;

    if (!templateWidth || !templateHeight) return;
    wrapWidth *= 1 - Y_SPACING * 2;
    wrapHeight *= 1 - X_SPACING * 2;
    const rateW = wrapWidth / templateWidth;
    const rateH = wrapHeight / templateHeight;

    if (rateH < rateW) {
      OS.setZoomLevel(rateH);
      return;
    }
    OS.setZoomLevel(rateW);
  };

  /**
   * 监听canvas wrap 的大小变化
   * */
  const listeningCanvasSize = (): ResizeObserver | null => {
    const node = CANVAS_WRAP_REF.current;
    if (!node) return null;
    const resizeObserver = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        const contentRect = entries[0].contentRect;
        adaptSize(contentRect.width, contentRect.height);
      });
    });
    resizeObserver.observe(node);

    return resizeObserver;
  };

  /**
   * 结束监听canvas wrap 的大小变化
   * */
  const unListeningCanvasSize = (resizeObserver: ResizeObserver) => {
    const node = CANVAS_WRAP_REF.current;
    if (!node) return;
    resizeObserver?.unobserve(node);
  };

  useEffect(() => {
    const resizeObserver = listeningCanvasSize();
    return () => {
      if (resizeObserver) unListeningCanvasSize(resizeObserver);
    };
  }, [templateWidth, templateHeight]);

  /**
   * 画布上拖动选中
   */
  const hendleMove = (_x: number, _y: number) => {
    // const canvas = CANVAS_WRAP_REF.current;
    // const startPoint = startPointRef.current;
    // if (!canvas || !startPoint) return;
    // const rect: Rect = getSelectionRectOfMove(x, y, startPoint, canvas);
    // setSelectRect(rect);
    // const selectedCmps = getCmpsOfSelection(rect, canvas);
    // if (selectedCmps.length > 0) {
    // const cmps = activedScene.getCmpsById(selectedCmps);
    // app.activeCmp(cmps);
    // }
  };

  /**
   * 移动开始
   */
  const handleMoveStart = (x: number, y: number) => {
    startPointRef.current = { x, y };
  };

  /**
   * 移动结束
   *  */
  const handleMoveEnd = () => {
    startPointRef.current = null;
    setSelectRect(null);
  };

  /**
   * 拖动时处理，考虑为拖动添加组件预留
   * @param e 拖动事件回调
   */
  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
  };

  /**
   * 拖动到某组件上方阻止冒泡
   * @param e 拖动事件回调
   */
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  /**
   * 点击画布空白处释放选中组件
   */
  const handleCanvasMouseDown = (e: React.MouseEvent) => {
    if (e.button !== 0 || !activedCmps.length) return;
    app.releaseAllCmps();
    app.unCheckStage();
  };

  const handleClick = () => {
    /** 取消选中音乐组件 */
    app.setActiveMediaCmp(CmpTypeEnum.UNKNOWN);
  };

  const canvasStyle = {
    width: templateWidth * zoomLevel,
    height: templateHeight * zoomLevel,
  };

  const sceneStyle = {
    width: templateWidth,
    height: templateHeight,
    transform: `scale(${zoomLevel})`,
  };

  return (
    <Movable
      onMove={hendleMove}
      onMoveStart={handleMoveStart}
      onMoveEnd={handleMoveEnd}
    >
      <section
        data-nameplate={NodeNameplate.CANVAS_WRAP}
        ref={CANVAS_WRAP_REF}
        className={style['canvas-wrap']}
        onDrag={handleDrag}
        onDragOver={handleDragOver}
        onMouseDown={handleCanvasMouseDown}
        onClick={handleClick}
      >
        <div style={canvasStyle} className={style.canvas}>
          <div
            className={style.canvas}
            ref={CANVAS_REF}
            data-type={app.type}
            data-id={app.id}
            data-nameplate={NodeNameplate.CANVAS}
          >
            <Scene
              style={sceneStyle}
              struc={activedScene}
              zoomLevel={zoomLevel}
              editable
            />
          </div>

          <EditWrapper
            zoomLevel={zoomLevel}
            className={style['edit-wrapper']}
            selectRect={selectRect}
          />
        </div>
      </section>
    </Movable>
  );
}

export default observer(Canvas);
