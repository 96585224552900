import { Dialog, Input } from '@bhb-frontend/lithe-ui';
import style from './RenameBigHeadModal.module.less';

interface RenameBigHeadModalProps {
  visible: boolean;
  name: string;
  onClose: () => void;
  onOk: () => void;
  onChange: (val: string) => void;
}

export default function RenameBigHeadModal(props: RenameBigHeadModalProps) {
  const { visible, name, onClose, onOk, onChange } = props;
  return (
    <Dialog
      timeout={0}
      visible={visible}
      isShowTitle={false}
      height={200}
      onCancel={onClose}
      onOk={onOk}
    >
      <h1 className={style.title}>输入数字头像名称</h1>
      <Input
        onChange={(e, value) => {
          onChange(value);
        }}
        value={name}
        size="large"
      />
    </Dialog>
  );
}
