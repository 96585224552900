import { useEffect, useState, useRef } from 'react';
import { LoadMore, Spin, Toast } from '@bhb-frontend/lithe-ui';
import cx from 'classnames';
import ImageWrapper from '@/components/ImageWrapper';
import { deleteUserBigHead } from '@/api/doc';
import { ListQuery, CustomListResponse } from '@/types/api';
import ResourceItemMenu from '../ResourceItemMenu';
import ConfirmModal from '@/components/Confirm';
import { Item, TabType } from '../../../config';
import style from './ResourceContent.module.less';

interface ResourceContentProps {
  /** 展示封面的key */
  coverUrlKey: string;
  /** 请求接口 */
  apiFn: (params: ListQuery) => Promise<CustomListResponse<Item>>;
  /** 是否默认选中第一个 */
  defaultFirst: boolean;
  /** 选择 */
  onSelect: (item: Item | null, tabName: TabType) => void;
  /** 当前选中的数据 */
  selectedData: Item | null;
  /** 当前tab */
  tabName: TabType;
  /** 加载状态 */
  loading: boolean;
  /** 去创建数字人 */
  toCreateDigitalMan: () => void;
}

export default function ResourceContent(props: ResourceContentProps) {
  const {
    coverUrlKey,
    apiFn,
    defaultFirst,
    onSelect,
    selectedData,
    tabName,
    loading,
    toCreateDigitalMan,
  } = props;

  /** 资源列表 */
  const [list, setList] = useState<Item[]>([]);

  /** 是否在请求列表中 */
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  /** 删除用户头像确认框 */
  const [confirmVisible, setConfirmVisible] = useState(false);
  /** 是否可以加载更多 */
  const [hasMore, setHasMore] = useState<boolean>(false);
  /** 请求参数 */
  const params = useRef<ListQuery>({ sid: '', pageSize: 10 });
  /** 删除id */
  const removeIdRef = useRef<string>('');
  /** 当前hoverId */
  const [hoverId, setHoverId] = useState('');

  const select = (item: Item | null) => {
    if (selectedData && selectedData?.id === item?.id) return;
    onSelect?.(item, tabName);
  };

  /** 获取列表 */
  const getList = async () => {
    setIsRequesting(true);
    try {
      const { data } = await apiFn(params.current);
      params.current.sid = data.sid;
      setHasMore(!!data.sid);
      return data;
    } finally {
      setIsRequesting(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    const { results } = await getList();
    setList(results);
    if (defaultFirst) {
      select(results[0] || null);
    }
  };

  /** 加载更多 */
  const loadMore = async () => {
    const { results } = await getList();
    setList([...list, ...results]);
  };

  useEffect(() => {
    initList();
  }, []);

  const selectFirstItem = () => {
    if (tabName === TabType.head) {
      if (list.length >= 5) {
        Toast.warning('头像数量已达上限（5个），请先删除后重试');
        return;
      }
      toCreateDigitalMan();
      return;
    }
    select(null);
  };

  const renderFirstItem = () => {
    if (tabName !== TabType.head) {
      return (
        <i
          className={cx(
            'iconfont',
            'icon-a-datou_icon_wu2x',
            style['item-icon']
          )}
        />
      );
    }

    return (
      <>
        <i
          className={cx(
            'iconfont',
            'icon-a-number_icon_add2x',
            style['item-icon']
          )}
        />
        <span className={style['recording-item-tip']}>添加</span>
      </>
    );
  };

  const isLoading = (id: string) =>
    loading && selectedData?.id === id && tabName !== TabType.bodyStyle;

  const onRemove = (id: string) => {
    removeIdRef.current = id;
    setConfirmVisible(true);
  };

  const handleConfirmRemove = () => {
    deleteUserBigHead(removeIdRef.current).then(() => {
      setConfirmVisible(false);
      Toast.success('删除头像成功');
      initList();
    });
  };

  return (
    <>
      <LoadMore
        className={style['load-more-component']}
        loading={isRequesting}
        hasMore={hasMore}
        loadingText={null}
        finishedText={null}
        onReachBottom={loadMore}
      >
        <div className={style['resource-content']}>
          <div
            onClick={selectFirstItem}
            className={cx(style['resousce-item'], style['first-item'], {
              [style.selected]: !selectedData && tabName !== TabType.head,
            })}
          >
            {renderFirstItem()}
          </div>

          {list.map(item => (
            <Spin
              key={item.id}
              className={style['spin-component']}
              spinning={isLoading(item.id)}
            >
              <div
                onClick={() => {
                  select(item);
                }}
                onMouseEnter={() => {
                  setHoverId(item.id);
                }}
                onMouseLeave={() => {
                  setHoverId('');
                }}
                className={cx(style['resousce-item'], {
                  [style.selected]: item.id === selectedData?.id,
                })}
              >
                <ImageWrapper
                  className={style['image-component']}
                  maskClassName={style['image-component-mask']}
                  urls={[item[coverUrlKey]]}
                />

                {list.length > 1 &&
                  tabName === TabType.head &&
                  hoverId === item.id && (
                    <ResourceItemMenu
                      onRemove={() => {
                        onRemove(item.id);
                      }}
                      className={style['resource-item-menu']}
                    />
                  )}
              </div>
            </Spin>
          ))}
        </div>
      </LoadMore>

      {tabName === TabType.head && (
        <ConfirmModal
          visible={confirmVisible}
          content="确认删除数字头像吗？"
          onOk={handleConfirmRemove}
          onCancel={() => {
            setConfirmVisible(false);
          }}
        />
      )}
    </>
  );
}
