/** 改变层级 */
export enum ChangeLayerType {
  /** 上一级 */
  UP = 'up',
  /** 下一级 */
  DOWN = 'down',
  /** 置顶 */
  TOP = 'top',
  /** 置底 */
  BOTTOM = 'bottom',
}

export enum ScaleMode {
  CONTAIN = 1,
  COVER = 2,
}
export enum ScaleModeZh {
  CONTAIN = '平铺',
  COVER = '铺满',
}
