import { mimeTypeMap } from '@/constants/formatData';
import {
  Resources,
  ThemeDetailItem,
  Image,
  Color,
  Video,
} from '@/types/ServerData';
import {
  getFootageMap,
  transformUsageToTag,
  transformVectorToObj,
} from '@/utils/formatData';

const origin = transformVectorToObj();

/** 生成背景图层 */
export function createBackCmp(
  themeInfo: ThemeDetailItem,
  resources: Record<string, Resources>
) {
  const { backgroundId, configure, footage } = themeInfo;
  // console.log(themeInfo);
  const { background, width, height } = configure;
  const { mimeType, sourceId } = background;
  const type: CmpType = 'Background';
  const fillType = mimeTypeMap[mimeType];

  const resourceObj = {};

  // 图片背景处理
  if (fillType === 'Image') {
    const resource = resources[sourceId] as Image;
    const {
      fileName,
      imageKey,
      imageUrl,
      extra,
      objectId,
      ...imgExtensionFields
    } = resource;
    Object.assign(resourceObj, {
      fileName,
      key: imageKey,
      url: imageUrl,
      sourceId: objectId,
      extra,
      imgExtensionFields,
    });
  }
  if (fillType === 'Color') {
    // 纯色背景处理
    const resource = getFootageMap(footage, ['colors'])[sourceId] as Color;
    Object.assign(resourceObj, {
      color: resource.color,
      sourceId: resource.objectId,
    });
  }

  if (fillType === 'Video') {
    const resource = resources[sourceId] as Video;
    const { fileName, fileKey, fileUrl, extra, objectId } = resource;
    Object.assign(resourceObj, {
      fileName,
      key: fileKey,
      url: fileUrl,
      sourceId: objectId,
      extra: {
        cover: extra?.web?.backgroundCoverUrl,
      },
    });
  }

  const struceData = {
    usage: 1,
    id: backgroundId,
    name: 'background',
    tag: transformUsageToTag(1),
    type,
    fillType,
    width,
    height,
    sourceId: background.sourceId,

    position: origin,
    anchor: origin,
    rotation: origin,
    // // 反转就是选择的Z轴
    flip: transformVectorToObj([1, 1, 1]),
    scalar: origin,
    style: {
      width,
      height,
    },
    actived: false,
    visible: true,
    isLock: true,
    loading: false,
    disabled: false,
    isLockScale: true,
    isLockFlip: true,
    isLockRotation: true,
    ...resourceObj,
  };
  return struceData;
}
