import { HorizontalAlign, VerticalAlign } from '@/constants/formatData';
import { TAGS_TYPE } from '@/constants/Tags';
import {
  Layer,
  Resources,
  ThemeDetailItem,
  Image,
  TextDetail,
} from '@/types/ServerData';
import { transformPatternsToTextFill } from '@/utils/formatData';

interface CreateTextParam {
  layer: Layer;
  tag: TAGS_TYPE;
  themeInfo: ThemeDetailItem;
  resources: Record<string, Resources>;
  textImageResources: Record<string, Image>;
  /** 文字贴图背景id */
  textBackId?: string;
}

/** 生成文字图层额外字段 */
export function createTextResource(param: CreateTextParam) {
  const { layer, tag, themeInfo, resources, textImageResources, textBackId } =
    param;
  const { textAnimation, sourceId = '' } = layer;
  const resource = resources[sourceId] as TextDetail;
  if (!resource) {
    // 资源错误将直接返回
    return {
      errorLayer: true,
    };
  }
  const { styleId } = resource || {};
  const { optionalFields } = themeInfo;
  const curField = optionalFields[styleId];
  // 这里 footage.texts优先级要高
  const mergeData = {
    ...curField,
    ...resource,
  };

  const {
    features,
    maxLines,
    leading,
    fontSize,
    kerning,
    hAlign,
    vAlign,
    content,
    fontDesc,
    charAttrs = [],
    contents = [],
    patterns,
    padding,
    ...textExtensionFields
  } = mergeData;
  const textConfig = {
    // 初始共有字段
    features,
    styleId,
    maxLines,
    lineHeight: Number(leading) / Number(fontSize),
    fontSize,
    kerning,
    isEdit: false,
    content,
    contents,
    textAnimation: layer.textAnimation,
    fontDesc,
    charAttrs,
    textExtensionFields,
    fill: {},
  };

  const textStyle = {
    lineHeight: Number(leading) / Number(fontSize),
    verticalAlign: VerticalAlign[vAlign],
    textAlign: HorizontalAlign[hAlign] as keyof typeof HorizontalAlign,
    fontSize,
    letterSpacing: kerning,
    paddingTop: padding.top,
    paddingRight: padding.right,
    paddingBottom: padding.bottom,
    paddingLeft: padding.left,
  };
  /** 文字填充 */
  if (patterns.length > 0) {
    Object.assign(textConfig, {
      fill: transformPatternsToTextFill(patterns, textBackId),
    });
  }
  // 文字动画
  if (textAnimation) {
    const {
      footage: { effects },
    } = themeInfo;
    const effect = (effects as ComponentModel.SpecialeEffect[]).find(
      effect => effect.objectId === textAnimation.sourceId
    );
    Object.assign(textConfig, {
      specialeEffect: effect,
    });
  }

  if (tag === 'Captions') {
    // todo 正文需要服务端对正文做拆分
    Object.assign(textConfig, {
      content: '',
    });
  }

  // if (TEXT_TAG.includes(tag)) {
  // 对所有文字做特殊处理， 标题 正文暂时只有客户端可以设置背景图片
  const stickerId = (textConfig?.fill as any)?.imageId || '';
  // 修复文字贴纸无贴纸报错的场景
  if (textImageResources[stickerId]) {
    const { fileName, imageKey, imageUrl, extra, ...imgExtensionFields } =
      textImageResources[stickerId];
    Object.assign(textConfig, {
      fileName,
      key: imageKey,
      url: imageUrl,
      extra,
      imgExtensionFields,
    });
  }
  // }
  return {
    textConfig,
    textStyle,
  };
}
