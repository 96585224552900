import AppStruc from '@/models/AppStruc';
import { getImageInfo } from '@/utils/image';
import { transformScene } from '../Cmps';

export function transformData(appData: AppStruc) {
  const {
    id,
    title,
    name,
    music,
    updateTime,
    labelId,
    labelName,
    durationUS,
    titleContent,
    extensionFields,
    scenes,
  } = appData;

  const scenesData = scenes.map(scenes => transformScene(scenes));
  const material = scenesData[0]?.materials[0] || {};

  const { imageKey } = getImageInfo(material?.cover || material?.url);
  const data = {
    ...extensionFields,
    durationUS,
    labelName,
    labelId,
    updatedTime: updateTime,
    musicSource: music?.source,
    musicVolume: music?.volume,
    musicId: music?.id,
    musicKey: music?.key,
    title,
    production: name,
    imageKey,
    id,
    // 特殊处理 全部写死多场景
    sceneMode: 'multiple',
    jsonText: {
      title: titleContent,
      scenes: scenesData,
    },
  };
  return data;
}
