import { WORKBENCH_URL } from '@/config/hosts';

export function goToWorkbench(url: string, target = '_self') {
  const inviteId = sessionStorage.getItem('inviteId');

  const workbenchUrl = `${WORKBENCH_URL}${url}`;
  const urlObj = new URL(workbenchUrl);

  if (inviteId) {
    urlObj.searchParams.set('inviteId', inviteId);
  }

  window.open(urlObj.toString(), target);
}

/** 延迟自动跳转回工作台 */
export function delayGoToWorkbench(url: string, delay = 0, target = '_self') {
  setTimeout(() => {
    goToWorkbench(url, target);
  }, delay);
}
