import { POINT_TYPE } from '@bhb-frontend/toolbox';
import { TAGS_MAP } from '@/constants/Tags';

export const ALL_POINTS = [
  POINT_TYPE.LEFT_BOTTOM,
  POINT_TYPE.LEFT_CENTER,
  POINT_TYPE.LEFT_TOP,
  POINT_TYPE.RIGHT_BOTTOM,
  POINT_TYPE.RIGHT_CENTER,
  POINT_TYPE.RIGHT_TOP,
  POINT_TYPE.TOP_CENTER,
  POINT_TYPE.BOTTOM_CENTER,
];

export const TEXT_POINTS = [
  POINT_TYPE.LEFT_BOTTOM,
  POINT_TYPE.LEFT_CENTER,
  POINT_TYPE.LEFT_TOP,
  POINT_TYPE.RIGHT_BOTTOM,
  POINT_TYPE.RIGHT_CENTER,
  POINT_TYPE.RIGHT_TOP,
];

export const TEXT_LIST: string[] = [
  TAGS_MAP.TextSticker,
  TAGS_MAP.Title,
  TAGS_MAP.DateTextSticker,
  TAGS_MAP.MapTextSticker,
];

export const PREVIEW_SIZE_OFFSET_X = 10;
export const PREVIEW_SIZE_OFFSET_Y = 20;
