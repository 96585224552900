import { MagneticLine, AxleDirection } from '@/types/OSModel';
import CmpStruc from '@/models/CmpStruc';
import { getRectRotatedRange } from '@/utils/math';
import { RectData } from '@/types/canvas';
import {
  LineData,
  uniqAlignLines,
  getRectMagneticLines,
} from '@/utils/magneticLine';
import { DISTANCE } from '@/constants/MagneticLine';

export default class MagneticLineHandler {
  /** 当前移动矩形信息 */
  private rectData: RectData;

  private targetCmp: CmpStruc;

  /** 画布视口的信息 */
  private canvasData: Size;

  private cmps: CmpStruc[];

  /** 横轴 */
  private horizontalLines: LineData[] = [];

  /** 纵轴 */
  private verticalLines: LineData[] = [];

  /** 缩放率 */
  private zoomLevel: number;

  constructor(targetCmp: CmpStruc, cmps, canvasData: Size, zoomLevel: number) {
    this.targetCmp = targetCmp;
    this.zoomLevel = zoomLevel;
    this.rectData = this.getRectData(targetCmp);
    this.canvasData = canvasData;
    this.cmps = cmps;
    this.initLine();
  }

  private getRectData = ({ style, rotation }: CmpStruc) => {
    const { top = 0, left = 0, width = 0, height = 0 } = style;
    const { z: rotate } = rotation;
    return {
      x: +left * this.zoomLevel,
      y: +top * this.zoomLevel,
      width: +width * this.zoomLevel,
      height: +height * this.zoomLevel,
      rotate,
    };
  };

  private initLine() {
    this.cmps.forEach(cmp => {
      if (cmp.id !== this.targetCmp.id) {
        const { xRange, yRange } = getRectRotatedRange(this.getRectData(cmp));
        const leftTop = {
          x: xRange[0],
          y: yRange[0],
        };

        const rightBottom = {
          x: xRange[1],
          y: yRange[1],
        };

        const width = rightBottom.x - leftTop.x;
        const height = rightBottom.y - leftTop.y;

        const { horizontal, vertical } = getRectMagneticLines(
          leftTop,
          rightBottom,
          { width, height }
        );

        this.horizontalLines.push(...horizontal);
        this.verticalLines.push(...vertical);
      }
    });

    // 画布可视区域的四个边界、水平中心、垂直中心
    const canvasWidth = this.canvasData.width * this.zoomLevel;
    const canvasHeight = this.canvasData.height * this.zoomLevel;

    const { horizontal, vertical } = getRectMagneticLines(
      { x: 0, y: 0 },
      { x: canvasWidth, y: canvasHeight },
      { width: canvasWidth, height: canvasHeight }
    );

    this.horizontalLines.push(...horizontal);
    this.verticalLines.push(...vertical);

    // 去重
    this.horizontalLines = uniqAlignLines(this.horizontalLines);
    this.verticalLines = uniqAlignLines(this.verticalLines);
  }

  public calcAlignmentLine(moveDistance: Point): {
    magneticLines: MagneticLine[];
    x: number;
    y: number;
  } {
    const magneticLines: MagneticLine[] = [];
    const { width, height, rotate, x, y } = this.rectData;

    const { xRange, yRange } = getRectRotatedRange({
      x: x + moveDistance.x,
      y: y + moveDistance.y,
      width,
      height,
      rotate,
    });

    let targetTop = y + moveDistance.y;
    let targetLeft = x + moveDistance.x;

    const targetMinRange = {
      x: xRange[0],
      y: yRange[0],
    };

    const targetMaxRange = {
      x: xRange[1],
      y: yRange[1],
    };

    const w = targetMaxRange.x - targetMinRange.x;
    const h = targetMaxRange.y - targetMinRange.y;

    const targetCenter = {
      x: targetMinRange.x + w / 2,
      y: targetMinRange.y + h / 2,
    };

    this.horizontalLines.some(({ value, range }) => {
      const min = Math.min(...range, targetMinRange.x, targetMaxRange.x);
      const max = Math.max(...range, targetMinRange.x, targetMaxRange.x);
      if (Math.abs(targetMinRange.y - value) < DISTANCE) {
        targetTop -= targetMinRange.y - value;
        magneticLines.push({
          direction: AxleDirection.x,
          axis: { x: min, y: value },
          length: max - min,
        });
        return true;
      }

      if (Math.abs(targetMaxRange.y - value) < DISTANCE) {
        targetTop -= targetMaxRange.y - value;
        magneticLines.push({
          direction: AxleDirection.x,
          axis: { x: min, y: value },
          length: max - min,
        });
        return true;
      }

      if (Math.abs(targetCenter.y - value) < DISTANCE) {
        targetTop -= targetCenter.y - value;
        magneticLines.push({
          direction: AxleDirection.x,
          axis: { x: min, y: value },
          length: max - min,
        });
        return true;
      }
      return false;
    });

    this.verticalLines.some(({ value, range }) => {
      const min = Math.min(...range, targetMinRange.y, targetMaxRange.y);
      const max = Math.max(...range, targetMinRange.y, targetMaxRange.y);

      if (Math.abs(targetMinRange.x - value) < DISTANCE) {
        targetLeft -= targetMinRange.x - value;
        magneticLines.push({
          direction: AxleDirection.y,
          axis: { x: value, y: min },
          length: max - min,
        });
        return true;
      }
      if (Math.abs(targetMaxRange.x - value) < DISTANCE) {
        targetLeft -= targetMaxRange.x - value;
        magneticLines.push({
          direction: AxleDirection.y,
          axis: { x: value, y: min },
          length: max - min,
        });
        return true;
      }
      if (Math.abs(targetCenter.x - value) < DISTANCE) {
        targetLeft -= targetCenter.x - value;
        magneticLines.push({
          direction: AxleDirection.y,
          axis: { x: value, y: min },
          length: max - min,
        });
        return true;
      }
      return false;
    });

    return {
      magneticLines,
      x: targetLeft,
      y: targetTop,
    };
  }
}
