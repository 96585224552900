import { forwardRef, useState, Ref, ReactElement } from 'react';
import classnames from 'classnames';
import Style from './index.module.less';

interface ImageWrapperProps {
  /** 需要展示的url，可以放置不同格式的图片，用于兼容浏览器，优先级按照数组的顺序 */
  urls: string[];
  className?: string;
  /** 图片的填充方式 */
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  /** 会员标识图标 */
  memberIocn?: string;
  /** 点击事件 */
  onClick?: () => void;
  /** 蒙层拓展类 */
  maskClassName?: string;
}

function ImageWrapper(props: ImageWrapperProps, ref: Ref<HTMLDivElement>) {
  const {
    urls,
    className,
    objectFit = 'contain',
    onClick,
    maskClassName,
  } = props;

  const [imageVisible, setImageVisible] = useState<boolean>(false);

  const picture = (): ReactElement => (
    <picture
      onLoad={() => {
        setImageVisible(true);
      }}
      className={Style.imageContainer}
    >
      {urls.map((src: string) => (
        <source srcSet={src} key={src} />
      ))}

      <img src={urls[urls.length - 1]} style={{ objectFit }} alt="" />
    </picture>
  );

  return (
    <div
      className={classnames(className, Style.imageWrapper)}
      ref={ref}
      onClick={onClick}
    >
      {!!urls.length && picture()}
      <div
        className={classnames(maskClassName, Style.imageMask, {
          [Style.imageMaskHidden]: imageVisible,
        })}
      />
    </div>
  );
}

export default forwardRef(ImageWrapper);
