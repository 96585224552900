export enum TaskType {
  textMapMaterial = 'textMapMaterial',
  keywordPick = 'keywordPick',
  slideFigure = 'slideFigure',
  faceStyle = 'faceStyle',
}

/** 创建特效任务参数 */
export interface CreateEffectTaskParams {
  type: string;
  text?: string;
  /** 风格特效id type为faceAnime和pandaFace需要传 */
  effectMaterialId?: string;
  /** 文件url */
  fileUrl?: string;
  customExtra?: {
    /** 下巴点X坐标 type为faceAnime和pandaFace需要传 */
    chinPointX: number;
    /** 下巴点Y坐标 type为faceAnime和pandaFace需要传 */
    chinPointY: number;
  };
}

/** 大头数字人 - 用户头像 */
export interface UserBigHeadItem {
  id: string;
  previewImageKey: string;
  previewImageUrl: string;
  /** 下颚在图片中的 X坐标 */
  chinPointX: number;
  /** 下颚在图片中的 Y标 */
  chinPointY: number;
  width: number;
  height: number;
}

/** 大头数字人 - 身体风格  */
export interface BodyStyleItem {
  id: string; // id
  name: string; // 身体名字
  coverKey: string; // 封面图片key
  preImageKey: string; // 预览图片key
  coverUrl: string; // 封面图片
  preImageUrl: string; // 预览图片
}

/** 大头数字人 - 头像风格  */
export interface HeadStyleItem {
  id: string; // id
  name: string; // 名称
  thumbnailUrl: string; // 缩略图  当 type=faceStyle,该值等于封面缩略图
  materialUrl: string; // 素材地址 当 type=faceStyle,该值等于封面
  mediaSize: number; // 媒体文件大小
  mediaType: string; // 媒体文件类型 image, audio, video
  width: number; // 宽
  height: number; // 高
  fps: number; // 素材fps
  duration: number; // 素材时长 皮秒
  memberType: 'free' | 'basic' | 'professional'; // free - 免费版，basic - 基础版，professional - 专业版(客户端看免费不展示，基础版 = VIP，专业版 = SVIP)
  type: string; // 风格类型
}

/** 保存到我的模版参数 */
export interface SaveTemplateParmas {
  coverKey: string; // 封面图地址
  titleBgColor?: string; // 标题背景色
  brief?: string; // 模板描述
  preVideoUrl?: string; // 预览视频
  presetIpImageId?: string; // 默认数字虚拟人
  ipImageId?: string; // 我的 ip 形象
  sceneId: string; // 场景id
}

export interface Statement {
  recordingAvatarStatement: 'enable' | 'disable';
}

export interface Speed {
  medium: number;
  quick: number;
  quicker: number;
  slow: number;
  slower: number;
  ultraQuick: number;
}
