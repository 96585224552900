import { forwardRef } from 'react';

interface CmpDeleteProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function CmpDelete(props: CmpDeleteProps, ref: React.Ref<HTMLSpanElement>) {
  const { className, style, onClick } = props;

  return (
    <span
      ref={ref}
      onClick={onClick}
      className={className}
      style={{ ...style, marginRight: 0 }}
    >
      <i className="iconfont icon-theme_icon_delete" />
    </span>
  );
}

export default forwardRef(CmpDelete);
