import { Tooltips } from '@bhb-frontend/lithe-ui';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import cs from 'classnames';
import { event } from '@/utils/event';
import { TextStruc } from '@/models/CmpStruc';
import ColorSelector from '../ColorSelector';
import { combineColor } from '@/helpers/Styles';

import { SETTING_TEXT_BACK_KEY } from '@/constants/StoreCacheKeys';
import assets from '@/assets';
import style from './FontFill.module.less';

interface FontFillProps {
  className?: string;
  model: TextStruc;
}

function FontFill(props: FontFillProps) {
  const { className, model } = props;
  const { fill, selectedStyle } = model;

  const changeBack = (backgroundColor: string) => {
    event.fire(SETTING_TEXT_BACK_KEY, backgroundColor);
  };

  const clearBack = () => {
    const fillData = { ...fill };
    Reflect.deleteProperty(fillData, 'backgroundColor');
    model.update({ fill: fillData });
  };

  const clearFillBtn = (
    <div onClick={clearBack} className={style['clear-fill-btn']}>
      清除填充
    </div>
  );

  /** 展示的颜色 */
  const showColor = useMemo(() => {
    if (!selectedStyle || !selectedStyle.background)
      return fill?.backgroundColor;
    if (typeof selectedStyle.background === 'string')
      return selectedStyle.background;
    return combineColor(selectedStyle.background);
  }, [selectedStyle, fill?.backgroundColor]);

  return (
    <ColorSelector
      color={showColor}
      prefixNode={clearFillBtn}
      changeColor={changeBack}
    >
      <span>
        <Tooltips tips="背景填充" placement="bottom" space={15}>
          <div className={className}>
            <img
              className={style['font-fill-icon']}
              src={assets.images.doc['make_icon_fill.png']}
              alt=""
            />
            <div
              style={{
                background: showColor,
              }}
              className={cs(style['color-panel'], {
                [style.border]:
                  !showColor || showColor?.toLowerCase() === '#ffffff',
              })}
            />
          </div>
        </Tooltips>
      </span>
    </ColorSelector>
  );
}

export default observer(FontFill);
