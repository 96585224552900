import request from '@/api/init';
import {
  DetailResponse,
  ListQuery,
  CustomListResponse,
  CommonResponse,
} from '@/types/api';
import {
  CreateEffectTaskParams,
  UserBigHeadItem,
  BodyStyleItem,
  HeadStyleItem,
  SaveTemplateParmas,
  Statement,
  Speed,
} from '@/types/doc';

/**
 * 语言转文字
 */
export function audio2Text(
  audioLink: string
): Promise<DetailResponse<{ taskId: string }>> {
  return request.post('/clip/documents/video/link', {
    link: audioLink,
  });
}

/**
 * 创建特效任务
 * 智能配图、关键词提取、大头数字人
 */
export function createEffectTask(
  data: CreateEffectTaskParams
): Promise<DetailResponse<{ taskId: string }>> {
  return request.post('/v1/effects', data);
}

/**
 * 取消特效任务
 */
export function cancelEffectTaskById(
  taskIds: string[]
): Promise<CommonResponse> {
  return request.put('/v1/effects/cancel', { taskIds });
}

/**
 * 获取用户大头列表
 * */
export function getUserBigHeadList(
  params: ListQuery
): Promise<CustomListResponse<UserBigHeadItem>> {
  return request.get('/clip/head_figure/head', params);
}

/**
 *  删除用户大头图像
 *  */
export function deleteUserBigHead(id: string): Promise<CommonResponse> {
  return request.delete(`/clip/head_figure/head/${id}`);
}

/**
 * 获取大头身体模板列表
 */
export function getBodyStyleList(
  params: ListQuery
): Promise<CustomListResponse<BodyStyleItem>> {
  return request.get('/figure_body/index', params);
}

/**
 * 获取风格头像列表
 *  */
export function getHeadStyleList(
  params: ListQuery
): Promise<CustomListResponse<HeadStyleItem>> {
  return request.get('/face_style/index', params);
}

/**
 * 保存我的模版
 *  */
export function saveTemplateToSelf(id: string, parmas: SaveTemplateParmas) {
  return request.post(`/clip/documentId/${id}/theme`, parmas);
}

/**
 * 修改声明提示
 *  */
export function editTipConfig(data: Statement) {
  return request.put('/users/info/tip_config', data);
}

/**
 * 获取配音语速
 */
export function getDubSpeed() {
  return request.get<DetailResponse<Speed>>('/clip/dubbing/speeds');
}

/** 抠图 */
export function cutoutImage(imageUrl: string) {
  return request.post<DetailResponse<{ taskId: string }>>(
    '/effects/segment/image',
    {
      imageUrl,
      /**
       * 是否返回灰度图
       * 0 - 不返回，
       * 1 - 返回灰度图和人像图，
       * 2 - 只返回灰度图
       * */
      returnGrayscale: 0,
      /**
       * video - 图文快剪（默认），
       * live - 直播快剪
       * */
      module: 'video',
    }
  );
}

/**
 * 获取视频的封面
 */
export function getVideoCover(filePath: string) {
  return request.get('/clip/file/video/cover', {
    filePath,
    scene: 'document',
  });
}

/** 数字人人脸图片审核 */
export function bigHeadAudit(imgUrl: string): Promise<DetailResponse<unknown>> {
  const uri = '/cfs/sync/image';
  return request.post(uri, { imgUrl });
}
