/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/fae4989b31d93b91642cfc0051ac457c.png */
  'icon-common-play.png': 'fae4989b31d93b91642cfc0051ac457c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/cd065b27d01bcbcbbb8b25817932917f.png */
  'icon-dingyuehao-def.png': 'cd065b27d01bcbcbbb8b25817932917f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5632586e0518c90311dbb6070c677539.png */
  'icon-dingyuehao-pre.png': '5632586e0518c90311dbb6070c677539.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/52b5c19f80f22af9ede4a5667414a233.png */
  'icon-tuwen-def.png': '52b5c19f80f22af9ede4a5667414a233.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e29840c55f0cdbd62930d4655dbb64ec.png */
  'icon-tuwen-pre.png': 'e29840c55f0cdbd62930d4655dbb64ec.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/abb0ecf76bfb8ce0dfdd082733832ab6.png */
  'icon-zhibo-def.png': 'abb0ecf76bfb8ce0dfdd082733832ab6.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/31ba6f9758d697c47b633edd98bf9a1f.png */
  'icon-zhibo-pre.png': '31ba6f9758d697c47b633edd98bf9a1f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/3890973475c5dc2d47892218e06a6d4b.jpg */
  'img-guanwang-bg.png': '3890973475c5dc2d47892218e06a6d4b.jpg',

  /* https://bhb-frontend.bhbcdn.com/static/files/61c59c9302bd59b452d3ec748c9b0ec2.png */
  'img-home-cover.png': '61c59c9302bd59b452d3ec748c9b0ec2.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ef7f93e7614245b7f22841b1d92b525d.png */
  'logo.png': 'ef7f93e7614245b7f22841b1d92b525d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/673ba6682d94b9e1ad874b4f6c5025f5.png */
  'icon-number1.png': '673ba6682d94b9e1ad874b4f6c5025f5.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/7e641598a43a73dafcdf3c3a1040fbf2.png */
  'icon-number2.png': '7e641598a43a73dafcdf3c3a1040fbf2.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/148320ec9824a2381912d478df6b450e.png */
  'img-arrow.png': '148320ec9824a2381912d478df6b450e.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/067856dcedfdfe2f89084d4c333ff9c5.png */
  'img-more.png': '067856dcedfdfe2f89084d4c333ff9c5.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d916ace5db9f39c911b7f78b33fa9855.png */
  'icon-mobile-1.png': 'd916ace5db9f39c911b7f78b33fa9855.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9d4b49bd48aca13bd45021d3bbd82779.png */
  'icon-mobile-2.png': '9d4b49bd48aca13bd45021d3bbd82779.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/bb53f59fc8b87b38b1c60e92394d1723.png */
  'icon-mobile-3.png': 'bb53f59fc8b87b38b1c60e92394d1723.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/56af37e99c4aef0b87ed02b60e28c110.png */
  'img-mobile-bg.png': '56af37e99c4aef0b87ed02b60e28c110.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/b515c6cbc2061dc27e4682eab555fdf4.png */
  'img-mobile-phone.png': 'b515c6cbc2061dc27e4682eab555fdf4.png',
};
