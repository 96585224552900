import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Movable } from '@bhb-frontend/lithe-ui/lib';
import { EditorBox, RectData, dragAction } from '@bhb-frontend/toolbox';

import { useRefState } from '@/hooks/useRefState';
import {
  ALL_POINTS,
  Box,
  checkOutBorder,
  initCropSize,
  initImg,
  Position,
} from './tools';
import { calcImgRatioScaleInfo, compressImg, makeImage } from '@/utils/image';

import Style from './style.module.less';

export interface CroppedImgProps {
  title?: string;
  visible: boolean;
  imgSrc: string;
  imgType: string;
  imgName: string;
  imgWidth: number;
  imgHeight: number;
  img: HTMLImageElement;
  croppedDefaultWidth: number;
  croppedDefaultHeight: number;
  cancel: () => void;
  confirm: (blob: Blob, name: string) => void;
}

export default function CroppedImg(props: CroppedImgProps) {
  const {
    title = '裁剪',
    visible,
    img,
    imgSrc,
    imgType,
    imgName,
    imgWidth,
    imgHeight,
    croppedDefaultWidth,
    croppedDefaultHeight,
    cancel,
    confirm,
  } = props;

  const croppedBoxRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [imgPosition, setImgPosition, imgPositionRef] =
    useRefState<Position | null>(null);
  const [imgBoxStyle, setImgBoxStyle] = useState<Box>({ width: 0, height: 0 });
  const [dragBoxStyle, setDragBoxStyle, dragBoxStyleRef] = useRefState(() => ({
    width: croppedDefaultWidth,
    height: croppedDefaultHeight,
    x: 0,
    y: 0,
  }));

  useEffect(() => {
    if (!croppedBoxRef.current) return;

    // 获取 croppedbox 宽高
    const { width: boxWidth, height: boxHeight } =
      croppedBoxRef.current.getBoundingClientRect();

    // 获取图片在 box 中的渲染宽高
    const res = initImg(imgWidth, imgHeight, boxWidth, boxHeight);
    setImgBoxStyle(res);

    // 计算并存储图片的位置信息
    const left = (boxWidth - res.width) / 2;
    const right = left + res.width;
    const top = (boxHeight - res.height) / 2;
    const bottom = top + res.height;

    setImgPosition({ left, top, right, bottom });

    // 计算并存储裁剪框的宽高、位置信息
    const dragRes = initCropSize(
      dragBoxStyle.width,
      dragBoxStyle.height,
      res.width,
      res.height
    );
    const x = (boxWidth - dragRes.width) / 2;
    const y = (boxHeight - dragRes.height) / 2;
    setDragBoxStyle({ ...dragRes, x, y });
  }, [imgWidth, imgHeight]);

  const onMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dragAction(e as unknown as MouseEvent, {
      move: e => {
        const imgPositionCurrent = imgPositionRef();
        const boxPositionCurrent = dragBoxStyleRef();
        const { x, y } = boxPositionCurrent;

        const boxPosition = {
          ...boxPositionCurrent,
          x: x + e.movementX,
          y: y + e.movementY,
        };
        if (
          !imgPositionCurrent ||
          checkOutBorder(boxPosition, imgPositionCurrent)
        ) {
          return;
        }
        setDragBoxStyle(oldVal => ({
          ...oldVal,
          x: x + e.movementX,
          y: y + e.movementY,
        }));
      },
    });
  };

  const handleScale = ({ width, height, x, y }: RectData) => {
    // 边界碰撞判断
    const boxPosition = { x, y, width, height };
    if (!imgPosition || checkOutBorder(boxPosition, imgPosition)) return;
    setDragBoxStyle({ width, height, x, y });
  };

  /** 裁剪图片 */
  const croppedImg = () => {
    if (!imgRef.current || !imgPosition) return;
    /** 原图片与图片 dom 的比例 */
    const r = img.width / imgRef.current.width;
    // 创建一个临时画布来绘制缩小后的图像
    const canvas = document.createElement('canvas');
    /** 裁剪图片的宽 */
    const croppedWidth = dragBoxStyle.width * r;
    /** 裁剪图片的高 */
    const croppedHeight = dragBoxStyle.height * r;
    canvas.width = croppedWidth;
    canvas.height = croppedHeight;

    // 在画布上绘制缩小后的图像并返回新的数据URL
    const ctx = canvas.getContext('2d');
    /** canvas，是用原图进行画 */
    ctx?.drawImage(
      img,
      (imgPosition.left - dragBoxStyle.x) * r,
      (imgPosition.top - dragBoxStyle.y) * r,
      img.width,
      img.height
    );
    canvas.toBlob(
      async blob => {
        if (!blob) return;
        /** （背景图：宽高最大是 1280） */
        const MAX_VALUE = 1280;
        /** 如果裁剪后的图片大小不符合要求 */
        if (croppedWidth > MAX_VALUE || croppedHeight > MAX_VALUE) {
          // 执行压缩
          compress(blob, MAX_VALUE, croppedWidth, croppedHeight);
        } else {
          confirm(blob, imgName);
        }
      },
      imgType,
      1
    );
  };

  const compress = async (
    blob: Blob,
    maxValue: number,
    croppedWidth: number,
    croppedHeight: number
  ) => {
    /** 计算图片新的宽高值 */
    const { width, height } = calcImgRatioScaleInfo(
      croppedWidth,
      croppedHeight,
      [
        {
          type: 'max',
          width: maxValue,
          height: maxValue,
        },
      ]
    );
    const img = await makeImage(URL.createObjectURL(blob));
    compressImg(
      img,
      width,
      height,
      blob => {
        confirm(blob, imgName);
      },
      imgType,
      1
    );
  };

  return (
    <Modal
      visible={visible}
      contentClassName={Style.croppedBoxModal}
      timeout={0}
      width="auto"
      height="auto"
      onClose={cancel}
    >
      <header className={Style.croppedBoxModalHeader}>{title}</header>
      <div className={Style.croppedBoxModalContent}>
        <div className={Style.croppedBox} ref={croppedBoxRef}>
          <div
            className={Style.croppedBoxImg}
            style={{ width: imgBoxStyle.width, height: imgBoxStyle.height }}
          >
            <img src={imgSrc} ref={imgRef} alt="" />
          </div>
          <Movable>
            <div
              // className={Style.croppedBoxDrag}
              style={{
                position: 'absolute',
                width: dragBoxStyle.width,
                height: dragBoxStyle.height,
                left: dragBoxStyle.x,
                top: dragBoxStyle.y,
              }}
            />
          </Movable>

          <EditorBox
            onMouseDown={onMouseDown}
            className={Style.croppedBoxDrag}
            isShowRotate={false}
            points={ALL_POINTS}
            onScale={handleScale}
            rectInfo={{
              ...dragBoxStyle,
              rotate: 0,
            }}
          />
        </div>
      </div>
      <footer className={Style.croppedBoxModalFooter}>
        <Button onClick={cancel}>取消</Button>
        <Button type="primary" onClick={croppedImg}>
          确定
        </Button>
      </footer>
    </Modal>
  );
}
