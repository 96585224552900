import { useState } from 'react';
import { Popup, Menu } from '@bhb-frontend/lithe-ui';
import cx from 'classnames';
import style from './ResourceItemMenu.module.less';

interface ResourceItemMenuProps {
  className?: string;
  onRemove?: () => void;
}

const DELETE_KEY = 'delete';

export default function ResourceItemMenu(props: ResourceItemMenuProps) {
  const { className, onRemove } = props;
  const renderPoint = new Array(3).fill(null).map((_, index) => {
    const id = String(index);
    return <i className={style.point} key={id} />;
  });

  const [popupVisible, setPopupVisible] = useState(false);

  const overlay = (
    <Menu
      onItemClick={() => {
        setPopupVisible(false);
      }}
      width={90}
      onChange={val => {
        if (val === DELETE_KEY) {
          onRemove?.();
        }
      }}
      className={style['overlay-containe']}
    >
      <Menu.Item value={DELETE_KEY} className={style['menu-item']}>
        <i className="iconfont icon-dingyue_icon_delete" />
        <span>删除</span>
      </Menu.Item>
    </Menu>
  );

  const handlePopupOpen = () => {
    setPopupVisible(true);
  };

  return (
    <Popup
      visible={popupVisible}
      placement="bottomLeft"
      space={5}
      overlay={overlay}
      trigger="click"
      onOpen={handlePopupOpen}
    >
      <div
        onClick={e => e.stopPropagation()}
        className={cx(className, style['menu-container'])}
      >
        {renderPoint}
      </div>
    </Popup>
  );
}
