import { makeAutoObservable } from 'mobx';
import { getCookie, setCookie, expiresDate } from '@/utils/cookie';
import { getUserInfo } from '@/api/user';
import { goLogin } from '@/utils/goLogin';

import {
  InterLiveClipRights,
  InterUserItems,
  InterVideoClipItems,
  InterWechatItems,
  StatusStatement,
} from '@/types/user';

export default class UserStore implements InterUserItems {
  id!: string;

  userId!: string;

  name!: string;

  avatar!: string;

  mobilePhoneNumber!: string;

  wechat!: InterWechatItems;

  sessionToken!: string;

  userNo!: number;

  socketLink!: string;

  videoClipRights!: InterVideoClipItems;

  liveClipRights!: InterLiveClipRights;

  changeAccount!: boolean;

  hasBeInvited!: boolean;

  skipInviteCode!: boolean;

  innerRole!: string;

  figureMakeRiskTip!: string;

  dubbingLegalTip!: StatusStatement;

  liveCreateRiskTip!: string; // v1.12.0 新增 直播创建风险提示

  tipConfig!: {
    recordingAvatarStatement: StatusStatement; // 录制头像声明 enable:开启|disable:关闭
  };

  liveRobot?: boolean; // 直播快剪是否有的权限

  videoRobot?: boolean; // 图文快剪是否有的权限

  constructor() {
    makeAutoObservable(this);
  }

  public initUser = () => {
    if (this.getToken()) {
      this.getUser();
      return;
    }
    // Login.show({
    //   isShowCloseIcon: false,
    //   isMaskClosable: false,
    //   isEscClose: false,
    // });
    goLogin();
  };

  /** 设置用户信息 */
  public setUserInfo(userInfo: InterUserItems) {
    const { sessionToken, socketLink } = userInfo;
    const param = {};
    try {
      const isRememberToken = JSON.parse(
        getCookie('isRememberToken') as string
      );
      if (isRememberToken) {
        Object.assign(param, { expires: expiresDate });
      }
    } catch (error) {
      console.warn(error);
    }
    sessionToken && setCookie('token', sessionToken, param);
    socketLink && setCookie('socketLink', socketLink);
    for (const key in userInfo) {
      this[key] = userInfo[key];
    }
  }

  public async getUser() {
    try {
      const { data } = await getUserInfo();
      this.setUserInfo(data);
    } catch (error) {
      console.log(error);
    }
  }

  /** 获取用户token */
  public getToken() {
    return getCookie('token');
  }

  /** 用户是否登录 */
  public isLogin() {
    return !!this.id;
  }
}
