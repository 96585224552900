import { TagsEnum, TAGS_TYPE } from '@/constants/Tags';
import {
  TextStruc,
  GroupStruc,
  ImageStruc,
  BackgroundStruc,
  VideoStruc,
} from '@/models/CmpStruc';
/** usage转换Tag */
export const usagetoTagMap: Record<number, TAGS_TYPE> = {
  [TagsEnum.BACKGROUND]: 'Background',
  [TagsEnum.IMAGE]: 'Image',
  [TagsEnum.TITLE]: 'Title',
  [TagsEnum.CAPTIONS]: 'Captions',
  [TagsEnum.WATERMARK]: 'Watermark',
  [TagsEnum.STICKER]: 'Sticker',
  [TagsEnum.TEXT_STICKER]: 'TextSticker',
  [TagsEnum.DATE_TEXT_STICKER]: 'DateTextSticker',
  [TagsEnum.MAP_TEXT_STICKER]: 'MapTextSticker',
};

export const tagToUsageMap: Record<TAGS_TYPE, number> = {
  Background: TagsEnum.BACKGROUND,
  Image: TagsEnum.IMAGE,
  Title: TagsEnum.TITLE,
  Captions: TagsEnum.CAPTIONS,
  Watermark: TagsEnum.WATERMARK,
  Sticker: TagsEnum.STICKER,
  TextSticker: TagsEnum.TEXT_STICKER,
  DateTextSticker: TagsEnum.DATE_TEXT_STICKER,
  MapTextSticker: TagsEnum.MAP_TEXT_STICKER,
  Unknown: 0,
};

/** usage转换Type */
export const usagetoTypeMap: Record<number, CmpType> = {
  [TagsEnum.BACKGROUND]: 'Background',
  [TagsEnum.IMAGE]: 'Image',
  [TagsEnum.TITLE]: 'Text',
  [TagsEnum.CAPTIONS]: 'Text',
  [TagsEnum.WATERMARK]: 'Unknown',
  [TagsEnum.STICKER]: 'Image',
  [TagsEnum.TEXT_STICKER]: 'Text',
  [TagsEnum.DATE_TEXT_STICKER]: 'Text',
  [TagsEnum.MAP_TEXT_STICKER]: 'Text',
};

export const MimeType = {
  /** 纯色背景 */
  Color: 6,
  /** 图片背景 */
  Image: 7,
  /** 视频背景 */
  Video: 8,
};

/** 背景mimeType 转换 类型 */
export const mimeTypeMap: Record<number, 'Color' | 'Image' | 'Video'> = {
  [MimeType.Color]: 'Color',
  [MimeType.Image]: 'Image',
  [MimeType.Video]: 'Video',
};

export type Struce =
  | typeof TextStruc
  | typeof GroupStruc
  | typeof ImageStruc
  | typeof BackgroundStruc
  | typeof VideoStruc;

/**  type 转换成 Struc */
export const tagToStruce = {
  Background: BackgroundStruc,
  Image: ImageStruc,
  Title: TextStruc,
  Captions: TextStruc,
  Sticker: ImageStruc,
  TextSticker: TextStruc,
  DateTextSticker: TextStruc,
  MapTextSticker: TextStruc,
};

/** 水平方向转换 */
export enum HorizontalAlign {
  'left',
  'center',
  'right',
}

/** 垂直方向转换 */
export enum VerticalAlign {
  'top',
  'middle',
  'bottom',
}
