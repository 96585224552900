import cs from 'classnames';
import WeChatQRCode from '@/components/WeChatQRCode';
import Style from './ContactBusiness.module.less';

interface ContactBusinessProps {
  tips?: string;
  onClose?: () => void;
}

/** 联系商务 */
export default function ContactBusiness(props: ContactBusinessProps) {
  const { tips, onClose } = props;

  return (
    <>
      <div className={Style['contact-business-head']}>
        <span onClick={onClose}>
          <i
            className={cs(
              'iconfont icon-a-common_icon_arrow_left1x',
              Style['arrow-icon']
            )}
          />
          返回
        </span>
      </div>
      <WeChatQRCode tips={tips} />
    </>
  );
}
