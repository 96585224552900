// import { useEffect, useState } from 'react';
import { useStores } from '@/store';
import formatDuration from '@/utils/formatDuration';

import Style from './style.module.less';

function AudioText() {
  const { app } = useStores();

  const onFocus = () => {
    app.releaseAllCmps();
  };

  const onInput = (index: number, event: any) => {
    const text = event.target.innerText;

    const c = app.activedScene.captions.map((item, i) => {
      if (i === index) {
        return { ...item, text };
      }
      return item;
    });

    const allTexts: AllTexts[] = [
      {
        text: app.activedScene.captions.map(item => item.text).join('\n'),
      },
    ];
    app.activedScene.update({
      captions: c,
      allTexts,
    });
  };

  // useEffect(() => {
  //   if (app.activedScene.captions) {
  //     console.log('length: ', app.activedScene.captions.length);

  //     if (!app.activedScene.captions.length) return;
  //     app.activedScene.update({
  //       captions: [{ text: 'hhh' }, { text: 'xxx' }],
  //     });
  //   }
  // });

  return (
    <ul className={Style.audioText}>
      {app.activedScene.captions.map((item, index) => (
        <li key={item.text + index}>
          <span className={Style.time}>
            {formatDuration(item.startMs || 0)}
          </span>
          <div
            className={Style.text}
            contentEditable
            suppressContentEditableWarning
            onFocus={onFocus}
            onInput={e => {
              onInput(index, e);
            }}
          >
            {item.text}
          </div>
        </li>
      ))}
    </ul>
  );
}

export default AudioText;
