export enum ERROR_CODE {
  /** 没有登录  */
  NO_LOGIN = 100,
  /** 登录凭证已经过期 */
  TOKEN_EXPIRE = 101,
  /** 不支持的直播平台 */
  NOT_SUPPORT_PLATFORM = 2001,
  /** 直播分享地址解析失败 */
  SHARE_URL_PARSE_FAILED = 2002,
  /** 源站直播已结束 */
  SOURCE_LIVE_CLOSED = 2003,
  /** 无效的直播分享地址 */
  INVALID_SHARE_URL = 2004,
  /** 超过直播开启数量 */
  LIVE_CREATE_CONST_LIMIT = 2005,
  /** 重复开播 */
  DUPLICATE_START_LIVE = 2008,
  /** 未找到直播信息 */
  NOT_FIND = 2009,
  /** 生成视频次数限制 */
  CREATE_VIDEO_NUM_LIMIT = 1011,
  /** 渲染个数超过限制 */
  RENDER_LIMIT = 1002,
  /** 用户已被禁用 */
  USER_DENY = 406,
  /** 图文快剪容量不足 */
  STORAGE_DEFICIENCY = 1009,
  /** 不是自己的文档 */
  NOT_MY_DOC = 1007,
}
