import { cloneElement } from 'react';
import { observer } from 'mobx-react';
import { InnerCmpProps } from '@/components/Cmp';
import CmpStruc from '@/models/CmpStruc';
import { moveHandle } from '@/utils/move';

interface MoveProps {
  children?: React.ReactElement<InnerCmpProps>;
  model: CmpStruc;
  zoomLevel: number;
  editable: boolean;
}

function Move(props: MoveProps) {
  const { children, model, zoomLevel, editable } = props;

  if (!children) return null;
  if (!editable) return children;

  const {
    onMouseDown: mouseDownHandle,
    className,
    children: subChildren,
  } = children.props;

  const onMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    mouseDownHandle?.(e);
    if (model.isLock) return;
    moveHandle(e.nativeEvent, model, zoomLevel);
  };

  return cloneElement(children, { className, onMouseDown }, subChildren);
}

export default observer(Move);
