import { Tooltips } from '@bhb-frontend/lithe-ui';
import { material } from '@/store';
import { MaterialEnum } from '@/types/material';
import assets from '@/assets';
import style from './FontAnimation.module.less';

export default function FontAnimation() {
  const onTextAnimationClick = () => {
    material.changeMenu(MaterialEnum.TEXT_ANIMATION);
  };

  return (
    <Tooltips
      trigger="hover"
      className={style['tooltips-conponent']}
      tips="文字动画"
      placement="bottom"
    >
      <div
        className={style['font-animation-box']}
        onClick={onTextAnimationClick}
      >
        <img
          className={style['font-animation-icon']}
          src={assets.images.doc['theme_icon_animation.png']}
          alt=""
        />
        <span>动画</span>
      </div>
    </Tooltips>
  );
}
