import { createContext, useContext } from 'react';
import MaterialStore from './Material';
import AppConfigStore from './AppConfig';
import UserStore from './User';
import AppStore from './App';
import OSStore from './OS';
import HistoryStore from './History';
import FontStore from './FontList';
import SpeedStore from './Speed';
import ChannelAppStore from './ChannelApp';

export interface Stores {
  OS: OSStore;
  app: AppStore;
  user: UserStore;
  history: HistoryStore;
  material: MaterialStore;
  appConfig: AppConfigStore;
  fontStore: FontStore;
  speed: SpeedStore;
  channelApp: ChannelAppStore;
}
export const history = new HistoryStore();

export const material = new MaterialStore();

export const appConfig = new AppConfigStore();

export const user = new UserStore();

export const app = new AppStore(user);
export const OS = new OSStore();

export const fontStore = new FontStore();

export const speed = new SpeedStore();

export const channelApp = new ChannelAppStore();

const storeContext = createContext<Stores>({
  OS,
  app,
  user,
  history,
  material,
  appConfig,
  fontStore,
  speed,
  channelApp,
});

export function useStores() {
  return useContext<Stores>(storeContext);
}
