// import Login from '@bhb-frontend/mtv-login';

import service, { FullResponse } from '@bhb-frontend/utils/lib/request';
// import { userStore } from '@/store';
import { Toast } from '@bhb-frontend/lithe-ui';
import md5 from 'md5';
import { BASE_URL_MAP, CONTEXT_ENV, ENV, WORKBENCH_URL } from '@/config/hosts';
import { goLogin } from '@/utils/goLogin';

import { ERROR_CODE } from '@/constants/ErrorCode';
import { getCookie, removeCookie } from '@/utils/cookie';
import { DOC_ERROR_CODE } from '@/constants/Createvideo';
import { delayGoToWorkbench } from '@/utils/workbench';

// 不需要全局toast提示的错误码集合
const noToastCode: Array<ERROR_CODE> = [
  ERROR_CODE.NO_LOGIN,
  ERROR_CODE.TOKEN_EXPIRE,
  ERROR_CODE.NOT_SUPPORT_PLATFORM,
  ERROR_CODE.SHARE_URL_PARSE_FAILED,
  ERROR_CODE.SOURCE_LIVE_CLOSED,
  ERROR_CODE.INVALID_SHARE_URL,
  ERROR_CODE.LIVE_CREATE_CONST_LIMIT,
  ERROR_CODE.DUPLICATE_START_LIVE,
  ERROR_CODE.NOT_FIND,
  ERROR_CODE.CREATE_VIDEO_NUM_LIMIT,
  ERROR_CODE.RENDER_LIMIT,
  DOC_ERROR_CODE.STORAGE_LIMIT_ERROR,
  DOC_ERROR_CODE.EQUITY_DEFICIENCY,
  DOC_ERROR_CODE.CREATE_VIDEO_NUM_LIMIT,
  DOC_ERROR_CODE.FIGURE2D_PERMISSION_LIMIT,
];

export const baseUrl: string =
  BASE_URL_MAP[ENV] || BASE_URL_MAP[CONTEXT_ENV.dev];

// service基础配置
service.setGlobalConfig({
  baseUrl,
  timeout: 10000, // 请求超时时间
});

// request interceptor
service.interceptors.request(config => {
  const date = Date.now();

  if (!config.headers) return config;
  // 禁止缓存
  config.headers['Cache-Control'] = 'no-cache';
  // 跨域
  config.headers['Access-Control-Allow-Origin'] = '*';
  const appVersion = window?.MTV_CONFIG?.appVersion || '1.18.0';
  // 客户端信息
  const bhbClientInfo = {
    platform: 'web', // 应用平台 ios,android,web,wechat
    appVersion,
    // deviceId: String, // 设备唯一标识符
    // osVersion: String // 系统版本， android例如：9.0.0
    requestTime: date, // 请求时间，发起请求的时候拿到的客户端时间
  };
  config.headers['bhb-client-info'] = JSON.stringify(bhbClientInfo);

  // 接口鉴权（bhb-api-auth），计算规则：md5(secret + origin + requestTime)，其中secret为固定值

  // 在浏览器环境
  const origin = window.location.origin;

  config.headers['bhb-channel-host'] = WORKBENCH_URL;

  config.headers['bhb-api-auth'] = md5(
    `h98zdbtqwu90qre8md3ndw${origin}${date}`
  );

  // headers 携带token
  const token: string = getCookie('token') || '';

  if (token) {
    config.headers['bhb-session-token'] = token;
  }

  return config;
});

// response interceptor
service.interceptors.response((response: FullResponse) => {
  const { error } = response.body;
  if (error && error !== 0) {
    // 登录过期，账号注销、禁用 。均走登出流程
    if (
      [
        ERROR_CODE.NO_LOGIN,
        ERROR_CODE.TOKEN_EXPIRE,
        ERROR_CODE.USER_DENY,
      ].includes(error)
    ) {
      removeCookie('token');
      Toast.error('登录状态已发生改变，请重新登录');
      // Login.show({
      //   isShowCloseIcon: false,
      //   isMaskClosable: false,
      //   isEscClose: false,
      // });
      goLogin();
    }

    // 统一提示
    if (!noToastCode.includes(+error)) {
      Toast.error(response.body.message);
    }
    // 不是自己的文档
    if (error === ERROR_CODE.NOT_MY_DOC) {
      delayGoToWorkbench('/doc', 2000);
    }
  }
  return response;
});

export default service;
