export enum AxleDirection {
  x = 'x',
  y = 'y',
}

export interface GuideLine {
  id: string;
  direction: AxleDirection;
  value: number;
}

export interface MagneticLine {
  direction: AxleDirection;
  axis: { x: number; y: number };
  length: number;
}
