import { RectData, Range } from '@/types/canvas';

/**
 * 判断两矩形是否相交（只要有一个点重复即可认为相交）
 * @param {Rect} rect1
 * @param {Rect} rect2
 * @returns {boolean}
 */
export function isIntersect(rect1: Rect, rect2: Rect): boolean {
  const { left: l1, top: t1, right: r1, bottom: b1 } = rect1;
  const { left: l2, top: t2, right: r2, bottom: b2 } = rect2;
  if (l2 <= r1 && t2 <= b1 && r2 >= l1 && b2 >= t1) return true;
  return false;
}

/**
 * 角度转弧度
 * @param degree 角度
 * @return {number}
 */
export function degree2Radian(degree: number): number {
  return (degree * Math.PI) / 180;
}

/**
 * 弧度转角度
 * @param radian 弧度
 * @return {number}
 */
export function radian2Degree(radian: number): number {
  return (radian * 180) / Math.PI;
}

/**
 * 计算p2相对p1的角度
 * @param p1 坐标点1
 * @param p2 坐标点2
 * @returns {number}
 */
export function getPointsDegree(p1: Point, p2: Point): number {
  let r = Math.atan2(p2.y - p1.y, p2.x - p1.x);
  if (r > -Math.PI && r < -Math.PI / 2) r += 2 * Math.PI;
  return Math.round(radian2Degree(r) + 90);
}

/**
 * 根据XY轴按勾股定理计算斜边长
 * @param x x轴值
 * @param y y轴值
 * @returns {number}
 */
export function getDiagonalByPythagorean(x: number, y: number): number {
  const p = Math.abs(x) >= Math.abs(y) ? x : y;
  const d = Math.sqrt(x ** 2 + y ** 2);
  return (p > 0 ? 1 : -1) * d;
}

/**
 * 根据角度和XY轴计算斜边长
 * @param r 角度
 * @param d1 偏移值1
 * @param d2 偏移值2
 * @returns {number}
 */
export function getDiagonalByRotate(r: number, d1: number, d2: number): number {
  let d = 0;
  if (r === 0 || r === 180 || r === 360) {
    d = d1;
  } else if (r === 90 || r === 270) {
    d = d2;
  } else {
    d = getDiagonalByPythagorean(d1, d2);
  }
  return d;
}

/**
 * 根据斜边长和勾股定理计算XY轴坐标
 * @param r 弧度
 * @param d 斜边
 * @param isReverse
 * @returns {Point}
 */
export function getPointByPythagorean(
  r: number,
  d: number,
  isReverse = false
): Point {
  const x = (d * Math.cos(r)) / 2;
  const y = (d * Math.sin(r)) / 2;
  return isReverse ? { x: y, y: x } : { x, y };
}

/**
 * 返货矩形中心点
 * @param width 宽度
 * @param height 高度
 * @param left 左坐标
 * @param top 上坐标
 * @returns {Point}
 */
export function getRectCenter(
  width: number,
  height: number,
  left: number,
  top: number
): Point {
  const x = left + width / 2;
  const y = top + height / 2;
  return { x, y };
}

/**
 * 计算元素在画布中的矩形范围旋转后的新位置范围
 * @param element 元素的位置大小和旋转角度信息
 */
export function getRectRotatedRange(element: RectData): {
  xRange: Range;
  yRange: Range;
} {
  const { x, y, width, height, rotate = 0 } = element;

  const radius = Math.sqrt(width ** 2 + height ** 2) / 2;
  const auxiliaryAngle = (Math.atan(height / width) * 180) / Math.PI;

  const tlbraRadian = ((180 - rotate - auxiliaryAngle) * Math.PI) / 180;
  const trblaRadian = ((auxiliaryAngle - rotate) * Math.PI) / 180;

  const middleLeft = x + width / 2;
  const middleTop = y + height / 2;

  const xAxis = [
    middleLeft + radius * Math.cos(tlbraRadian),
    middleLeft + radius * Math.cos(trblaRadian),
    middleLeft - radius * Math.cos(tlbraRadian),
    middleLeft - radius * Math.cos(trblaRadian),
  ];
  const yAxis = [
    middleTop - radius * Math.sin(tlbraRadian),
    middleTop - radius * Math.sin(trblaRadian),
    middleTop + radius * Math.sin(tlbraRadian),
    middleTop + radius * Math.sin(trblaRadian),
  ];

  return {
    xRange: [Math.min(...xAxis), Math.max(...xAxis)],
    yRange: [Math.min(...yAxis), Math.max(...yAxis)],
  };
}
