import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Quill, { RangeStatic } from 'quill';
import Delta from 'quill-delta';
import cs from 'classnames';
import { event } from '@/utils/event';
import { useStores } from '@/store';

import {
  SETTING_TEXT_COLOR_KEY,
  SETTING_TEXT_BACK_KEY,
} from '@/constants/StoreCacheKeys';
import { getCharAttrs } from '@/utils/charAttrs';
import { getDefaultContent } from '@/config/defaultCmpTextContent';

import { TextStruc } from '@/models/CmpStruc';
import {
  getTextFillStyle,
  getCmpInnerStyles,
  getCmpOuterStyles,
  getRectDisplayStyle,
} from '@/helpers/Styles';
import Style from './RichText.module.less';

interface RichTextProps {
  isMulti: boolean;
  zoomLevel: number;
  model: TextStruc;
}

const getBindings = () => ({
  custom: {
    key: 13,
    shiftKey: true,
    handler() {
      return false;
    },
  },
  tab: {
    key: 9,
    shiftKey: null,
    handler() {
      return false;
    },
  },
});

function RichText(props: RichTextProps) {
  const { isMulti, model, zoomLevel } = props;
  const { charAttrs, content, fill, isTitle, isCaptions, tag, style } = model;
  const { app, OS } = useStores();

  const quillRef = useRef<Quill>(null);
  const richTextContainerRef = useRef<HTMLDivElement>(null);
  const richTextRef = useRef<HTMLDivElement>(null);
  const isFocus = useRef(false);
  const selectedRange = useRef<RangeStatic | null>(null);

  /** 获取文字 */
  const getTextValue = () => {
    let textValue = model.isTitle ? app.titleContent : content;
    if (!textValue) {
      textValue = getDefaultContent(tag);
    }
    return textValue;
  };

  /**
   * 捕获粘贴内容并过滤，仅支持纯文本
   */
  const editorMatcher = (_node, delta: Delta) => {
    delta.ops = delta.ops
      .map((op: Delta) => ({
        insert: op.insert.replaceAll(/\n|\t/g, ' '),
      }))
      .filter((op: Delta) => typeof op.insert === 'string');
    return delta;
  };

  /**
   * 绑定selection-change事件
   */
  const selectChange = (
    newRange: RangeStatic,
    _oldRange
    // source: 'api' | 'user'
  ) => {
    selectedRange.current = newRange;

    // 兼容火狐浏览器，保留上一次的选取
    // if (
    //   (/firefox/i.test(window.navigator.userAgent) && newRange === null) ||
    //   source === 'api'
    // ) {
    //   // model.setTextRange(oldRange);
    //   range.current = oldRange;
    //   console.log('oldRange', oldRange);
    // }

    if (newRange) {
      const style = quillRef.current?.getFormat(newRange);
      model.setSelectedStyle(style);
    }
  };

  /** 切换组件，清空文字展示样式 */
  useEffect(() => {
    selectedRange.current = null;
    model.clearSelectedStyle();
  }, [model]);

  /**
   * 绑定text-change事件
   */
  const textChange = () => {
    const delta = quillRef.current?.getContents();
    let content = quillRef.current?.getText();
    // 删除最后一项 \n
    content = content?.substring(0, content.length - 1);
    const charAttrs = getCharAttrs(delta, fill?.color || '');

    if (selectedRange.current) {
      const style = quillRef.current?.getFormat(selectedRange.current);
      model.setSelectedStyle(style);
    }
    if (isTitle) {
      model.update({ charAttrs });
      app.setTitleContent(content);
      return;
    }

    model.update({ content, charAttrs });
  };

  /**
   * 聚焦事件回调
   */
  const focusChange = () => {
    isFocus.current = true;
  };

  /**
   * 失焦事件回调
   */
  const blurChange = () => {
    isFocus.current = false;
    if (!isTitle && !isCaptions && model.content === '') {
      model.remove();
    }
  };

  /**
   *  禁止编辑
   * */
  const disableEdit = () => {
    quillRef.current?.enable(false);
    quillRef.current?.blur();
  };

  /**
   * 开启编辑
   */
  const editableEdit = () => {
    quillRef.current?.enable(true);
    quillRef.current?.focus();
    quillRef.current?.setSelection(0, getTextValue().length);
  };

  /**
   * 实例化quill
   * */
  const initEditor = () => {
    quillRef.current = new Quill(richTextRef.current, {
      modules: {
        keyboard: {
          bindings: getBindings(),
        },
      },
    });

    quillRef.current.clipboard.addMatcher(Node.ELEMENT_NODE, editorMatcher);
    quillRef.current.on('selection-change', selectChange);
    quillRef.current.on('text-change', textChange);
    quillRef.current.root.addEventListener('focus', focusChange);
    quillRef.current.root.addEventListener('blur', blurChange);
  };

  const formatTitleText = (val: string): Delta => {
    quillRef.current?.setText(val);
    charAttrs?.forEach((i: ComponentModel.CharAttr) => {
      const { bgColor, color, endPos, start } = i;
      if (bgColor) {
        quillRef.current?.formatText(start, endPos - start, {
          background: bgColor,
        });
      }
      if (color) {
        quillRef.current?.formatText(start, endPos - start, {
          color,
        });
      }
    });
    return quillRef.current?.getContents();
  };

  /** 初始化富文本内容 */
  const initTextContent = (val: string) => {
    const delta = formatTitleText(val);
    quillRef.current?.setContents(delta);
  };

  /**
   * 设置文字颜色
   */
  const setTextColor = (color: string) => {
    if (selectedRange.current) {
      const { index, length } = selectedRange.current;
      quillRef.current?.formatText(index, length, { color });
      return;
    }
    model.update({ fill: { ...model.fill, color } });
  };

  useEffect(() => {
    event.on(SETTING_TEXT_COLOR_KEY, setTextColor);

    return () => {
      event.off(SETTING_TEXT_COLOR_KEY, setTextColor);
    };
  }, [model]);

  /**
   * 设置文字背景
   */
  const setTextBackground = (background: string) => {
    if (selectedRange.current) {
      const { index, length } = selectedRange.current;
      quillRef.current?.formatText(index, length, { background });
      return;
    }
    model.update({ fill: { ...model.fill, backgroundColor: background } });
  };

  useEffect(() => {
    event.on(SETTING_TEXT_BACK_KEY, setTextBackground);
    return () => {
      event.off(SETTING_TEXT_BACK_KEY, setTextBackground);
    };
  }, [model]);

  /**
   * 初始化富文本
   */
  useEffect(() => {
    initEditor();
    initTextContent(getTextValue());
  }, [model]);

  useEffect(() => {
    model.isEdit ? editableEdit() : disableEdit();
  }, [model.isEdit]);

  const isShow = !isMulti && model.isEdit;

  /**
   * 监听输入变化，更新高度
   * */
  const listeningSize = (): ResizeObserver | null => {
    /** 字幕不自动更改高度 */
    if (!richTextContainerRef.current || isCaptions) return null;

    const resizeObserver = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        // if (OS.isScaleing) return;
        const offsetHeight = (entries[0].target as HTMLDivElement).offsetHeight;
        const height = offsetHeight || model.style.height;
        model.updateStyle({
          height,
        });
      });
    });

    resizeObserver.observe(richTextContainerRef.current);
    return resizeObserver;
  };

  /**
   * 结束输入变化，更新高度
   * */
  const unListeningSize = (resizeObserver: ResizeObserver) => {
    if (!richTextContainerRef.current) return;
    resizeObserver.unobserve(richTextContainerRef.current);
  };

  useEffect(() => {
    const resizeObserver = listeningSize();
    return () => {
      if (resizeObserver) unListeningSize(resizeObserver);
    };
  }, [model]);

  /**
   * 字幕不走常规的更新高度机制，手动计算高度
   */
  useEffect(() => {
    !OS.isScaleing && isCaptions && model.changeTextHeight();
  }, [style.fontSize]);

  const outerStyle = getCmpOuterStyles(model, zoomLevel);
  const innerStyle = getCmpInnerStyles(model);
  outerStyle.transform = `scale(${zoomLevel})`;
  Reflect.deleteProperty(outerStyle, 'height');

  return (
    <div
      className={cs(Style['rich-text-contariner'], {
        [Style.show]: isShow,
      })}
      style={getRectDisplayStyle(model, zoomLevel)}
    >
      <div
        ref={richTextContainerRef}
        style={{
          ...outerStyle,
          transformOrigin: 'top left',
        }}
      >
        <div
          className={Style['rich-text-main']}
          style={{
            ...getTextFillStyle(fill),
            ...innerStyle,
          }}
          spellCheck="false"
          ref={richTextRef}
        />
      </div>
    </div>
  );
}

export default observer(RichText);
