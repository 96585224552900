import { Image } from '@bhb-frontend/lithe-ui';
import style from './ContextMenuCover.module.less';
import { TAGS_MAP, TAGS_TYPE } from '@/constants/Tags';
import assets from '@/assets/images';
import CmpStruc, { ImageStruc } from '@/models/CmpStruc';

interface ContextMenuCoverProps {
  model: CmpStruc;
}

export default function ContextMenuCover(props: ContextMenuCoverProps) {
  const { model } = props;

  const getImgUrl = (): string | undefined => {
    const texts: TAGS_TYPE[] = [
      TAGS_MAP.Title,
      TAGS_MAP.Captions,
      TAGS_MAP.TextSticker,
      TAGS_MAP.DateTextSticker,
      TAGS_MAP.MapTextSticker,
    ];

    if (texts.includes(model.tag)) {
      return assets.doc['font-logo.png'];
    }
    return (model as ImageStruc).url;
  };
  return (
    <div className={style['cover-label']}>
      <Image width={44} height={44} src={getImgUrl()} />
    </div>
  );
}
