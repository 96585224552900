import { randomString } from '@bhb-frontend/utils/lib/random';
import {
  FontStyle,
  Image,
  Layer,
  Resources,
  TextDetail,
  ThemeDetailItem,
} from '@/types/ServerData';
import {
  transformUsageToTag,
  transformUsageToType,
  transformVectorToObj,
  transformAnchor,
} from '@/utils/formatData';
import assets from '@/assets/images';
import { createTextResource } from './Text';
// import { Material } from '@/types/doc-materials';
import { StickerDetail } from '@/types/material';
import {
  getDefaultOptionalField,
  getFootageText,
  getImage,
  getStickerLayer,
} from '@/helpers/TextDefaultConfig';
import { getImageInfo } from '@/utils/image';

import { fontStore } from '@/store';

interface CreateCmpParam {
  /** 图层 */
  layer: Layer;
  /** 图片资源Map */
  resources: Record<string, Resources>;
  /** 文字贴纸的图片资源 */
  textImageResources?: Record<string, Image>;
  materials?: Material[];
  themeInfo: ThemeDetailItem;
  /** 文字贴图背景id */
  textBackId?: string;
}

/** 生成单个cmp */
export function createCmp(param: CreateCmpParam) {
  const {
    layer,
    resources,
    textImageResources = {},
    materials = [],
    themeInfo,
    textBackId,
  } = param;
  const {
    usage,
    width: orgWidth,
    height: orgHeight,
    sourceId = '',
    transform,
    scaleMode,
    extra = {},
    // imageInfo,
    objectId,
    usageName,
    flip = {
      x: 1,
      y: 1,
      z: 1,
    },
    ...extensionFields
  } = layer;
  const tag = transformUsageToTag(usage);
  const type = transformUsageToType(usage);
  const { anchor, position, scalar, rotationZ, rotationX, rotationY } =
    transform;
  const positionObj = transformVectorToObj(position);
  let anchorObj = transformVectorToObj(anchor);
  const scalarObj = transformVectorToObj(scalar);
  const rotationObj = {
    x: rotationX,
    y: rotationY,
    z: rotationZ,
  };
  const resource = resources[sourceId];
  const resourceObj = {};

  /** 计算缩放 */
  const scalarX = scalarObj.x / 100;
  const scalarY = scalarObj.y / 100;

  /** 缩放后的宽高 */
  const width = orgWidth * scalarX;
  const height = orgHeight * scalarY;
  // 锚点需要计算缩放
  anchorObj = {
    x: anchorObj.x * scalarX,
    y: anchorObj.y * scalarY,
    z: anchorObj.z,
  };
  /** 缩放后的样式 */
  const style = {
    width,
    height,
    left: positionObj.x - anchorObj.x,
    top: positionObj.y - anchorObj.y,
  };
  // 锚点转换成百分
  anchorObj = transformAnchor(anchorObj, { width, height });

  // 资源丢失将直接返回， 除插图
  if (tag !== 'Image' && !resource) return false;

  // 图片处理
  if (type === 'Image' && resource) {
    const {
      fileName,
      imageKey,
      imageUrl,
      extra: imgExtra,
      ...imgExtensionFields
    } = resource as Image;
    Object.assign(resourceObj, {
      fileName,
      key: imageKey,
      url: imageUrl,
      extra: {
        ...imgExtra,
        ...extra,
      },
      imgExtensionFields,
    });
  }
  // 插图
  if (tag === 'Image') {
    // 插图图层特殊处理， 因为图片在 materials 里，取第一张
    const imgResource = materials[0];

    const {
      source = 'network',
      type = 'image',
      url = assets.material['empty.png'],
      cover,
    } = imgResource || {};

    const { hiddenAnimation } = extra;
    Object.assign(resourceObj, {
      fileName: type,
      url: type === 'image' ? url : cover,
      scaleMode: scaleMode || 1,
      hiddenAnimation,
      extra: {
        source,
      },
    });
  }

  if (type === 'Text') {
    // 生成文字特殊字段
    const { textConfig, textStyle, errorLayer } = createTextResource({
      layer,
      tag,
      themeInfo,
      resources,
      textImageResources,
      textBackId,
    });
    // 资源错误将直接返回
    if (errorLayer) return false;
    Object.assign(resourceObj, textConfig);
    Object.assign(style, textStyle);
  }

  if (extra?.hidden) {
    Object.assign(resourceObj, {
      visible: false,
    });
  }

  return {
    extensionFields,
    usage,
    id: objectId,
    name: usageName,
    tag,
    type,
    width,
    height,
    sourceId,
    scaleMode,
    position: positionObj,
    anchor: anchorObj,
    rotation: rotationObj,
    // 反转就是选择的Z轴
    flip,
    scalar: scalarObj,
    style,
    actived: false,
    visible: true,
    isLock: false,
    loading: false,
    disabled: false,
    isLockScale: false,
    isLockFlip: false,
    isLockRotation: false,
    ...resourceObj,
  };
}

/** 生成img cmp */
export function createImage(
  layerInfo: Layer,
  footageInfo: Resources,
  materials: Material[] = []
) {
  // 因为一些是双向绑定数据， 浅拷贝一下去掉
  const layer = JSON.parse(JSON.stringify(layerInfo)) as Layer;
  const footage = JSON.parse(JSON.stringify(footageInfo)) as Resources;
  return createCmp({
    layer,
    resources: {
      [footage.objectId]: footage,
    },
    themeInfo: {} as ThemeDetailItem,
    materials,
  });
}

export interface CreateTextStickerParam {
  textConfig: Partial<StickerDetail>;
  source: 'network' | 'material';
  optional?: TextDetail & FontStyle;
  text?: string;
  configure: Configure;
}
/** 生成文字贴纸 */
export function createTextSticker(param: CreateTextStickerParam) {
  const { textConfig, source, optional, text, configure } = param;
  const objectId = `layer-${randomString()}-web`;
  const textId = `text-${randomString()}-web`;
  const styleId = `style-${randomString()}-web`;
  const sourceId = `image-${randomString()}-web`;
  const optionalField =
    optional || getDefaultOptionalField(fontStore.fontList[0]);
  optionalField.objectId = styleId;
  // 兼容新导出文字，v1.9.2，优先取contents内容，没有再去拿content内容
  let content = optionalField.contents?.[0] || optionalField.content;
  if (text) {
    content = text;
  }
  // 贴纸背景
  const backConfg = textConfig.config?.patterns.find(i => i.style === 8);
  // footage-text
  const textFontage = getFootageText({
    styleId,
    objectId: textId,
    content,
    optionalField,
    stickerId: textConfig.id,
  });

  const { imageKey, fileName, imageUrl } = getImageInfo(
    textConfig.imageUrl || ''
  );
  // footage - image
  const image = getImage(fileName, imageKey, imageUrl, sourceId);
  // layer
  const layer = getStickerLayer(
    textId,
    optionalField.width,
    optionalField.height,
    optionalField.usage,
    configure,
    objectId
  );

  const extra: Record<string, any> = {
    source,
  };
  if (textConfig.id && backConfg) {
    // 是否存在贴图
    extra.stickerId = backConfg.sourceId;
    Object.assign(image, { extra });
  }

  const resources = {
    [textId]: textFontage,
  };

  const optionalFields = {
    [styleId]: optionalField,
  };

  const cmp = createCmp({
    layer,
    resources,
    textImageResources: {
      [sourceId]: image,
    },
    themeInfo: {
      optionalFields,
    } as ThemeDetailItem,
    textBackId: sourceId,
  });

  return cmp;
}

interface CreateStickerParam {
  stickerConfig: Partial<StickerDetail>;
  source: 'network' | 'material';
  configure: Configure;
}
/** 生成贴纸 */
export function createSticker(param: CreateStickerParam) {
  const { stickerConfig, source, configure } = param;
  const sourceId = `image-${randomString()}-web`;
  const objectId = `layer-${randomString()}-web`;
  const { imageKey, fileName, imageUrl } = getImageInfo(
    stickerConfig.imageUrl || ''
  );

  // footage - image
  const image = getImage(fileName, imageKey, imageUrl, sourceId);

  // layer
  const layer = getStickerLayer(
    sourceId,
    stickerConfig.width || 0,
    stickerConfig.height || 0,
    8,
    configure,
    objectId
  );
  const extra: Record<string, any> = {
    source,
    stickerId: '',
  };
  if (stickerConfig.id) {
    // 是否存在贴图
    extra.stickerId = stickerConfig.id;
  }
  Object.assign(image, { extra });
  const cmp = createImage(layer, image);
  return cmp;
}
