import { useDrag, useDrop } from 'react-dnd';
import cx from 'classnames';

import assets from '@/assets';
import { app } from '@/store';

import Style from './style.module.less';

interface Item {
  id: string;
  originalIndex: number;
}

export interface ItemProps {
  item: Material;
  id: string;
  index: number;
  uploading: boolean;
  findItem: (id: string) => { index: number };
  moveItem: (id: string, to: number) => void;
  previewItem: (item: Material) => void;
}

function MaterialItem(props: ItemProps) {
  const { materials } = app.activedScene;
  const { item, id, index, uploading, findItem, moveItem, previewItem } = props;

  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'ITEM',
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppendId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppendId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveItem]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'ITEM',
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          moveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, moveItem]
  );

  const onMaterialRemove = (index: number) => {
    const newList: Material[] = materials.filter(
      (_: Material, i) => i !== index
    );

    app.activedScene.update({ materials: newList });
  };

  const onVolumeClick = (index: number) => {
    const newList: Material[] = materials.map((item: Material, i: number) =>
      i === index ? { ...item, videoMute: !item.videoMute } : item
    );
    app.activedScene.update({ materials: newList });
  };

  const getItemStyle = (item: Material) => ({
    backgroundImage: `url("${item.thumbUrl || item.cover || item.url || ''}"`,
    cursor: uploading ? 'default' : 'pointer',
  });

  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={node => drag(drop(node))}
      className={cx(Style.boxCard, { [Style.uploading]: uploading })}
      style={{ ...getItemStyle(item), width: 88, height: 88, opacity }}
      onClick={() => {
        previewItem(item);
      }}
    >
      {/* 删除按钮  */}
      {!uploading && (
        <img
          className={Style.boxCardClose}
          src={assets.images.doc['pic_icon_close.png']}
          alt=""
          onClick={e => {
            e.stopPropagation();
            onMaterialRemove(index);
          }}
        />
      )}
      {/* <!-- placeholder --> */}
      {!!item.placeholder && (
        <img
          className={Style.boxCardPlaceholder}
          src={assets.images.doc['doc_icon_loading.png']}
          alt=""
        />
      )}
      {item.type === 'video' && (
        <>
          {/* <!-- 视频播放按钮 --> */}
          <img
            className={Style.boxCardVideo}
            src={assets.images.doc['edit_icon_play.png']}
            alt=""
          />
          {/* <!-- 音量控制 --> */}
          <img
            className={Style.boxCardVolume}
            src={
              item.videoMute
                ? assets.images.doc['video_icon_volume_mute.png']
                : assets.images.doc['video_icon_volume_def.png']
            }
            alt=""
            onClick={e => {
              e.stopPropagation();
              onVolumeClick(index);
            }}
          />
        </>
      )}
    </div>
  );
}

export default MaterialItem;
