/** 媒体配置 */
import {
  getBodyStyleList,
  getHeadStyleList,
  getUserBigHeadList,
} from '@/api/doc';
import { UserBigHeadItem, BodyStyleItem, HeadStyleItem } from '@/types/doc';
import { EFFECT_TASK_RESULT_ERROR } from './types';

export const mediaConstraints = {
  audio: true,
  video: {
    width: 480,
    height: 480,
  },
};

export const CREATE_DIGITAL_IMAGE_ERROR_TIP: { [key: number]: string } = {
  905: '特效处理异常',
  913: '服务端处理异常',
  914: '识别不到人脸',
  915: '讲话语音不符合要求',
  916: '包含多个人脸',
  917: '人脸五官不完整',
};

export type Item = BodyStyleItem | HeadStyleItem | UserBigHeadItem;

export enum TabType {
  head = 'head',
  headStyle = 'headStyle',
  bodyStyle = 'bodyStyle',
}

export interface HeadImageInfo {
  /** 图片的宽 */
  width: number;
  /** 图片的高 */
  height: number;
  /** 下颚在图片中的x坐标 */
  chinPointX: number;
  /** 下颚在图片中的y坐标 */
  chinPointY: number;
}

export const TBA_ITEMS = [
  {
    label: '选择头像',
    key: TabType.head,
    apiFn: getUserBigHeadList, // 请求接口
    coverUrlKey: 'previewImageUrl', // 封面字段
    defaultFirst: true, // 默认选中第一个吗？
  },
  {
    label: '头像风格',
    key: TabType.headStyle,
    apiFn: getHeadStyleList,
    coverUrlKey: 'thumbnailUrl',
    defaultFirst: false,
  },
  {
    label: '身体风格',
    key: TabType.bodyStyle,
    apiFn: getBodyStyleList,
    coverUrlKey: 'coverUrl',
    defaultFirst: true,
  },
];

/** 生成预览图的尺寸 */
export const PREVIEW_WIDTH = 680;
export const PREVIEW_HEIGHT = 908;

/** 设计稿信息 */
export const DESIGN_INFO = {
  width: 384, // 设计稿尺寸
  height: 512,
  headHidht: 280, // 设计稿中的头部信息
  headWidth: 280,
  headX: 52,
  headY: 10,
};

/** 下颚在设计稿的位置 */
export const LOWER_JAW_INFO_IN_DESIGN = {
  x: 192,
  y: 290,
};

export const SAVE_HEAD_FIGURE_RESULT_ERROR_TIP: { [key: number]: string } = {
  1201: '头像不存在',
  1202: '身体不存在',
  1203: '特效不存在',
  1204: '创建特效任务失败',
  1205: '存在数字头像保存任务',
  1206: '特效处理失败',
  1207: '特效头像转存失败',
};

export function getEffectErrorTipInfo({
  openCreateBigHead,
  onAgainSelectImage,
  againCreateDigital,
}) {
  return {
    [EFFECT_TASK_RESULT_ERROR.noFace]: {
      title: '照片不包含人脸',
      content: '请选择包含人脸，且正面展示五官的照片',
      cancelText: '取消',
      onCancel: openCreateBigHead,
      okText: '重新选择',
      onOk: onAgainSelectImage,
    },
    [EFFECT_TASK_RESULT_ERROR.serverError]: {
      title: '服务异常',
      content: '服务异常，请联系客服',
      cancelText: '取消',
      onCancel: openCreateBigHead,
      okText: '重新',
      onOk: againCreateDigital,
    },
    [EFFECT_TASK_RESULT_ERROR.aiError]: {
      title: '服务异常',
      content: '服务异常，请联系客服',
      cancelText: '取消',
      onCancel: openCreateBigHead,
      okText: '重新',
      onOk: againCreateDigital,
    },
    [EFFECT_TASK_RESULT_ERROR.incompleteFace]: {
      title: '照片未识别到五官',
      content: '请选择清晰且正面展示五官的照片',
      cancelText: '取消',
      onCancel: openCreateBigHead,
      okText: '重新选择',
      onOk: onAgainSelectImage,
    },
    [EFFECT_TASK_RESULT_ERROR.moreFace]: {
      title: '照片识别到多人',
      content: '请选择本人自拍的正脸照片',
      cancelText: '取消',
      onCancel: openCreateBigHead,
      okText: '重新选择',
      onOk: onAgainSelectImage,
    },
    [EFFECT_TASK_RESULT_ERROR.avatarFailed]: {
      title: '图片审核不通过',
      content: '请选择合规图片上传',
      cancelText: '取消',
      onCancel: openCreateBigHead,
      okText: '重新选择',
      onOk: onAgainSelectImage,
    },
  };
}

export const STATEMENT_TEXT = {
  title: '录制头像声明',
  content:
    '欢迎您使用闪剪录制头像服务，请确保您录制的内容严格遵守法律法规及相关规定；请勿将卡通头像用于任何违法、违规或违背公序良俗的场景，如：侵权、搬运、低俗等。',
  confirm: '我已知晓',
};
