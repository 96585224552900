import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ENV, CONTEXT_ENV } from '@/config/hosts';

const sentryConfig = {
  dsn: '',
  integrations: [new BrowserTracing()],
};

export function initSentry() {
  switch (ENV) {
    // 仅正式环境开启sentry
    case CONTEXT_ENV.prod:
      Object.assign(sentryConfig, {
        dsn: 'https://d61f206c2bb54fb481222de00de38e27@sentry.bhbapp.cn/36',
        release: process.env.RELEASE_VERSION,
      });
      break;
    default:
      return;
  }

  // if (ENV === CONTEXT_ENV.prod) {
  //   Object.assign(sentryConfig, { tracesSampleRate: 1.0 });
  // }

  Sentry.init(sentryConfig);
}
