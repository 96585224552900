import { useRef, useState, useImperativeHandle, forwardRef, Ref } from 'react';

import { Toast, Loading } from '@bhb-frontend/lithe-ui/lib';
import { useStores } from '@/store';

import ConfirmModal from '@/components/Confirm';

import Style from './style.module.less';
import {
  MAX_VIDEO_UPLOAD_DURATION,
  VIDEO_UPLOAD_MAX,
  VIDEO_UPLOAD_TEXT,
} from '../../const';
import audioBufferToWav from '@/utils/doc';
import { MultipleUpload } from '@/utils/upload';
// import { storagePaidGuidance } from '@/utils/createVideo';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';

import { validateArrayFile } from '@/utils/file';
// 最后上传结果
interface UploadResults {
  url: string;
  duration?: number;
  coverUrl: string;
}

interface Props {
  accept?: string;

  maxSize?: number;

  /** 视频封面 */
  coverWidth?: number;
  coverHeight?: number;

  /** 是否自动上传,自动上传则返回上传后的url地址为oss地址，否则为base64(这里可以考虑返回文件) */
  autoUpload?: boolean;

  /** 是否多选 */
  multiple?: boolean;

  /** 视频转换 wav */
  transform?: boolean;

  uploadChange: (res: UploadResults[]) => void;
  onAudioDurationChange: (duration: number) => void;

  children: React.ReactNode;

  /** 上传文件类型 */
  acceptType: string[];
}

export interface UploadRefFn {
  openLoading: (text: string) => void;
  closeLoading: () => void;
}

function UploadVideo(props: Props, ref: Ref<UploadRefFn>) {
  const { user, app } = useStores();
  const {
    accept,
    maxSize = 0,
    coverWidth = 112,
    coverHeight = 112,
    autoUpload = false,
    transform = false,
    multiple = false,
    children,
    uploadChange,
    onAudioDurationChange,
    acceptType,
  } = props;

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('loading');
  // const [curProgress, setCurProgress] = useState(0);

  const uploadInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    openLoading,
    closeLoading,
  }));

  const openLoading = (text: string) => {
    !loading && setLoading(true);
    setLoadingText(text);
  };
  const closeLoading = () => {
    setLoading(false);
    setLoadingText('');
  };

  const uploadVideo = async (event: any) => {
    const file = (event.target.files as any[]) || [];
    let files = await validateArrayFile([...file], acceptType, '文件类型错误');
    if (!files) return;
    // 文档上传视频_视频大小<30M且<5分钟可上传
    let initLen = files.length;
    if (initLen === 0) {
      return;
    }
    if (transform) {
      // 格式wav转换走另外一套流程
      transformFormat(files);
      event.target.value = '';
      return;
    }

    const promises: Promise<UploadResults>[] = [];

    let res: UploadResults[] = [];

    if (autoUpload) {
      // 自动上传到oss
      if (maxSize && files[0].size > maxSize) {
        Toast.warning(`上传视频不能超过${maxSize / 1024 / 1024}M`);
      } else {
        res = await uploadOss(files);
      }
    } else {
      files = [...files].filter(
        (f: File) => f.size && f.size <= VIDEO_UPLOAD_MAX
      );
      if (initLen !== files.length) {
        Toast.warning(
          `上传视频不能超过${VIDEO_UPLOAD_TEXT}，已过滤${
            initLen - files.length
          }个视频`
        );
        initLen = files.length;
      }

      // 非直接上传到oss，文档编辑模块
      files.forEach((file: File) => {
        // 将视频信息添加到promise中
        promises.push(getVideoDetail(file));
      });

      // const loading = this.$loading({ lock: true });
      res = await Promise.all(promises);
      // loading.close();

      console.log('video', res);

      res = res.filter(
        (v: UploadResults) =>
          v.duration && v.duration <= MAX_VIDEO_UPLOAD_DURATION
      );
      if (initLen !== res.length) {
        Toast.warning(
          `上传视频不能超过${MAX_VIDEO_UPLOAD_DURATION / 60}分钟，已过滤${
            initLen - res.length
          }个视频`
        );
      }
      if (!res.length) {
        return;
      }
    }

    event.target.value = '';
    uploadChange(res);
  };

  /** MP4 转换 格式 wav */
  const transformFormat = async (files: File[]) => {
    const [file] = files;
    openLoading('处理中......');
    const fileInfo = await getVideoDetail(file);
    const { duration = 0 } = fileInfo;
    const durationInteger = Math.floor(duration);
    if (durationInteger > MAX_VIDEO_UPLOAD_DURATION) {
      Toast.error('视频时长超过5分钟，不支持上传识别');
      closeLoading();
      return;
    }
    onAudioDurationChange(duration);

    const sampleRate = 44100 / 2;
    /* eslint-disable */
    const audioContext = new (window.AudioContext ||
      // @ts-ignore
      window.webkitAudioContext)();
    /* eslint-enable */
    const offlineAudioContext = new OfflineAudioContext(
      1,
      sampleRate * duration,
      sampleRate
    );
    const soundSource = offlineAudioContext.createBufferSource();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      try {
        const videoFileAsBuffer = reader.result as ArrayBuffer;
        /* eslint-disable */
        audioContext
          .decodeAudioData(
            // @ts-ignore
            videoFileAsBuffer
          )
          .then(async decodedAudioData => {
            // @ts-ignore
            soundSource.buffer = decodedAudioData;
            soundSource.connect(offlineAudioContext.destination);
            soundSource.start();

            const renderedBuffer = await offlineAudioContext.startRendering();
            const wav = audioBufferToWav(renderedBuffer);
            const blob = new window.Blob([new DataView(wav)], {
              type: 'audio/wav',
            });
            const [fileName] = file.name.split('.');
            const wavFile = new File([blob], `${fileName}.wav`, {
              type: 'audio/wav',
            });
            if (wavFile.size > VIDEO_UPLOAD_MAX) {
              Toast.warning(
                `上传视频不能超过${VIDEO_UPLOAD_MAX / 1024 / 1024}M`
              );
              return;
            }
            transformUpload(wavFile);
          })
          .catch(() => {
            Toast.error('上传视频不能超过100M');
            closeLoading();
          });
      } catch (error) {
        console.log(`Rendering failed: ${error}`);
        Toast.error('转换失败');
        closeLoading();
        onAudioDurationChange(0);
      }
    };
  };

  /** 转换上传  */
  const transformUpload = async (file: File) => {
    const res = await uploadOss([file]);
    uploadChange(res);
  };

  // 获取本地视频信息
  const getVideoDetail = (file: any): Promise<UploadResults> =>
    new Promise(resolve => {
      const video = document.createElement('video');
      const url = URL.createObjectURL(file);
      video.src = url;
      video.setAttribute('preload', 'auto');
      video.addEventListener('loadeddata', async () => {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = coverWidth;
        canvas.height = coverHeight;
        canvas
          ?.getContext('2d')
          ?.drawImage(video, 0, 0, coverWidth, coverHeight); // 绘制canvas
        const coverUrl = canvas.toDataURL('image/jpeg'); // 转换为base64
        // app.setVideoDetails({
        //   base64: (await toBase64(file)) as string,
        //   url,
        // });

        resolve({
          url,
          duration: Number(video.duration.toFixed(2)),
          coverUrl,
        });
      });
    });

  // 上传文件
  const uploadOss = (files: File[]): Promise<any> =>
    new Promise((resolve, reject) => {
      const uploader = new MultipleUpload(files);

      uploader.addQueueScene('document');
      uploader.addQueueModule('video');

      // 上传进度
      uploader.queueProgress((percent: number) => {
        // setCurProgress(percent);
        if (transform) {
          openLoading(`上传中,进度 ${percent}%`);
        }
      });

      // 返回url
      uploader
        .upload()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        })
        .finally(() => {
          // 转换视频的loading不在此消失 避免loading 重新挂载
          !transform && closeLoading();
        });
    });

  // 触发文件选择校验
  const triggerClick = () => {
    const balanceStorage = user.videoClipRights?.balanceStorage || 0;
    if (balanceStorage <= 0) {
      // Toast.error('空间不足，建议整理您的文档及音频腾出空间');
      // 图文快剪存储空间付费引导
      // storagePaidGuidance();
      WeChatQRcodeModal.show();
      return;
    }

    // 当前场景已有文档内容时,弹框提示
    const { getAllText, captions } = app.activedScene || {};
    const audioCmp = app.activedScene.sound;
    const recordUrl = audioCmp?.tag === 'record' ? audioCmp.url : '';
    let hasText: boolean;
    if (recordUrl && captions) {
      hasText = captions.length > 0;
    } else {
      hasText = Boolean(getAllText);
    }

    if (hasText) {
      setConfirmVisible(true);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = () => {
    uploadInputRef.current?.click();
    setConfirmVisible(false);
  };

  return (
    <>
      <div className={Style.uploadBox} onClick={triggerClick}>
        <input
          ref={uploadInputRef}
          type="file"
          accept={accept}
          multiple={multiple}
          className={Style.uploadBoxInput}
          onChange={uploadVideo}
        />
        {children}
      </div>
      <ConfirmModal
        visible={confirmVisible}
        title="确定使用录音提取吗？"
        content="录音会自动识别成文字，现有文字内容将会被替换"
        onOk={handleConfirm}
        onCancel={() => {
          setConfirmVisible(false);
        }}
      />
      <Loading spinning={loading} fullScreen tip={loadingText} />
    </>
  );
}
export default forwardRef<UploadRefFn, Props>(UploadVideo);
