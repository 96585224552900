import { observer } from 'mobx-react';
import { BackgroundStruc } from '@/models/CmpStruc';
import { material } from '@/store';
import { MaterialEnum } from '@/types/material';
import style from './Background.module.less';

interface BackgroundSettingProps {
  model: BackgroundStruc;
}

function BackgroundSetting(_props: BackgroundSettingProps) {
  return (
    <div className={style.background}>
      <div
        onClick={() => material.changeMenu(MaterialEnum.BACK)}
        className={style['replace-back']}
      >
        替换背景
      </div>
    </div>
  );
}

export default observer(BackgroundSetting);
