import { useState, useEffect, useRef } from 'react';
// import cx from 'classnames';
import { Toast } from '@bhb-frontend/lithe-ui';
import MaterialContent from '@/components/Material/MaterialContent';
import GetListWrapper from '@/components/Material/GetListWrapper';
import {
  getDiagitalManCategory,
  getDigitalManById,
  // getBigHeadList,
  getSelfDigitalMan,
  getDigitalManSearch,
} from '@/api/material';

import {
  // getUserBigHeadList,
  editTipConfig,
  cancelEffectTaskById,
  createEffectTask,
  bigHeadAudit,
} from '@/api/doc';

import {
  PresetType,
  Category,
  GetDigitalManParmas,
  DigitalManDetail,
} from '@/types/material';
// import { ListQuery } from '@/types/api';
import DigitaiManContent from './DigitaiManContent';
import type { DigitaiManContentRef } from './DigitaiManContent';
import {
  SELF_CATEGORY,
  // DIGITAL_MAN_TABS,
  DigitalManType,
} from '../../const';
import { user, useStores } from '@/store';
import AddBigHeadModal from './modal/AddBigHeadModal';
import CreateBigHeadModal from './modal/CreateBigHeadModal';
import RecordingBigHeadModal from './modal/RecordingBigHeadModal';
import MakingBigHeadModal from './modal/MakingBigHeadModal';
import CountDownModal from '@/components/CountDownModal';
import ConfirmModal from '@/components/Confirm';
import Uploader from '@/utils/upload';
import { ModalVisible, StatementStatus } from './types';
import { SocketResponse, BigHeadEffectsResult } from '@/types/socket';
import { TaskType } from '@/types/doc';
import { SOCKET_EVENTS } from '@/constants/SocketEvents';
import {
  CREATE_DIGITAL_IMAGE_ERROR_TIP,
  getEffectErrorTipInfo,
  STATEMENT_TEXT,
} from './config';
import socket from '@/core/socket';
import Style from './style.module.less';
import SearchList from '../../components/SearchList';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';
import { getUploadImage } from '@/utils/file';

function DigtalMan() {
  const {
    // appConfig: { config },
    user: { tipConfig },
  } = useStores();

  const [
    activeDigitalMan,
    // setActiveDigitalMan
  ] = useState(DigitalManType.cloneMan);

  /** 弹框可见状态 */
  const [modalVisible, setModalVisible] = useState<ModalVisible>(() => ({
    /** 增加数字头像 */
    addBigHead: false,
    /** 新创建数字头像 */
    createBigHead: false,
    /** 创建数字头像失败提示 */
    createdBigHeadError: false,
    /** 制作头像中 */
    makingBigHead: false,
    /** 录制 */
    recordingBigHead: false,
    /** 录制声明 */
    recordingStatement: false,
  }));

  /** 克隆数字人分类 */
  const [cloneManCategorys, setCloneManCategorys] = useState<Category[]>([]);
  /** 克隆数字人 默认选择的分类 */
  const [acitveCloneManCategory, setAcitveCloneManCategory] = useState('');
  /** 我的分类请求参数 */
  const selfPayloadRef = useRef({ type: PresetType.figure2d });
  /** 普通分类请求参数 */
  const payloadRef = useRef({ type: PresetType.presetFigure });
  /** input元素 */
  const inputRef = useRef<HTMLInputElement>(null);
  /** 当前制作数字人的图片地址，用于重试操作 */
  const curDigitalManImageUrl = useRef<string>('');
  /** 任务id */
  const taskId = useRef<string>('');
  /** 是在关闭创建框时，打开增加数字头像框*/
  const isOpenAddBigHeadForCloseCreate = useRef<boolean>(true);
  /** 错误码 */
  const errorCode = useRef<number>(0);

  const bigHeadContentRef = useRef<DigitaiManContentRef | null>(null);

  /** 初始化所有Modal */
  const initModalVisible = (): ModalVisible => {
    const visibles: ModalVisible = {};
    Object.keys(modalVisible).forEach((key: string) => {
      visibles[key] = false;
    });
    return visibles;
  };

  /** 关闭弹窗 */
  const closeModal = () => {
    const visibles = initModalVisible();
    setModalVisible(visibles);
  };

  /** 打开弹窗 */
  const openModal = (key: keyof ModalVisible) => {
    const visibles = initModalVisible();
    setModalVisible({ ...visibles, [key]: true });
  };

  const openCreateBigHead = () => {
    openModal('createBigHead');
  };

  /** 打开录制框 */
  const openRecording = () => {
    if (tipConfig.recordingAvatarStatement === StatementStatus.enable) {
      openModal('recordingStatement');
      return;
    }
    openModal('recordingBigHead');
  };

  /** 选择重新图片 */
  const onAgainSelectImage = () => {
    openCreateBigHead();
    inputRef.current?.click();
  };

  /** 重新发起数字头像任务 */
  const againCreateDigital = async () => {
    openModal('makingBigHead');
    const { data } = await createEffectTask({
      type: TaskType.slideFigure,
      fileUrl: curDigitalManImageUrl.current,
    });
    taskId.current = data.taskId;
  };

  /** 错误码提示信息 */
  const CREATE_DIGITAL_IMAGE_ERROR_INFO = useRef(
    getEffectErrorTipInfo({
      openCreateBigHead,
      onAgainSelectImage,
      againCreateDigital,
    })
  );

  /** 取消ai制作 */
  const onCancelMakingBigHead = () => {
    cancelEffectTaskById([taskId.current]);
    taskId.current = '';
    openCreateBigHead();
  };

  /** 关闭创建框 */
  const closeCreateBigHeadModal = () => {
    if (isOpenAddBigHeadForCloseCreate.current) {
      openModal('addBigHead');
      return;
    }
    closeModal();
    isOpenAddBigHeadForCloseCreate.current = true;
  };

  /** 获取克隆数字分类 */
  const getCloneManCategory = async () => {
    const { data } = await getDiagitalManCategory();
    const results = [{ ...SELF_CATEGORY }, ...data.results];
    setAcitveCloneManCategory(results[1]?.id || '');
    setCloneManCategorys(results);
  };

  useEffect(() => {
    getCloneManCategory();
  }, []);

  /** 添加克隆人 */
  const addCloneMan = () => {
    // window.open(config.figure2dContactLink, '_blank');
    WeChatQRcodeModal.show({ tips: '定制数字人，微信扫码联系商务' });
  };

  /** 添加大头数字人 */
  // const addBigHead = async () => {
  //   const { data } = await getUserBigHeadList({ pageSize: 1 });
  //   if (data.results.length) {
  //     openModal('addBigHead');
  //     return;
  //   }
  //   isOpenAddBigHeadForCloseCreate.current = false;
  //   openCreateBigHead();
  // };

  const getPayload = (key: string) => {
    if (key === SELF_CATEGORY.id) {
      return selfPayloadRef.current;
    }
    return payloadRef.current;
  };

  /** 我已知晓 */
  const iKnow = async () => {
    // openRecording();
    openModal('recordingBigHead');
    // openCreateBigHead();
    // isOpenAddBigHeadForCloseCreate.current = false;
    await editTipConfig({ recordingAvatarStatement: StatementStatus.disable });
    user.getUser();
  };

  /** 上传图片制作数字人 */
  const uploadImagaForCreateDigital = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const orgFile = e.target.files?.[0] as File;
    // 手动校验类型
    const imageFile = await getUploadImage(orgFile, ['jpg', 'jpeg', 'png']);
    if (!imageFile) return;
    e.target.value = '';
    // openModal('makingBigHead');
    const uploader = new Uploader(imageFile);
    const { url } = await uploader.upload();
    try {
      await bigHeadAudit(url);
      openModal('makingBigHead');
      curDigitalManImageUrl.current = url;
      const { data } = await createEffectTask({
        type: TaskType.slideFigure,
        fileUrl: url,
      });
      taskId.current = data.taskId;
    } catch (error: any) {
      console.log(error);
      errorCode.current = error.body.error;
      openModal('createdBigHeadError');
    }
  };

  const socketListener = (res: SocketResponse<BigHeadEffectsResult>) => {
    const { event, data } = res;
    const { taskId: id, type, status, code } = data;

    if (
      event !== SOCKET_EVENTS.EFFECT_TASK_RESULT ||
      !id ||
      taskId.current !== id ||
      !type ||
      type !== TaskType.slideFigure
    )
      return;

    taskId.current = '';
    if (status === 'failed') {
      const isNeedErrorDialog = code in CREATE_DIGITAL_IMAGE_ERROR_INFO.current;
      if (isNeedErrorDialog) {
        errorCode.current = code;
        openModal('createdBigHeadError');
        return;
      }
      const msg = CREATE_DIGITAL_IMAGE_ERROR_TIP[code] || '未知错误';
      Toast.warning(msg);
      openCreateBigHead();
      return;
    }
    openModal('addBigHead');
  };

  /** 添加大头数字人成功 */
  const addBigHeadSuccess = () => {
    closeModal();
    bigHeadContentRef.current?.initList?.();
  };

  useEffect(() => {
    socket.on(socketListener);
    return () => {
      socket.off(socketListener);
    };
  }, []);

  /** 克隆数字人 */
  const cloneManChildren = (
    <MaterialContent
      categorys={cloneManCategorys.map(({ id, name }) => ({
        key: id,
        label: name,
        apiFn: id === SELF_CATEGORY.id ? getSelfDigitalMan : getDigitalManById,
        payload: getPayload(id),
      }))}
      activeTab={acitveCloneManCategory}
    >
      <GetListWrapper<GetDigitalManParmas, DigitalManDetail>>
        <DigitaiManContent
          onAdd={addCloneMan}
          addItemName="定制数字人"
          activeDigitalMan={activeDigitalMan}
        />
      </GetListWrapper>
    </MaterialContent>
  );

  // /** 大头数字人 */
  // const bigHeadChildren = (
  //   <GetListWrapper<ListQuery, DigitalManDetail> apiFn={getBigHeadList}>
  //     <DigitaiManContent
  //       ref={bigHeadContentRef}
  //       onAdd={addBigHead}
  //       addItemName="添加数字头像"
  //       className={Style['big-head-content']}
  //       activeDigitalMan={activeDigitalMan}
  //       isShowMore
  //     />
  //   </GetListWrapper>
  // );

  // const tabChildren = {
  //   [DigitalManType.cloneMan]: cloneManChildren,
  //   [DigitalManType.bigHead]: bigHeadChildren,
  // };

  // const renderTabBar = () => (
  //   <div className={Style['digital-type']}>
  //     {DIGITAL_MAN_TABS.map(item => (
  //       <div
  //         onClick={() => {
  //           setActiveDigitalMan(item.key);
  //         }}
  //         className={cx(Style['digital-type-item'], {
  //           [Style['digital-type-active']]: activeDigitalMan === item.key,
  //         })}
  //         key={item.key}
  //       >
  //         {item.label}
  //       </div>
  //     ))}

  //     <Tooltips placement="bottom" tips="数字人伦理">
  //       <div
  //         onClick={() => {
  //           window.open(config.figureEthicLink, '_blank');
  //         }}
  //         className={Style['digital-man-tip-box']}
  //       >
  //         <i
  //           className={cx(
  //             'iconfont icon-a-tuwen_icon_tips1x',
  //             Style['digital-man-tip-icon']
  //           )}
  //         />
  //       </div>
  //     </Tooltips>
  //   </div>
  // );

  const errorInfo = CREATE_DIGITAL_IMAGE_ERROR_INFO.current[errorCode.current];

  return (
    <>
      <SearchList
        className={Style['search-box']}
        placeholder="搜索数字人"
        apiFn={getDigitalManSearch}
      >
        <DigitaiManContent isSearch />
      </SearchList>
      {/* <Tabs
        activeKey={activeDigitalMan}
        tabPosition="top"
        renderTabBar={renderTabBar}
        items={DIGITAL_MAN_TABS.map(digitalMan => ({
          ...digitalMan,
          children: tabChildren[digitalMan.key],
        }))}
      /> */}

      {cloneManChildren}

      {/* 添加数字人框 */}
      {modalVisible.addBigHead && (
        <AddBigHeadModal
          addSuccess={addBigHeadSuccess}
          openCreate={openCreateBigHead}
          onClose={closeModal}
          visible
        />
      )}

      {/* 创建框 */}
      {modalVisible.createBigHead && (
        <CreateBigHeadModal
          openRecording={openRecording}
          openFileInput={() => {
            inputRef.current?.click();
          }}
          onClose={closeCreateBigHeadModal}
          visible
        />
      )}

      {/* 录制框 */}
      {modalVisible.recordingBigHead && (
        <RecordingBigHeadModal
          visible
          recordingSuccess={() => {
            openModal('addBigHead');
          }}
          onClose={openCreateBigHead}
        />
      )}

      {/* ai 生成中框 */}
      {modalVisible.makingBigHead && (
        <MakingBigHeadModal onCancel={onCancelMakingBigHead} visible />
      )}

      {/* 录制声明框 */}
      {modalVisible.recordingStatement && (
        <CountDownModal
          title={STATEMENT_TEXT.title}
          content={STATEMENT_TEXT.content}
          visible
          onClose={iKnow}
        />
      )}

      {/* 创建数字人错误提示框 */}
      {modalVisible.createdBigHeadError && (
        <ConfirmModal {...errorInfo} visible />
      )}

      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={uploadImagaForCreateDigital}
      />
    </>
  );
}

export default DigtalMan;
