/**
 * 16进制颜色转换成rgba
 */
export function hexToRgba(hex?: string, opacity = 1) {
  if (!hex) return '';
  const r = parseInt(`0x${hex.slice(1, 3)}`, 16);
  const g = parseInt(`0x${hex.slice(3, 5)}`, 16);
  const b = parseInt(`0x${hex.slice(5, 7)}`, 16);
  return `rgba(${r},${g},${b},${opacity})`;
}
