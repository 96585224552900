import { Dialog } from '@bhb-frontend/lithe-ui';

import Style from './style.module.less';

export interface CountDownProps {
  visible: boolean;
  title?: string;
  content?: string;
  okText?: React.ReactNode | null;
  cancelText?: React.ReactNode | null;
  loading?: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  width?: number;
}

export default function ConfirmMoal(props: CountDownProps) {
  const {
    visible,
    title,
    okText = '确定',
    cancelText = '取消',
    loading,
    content,
    onOk,
    onCancel,
    width = 335,
  } = props;

  return (
    <Dialog
      visible={visible}
      onCancel={onCancel}
      timeout={0}
      isShowTitle={false}
      title=""
      closable={false}
      maskStyle={{ backdropFilter: 'none' }}
      contentClassName={Style.confirmModal}
      contentStyle={{ padding: '20px 38px 25px' }}
      width={width}
      height="auto"
      footer={null}
    >
      {loading && loading}
      {title && <h3 className={Style.confirmModalTitle}>{title}</h3>}
      {content && <p className={Style.confirmModalContent}>{content}</p>}

      <div className={Style.confirmModalFooter}>
        {cancelText && (
          <div className={Style.confirmModalFooterCancel} onClick={onCancel}>
            {cancelText}
          </div>
        )}
        {okText && (
          <div className={Style.confirmModalFooterOk} onClick={onOk}>
            {okText}
          </div>
        )}
      </div>
    </Dialog>
  );
}
