import './style.less';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface GoodsItemProps {
  goodsCover?: string;
  memberIcon?: string;
  title?: ReactNode;
  desc?: string;
  className?: string;
}

const GoodsItem = (props: GoodsItemProps) => {
  const { goodsCover, memberIcon, title, desc, className } = props;

  return (
    <div className={classNames('goods-container', className)}>
      <div className="goods-cover-box">
        {goodsCover && (
          <img
            className={memberIcon ? 'goods-cover' : 'image-icon'}
            src={goodsCover}
            alt="cover"
          />
        )}

        {memberIcon && (
          <img className="member-icon" src={memberIcon} alt="icon" />
        )}
      </div>

      <div className="goods-info-box">
        {title && <span className="goods-title">{title}</span>}

        {desc && <span className="goods-desc">{desc}</span>}
      </div>
    </div>
  );
};

export default GoodsItem;
