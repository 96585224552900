import { useRef, useEffect, useState } from 'react';
import { Modal, Button, Toast, Spin, Tooltips } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import cx from 'classnames';
import { useRefState } from '@/hooks/useRefState';
import { cancelEffectTaskById, createEffectTask } from '@/api/doc';
import { TaskType } from '@/types/doc';
import { SocketResponse, BigHeadEffectsResult } from '@/types/socket';
import Uploader from '@/utils/upload';
import { mediaConstraints, CREATE_DIGITAL_IMAGE_ERROR_TIP } from '../../config';
import { recordStatus } from '@/constants/RecordingStatus';
import assets from '@/assets/images';
import { useStores } from '@/store';
import { SOCKET_EVENTS } from '@/constants/SocketEvents';
import socket from '@/core/socket';
import style from './RecordingBigHeadModal.module.less';

const errorTip = {
  openError: '打开媒体设备失败',
  notSupported: '当前浏览器不支持录制视频',
};

interface RecordingBigHeadModelProps extends ModalProps {
  recordingSuccess: () => void;
}

export default function RecordingBigHeadModel(
  props: RecordingBigHeadModelProps
) {
  const { onClose, recordingSuccess, ...otherProps } = props;
  const {
    appConfig: { config },
  } = useStores();

  /** 媒体流 */
  const streamRef = useRef<MediaStream | null>(null);
  /** 录制的video标签 */
  const recordingVideoRef = useRef<HTMLVideoElement>(null);
  /** 预览视频video标签 */
  const previewVideoRef = useRef<HTMLVideoElement>(null);
  /** 录制器 */
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  /** 浏览器支持录制视频格式集合 */
  const supportedMimeTypesRef = useRef<string[]>([]);
  /** 是否已经打开媒体 */
  const isOpenedMediaRef = useRef<boolean>(false);
  /** 定时器 */
  const timer = useRef<number>(0);
  /** 是否取消 */
  const isCancel = useRef<boolean>(false);
  /** 任务id */
  const taskId = useRef<string>('');
  /** 录制时间 */
  const [recordTime, setRecordTime, getRecordTimeRef] = useRefState(0);
  /** 视频blob文件 */
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  /** 是否在录制中 */
  const [isOpeningMedia, setIsOpeningMedia] = useState<boolean>(false);
  /** 是否预览中 */
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  /** 当前录制的状态 */
  const [recordingStatus, setRecordingStatus] = useState(
    recordStatus.BEFORE_RECORDING
  );

  /** 是否是预览模式 */
  const isPreview = [
    recordStatus.AFTER_RECORDING,
    recordStatus.SUBMITING_RECORDING,
  ].includes(recordingStatus);

  /**
   * 播放视频
   *  */
  const palyVideo = () => {
    previewVideoRef.current?.play();
    setIsPreviewing(true);
  };

  /**
   * 暂停视频
   * */
  const pauseVideo = () => {
    previewVideoRef.current?.pause();
    setIsPreviewing(false);
  };

  /**
   * 获取浏览器支持录制的类型
   */
  const getSupportedMimeTypes = () => {
    const possibleTypes = ['video/webm', 'video/mp4'];
    return possibleTypes.filter(mimeType =>
      MediaRecorder.isTypeSupported(mimeType)
    );
  };

  /**
   * 关闭摄像
   * */
  const stopStreamedVideo = () => {
    if (!streamRef.current) return;
    const tracks: MediaStreamTrack[] = streamRef.current?.getTracks();
    tracks?.forEach(track => {
      track.stop();
    });
  };

  /**
   * 创建定时器
   *  */
  const createTimer = () => {
    timer.current = window.setInterval(() => {
      setRecordTime(getRecordTimeRef() + 1);
      if (getRecordTimeRef() >= 60) clearTimer();
    }, 1000);
  };

  /**
   *  清楚定时器
   *  */
  const clearTimer = () => {
    if (timer.current) {
      window.clearInterval(timer.current);
      setRecordTime(0);
      setRecordingStatus(recordStatus.AFTER_RECORDING);
      stopStreamedVideo();
    }
  };

  /** 开始录像 */
  const startRecording = () => {
    if (!isOpenedMediaRef.current) return;
    setRecordingStatus(recordStatus.RECORDING);
    mediaRecorderRef.current?.start(1000 * 60);
    createTimer();
  };

  /**
   * 重新录制
   *  */
  const again = () => {
    setRecordingStatus(recordStatus.BEFORE_RECORDING);
    setVideoBlob(null);
    pauseVideo();
    initMedia();
  };

  /**
   * 取消录制
   */
  const cancelRecording = () => {
    setRecordingStatus(recordStatus.AFTER_RECORDING);
    isCancel.current = true;
    if (!taskId.current) return;
    cancelEffectTaskById([taskId.current]);
    taskId.current = '';
  };

  /**
   *  暂停录制
   *  */
  const stopRecording = () => {
    // 最低录制10s
    if (recordTime < 10) return;
    mediaRecorderRef.current?.stop();
    clearTimer();
  };

  /**
   * 提交录制
   */
  const submitRecording = async () => {
    if (!videoBlob) return;
    setRecordingStatus(recordStatus.SUBMITING_RECORDING);
    isCancel.current = false;
    const type = supportedMimeTypesRef.current[0];
    const suffix = type.split('/')[1];
    const file = new File([videoBlob], `${new Date()}.${suffix}`, {
      type,
    });
    const uploader = new Uploader(file);
    const { url } = await uploader.upload();
    /** 提交过程中可能存在点击取消 */
    if (isCancel.current) {
      isCancel.current = false;
    }
    const { data } = await createEffectTask({
      type: TaskType.slideFigure,
      fileUrl: url,
    });
    taskId.current = data.taskId;
  };

  /**
   *  打开媒体错误
   * */
  const onMediaError = (tip: string = errorTip.openError) => {
    Toast.error(tip);
  };

  /**
   * 打开媒体成功
   *  */
  const onMediaSuccess = (stream: MediaStream) => {
    if (!recordingVideoRef.current) return;
    isOpenedMediaRef.current = true;
    streamRef.current = stream;
    recordingVideoRef.current.srcObject = stream;
    recordingVideoRef.current.onloadedmetadata = () => {
      mediaRecorderRef.current = new window.MediaRecorder(stream, {
        mimeType: supportedMimeTypesRef.current[0],
      });
      mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
        const blob = event.data;
        if (blob && blob.size > 0) {
          setVideoBlob(blob);
          if (previewVideoRef.current) {
            previewVideoRef.current.src = URL.createObjectURL(blob);
            palyVideo();
          }
        }
      };
    };
  };

  /**
   *  初始化媒体
   *  */
  const initMedia = () => {
    supportedMimeTypesRef.current = getSupportedMimeTypes();
    if (
      !navigator.mediaDevices.getUserMedia ||
      !window.MediaRecorder ||
      !supportedMimeTypesRef.current.length
    ) {
      onMediaError(errorTip.notSupported);
      return;
    }
    setIsOpeningMedia(true);
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(onMediaSuccess)
      .catch(() => onMediaError())
      .finally(() => {
        setIsOpeningMedia(false);
      });
  };

  const sockerCallback = (res: SocketResponse<BigHeadEffectsResult>) => {
    const { event, data } = res;
    const { status, taskId: id, type, code } = data;
    if (
      event !== SOCKET_EVENTS.EFFECT_TASK_RESULT ||
      !taskId ||
      taskId.current !== id ||
      !type ||
      type !== TaskType.slideFigure
    )
      return;

    taskId.current = '';
    if (status === 'failed') {
      setRecordingStatus(recordStatus.AFTER_RECORDING);
      const msg = CREATE_DIGITAL_IMAGE_ERROR_TIP[code] || '未知错误';
      onMediaError(msg);
    }

    recordingSuccess();
  };

  useEffect(() => {
    socket.on(sockerCallback);
    initMedia();

    return () => {
      stopStreamedVideo();
      socket.off(sockerCallback);
    };
  }, []);

  /**
   * 暗色按钮
   * */
  const renderDarkBtn = () => {
    const time = `00:${recordTime > 9 ? recordTime : `0${recordTime}`}`;
    const info = {
      [recordStatus.RECORDING]: {
        text: `结束: ${time}`,
        handle: stopRecording,
        disabled: recordTime < 10,
      },
      [recordStatus.AFTER_RECORDING]: {
        text: '重录',
        handle: again,
      },
      [recordStatus.SUBMITING_RECORDING]: {
        text: '取消',
        handle: cancelRecording,
      },
    };
    const currentInfo = info[recordingStatus];
    if (!currentInfo) return null;
    return (
      <Button
        onClick={() => {
          currentInfo.handle?.();
        }}
        className={cx(style.btn, style.dark, {
          [style.disabled]: currentInfo.disabled,
        })}
      >
        {currentInfo.text}
      </Button>
    );
  };

  /**
   * 亮色按钮
   */
  const renderBrightBtn = () => {
    const info = {
      [recordStatus.BEFORE_RECORDING]: {
        text: '录制',
        handle: startRecording,
      },
      [recordStatus.AFTER_RECORDING]: {
        text: '完成',
        handle: submitRecording,
      },
      [recordStatus.SUBMITING_RECORDING]: {
        text: '加载中',
        loading: true,
      },
    };
    const currentInfo = info[recordingStatus];
    if (!currentInfo) return null;
    return (
      <Button
        onClick={() => {
          currentInfo.handle?.();
        }}
        className={cx(style.btn, style.bright)}
      >
        {currentInfo.loading && (
          <span
            className={cx(
              'iconfont',
              'icon-a-theme_icon_loading2x',
              style.loading
            )}
          />
        )}
        {currentInfo.text}
      </Button>
    );
  };

  const renderVideo = () => (
    <>
      <span
        className={cx({
          [style.hide]: !isPreview,
        })}
      >
        <video
          onEnded={pauseVideo}
          autoPlay
          ref={previewVideoRef}
          className={cx(style.video)}
          onClick={pauseVideo}
        />
        {!isPreviewing && (
          <img
            onClick={palyVideo}
            className={cx(style.image, style['play-icon'])}
            src={assets.theme['recording_icon_play.png']}
            alt=""
          />
        )}
      </span>

      <span
        className={cx({
          [style.hide]: isPreview,
        })}
      >
        <video
          muted
          autoPlay
          ref={recordingVideoRef}
          className={cx(style.video, style['recording-video'])}
        />
        <img
          className={style.image}
          src={assets.theme['big-head.png']}
          alt=""
        />
      </span>
    </>
  );

  return (
    <Modal
      {...otherProps}
      width={440}
      height={708}
      contentClassName={style['record-big-head-modal']}
      closable={false}
    >
      <div className={style.header}>
        <span className={style.text}>录制自己</span>
        <i
          onClick={onClose}
          className={cx(
            'iconfont',
            'icon-a-common_icon_close_grey2x1',
            style['icon-close']
          )}
        />
      </div>

      <div className={style.main}>
        <Spin spinning={isOpeningMedia}>
          <div className={style['video-box']}>{renderVideo()}</div>
        </Spin>

        <div className={style.copywriting}>
          <img
            className={style['phoneme-image']}
            src={config.phonemeImageLink}
            alt=""
          />
        </div>

        <div className={style['btn-box']}>
          {renderDarkBtn()}
          {renderBrightBtn()}
        </div>

        {/* <p className={style['recording-tip']}>录制时间控制在10-60s</p> */}
        <div
          style={{
            display:
              recordingStatus === recordStatus.BEFORE_RECORDING
                ? 'block'
                : 'none',
          }}
          className={style['recording-tip']}
        >
          <Tooltips
            style={{
              whiteSpace: 'pre-wrap',
              backgroundColor: '#fff',
              color: '#191919',
              fontSize: 12,
              textAlign: 'left',
            }}
            placement="bottom"
            tips={
              <div className={style['recording-tip-content']}>
                <p>
                  通过录制自己的方式生成头像，可以获得最佳的视频效果呈现。为获得最佳效果，请按要求完成录制
                </p>
                <p>1.请确保背景整洁</p>
                <p>2.录制环境尽可能无噪音</p>
                <p>3.请正视摄像头，并确保脸部居中</p>
                <p>4.请使用正常音量和语速朗读页面上方的文字</p>
                <p>5.录制时屏幕内请不要出现其他肢体语言（如手部动作等）</p>
              </div>
            }
          >
            <p className={style['recording-tip-text']}>
              <span>录制须知</span>
              <i
                style={{ fontSize: '16px', verticalAlign: 'top' }}
                className="iconfont icon-a-zhibo_time_tips2x"
              />
            </p>
          </Tooltips>
        </div>
      </div>
    </Modal>
  );
}
