/* eslint-disable consistent-return */
import { useEffect, useState, useRef } from 'react';
import { Toast } from '@bhb-frontend/lithe-ui';
import AudioPlayer, { AudioPlayerType } from '@bhb-frontend/audio-player';
import { ListQuery } from '@/types/api';
import {
  MusicDetail,
  TabsCategory,
  MyMusicDetail,
  AudioQuery,
  MaterialReqParams,
} from '@/types/material';
import {
  getCategoryMusicById,
  getMusicCategory,
  getMaterialMusicList,
  addAudio,
  removeDocMaterial,
} from '@/api/material';
import GetListWrapper, {
  ListWrapperRefFn,
} from '@/components/Material/GetListWrapper';
import MaterialContent from '@/components/Material/MaterialContent';
import CategoryWrapper from '@/components/Material/CategoryWrapper';
import MusicList from './MusicList';

import Style from './style.module.less';
import { MusicTabTypes, MUSIC_TAB_TYPES } from './const';
import { useStores } from '@/store';
import { MAX_AUDIO_NUMBER } from '@/constants/Audio';
import Uploader from '@/utils/upload';
import ConfirmMoal from '@/components/Confirm';
import UploadProgress from '@/components/Material/UploadProgress';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';
import { getUploadImage } from '@/utils/file';
import { CmpTypeEnum } from '@/constants/CmpType';

function Music() {
  const { app, user } = useStores();
  const balanceStorage = user.videoClipRights?.balanceStorage || 0;
  /** 音乐类型 */
  const [activeMusicType, setActiveMusicType] = useState<MusicTabTypes>(
    MusicTabTypes.Network
  );
  /** 配音分类 */
  const [musicCategorys, setMusicCategorys] = useState<TabsCategory[]>([]);
  /** 默认选择的分类 */
  const [activeCategory, setActiveCategory] = useState('');
  /** 我的资源总数 */
  const [total, setTotal] = useState(0);
  /** 删除音乐二次确认弹窗 */
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  /** 控制上传组件显示 */
  const [isShowUpload, setIsShowUpload] = useState(false);
  /** 控制是否完成 */
  const [isComplete, setIsComplete] = useState(false);
  /** 当前选中的音乐ID */
  const selectMusicId = useRef('');
  const listWrapperRef = useRef<ListWrapperRefFn>(null);

  const audioPlayer = useRef(AudioPlayer.getInstance());

  /** 获取分类 */
  const getCategory = async () => {
    const { data } = await getMusicCategory();
    const reuslts = data.results.map(({ id, name }) => ({
      label: name,
      key: id,
    }));

    setActiveCategory(reuslts[0]?.key || '');

    setMusicCategorys(reuslts);
  };

  useEffect(() => {
    getCategory();
  }, []);

  /** 选择音乐 */
  const handleSelect = (item: MusicDetail) => {
    const { coverUrl, id, musicKey, musicUrl, name, materialKey, materialUrl } =
      item;
    const key = activeMusicType === MusicTabTypes.My ? materialKey : musicKey;
    const url = activeMusicType === MusicTabTypes.My ? materialUrl : musicUrl;
    app.setMusic({
      key,
      id,
      url,
      cover: coverUrl,
      name,
      source:
        activeMusicType === MusicTabTypes.Network ? 'network' : 'material',
    });

    audioPlayer.current.play(AudioPlayerType.MUSIC, url);
    app.setActiveMediaCmp(CmpTypeEnum.MUSIC);

    audioPlayer.current.setVolume(app.music.volume / 100);
  };

  /** 添加失败 */
  const addAudioError = () => {
    getMaterialMusicList();
    setIsComplete(true);
    setIsShowUpload(false);
    Toast.error('上传失败！');
  };

  /**
   * 购买空间弹窗
   */
  const buySpaceDialog = () => {
    WeChatQRcodeModal.show();
  };

  /** 上传音频成功回调 */
  const addSuccess = () => {
    setIsComplete(true);
    setTimeout(() => {
      Toast.success('上传成功');
      listWrapperRef.current?.handleInitList();
      user.getUser();
    }, 1000);
  };

  /** 添加音频 */
  const add = async (audioInfo: AudioQuery) => {
    const materials: MaterialReqParams = {
      materials: [audioInfo],
      module: 'video',
    };
    const res = await addAudio(materials).catch(() => {
      // this.$emit('addError');
      addAudioError();
    });
    if (res) {
      console.log(res);
      addSuccess();
      // this.$emit('addSuccess');
    }
  };

  const startUpload = () => {
    setIsShowUpload(true);
    setIsComplete(false);
    console.log('changeUpdateStatus');
  };

  /** 上传文件回调 */
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /** 控制用户上传的本地音乐 */
    if (total >= MAX_AUDIO_NUMBER) {
      Toast.warning('上传数量达到限制！');
      e.target.value = ''; // 结束后需要清空input值，否则同样的文件无法触发change
      return;
    }

    if (e.target.files && e.target.files?.length < 1) {
      e.target.value = ''; // 结束后需要清空input值，否则同样的文件无法触发change
      return;
    }
    // this.$emit('start-upload');
    const orgFile = e.target.files?.[0] as File;
    // 手动校验类型
    const file = await getUploadImage(orgFile, ['wav', 'mp3', 'mpeg']);
    if (!file) {
      e.target.value = '';
      return;
    }
    // 开始上传
    startUpload();
    const audio = document.createElement('audio');
    audio.src = URL.createObjectURL(file);
    const fileName = file.name;
    const fileSize = file.size;
    if (fileSize === 0) {
      addAudioError();
      e.target.value = ''; // 结束后需要清空input值，否则同样的文件无法触发change
      return;
    }
    if (fileSize > 10 * 1024 * 1024) {
      addAudioError();
      setTimeout(() => {
        Toast.error('上传大小不能超过10M！');
      });
      e.target.value = ''; // 结束后需要清空input值，否则同样的文件无法触发change
      return;
    }
    if (fileSize > balanceStorage) {
      addAudioError();
      // this.$emit('buy-space-dialog');
      buySpaceDialog();
      e.target.value = ''; // 结束后需要清空input值，否则同样的文件无法触发change
      return;
    }
    audio.addEventListener('loadedmetadata', () => {
      const uploader = new Uploader(file);
      uploader.addScene('material');
      uploader.addModule('video');
      uploader
        .upload()
        .then(res => {
          if (res) {
            add({
              name: fileName,
              materialUrl: res.url,
              fileSize,
              duration: Number(audio.duration.toFixed(2)),
            });
          }
        })
        .catch(() => {
          addAudioError();
        })
        .finally(() => {
          // loading.close()
          e.target.value = '';
        });
    });
  };

  /**
   * 删除本地音乐
   */
  const removeMusic = async () => {
    const ids: string[] = [];
    ids.push(selectMusicId.current);
    const params = {
      materialIds: ids,
    };
    const res = await removeDocMaterial(params);
    if (res) {
      listWrapperRef.current?.handleInitList();
      Toast.success('删除成功！');
      user.getUser();
      setRemoveModalVisible(false);
    }
  };

  return (
    <>
      <div className={Style['percent-container']}>
        <CategoryWrapper<MusicTabTypes>
          categorys={MUSIC_TAB_TYPES}
          activeKey={activeMusicType}
          onChange={key => {
            setActiveMusicType(key);
          }}
        />
      </div>
      {activeMusicType === MusicTabTypes.Network && (
        <MaterialContent categorys={musicCategorys} activeTab={activeCategory}>
          <GetListWrapper<ListQuery, MusicDetail>
            apiFn={getCategoryMusicById}
            payload={{}}
          >
            <MusicList onSelect={handleSelect} type={activeMusicType} />
          </GetListWrapper>
        </MaterialContent>
      )}
      {activeMusicType === MusicTabTypes.My && (
        <div className={Style.myMusic}>
          <div className={Style.musicAdd}>
            <label className={Style.musicAddBtn} htmlFor="musicFile">
              <i className="iconfont icon-a-music_icon_filed2x" />
              <div>上传本地音乐</div>
            </label>
            <input
              id="musicFile"
              style={{ display: 'none' }}
              type="file"
              accept=".mp3,.wav"
              name=""
              onChange={handleFileChange}
            />
          </div>
          {isShowUpload && activeMusicType === MusicTabTypes.My && (
            <UploadProgress
              complete={isComplete}
              uploadEnd={() => {
                setIsShowUpload(false);
              }}
            />
          )}
          {/* 弹窗 */}
          <ConfirmMoal
            visible={removeModalVisible}
            onCancel={() => {
              setRemoveModalVisible(false);
            }}
            onOk={removeMusic}
            title="提示"
            content="确认删除该音乐？"
          />
          <GetListWrapper<ListQuery, MyMusicDetail>
            ref={listWrapperRef}
            apiFn={getMaterialMusicList}
            getSuccess={data => {
              setTotal(data.total);
            }}
          >
            <MusicList
              onSelect={handleSelect}
              type={activeMusicType}
              onRemove={item => {
                setRemoveModalVisible(true);
                selectMusicId.current = item.id;
              }}
            />
          </GetListWrapper>
        </div>
      )}
    </>
  );
}

export default Music;
