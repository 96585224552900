import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Popup, Toast } from '@bhb-frontend/lithe-ui/lib';
import { randomString } from '@bhb-frontend/utils/lib/random';
import { store, StoreListenerParam } from '@bhb-frontend/store';

import assets from '@/assets';
import { user, app /* , material */ } from '@/store';
import { getTaskId, TaskType } from '@/api/app';
import { SOCKET_EVENTS } from '@/constants/SocketEvents';
import socket from '@/core/socket';
// import { storagePaidGuidance } from '@/utils/createVideo';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';
import {
  SCENE_MATERIAL_DROP_ADD,
  SCENE_MATERIAL_NETWORK_ADD,
  SCENE_MATERIAL_PASTE_ADD,
  SCENE_NAMESPACE,
  SETTING_ILLUSTRATION_SHOW_KEY,
} from '@/constants/StoreCacheKeys';

import ConfirmModal from '@/components/Confirm';

import Style from './style.module.less';
import {
  imageCheck,
  TaskQueueParams,
  uploadImage,
  UploadResourceResult,
  uploadVideo,
  videoCheck,
} from './helper';
// import { MaterialEnum } from '@/types/material';
import { MateriaResults } from '@/types/doc-materials';
import { DetailResponse } from '@/types/api';
import { OssCopyResult } from '@/types/file';
import { getOssCopy } from '@/api/file';
import { event } from '@/utils/event';
import { getVideoCover } from '@/api/doc';
import { validateArrayFile } from '@/utils/file';

type FileListType =
  | { type: 'file'; file: File }
  | { type: 'cors'; corsUrl: string };

function UploadResource() {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [allotVisible, setAllotVisible] = useState(false);

  const [popupVisible, setPopupVisible] = useState(false);

  const uploadFileRef = useRef<HTMLInputElement>(null);
  const allotTaskIdRef = useRef('');
  const taskRef = useRef<{
    queue: [(params: TaskQueueParams) => Promise<any>, TaskQueueParams][];
    pending: boolean;
  }>({
    queue: [],
    pending: false,
  });

  const collapseCopy = async ({
    newValue: item,
  }: StoreListenerParam<MateriaResults>) => {
    const { url, type, duration, width, height } = item;

    addPlaceholder(1);

    // 调用复制接口
    const res = await getOssCopy({
      url,
      type,
      module: 'video',
      scene: 'document',
      extra: {
        width,
        height,
      },
    }).catch(err => {
      console.log(err);
    });

    if (!res) {
      uploadError(1, app.activedScene.id);
      return;
    }

    const {
      data: { thumbnailFileUrl: thumbUrl, fileUrl },
    } = res;

    const material: Material = {
      url: fileUrl,
      cover: '',
      thumbUrl,
      type,
      duration: 0,
      source: 'network',
    };

    // 视频需要特殊处理
    if (type === 'video') {
      // 获取视频封面
      const {
        data: { fileUrl: cover },
      } = await getVideoCover(res.data.fileUrl);

      Object.assign(material, {
        videoMute: true,
        clipRange: [0, duration * 1000 * 1000],
        cover,
        duration,
      });
    }
    updateMaterials(app.activedScene.id, [material]);
  };

  const dropAdd = ({ newValue: files }: StoreListenerParam<File[]>) => {
    uploadFile(files);
  };
  const pasteAdd = async ({
    newValue: { arr, sceneId },
  }: StoreListenerParam<{ arr: FileListType[]; sceneId: string }>) => {
    const [files, urls] = arr.reduce(
      (result: [File[], string[]], curr) => {
        const [files, urls] = result;
        if (curr.type === 'file') {
          files.push(curr.file);
        }
        if (curr.type === 'cors') {
          urls.push(curr.corsUrl);
        }
        return result;
      },
      [[], []]
    );

    const promiseArray: Promise<DetailResponse<OssCopyResult>>[] = [];
    // 根据图片 url 数组构建 url 转存 promise 数组和占位元素数组
    urls.forEach(url => {
      const promise = getOssCopy({
        url,
        type: 'image',
        module: 'video',
        scene: 'document',
      });
      promiseArray.push(promise);
    });
    addPlaceholder(urls.length);

    const res = await Promise.allSettled(promiseArray);
    const results = res.reduce((res, item) => {
      const { status } = item;
      if (status === 'fulfilled') {
        res.push(item.value);
      }
      return res;
    }, [] as DetailResponse<OssCopyResult>[]);

    // 根据响应结果数组构建素材数组
    const materials: Material[] = results.map(result => ({
      type: 'image',
      url: result.data.fileUrl,
      cover: '',
      duration: 0,
      source: 'material',
    }));

    promiseArray.length &&
      updateMaterials(sceneId, materials, { deleteLen: promiseArray.length });
    uploadFile(files, sceneId);
  };

  // 初始化后监听 socket 连接
  useEffect(() => {
    onlistenAllot();
  }, [user.socketLink]);

  useEffect(() => {
    store.listen(SCENE_NAMESPACE, SCENE_MATERIAL_NETWORK_ADD, collapseCopy);
    store.listen(SCENE_NAMESPACE, SCENE_MATERIAL_DROP_ADD, dropAdd);
    store.listen(SCENE_NAMESPACE, SCENE_MATERIAL_PASTE_ADD, pasteAdd);

    return () => {
      store.unlisten(SCENE_NAMESPACE, SCENE_MATERIAL_NETWORK_ADD, collapseCopy);
      store.unlisten(SCENE_NAMESPACE, SCENE_MATERIAL_DROP_ADD, dropAdd);
      store.unlisten(SCENE_NAMESPACE, SCENE_MATERIAL_PASTE_ADD, pasteAdd);
    };
  });

  useEffect(() => {
    if (allotVisible) {
      getAllotTaskId();
    } else {
      allotTaskIdRef.current = '';
    }
  }, [allotVisible]);

  const handleAllot = () => {
    setPopupVisible(false);
    if (app.activedScene.materials?.length > 0) {
      setConfirmVisible(true);
    } else {
      activeAllot();
    }
  };

  /** 激活智能配图 */
  const activeAllot = () => {
    setConfirmVisible(false);
    if (!app.activedScene.allTexts.length) {
      Toast.warning('请先编辑正文内容');
      return;
    }
    setAllotVisible(true);
  };

  /**
   * 触发文件选择校验
   */
  const triggerCheck = () => {
    if (!uploadFileRef.current) return;
    const balanceStorage = user.videoClipRights?.balanceStorage || 0;
    if (balanceStorage <= 0) {
      // storagePaidGuidance();
      WeChatQRcodeModal.show();
      return;
    }
    uploadFileRef.current.click();
  };
  // const handleSearch = () => {
  //   material.changeMenu(MaterialEnum.MATERIAL);
  // };

  const getAllotTaskId = async () => {
    const text = app.activedScene.getAllText.replace(/[\n\r]+/g, '');
    try {
      const {
        data: { taskId },
      } = await getTaskId({ type: TaskType.textMapMaterial, text });
      allotTaskIdRef.current = taskId;
    } catch (error) {
      console.log(error);
      setAllotVisible(false);
    }
  };

  const uploadWithEvent = async (event: any) => {
    let files = (event.target.files as any[]) || [];
    files = [...files];
    const newFiles = await validateArrayFile(files, [
      'mp4',
      'mov',
      'png',
      'jpg',
      'jpeg',
      'webp',
      'gif',
      'quicktime',
    ]);
    uploadFile(newFiles, app.activedScene.id);
    // 需要清空input值，否则无法触发change
    event.target.value = '';
  };

  /**
   * 文件上传的主入口
   */
  const uploadFile = async (files: File[], sceneId = app.activedScene.id) => {
    // 过滤将要上传的图片元素
    const fileImages = imageCheck(
      files.filter(item => item.type.startsWith('image/'))
    );

    // 过滤将要上传的视频元素
    const fileVideos = await videoCheck(
      files.filter(item => item.type.startsWith('video/'))
    );

    // 填充占位元素
    addPlaceholder(fileImages.length + fileVideos.length);

    addTask(
      {
        fileImages,
        fileVideos,
      },
      sceneId
    );
  };

  /**
   * 因为添加占位图时，是在当前场景，所以不需要根据 id 筛选场景
   * */
  const addPlaceholder = (length: number) => {
    const placeholder: Material[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      placeholder.push({
        type: 'image',
        url: randomString(),
        cover: '',
        duration: 0,
        placeholder: true,
      });
    }
    // 占位图无需保存Ws
    app.activedScene.replaceFileds({
      materials: [...app.activedScene.materials, ...placeholder],
    });
  };

  const uploadError = (errLength: number, sceneId: string) => {
    updateMaterials(sceneId, [], { deleteLen: errLength });
  };

  /**
   * 执行队列中的任务
   */
  const taskExecute = () => {
    const { queue, pending } = taskRef.current;
    if (queue.length && !pending) {
      taskRef.current.pending = true;
      const taskItem = taskRef.current.queue.shift();
      if (taskItem) {
        const [task, params] = taskItem;
        task(params).then(() => {
          taskRef.current.pending = false;
          taskExecute();
        });
      }
    }
  };
  /**
   * 向队列中添加任务
   */
  const addTask = (params: TaskQueueParams, sceneId: string) => {
    taskRef.current.queue.push([
      async ({ fileImages, fileVideos }: TaskQueueParams) => {
        const allLength = fileImages.length + fileVideos.length;
        const images = await uploadImage(fileImages);
        const videos = await uploadVideo(fileVideos);
        const successLength = images.length + videos.length;

        if (allLength !== successLength) {
          // 有出错的需要删除占位图
          uploadError(allLength - successLength, sceneId);
        }

        uploadChange(
          {
            images: images.map(img => img.url),
            videos,
          },
          sceneId
        );
      },
      params,
    ]);
    taskExecute();
  };

  const uploadChange = (
    { images = [], videos = [] }: UploadResourceResult,
    sceneId: string
  ) => {
    const materials: Material[] = [];
    images.forEach(image => {
      materials.push({
        type: 'image',
        url: image,
        cover: '',
        duration: 0,
        source: 'material',
      });
    });
    videos.forEach(video => {
      const { url, coverUrl: cover, duration = 0 } = video;
      materials.push({
        url,
        duration,
        cover,
        type: 'video',
        videoMute: true,
        clipRange: [0, duration * 1000 * 1000],
        source: 'material',
      });
    });

    updateMaterials(sceneId, materials);
  };

  const updateMaterials = (
    sceneId: string,
    materials: Material[],
    option?: {
      deleteLen?: number;
      replaceAll?: boolean;
    }
  ) => {
    const isCurSence = sceneId && sceneId !== app.activedScene.id;
    const sence = isCurSence
      ? app.activedScene
      : app.scenes.find(item => item.id === sceneId);

    const index = sence?.materials.findIndex(item => !!item.placeholder) ?? -1;
    if (index < 0) return;

    const result: Material[] = [...(sence?.materials || [])];
    result.splice(index, option?.deleteLen || materials.length, ...materials);

    // 是否替换所有数据
    sence?.update({ materials: option?.replaceAll ? materials : result });
    const { imageCmp } = app.activedScene;
    // 当动画在功能栏是关闭状态时，则需要弹出提示
    if (!imageCmp || !imageCmp.visible) {
      // 取消图层选中
      app.releaseAllCmps();
      setTimeout(() => {
        event.fire(SETTING_ILLUSTRATION_SHOW_KEY, null);
      });
    }
  };

  /** 监听服务端返回的智能配图 */
  const onlistenAllot = () => {
    if (!user.socketLink) return;
    socket.on((res: any) => {
      const { event, data } = res;
      if (event !== SOCKET_EVENTS.EFFECT_TASK_RESULT) return;
      setAllotVisible(false);
      const { status, materials = [], message, taskId } = data;
      if (!allotTaskIdRef.current || allotTaskIdRef.current !== taskId) return;

      if (status === 'failed') {
        Toast.error(message);
        return;
      }

      if (materials.length === 0) {
        Toast.error('当前文案暂未检索到相关的素材');
        return;
      }
      // 覆盖素材列表
      const m = materials.map(({ url, thumbnailUrl }) => ({
        type: 'image',
        url,
        cover: '',
        duration: 0,
        thumbUrl: thumbnailUrl,
        source: 'network',
      }));

      addPlaceholder(m.length);

      updateMaterials(app.activedScene.id, m, {
        replaceAll: true,
      });
    });
  };

  const overlay = (
    <div className={Style.uploadPopover}>
      <div className={Style.uploadMenuItem} onClick={handleAllot}>
        <img src={assets.images.doc['pic_doc.png']} alt="" />
        智能配图
      </div>
      <div className={Style.uploadMenuItem} onClick={triggerCheck}>
        <i className="icon-a-doc_icon_local2x iconfont" />
        本地素材
      </div>
      {/* <div className={Style.uploadMenuItem} onClick={handleSearch}>
        <i className="icon-a-doc_icon_search2x iconfont" />
        搜索素材
      </div> */}
    </div>
  );
  return (
    <>
      <Popup
        overlay={overlay}
        placement="bottomLeft"
        visible={popupVisible}
        onEnter={() => setPopupVisible(true)}
      >
        <div className={Style.uploadBox}>
          <input
            ref={uploadFileRef}
            id="uploadFile"
            type="file"
            multiple
            accept=".mp4,.mov,.png,.jpg,.jpeg,.webp,.gif"
            onChange={uploadWithEvent}
          />

          <img src={assets.images.doc['tuwen_icon_pic_add.png']} alt="" />
        </div>
      </Popup>
      <ConfirmModal
        visible={confirmVisible}
        title="确定使用智能配图吗？"
        content="智能配图的素材将会替换当前场景已有素材，确定替换吗？"
        onOk={activeAllot}
        onCancel={() => {
          setConfirmVisible(false);
        }}
      />
      <ConfirmModal
        visible={allotVisible}
        title="智能配图中"
        loading={
          <div className={Style.loading}>
            <img src={assets.images.doc['pic_loading.png']} alt="loading" />
          </div>
        }
        okText={null}
        content="正在智能配图，请耐心等待"
        onCancel={() => {
          setAllotVisible(false);
        }}
      />
    </>
  );
}

export default observer(UploadResource);
