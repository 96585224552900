/** 视频生成等待的循环轮播文案 */
export const PENDINGLOOPTEXTS = [
  '正在加急为您生成视频，请耐心等待',
  '编辑视频时，切换不同数字人或配音试试',
  '更短的文案或素材，生成视频更快',
];

/** 视频生成 pending 文案的长度 */
export const PENDINGLOOPTEXTSSTYLE: any = {
  // 循环文案的个数，动画中的 step 用到
  '--step': PENDINGLOOPTEXTS.length,
};
