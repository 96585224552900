import React from 'react';
import { hexToRgba } from '@/utils/color';
import CmpsModel, { TextStruc } from '@/models/CmpStruc';

/**
 * 应用在容器上的样式
 */
const onContainerKeys = [
  'width',
  'height',
  'transform',
  'paddingTop',
  'paddingRight',
  'paddingLeft',
  'paddingBottom',
  'padding',
  'lineHeight',
];

/**
 * 排除应用在内部的样式
 */
const ignoreStyleKeys = [
  'boxShadowX',
  'boxShadowY',
  'boxShadowBlur',
  'boxShadowSpread',
  'boxShadowColor',
  'rotate',
  'top',
  'left',
  'position',
  'paddingTop',
  'paddingRight',
  'paddingLeft',
  'paddingBottom',
  'padding',
  'lineHeight',
];

/**
 * 获取组件容器上的样式
 * @param model 组件数据
 * @param zoomLevel 缩放比例
 * @returns CSSProperties
 */
export function getCmpOuterStyles<M extends CmpsModel = CmpsModel>(
  model: M,
  zoomLevel = 1
): React.CSSProperties {
  const containeStyle = filterOnContainerKeys(model.style);

  containeStyle.transform = getRectTransform(model, zoomLevel);
  /** 翻转 */
  if (model.flip.x < 1) {
    containeStyle.transform += 'scaleX(-1)';
  }
  /** 禁用 */
  if (model.disabled) {
    const { opacity = 1 } = model.style;
    containeStyle.opacity = +opacity * 0.5;
  }

  return containeStyle;
}

/**
 * 获取组件内部样式
 * @param model
 * @returns CSSProperties
 */
export function getCmpInnerStyles<M extends CmpsModel = CmpsModel>(model: M) {
  const cssStyle = formatCmpStyleToCss(model.style);

  if (model.isText) {
    const { fontDesc } = model as unknown as TextStruc;
    cssStyle.fontFamily = fontDesc.faceName;
  }

  return excludeOnContainerKeys(cssStyle);
}

/**
 * 格式化阴影
 * @param style 组件样式
 * @returns {string | null}
 */
function formatBoxShadow(style: ComponentStyle): string | null {
  const {
    boxShadowX,
    boxShadowY,
    boxShadowBlur,
    boxShadowSpread,
    boxShadowColor,
  } = style;
  if (
    boxShadowX != null ||
    boxShadowY != null ||
    boxShadowBlur != null ||
    boxShadowSpread != null ||
    boxShadowColor != null
  ) {
    const boxShadow = `${boxShadowX || 0}px ${boxShadowY || 0}px ${
      boxShadowBlur || 0
    }px ${boxShadowSpread || 0}px ${boxShadowColor || '#000000'}`;
    return boxShadow;
  }
  return null;
}

/**
 * 将组件样式格式化为CSS样式
 * @param style 组件样式属性
 * @returns {React.CSSProperties}
 */
export function formatCmpStyleToCss(
  style?: ComponentStyle
): React.CSSProperties {
  if (!style) return {};
  const css: React.CSSProperties = {};

  for (const key in style) {
    const value = style[key];
    if (ignoreStyleKeys.includes(key) || value == null) continue;
    css[key] = value;
  }
  return css;
}

/**
 *  单独拼接transform
 * @param style 组件样式属性
 * @zoomLevel zoomLevel 缩放比例
 * @returns 组件的transform
 */
export function getRectTransform<M extends CmpsModel = CmpsModel>(
  model: M,
  zoomLevel = 1
) {
  if (!model.style) return '';
  const { left = 0, top = 0 } = model.style;
  const { rotation } = model;

  return `translate(${Number(left) * zoomLevel}px,${
    Number(top) * zoomLevel
  }px) rotate(${rotation.z}deg)`;
}

/**
 * 获取元素基本的展示信息
 * @param style 组件样式属性
 * @zoomLevel zoomLevel 缩放比例
 */
export function getRectDisplayStyle<M extends CmpsModel = CmpsModel>(
  model: M,
  zoomLevel = 1
) {
  if (!model.style) return {};
  const { width, height } = model.style;
  return {
    width: Number(width) * zoomLevel,
    height: Number(height) * zoomLevel,
    transform: getRectTransform(model, zoomLevel),
  };
}

/**
 * 当前样式值转换为跟随缩放级别的值
 * @param style 正常的样式值
 * @param zoomLevel 缩放级别
 * @returns
 */
export function followToZoomlevel(
  style: React.CSSProperties,
  zoomLevel: number
): React.CSSProperties {
  if (zoomLevel === 1) return style;
  for (const key in style) {
    const value = style[key];
    if (typeof value === 'number') {
      style[key] = value * zoomLevel;
    }
  }
  return style;
}

/**
 * 过滤出容器上的样式
 * @param style 总样式
 * @returns
 */
export function filterOnContainerKeys(
  style?: ComponentStyle
): React.CSSProperties {
  if (!style) return {};
  const containerKeys: React.CSSProperties = {};

  const boxShadow = formatBoxShadow(style);
  if (boxShadow) containerKeys.boxShadow = boxShadow;
  for (const key in style) {
    if (onContainerKeys.includes(key)) {
      containerKeys[key] = style[key];
    }
  }
  return containerKeys;
}

/**
 * 排除掉容器上的样式
 * @param style 总样式
 * @returns
 */
export function excludeOnContainerKeys(
  style: React.CSSProperties
  // zoomLevel: number
): React.CSSProperties {
  const noContainerKeys: React.CSSProperties = {};
  for (const key in style) {
    if (!onContainerKeys.includes(key)) {
      noContainerKeys[key] = style[key];
    }
  }
  return noContainerKeys;
}

/**
 * 获取文字填充样式
 * @param fill 填充数据
 * @returns
 * */
export function getTextFillStyle(
  fill?: ComponentModel.Fill
): React.CSSProperties {
  if (!fill) return {};
  const { backgroundColor, backgroundAlpha = 100, color, alpha = 100 } = fill;
  return {
    color: hexToRgba(color, +alpha / 100),
    backgroundColor: hexToRgba(backgroundColor, +backgroundAlpha / 100),
  };
}

/**
 * 获取文字描边数据
 * @param fill 填充数据
 * @returns
 */
export function getTextStroke(
  fill?: ComponentModel.Fill
): React.CSSProperties | null {
  if (!fill || !fill.strokeColor) return null;
  const { strokeWidth = 0, strokeColor, strokeAlpha = 100 } = fill;
  return {
    WebkitTextStroke: `${strokeWidth}px ${hexToRgba(
      strokeColor,
      +strokeAlpha / 100
    )}`,
  };
}

/**
 * 组合颜色，返回渐变
 *  */
export function combineColor(colors: string[]): string {
  let res = '';
  // 每一种颜色在100%的占比量
  const step = 100 / colors.length;
  colors.forEach((color, index) => {
    res += `${color} ${step * index}%,${color} ${step * (index + 1)}%,`;
  });
  return `linear-gradient(to right, ${res.slice(0, res.length - 1)})`;
}
