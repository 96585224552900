import { makeObservable, observable, observe } from 'mobx';
import { randomString } from '@bhb-frontend/utils/lib/random';
import clone from '@bhb-frontend/utils/lib/clone';
import { CmpTypeEnum } from '@/constants/CmpType';
import { getTagsWithEnum } from '@/constants/Tags';
import CmpStruc from './CmpStruc';
import { ExtraType } from '@/constants/Extra';
import { getImageInfo } from '@/utils/image';
import CreateCmpStruc from '../FactoryStruc/CmpFactory';

/**
 * 测试贴图随时删除
 */
const testImg =
  'https://img.miaotui.com/common/mtv/2022/05/05/14/6a64694be0c0cadc158264e84d8896f3.webp';

const DEFAULT_WIDTH = 270;

type Image = ComponentModel.Image<any>;
export default class ImageStruc<T extends ComponentModel.Image<T> = Image>
  extends CmpStruc<T>
  implements ComponentModel.Image<T>
{
  fileName!: string;

  key!: string;

  url!: string;

  extra!: ImageExtra & T;

  originalWidth?: number | null = null;

  originalHeight?: number | null = null;

  /** 是否抠图 */
  isCutout = false;

  /** 备用url，用于抠图 */
  alternateUrl = '';

  /** 是否显示动画 */
  hiddenAnimation?: boolean;

  constructor(data?: Partial<ComponentModel.Image<T>>) {
    super(data);
    makeObservable(this, {
      fileName: observable,
      key: observable,
      alternateUrl: observable,
      isCutout: observable,
      url: observable,
      extra: observable,
      hiddenAnimation: observable,
    });

    this.url = data?.url || testImg;
    this.fileName = data?.fileName || '';
    this.key = data?.key || '';
    this.extra = data?.extra || ({} as ImageExtra & T);
    this.type = CmpTypeEnum.IMAGE;
    this.name = data?.name || '贴纸';
    this.tag = data?.tag || getTagsWithEnum('Image');
    this.hiddenAnimation = data?.hiddenAnimation ?? false;

    const width = DEFAULT_WIDTH;

    const height =
      this.originalWidth && this.originalHeight
        ? (width / this.originalWidth) * this.originalHeight
        : width;

    this.style = {
      ...this.style,
      width,
      height,
      ...data?.style,
    };

    observe(this, 'url', this.urlChange, false);
  }

  /**
   * 输出文本组件model模型
   * @returns {ComponentModel.Image}
   */
  model(): ComponentModel.Image<T> {
    const model = super.model();
    return {
      ...model,
      tag: this.tag,
      fileName: this.fileName,
      key: this.key,
      url: this.url,
      extra: this.extra,
      type: this.type,
      name: this.name,
      style: this.style,
      hiddenAnimation: this.hiddenAnimation,
    };
  }

  /** 复制一个组件 */
  clone(): CmpStruc {
    const model = clone(this.model(), true);
    // 所有的id 都需要重新生成
    const { id, sourceId, imgExtensionFields } = model;
    id && (model.id = `layer-${randomString()}-web`);
    const imageId = `image-${randomString()}-web`;
    sourceId && (model.sourceId = imageId);
    imgExtensionFields?.objectId && (imgExtensionFields.objectId = imageId);
    return CreateCmpStruc(model.type, model, this.getParent());
  }

  urlChange = change => {
    const { newValue } = change;
    const { imageKey, fileName = '' } = getImageInfo(newValue);
    this.update({
      fileName,
      key: imageKey,
    } as T);
  };

  /**
   * 获取组件类型
   * @returns {CmpTypeEnum.IMAGE}
   */
  protected getType(): CmpTypeEnum.IMAGE {
    return CmpTypeEnum.IMAGE;
  }

  /**
   * 获取tag
   * @returns
   */
  protected getTag() {
    return this.tag;
  }

  /** 是否可copy */
  get isCanCopy() {
    // 插图禁止复制
    if (this.tag === 'Image') return false;
    if (this.extra.scene?.type === ExtraType.DIGITAL_MAN) return false;
    return true;
  }

  /** 设置头抠图 */
  setCutoutInfo(url: string) {
    this.alternateUrl = url;
    this.isCutout = !this.isCutout;
  }
}
