import { MemberType } from './user';
import { ListQuery } from './api';
/** 侧边栏 */
export enum MaterialEnum {
  /** 模版 */
  THEME = 'theme',
  /** 配音 */
  DUBBING = 'dubbing',
  /** 音乐 */
  MUSIC = 'music',
  /** 背景 */
  BACK = 'back',
  /** 贴纸 */
  STICKER = 'sticker',
  /** 文字贴纸 */
  TEXT_STICKER = 'textSticker',
  /** 文字动画 */
  TEXT_ANIMATION = 'textAnimation',
  /** 数字人 */
  DIGITAL_MAN = 'DIGITAL_MAN',
  /** 素材 */
  MATERIAL = 'material',
  /** 空 */
  DEFAULT = '',
}

export enum ThemeType {
  theme = 'theme',
  subscribeTheme = 'subscribeTheme',
}

export enum Percent {
  // 9and16表示9:16的，16and9表示16:9的  默认9and16
  Vertical = '9and16',
  Horizontal = '16and9',
}

export enum PresetType {
  /** 预设ip形象 */
  presetIpImage = 'presetIpImage',
  /** 预设数字人 */
  presetFigure = 'presetFigure',

  /** 2d数字人 */
  figure2d = 'figure2d',
  /** 图片类型 */
  image = 'image',
}

/** 资源操作菜单类型*/
export enum ItemMenuType {
  delete = 'delate',
  copy = 'copy',
  rename = 'rename',
}

export type MaterialEnumKeys = keyof typeof MaterialEnum;

interface MenuItemModel {
  /** name */
  name: MaterialEnum;
  /** 名称 */
  label: string;
  /** 图标 */
  icon: string;
  /** 是否隐藏名称 */
  hiddenLabel?: boolean;
  /** 组件 */
  component: () => JSX.Element;
  /** 隐藏右侧把手 */
  hiddenSidebar?: boolean;
}

/** 菜单 */
export type TMenu = MenuItemModel[];

/** 主题分类 */
export interface Category {
  id: string;
  name: string;
}

/** Tab 分类 */
export interface TabsCategory {
  /** 名称 */
  label: React.ReactNode;
  /** key名 */
  key: string;
  /** 显示内容 */
  // children: React.ReactNode;
}

export interface GetThemeParmas extends ListQuery {
  /** 比例 */
  percent: Percent;
  /** 类型 */
  type?: string;
}

export interface SearchParams extends ListQuery {
  keyword: string;
}

export interface GetDigitalManParmas extends ListQuery {
  /** presetIpImage - 预设ip形象，presetFigure - 预设数字人 */
  type: PresetType;
  /** free - 免费版，basic - 基础版，professional - 专业版 */
  memberType?: MemberType;
}

export interface GetBackgroundParams extends ListQuery {
  percent: Percent;
}

export interface ThemeDetail {
  id: string;
  name: string;
  /** 封面key */
  coverKey: string;
  /** 封面url */
  coverUrl: string;
  /** 比例 */
  percent: string;
  /** 模式 */
  module: string;
  /** 是否默认 */
  isDefault: boolean;
  /** 比例 */
  percen: Percent;
  /** 预览视频地址 */
  preVideoUrl?: string;
  /** 预览图 */
  previewCoverUrl: string;
}

/** 配音详情 */
export interface DubbingDetail {
  id: string; // 配音id
  name: string; // 配音名称
  coverUrl: string; // 配音封面url
  exampleUrl: string; // 示例音频url
  materialUrl: string; // 音频url
  /**
   * free - 免费版，basic - 基础版，professional - 专业版 (客户端看免费不展示，基础版 = VIP，专业版 = SVIP)
   */
  memberType?: MemberType;
  tipText?: string;

  musicPlaying?: boolean;
}

export interface DigitalManDetail {
  /** id */
  id: string;
  /** 形象照 */
  avatar: string;
  /** 封面 */
  coverUrl: string;
  /** 名称 */
  name: string;
  /** 简介 */
  brief: string;
  /**
   * ip 形象类型 image:图片类型|figure2d:2d数字人
   */
  type: '' | 'image' | 'figure2d';
  /**
   * free - 免费版，basic - 基础版，professional - 专业版 (客户端看免费不展示，基础版 = VIP，专业版 = SVIP)
   */
  memberType: MemberType;
  /** 过期时间 */
  expire: string;
  /** 配音 */
  dubbingInfo: DubbingDetail;
  /** 大头数字人预览图 */
  previewImageUrl: string;
  /** 大头数字人 是否可用 */
  enable: boolean;
  /** 缩略图 */
  thumbnailUrl: string;
}

/** 音乐详情 */
export interface MyMusicDetail {
  id: string; // 素材id
  type?: string; // 素材类型 ["audio" - 音频]
  name?: string; // 素材名称
  materialKey: string;
  materialUrl: string; // 素材url地址
  fileSize?: number; // 素材文件大小 单位：字节
  duration: number; // 时长 单位：秒
  createdAt?: string; // 创建时间
}
export interface MusicDetail extends MyMusicDetail {
  id: string; // 音乐id
  name?: string; // 音乐名称
  artist?: string; // 作者
  coverUrl?: string; // 封面图片地址
  musicUrl: string; // 音乐地址
  materialUrl: string; // 本地上传音乐
  materialKey: string;
  duration: number; // 时长
  fileSize?: number; // 文件大小
  musicSource?: 'network' | 'material';
  source?: 'network' | 'material';
  categoryId?: string; // 分类id
  musicKey?: string;

  musicPlaying?: boolean;
}

export interface BackgroundDetail {
  id: string; // 背景唯一id
  name: string; // 背景名
  percent: string; // 背景比例
  backgroundUrl: string; // 背景图地址
  backgroundKey: string; // 背景图key
  /**
   * 封面地址
   */
  coverUrl: string;
}

export interface StickerDetail {
  id: string; // id
  name?: string; // 贴图名
  coverKey?: string; // 封面key
  coverUrl: string; // 封面url
  imageKey?: string; // 贴图图片key
  imageUrl?: string; // 贴图图片url
  width?: number;
  height?: number;
  config?: any;
}

// 文字动画
export interface TextAnimationDetail {
  id: string;
  categoryIds: any[]; // 保留字段
  makeCount: number;
  coverUrl: string;
  name: string;
  resourceUrl: string;
}

// 文字动画
export interface TextAnimationItem {
  id: string;
  categoryIds: any[]; // 保留字段
  makeCount: number;
  coverUrl: string;
  name: string;
  resourceUrl: string;
}

/** 音频素材参数 */
export interface AudioQuery {
  name: string; // 素材名称
  materialUrl: string; // 素材url地址
  fileSize: number; // 素材文件大小 单位：字节
  duration: number; // 时长 单位：秒
}

/** 素材请求参数 */
export interface MaterialReqParams {
  materials: Array<AudioQuery>;
  module?: string;
}
