import { useEffect, useRef, useState } from 'react';

interface Props {
  seconds: number;
}
type Fnc = () => void;
const noop = () => {
  console.log();
};

const useCountDown = (
  props: Partial<Props>
): [number, () => void, (t: number) => void] => {
  const { seconds } = props;
  const [time, setTime] = useState(seconds || 0);
  const [timerId, setTimerId] = useState(0);
  const tickRef = useRef<Fnc>(noop);

  const tick = () => {
    if (time > 0) {
      setTime(time - 1);
    }
  };

  useEffect(() => {
    tickRef.current = tick;
  });

  const interval = () => {
    const id = window.setInterval(() => tickRef.current(), 1000);
    setTimerId(id);
  };

  useEffect(() => {
    interval();

    return () => clearInterval(timerId);
  }, []);

  const resetTime = (t: number) => {
    clearTime();
    setTime(t);
    interval();
  };

  const clearTime = () => {
    if (timerId) {
      clearInterval(timerId);
    }
  };

  return [time, clearTime, resetTime];
};

export default useCountDown;
