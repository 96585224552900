import { tagToUsageMap } from '@/constants/formatData';
import CmpStruc, { ImageStruc, TextStruc } from '@/models/CmpStruc';
import { ThemeDetailItem } from '@/types/ServerData';
import {
  pxToNumber,
  transformObjToVector,
  transformAnchor,
} from '@/utils/formatData';
import { transformTextToData } from './Text/TextToData';

/** 转换cmp */
export function transformCmp(cmp: CmpStruc, themeInfo: ThemeDetailItem) {
  const modal = cmp.model();
  const {
    tag,
    type,
    id,
    name,
    sourceId,
    flip,
    position,
    anchor,
    rotation,
    scalar,
    extensionFields,
    style,
    extra = {},
    fileName,
    key,
    url,
    visible,
    scaleMode,
  } = modal;
  const layerExtra = {};
  const { width = 0, height = 0, left = 0, top = 0 } = style || {};
  // extra关于数字人信息,存在layer中
  const { scene, ...sourceExtra } = extra;
  if (scene) {
    Object.assign(layerExtra, { scene });
  }
  /** 计算缩放 */
  const scalarX = scalar.x / 100;
  const scalarY = scalar.y / 100;
  const orgWidth = Number((pxToNumber(width) / scalarX).toFixed(3));
  const orgHeight = Number((pxToNumber(height) / scalarY).toFixed(3));
  // 锚点 转换成 px 值
  const anchorObj = transformAnchor(
    anchor,
    {
      width: orgWidth,
      height: orgHeight,
    },
    false
  );
  const anchorVector = transformObjToVector(anchorObj);

  // 旋转
  const rotationVector = transformObjToVector(rotation);
  /** 缩放 */
  const scalarVector = transformObjToVector(scalar);
  // 位置
  const positionVector = transformObjToVector(position);
  const positionX = (pxToNumber(left) + anchorObj.x * scalarX).toFixed(3);
  const positionY = (pxToNumber(top) + anchorObj.y * scalarY).toFixed(3);
  positionVector[0] = Number(positionX);
  positionVector[1] = Number(positionY);

  const usage = tagToUsageMap[tag];

  const layerOptions = {};

  // 插图不需要对资源收集
  if (type === 'Image' && tag !== 'Image') {
    const { imgExtensionFields } = modal as ImageStruc;
    // 除插图外的图片资源
    const imageRource = {
      fileName,
      imageKey: key,
      imageUrl: url,
      objectId: sourceId,
      extra: sourceExtra,
      ...imgExtensionFields,
    };
    themeInfo.footage.images.push(imageRource);
  }

  // 插图
  if (tag === 'Image') {
    const { hiddenAnimation } = modal as ImageStruc;
    Object.assign(layerExtra, {
      hiddenAnimation,
    });
  }

  // 文字资源
  if (type === 'Text') {
    const textCmp = cmp as TextStruc;
    const textLayerOpts = transformTextToData({
      cmp: textCmp,
      themeInfo,
      width: orgWidth,
      height: orgHeight,
      positionVector,
    });
    Object.assign(layerOptions, textLayerOpts);
  }

  if (!visible) {
    Object.assign(layerExtra, {
      hidden: true,
    });
  }

  const layer = {
    ...extensionFields,
    ...layerOptions,
    extra: layerExtra,
    usage,
    objectId: id,
    usageName: name,
    sourceId,
    width: orgWidth,
    height: orgHeight,
    scaleMode,
    transform: {
      rotationX: rotationVector[0],
      rotationY: rotationVector[1],
      rotationZ: rotationVector[2],
      anchor: anchorVector,
      scalar: scalarVector,
      position: positionVector,
    },
    flip,
  };
  // console.log('layer', layer);
  return layer;
}
