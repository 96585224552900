import { BHB_PREFIX } from './CacheKeys';

// ============ 文字设置 ==============
/** 文字设置命名空间 */
// export const SETTING_TEXT_NAMESPACE = `${BHB_PREFIX}:SETTING:TEXT:NAMESPACE`;

/** 文字颜色设置 */
export const SETTING_TEXT_COLOR_KEY = `${BHB_PREFIX}:SETTING:TEXT:COLOR:KEY`;

/** 文字背景设置 */
export const SETTING_TEXT_BACK_KEY = `${BHB_PREFIX}:SETTING:TEXT:BACK:KEY`;

/** 插图提示设置 */
export const SETTING_ILLUSTRATION_SHOW_KEY = `${BHB_PREFIX}:SETTING:ILLUSTRATION:SHOW:KEY`;

// ============ 场景 ==============
/** 场景 命名空间 */
export const SCENE_NAMESPACE = `${BHB_PREFIX}:SCENE:NAMESPACE`;

/** 粘贴添加 */
export const SCENE_MATERIAL_PASTE_ADD = `${BHB_PREFIX}:SCENE:MATERIAL:PASTE:ADD`;
/** 拖拽区添加 */
export const SCENE_MATERIAL_DROP_ADD = `${BHB_PREFIX}:SCENE:MATERIAL:DROP:ADD`;
/** 网络素材添加 */
export const SCENE_MATERIAL_NETWORK_ADD = `${BHB_PREFIX}:SCENE:MATERIAL:NETWORK:ADD`;

// ============= 素材区 ================
/** 关闭素材区面板 */
export const MATERIAL_CLOSE_MEUN = `${BHB_PREFIX}:MATERIAL:CLOSE:MEUN`;

// ================ 文档区 ==============
export const DOC_EDTIOR_BLUR = `${BHB_PREFIX}:DOC:EDTIOR:BLUR`;
