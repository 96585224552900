import { useEffect, useState } from 'react';
import { byMimeTypesCheckBrower } from '@/utils/Brower';

import style from './style.module.less';
import {
  MARK_LIST,
  MIME_TYPE_LIST_360,
  SEVEN_TIME_STAMP,
} from '@/constants/Brower';

export default function BrowerTips() {
  const [show, setShow] = useState<boolean>(false);

  const init = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isChrome =
      ua.includes('chrome') &&
      !MARK_LIST.some(item => ua.includes(item)) &&
      !byMimeTypesCheckBrower('type', MIME_TYPE_LIST_360);
    // 为null时，视为7天内没有手动关闭过
    const oldTimestamp = window.localStorage.getItem('mtv_timestamp');
    const currentTimestamp = new Date().getTime();
    if (
      !isChrome &&
      currentTimestamp - Number(oldTimestamp) > SEVEN_TIME_STAMP
    ) {
      setShow(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleClose = () => {
    const timestamp = `${new Date().getTime()}`;
    window.localStorage.setItem('mtv_timestamp', timestamp);
    setShow(false);
  };

  if (!show) return null;

  return (
    <div className={style['brower-tips']}>
      <span className={style.text}>
        由于部分浏览器存在兼容性问题，建议使用谷歌浏览器
      </span>
      <div className={style.btn}>
        <a href="https://www.google.cn/chrome/" target="_block">
          立即下载
        </a>
      </div>
      <span
        className={`iconfont icon-a-common_icon_close_grey2x ${style['close-icon']}`}
        onClick={handleClose}
      />
    </div>
  );
}
