/**
 * 录制状态
 */
export enum recordStatus {
  /** 录制前 */
  BEFORE_RECORDING = 1,

  /** 录制中 */
  RECORDING = 2,

  /** 录制后 */
  AFTER_RECORDING = 3,

  /** 提交录制 */
  SUBMITING_RECORDING = 4,
}
