import { useEffect } from 'react';
import cs from 'classnames';
import { observer } from 'mobx-react';
import { ImageStruc } from '@/models/CmpStruc';
import { makeImage } from '@/utils/image';

import { CmpsFactoryProps } from '../Cmp';
import cssmodule from './Image.module.less';

export interface ImageProps extends CmpsFactoryProps<ImageStruc> {}

function Image(props: ImageProps) {
  const { model, style } = props;

  const { url, isImage, scaleMode } = model;

  /**
   * 自适应宽高计算
   */
  const adaptiveSize = async () => {
    const { style } = model;
    if (!style) return;
    try {
      model.update({ loading: true });
      const { width = 0, top = 0, left = 0, height = 0 } = style;
      const image = await makeImage(url);
      const imageW = image.naturalWidth || image.width;
      const imageH = image.naturalHeight || image.height;
      const rateW = imageW / +width;
      const rateH = imageH / +height;
      const maxRate = Math.max(rateH, rateW);
      const newWidth = imageW / maxRate;
      const newHeight = imageH / maxRate;

      model.updateStyle({
        width: newWidth,
        height: newHeight,
        left: +left + (+width - newWidth) / 2,
        top: +top + (+height - newHeight) / 2,
      });
    } finally {
      model.update({ loading: false });
    }
  };

  useEffect(() => {
    !isImage && adaptiveSize();
  }, [url]);

  return (
    <div className={cssmodule.container} style={style}>
      {model.url && (
        <>
          {isImage && (
            <img
              className={cs(cssmodule['gaussian-blur-image'], cssmodule.image)}
              src={url}
              alt=""
            />
          )}
          <img
            className={cs(cssmodule.image, {
              [cssmodule.contain]: scaleMode === 1,
              [cssmodule.cover]: scaleMode === 2,
            })}
            src={url}
            alt=""
          />
        </>
      )}
    </div>
  );
}

export default observer(Image);
