import { ENV } from '@/config/hosts';
import config from '../../config/config.json';

export const getWindow = () =>
  typeof window !== 'undefined' ? window : undefined;

const { ...winConfig } = config;
const win = getWindow();

export function initProject() {
  if (win) {
    // const title = '闪剪-图文快剪';
    // win.document.title = title;
    win.MTV_CONFIG = {
      ...winConfig.mtv_config,
      env: ENV,
      lang: 'zh-CN',
    };
  }
}
