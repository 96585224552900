import { Modal } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';

import { useCountDown } from '@/hooks';

import Style from './style.module.less';

export interface CountDownProps extends ModalProps {
  title: string;
  content: React.ReactNode;
  confirm?: string;
  time?: number;
}

export default function CountDownModal(props: CountDownProps) {
  const {
    title,
    content,
    confirm = '我已知晓',
    time: defaultTime = 3,
    onClose,
    ...otherProps
  } = props;

  // const [time, setTime] = useState(defaultTime);
  const [time] = useCountDown({ seconds: defaultTime });

  const handleClose = () => {
    if (time) return;
    onClose?.();
  };

  return (
    <Modal
      {...otherProps}
      closable={false}
      timeout={0}
      maskStyle={{ backdropFilter: 'none' }}
      contentClassName={Style.wrapper}
      width={335}
      height="auto"
      // contentStyle={{ translate: '0 -80%' }}
    >
      <h3 className={Style.wrapperTitle}>{title}</h3>
      <p className={Style.wrapperContent}>{content}</p>
      <button
        type="button"
        className={`${Style.wrapperButton} ${
          !time && Style.wrapperButtonActive
        }`}
        onClick={handleClose}
      >
        {confirm} {!!time && <span>（{time}s）</span>}
      </button>
    </Modal>
  );
}
