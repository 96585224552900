import { Toast } from '@bhb-frontend/lithe-ui/lib';
import {
  IMG_UPLOAD_MAX,
  IMG_UPLOAD_MAX_TEXT,
  MAX_VIDEO_UPLOAD_DURATION,
  VIDEO_UPLOAD_MAX,
  VIDEO_UPLOAD_TEXT,
} from '@/layout/Material/const';
import { MultipleUpload } from '@/utils/upload';
import { compressImage, dataURLtoFile } from '@/utils/file';
import { getVideoCover } from '@/api/doc';

// 视频上传结果
export interface VideoUploadResults {
  url: string;
  duration?: number;
  coverUrl: string;
  file?: File;
}

/**
 * UploadResource 组件上传后的回调结果
 */
export interface UploadResourceResult {
  /**
   * 图片的地址
   */
  images: string[];
  videos: VideoUploadResults[];
}

/**
 * UploadResource.vue 组件中使用的视频信息
 */
export interface UploadResourceVideoInfo {
  /**
   * 视频地址
   */
  url: string;
  /**
   * 视频封面地址
   */
  coverUrl: string;
  /**
   * 视频时长(s)
   */
  duration: number;
  /**
   * 视频文件
   */
  file: File;
}

/**
 * 任务队列中任务的参数
 */
export interface TaskQueueParams {
  /**
   * 图片文件列表
   */
  fileImages: File[];
  /**
   * 视频文件列表
   */
  fileVideos: UploadResourceVideoInfo[];
}

/**
 * 图片文件的尺寸检查与过滤
 */
export function imageCheck(files: File[]): File[] {
  const initLen = files.length;
  // 限制过大文件
  files = [...files].filter((item: File) => item.size < IMG_UPLOAD_MAX);
  // 过滤大图片
  if (initLen !== files.length) {
    Toast.warning(
      `上传图片不能超过${IMG_UPLOAD_MAX_TEXT}，已过滤${
        initLen - files.length
      }张图片`
    );
  }
  return files;
}

/**
 * 视频文件的尺寸检查与过滤
 */
export async function videoCheck(
  files: File[]
): Promise<UploadResourceVideoInfo[]> {
  // 文档上传视频_视频大小<30M且<5分钟可上传
  let initLen = files.length;

  const promises: Promise<UploadResourceVideoInfo>[] = [];

  let fileInfo: UploadResourceVideoInfo[] = [];

  files = [...files].filter((f: File) => f.size && f.size <= VIDEO_UPLOAD_MAX);
  if (initLen !== files.length) {
    Toast.warning(
      `上传视频不能超过${VIDEO_UPLOAD_TEXT}，已过滤${
        initLen - files.length
      }个视频`
    );
    initLen = files.length;
  }

  // 将视频信息添加到promise中
  files.forEach((file: File) => {
    promises.push(getVideoDetail(file));
  });

  // const loading = $loading({ lock: true });
  fileInfo = await Promise.all(promises);
  // loading.close();

  fileInfo = fileInfo.filter(
    (v: VideoUploadResults) =>
      v.duration && v.duration <= MAX_VIDEO_UPLOAD_DURATION
  );
  if (initLen !== fileInfo.length) {
    Toast.warning(
      `上传视频不能超过${MAX_VIDEO_UPLOAD_DURATION / 60}分钟，已过滤${
        initLen - fileInfo.length
      }个视频`
    );
  }
  return fileInfo;
}

export async function uploadImage(
  files: File[]
): Promise<Record<string, any>[]> {
  if (files.length) {
    // 压缩文件
    const compressedFiles: File[] = [];
    const promiseArr: any[] = [];
    for (const file of files) {
      const { type } = file;
      promiseArr.push(compressImage(file)(1280, type)());
    }
    const resList: {
      status: 'fulfilled' | 'rejected';
      value?: string | File;
    }[] = await Promise.allSettled(promiseArr);
    const successRes = resList.filter(item => item.status === 'fulfilled');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < successRes.length; i++) {
      const item = successRes[i].value;
      if (!item) continue;
      if (typeof item !== 'string') {
        compressedFiles.push(item);
        continue;
      }
      const { name } = files[i];
      const compressedFile = dataURLtoFile(item, name);
      if (compressedFile) {
        compressedFiles.push(compressedFile);
      }
    }
    try {
      const res = await uploadOss(compressedFiles);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  return [];
}

export async function uploadVideo(
  fileInfo: UploadResourceVideoInfo[]
): Promise<VideoUploadResults[]> {
  const res: any[] = [];
  for (const item of fileInfo) {
    // 调用后端接口生成视频的截图作为封面
    try {
      // eslint-disable-next-line no-await-in-loop
      const [ossRes] = await uploadOss([item.file]);
      const {
        data: { fileUrl: coverUrl },
        // eslint-disable-next-line no-await-in-loop
      } = await getVideoCover(ossRes.url);
      res.push({
        ...item,
        ...ossRes,
        coverUrl,
      });
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return res;
}

// 上传文件
function uploadOss(files: File[]): Promise<Record<string, any>[]> {
  return new Promise((resolve, reject) => {
    // const loading = $loading({ lock: true });
    const uploader = new MultipleUpload(files);

    uploader.addQueueScene('document');
    uploader.addQueueModule('video');

    // 上传进度
    uploader.queueProgress((percent: number) => {
      console.log({ percent });

      // progress = percent;
    });

    // 返回url
    uploader
      .upload()
      .then(res => {
        // TODO: 上传
        /** 取消图层激活状态 */
        // $EventBus.$emit(CANCEL_LAYER_ACTIVE);
        /** 上传插图后，如果插图没有打开，则展示插图 tip */
        // $EventBus.$emit(UPLOAD_IMAGE_VIDEO);
        resolve(res);
      })
      .catch(err => {
        console.error(err);
        reject(err);
      })
      .finally(() => {
        // loading.close();
      });
  });
}

// 获取本地视频信息
function getVideoDetail(file: File): Promise<UploadResourceVideoInfo> {
  const coverWidth = 112;
  const coverHeight = 112;
  return new Promise(resolve => {
    const video = document.createElement('video');
    const url = URL.createObjectURL(file);
    video.src = url;
    video.setAttribute('preload', 'auto');
    video.addEventListener('loadeddata', () => {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      canvas.width = coverWidth;
      canvas.height = coverHeight;
      canvas?.getContext('2d')?.drawImage(video, 0, 0, coverWidth, coverHeight); // 绘制canvas
      const coverUrl = canvas.toDataURL('image/jpeg'); // 转换为base64

      resolve({
        url,
        duration: Number(video.duration.toFixed(2)),
        coverUrl,
        file,
      });
    });
  });
}

// 添加占位图
export function addPlaceholder(placeholder: Material[]) {
  console.log(placeholder);
}

export function showTime(val: number) {
  let minutes = 0;
  let seconds = 0;
  if (val >= 60) {
    minutes = Math.floor(val / 60);
    seconds = val % 60;
  } else {
    seconds = val;
  }
  const secondsStr = seconds.toFixed(2).split('.');
  const a = secondsStr[0].length >= 2 ? secondsStr[0] : `0${secondsStr[0]}`;
  const b = secondsStr[1].length >= 2 ? secondsStr[1] : `0${secondsStr[1]}`;
  return `${minutes >= 10 ? minutes : `0${minutes}`}:${a}.${b}`;
}
