import { Button, Modal, Toast } from '@bhb-frontend/lithe-ui';
import { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import AudioPlayer from '@bhb-frontend/audio-player';
import assets from '@/assets';
import style from './style.module.less';
import { PENDINGLOOPTEXTS, PENDINGLOOPTEXTSSTYLE } from '@/constants/View';
import { cancelVideo, createPreviewVideo } from '@/api/app/video';
import { useStores } from '@/store';
import socket from '@/core/socket';
import { SocketResponse } from '@/types/socket';
import { SOCKET_RESPONSE } from '@/constants/Socket';
import { IModaleText, TRejectContent } from '@/types/videos';
import { DOC_ERROR_CODE } from '@/constants/Createvideo';
import { DocDetail } from '@/types/ServerData';
import HintModal from './HintModal';
import {
  getAuditErrText,
  getPrErrText,
  getRejectContent,
  videoErrorConent,
  VIDEO_STATUS,
} from '@/constants/Audio';
import WeChatQRcodeModal from '@/components/WeChatQRCode/WeChatQRcodeModal';
import { ErrorResponse } from '@/types/api';

const View = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { app, user } = useStores();
  /** 视频创建失败 */
  const [createError, setCreateError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const videoSrc = useRef<string>('');

  const videoRef = useRef<HTMLVideoElement>(null);

  const taskId = useRef<string>('');

  /** 提示弹窗文案 */
  const hintModaleText = useRef<Partial<IModaleText>>({});

  /** 提示弹窗展示 */
  const [hintModalVisible, setHintModalVisible] = useState<boolean>(false);

  /** 是否存在数字人 */
  const [isHaveDigital, setIsHaveDigital] = useState<boolean>(false);

  /** 是否竖版 */
  const [isVertical, setIsVertical] = useState<boolean>(true);

  const audioPlayer = useRef(AudioPlayer.getInstance());

  useEffect(() => {
    if (!user.socketLink) return;
    socket.on((res: SocketResponse<any>) => {
      if (taskId.current !== res.data?.renderTaskId) return;
      switch (res.event) {
        case SOCKET_RESPONSE.CREATE_VIDEO_SUCCESS:
          setLoading(false);
          videoSrc.current = res.data.videoUrl;
          break;
        case SOCKET_RESPONSE.CREATE_VIDEO_FAILED:
          hangdleError(res.data.rejectContent, res.data?.code);
          break;
        case SOCKET_RESPONSE.VIDEO_STATUS_CHANGE:
          if (
            [
              VIDEO_STATUS.REJECT,
              VIDEO_STATUS.REVIEWFAIL,
              VIDEO_STATUS.FAIL,
            ].includes(res.data.status)
          ) {
            hangdleError(res.data.rejectContent, res.data?.code);
          }
          break;

        default:
          break;
      }
    });
  }, [user.socketLink]);

  useEffect(() => {
    videoSrc.current = '';
    if (visible) {
      setLoading(true);
      setCreateError(false);
      return;
    }
    if (!videoRef.current?.paused) {
      videoRef.current?.pause();
    }
  }, [visible]);

  /** 点击预览 */
  const handleClick = async () => {
    // 文档区是否存在占位图
    const isPlaceholder = app.scenes.some(scene => scene.getIsPlaceholder());
    if (isPlaceholder) {
      Toast.warning('资源上传中，请稍后重试');
      return;
    }
    setLoading(true);
    audioPlayer.current?.pause();

    const { sceneThemeList, documentInfo, documentId } = app.getServeData();
    try {
      const res = await createPreviewVideo({
        documentInfo,
        documentId,
        sceneThemeList,
      });
      const { data, error } = res;
      if (error === 0) {
        const { renderTaskId, isReturn, paidContent, rejectContent } = data;
        if (isReturn && paidContent?.videoMoreTime) {
          // 视频超出时长
          hintModaleText.current = getPrErrText(paidContent.videoMoreTime);
          setVisible(false);
          setHintModalVisible(true);
          return;
        }
        if (Array.isArray(rejectContent) && rejectContent.length) {
          // 违规内容
          onError(rejectContent);
          return;
        }
        taskId.current = renderTaskId;
        const serveData = documentInfo as DocDetail;
        // 判断是否有数字人
        isHaveMan(serveData);
        // 判断主题横版  竖版
        isVerticalTheme(serveData);

        setVisible(true);
      }
    } catch (err) {
      const errorCode = (err as ErrorResponse)?.body?.error;
      if (errorCode) {
        if (errorCode === DOC_ERROR_CODE.EQUITY_DEFICIENCY) {
          WeChatQRcodeModal.show();
        }
      }
    }
  };

  /** 是否存在数字人 */
  const isHaveMan = (docDetail: DocDetail) => {
    setIsHaveDigital(false);
    const scenes = docDetail?.jsonText?.scenes || [];

    if (!scenes) return;

    const isDigital = scenes.some(
      item => item.ipImageId || item.presetIpImageId
    );

    setIsHaveDigital(isDigital);
  };

  /** 是否竖版主题 */
  const isVerticalTheme = (docDetail: DocDetail) => {
    setIsVertical(true);
    const scenes = docDetail?.jsonText?.scenes || [];
    // 所有场景的模版一样， 所以使用一个即可
    const themeInfo = scenes[0]?.themeInfo;
    if (themeInfo) {
      const { orientation } = themeInfo;
      if (orientation === 'horizontal') {
        setIsVertical(false);
      }
    }
  };

  /** 预览失败 */
  const onError = (rejectContent: TRejectContent) => {
    const text = getRejectContent(rejectContent);
    hintModaleText.current = getAuditErrText(text);
    setVisible(false);
    setHintModalVisible(true);
  };

  const hangdleError = (rejectContent?: TRejectContent, code?: number) => {
    setLoading(false);
    if (code === DOC_ERROR_CODE.AUDIT_FAILURE) {
      if (Array.isArray(rejectContent) && rejectContent.length) {
        // 内容审核不通过
        onError(rejectContent);
        return;
      }
      // 视频审核不通过
      hintModaleText.current = videoErrorConent;
      setVisible(false);
      setHintModalVisible(true);
      return;
    }
    // 云空间不足，提示联系商务
    if (code === DOC_ERROR_CODE.STORAGE_LIMIT_ERROR) {
      setVisible(false);
      WeChatQRcodeModal.show();
      return;
    }

    setCreateError(true);
  };

  const renderError = () => (
    <div className={style['error-content']}>
      <i className="iconfont icon-video_icon_broken" />
      视频生成失败
    </div>
  );

  /** 点击关闭按钮 */
  const handleCloes = async () => {
    setVisible(false);
    if (!loading) return;
    await cancelVideo({
      renderTaskId: taskId.current,
    }).catch(err => {
      console.log(err);
    });
  };

  const renderLoading = () => (
    <div className={style['loading-content']}>
      <div className={style.loading}>
        <img src={assets.images.doc['pic_loading.png']} alt="loading" />
      </div>
      <div className={style['loading-title']}>预览加载中</div>
      <div className={style['tip-scroll']} style={PENDINGLOOPTEXTSSTYLE}>
        <ul className={style['tip-scroll-ul']}>
          {PENDINGLOOPTEXTS.concat(PENDINGLOOPTEXTS[0]).map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${item}-${index}`} className={style['tip-scroll-li']}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const renderVideo = () => (
    <div
      className={`${style['video-wrapper']} ${
        isVertical
          ? style['video-wrapper-vertical']
          : style['video-wrapper-horizontal']
      }`}
    >
      {videoSrc && (
        <video
          ref={videoRef}
          autoPlay
          controls
          src={videoSrc.current}
          className={style['preview-video']}
          // webkit-playsinline="true"
          // playsinline="true"
          // preload
          // destroy-on-close
        />
      )}
      {isHaveDigital && (
        <div className={style['digital-man-tip']}>
          <i className="iconfont icon-a-preview_icon_tips2x" />
          为了你能快速预览，确认内容是否正确；
          <br />
          预览时数字人为单帧静态，仅供参考
        </div>
      )}
    </div>
  );

  const renderContent = () => (loading ? renderLoading() : renderVideo());

  return (
    <>
      <Button className="opt-btn" onClick={handleClick}>
        预览
      </Button>
      <Modal
        visible={visible}
        closable={false}
        width="409px"
        height="763px"
        className={style.view}
        timeout={0}
      >
        <div className={style.header}>
          <div className={style.title}>预览</div>
          <div className={style.close} onClick={handleCloes}>
            <i className="iconfont icon-dingyue_icon_del" />
          </div>
        </div>
        <div className={style.content}>
          {createError ? renderError() : renderContent()}
        </div>
      </Modal>

      <HintModal
        visible={hintModalVisible}
        onOk={() => setHintModalVisible(false)}
        modaleText={hintModaleText.current}
      />
    </>
  );
};

export default observer(View);
