import { useState, useRef } from 'react';
import { LoadMore, Spin, Toast } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { observer } from 'mobx-react';
import copy from 'copy-to-clipboard';
import { ThemeDetail, Percent, ItemMenuType } from '@/types/material';
import ImageWrapper from '@/components/ImageWrapper';
import { removeTheme } from '@/api/material';
import { useStores } from '@/store';
import { saveTemplateToSelf } from '@/api/doc';
import { SELF_CATEGORY } from '../../const';
import ConfirmModal from '@/components/Confirm';
import { getCoverInfo } from '@/utils/getCoverInfo';
import ItemMenu from '../../components/ItemMenu/ItemMenu';
import Style from './style.module.less';

const itemMenu = [
  {
    label: '删除',
    icon: <i className="iconfont icon-dingyue_icon_delete" />,
    value: ItemMenuType.delete,
  },
  {
    label: '复制id',
    icon: <i className="iconfont icon-a-tuwen_icon_copy1x" />,
    value: ItemMenuType.copy,
  },
];

interface TempalteContentProps {
  /** 资源列表 */
  list?: ThemeDetail[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;
  /** 主题模板比例 */
  percent: Percent;
  /** 当前的tab分类id */
  tabId?: string;
  /** 初始化列表 */
  initList?: () => void;
  /** 设置模板比例 */
  setPercent: (percent: Percent) => void;
}

function TempalteContent(props: TempalteContentProps) {
  const {
    list,
    loading,
    hasMore,
    loadMore,
    percent,
    tabId,
    initList,
    setPercent,
  } = props;

  /** 是否保存模板中 */
  const [isSaveingTemp, setIsSaveingTemp] = useState<boolean>(false);
  /** 删除弹框 */
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  /** 替换弹框 */
  const [replaceModalVisible, setReplaceModalVisible] =
    useState<boolean>(false);
  /* 替换比例提示 */
  const [changePercentTipModalVisible, setChangePercentTipModalVisible] =
    useState<boolean>(false);

  const removeId = useRef<string>('');
  const replaceId = useRef<string>('');

  const { app } = useStores();
  const { activedScene, id: documentId, scenes } = app;

  /** 比例 */
  const aspect = scenes[0]?.configure?.aspect;
  /** 是否可切换的目标比例tab */
  const isCanReplaceTemp = () => {
    if (scenes.length > 1) {
      if (
        (activedScene.orientation === 'horizontal' &&
          percent === Percent.Vertical) ||
        (activedScene.orientation === 'vertical' &&
          percent === Percent.Horizontal)
      ) {
        return false;
      }
    }

    return true;
  };

  /** 切换模板 */
  const handleClick = async (item: ThemeDetail) => {
    if (!isCanReplaceTemp()) {
      setChangePercentTipModalVisible(true);
      return;
    }
    replaceId.current = item.id;
    setReplaceModalVisible(true);
  };

  const replaceTemplate = () => {
    // 先释放组件
    app.releaseAllCmps();
    app.activedScene.replaceTheme(replaceId.current);
    setReplaceModalVisible(false);
  };

  /** 保存模板 */
  const saveTemplate = async () => {
    const { id, cover, presetIpImageId, ipImageId, configure } = activedScene;
    setIsSaveingTemp(true);
    let coverKey = cover || '';
    try {
      coverKey = (await getCoverInfo()).key || '';
    } catch (error) {
      console.log('截图失败：', error);
    }

    try {
      await saveTemplateToSelf(documentId || '', {
        presetIpImageId,
        sceneId: id,
        coverKey,
        ipImageId,
      });
      const { width = 0, height = 0 } = configure;
      Toast.success('保存模板成功');
      /** 防止当前激活场景为9:16，在用户在16:9中保存，需要跳转到对应的模板比例类型 */
      if (width > height && percent !== Percent.Horizontal) {
        setPercent(Percent.Horizontal);
        return;
      }
      if (width < height && percent !== Percent.Vertical) {
        setPercent(Percent.Vertical);
        return;
      }
      initList?.();
    } catch (err) {
      console.log(err);
      Toast.error('保存模板失败');
    } finally {
      setIsSaveingTemp(false);
    }
  };

  const onChange = (value: ItemMenuType, id: string) => {
    if (value === ItemMenuType.delete) {
      removeId.current = id;
      setRemoveModalVisible(true);
      return;
    }
    if (value === ItemMenuType.copy) {
      copy(id);
      Toast.success('复制id成功');
    }
  };

  const onRemove = async () => {
    await removeTheme(removeId.current);
    Toast.success('删除成功！');
    initList?.();
    setRemoveModalVisible(false);
  };

  return (
    <div className={Style['template-content']}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        finishedText=""
        className={Style['load-more']}
      >
        {/* 添加模板 */}
        {tabId === SELF_CATEGORY.id && (
          <div
            onClick={saveTemplate}
            className={cs(
              percent === Percent.Vertical
                ? Style['vertical-template']
                : Style['horizontal-template'],
              Style['template-item']
            )}
          >
            {isSaveingTemp ? (
              <Spin spinning />
            ) : (
              <i className="iconfont icon-a-tuwen_template_icon_baocun1x" />
            )}
            <span className={Style['save-template-title']}>
              {isSaveingTemp ? '保存模板中' : '保存模板'}
            </span>
          </div>
        )}

        {/* item 模板 */}
        {list?.map(item => (
          <div
            id={`menu-${item.id}`}
            key={item.id}
            className={cs(
              percent === Percent.Vertical
                ? Style['vertical-template']
                : Style['horizontal-template'],
              Style['template-item']
            )}
          >
            <ImageWrapper
              objectFit="cover"
              urls={[item.coverUrl]}
              onClick={() => handleClick(item)}
            />

            {tabId === SELF_CATEGORY.id && (
              <ItemMenu<ItemMenuType>
                onChange={value => {
                  onChange(value, item.id);
                }}
                items={itemMenu}
                container={() => document.querySelector(`#menu-${item.id}`)!}
                className={Style['item-menu']}
              />
            )}
          </div>
        ))}
      </LoadMore>

      {/* 弹窗 */}
      <ConfirmModal
        visible={removeModalVisible}
        onCancel={() => {
          setRemoveModalVisible(false);
        }}
        onOk={onRemove}
        title="提示"
        content="确认删除该模板?"
      />

      <ConfirmModal
        onCancel={() => {
          setReplaceModalVisible(false);
        }}
        onOk={replaceTemplate}
        visible={replaceModalVisible}
        title="确定切换模板吗？"
        content="切换模板后，页面样式将会被替换"
      />

      <ConfirmModal
        visible={changePercentTipModalVisible}
        cancelText="关闭"
        content={`
当前文档的画布比例为[${aspect?.num}:${aspect?.den}]，不支持更换比例。`}
        okText={null}
        width={440}
        onCancel={() => {
          setChangePercentTipModalVisible(false);
        }}
      />
    </div>
  );
}

export default observer(TempalteContent);
