import { useState, useRef, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import Quill from 'quill';
import Delta from 'quill-delta';
import { throttle } from '@bhb-frontend/utils/lib/throttle';
import { TextStruc } from '@/models/CmpStruc';
import { getTextStroke } from '@/helpers/Styles';
import { CmpsFactoryProps } from '../Cmp';
import { useStores } from '@/store';
import cssmodule from './Text.module.less';
import { toPoint9 } from '@/utils/point9';
import { getDefaultContent } from '@/config/defaultCmpTextContent';

export interface TextProps extends CmpsFactoryProps<TextStruc> {}

function Text(props: TextProps) {
  const { app } = useStores();
  const { model, style, editable } = props;
  const { content, charAttrs, fill, isTitle, tag } = model;

  const { width = 0, height = 0 } = model.style;

  const textMainRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);
  /** 点九图地址 */
  const [point9Url, setPoint9Url] = useState('');

  const [textValue, setTextValue] = useState('');

  /**
   * 实例化quill
   * */
  const initEditor = () => {
    quillRef.current = new Quill(textMainRef.current, {});
    quillRef.current?.enable(false);
    quillRef.current?.blur();
  };

  const formatTitleText = (val: string): Delta => {
    quillRef.current?.setText(val);
    if (charAttrs && charAttrs.length) {
      charAttrs.forEach((i: Delta) => {
        const { bgColor, color, start, endPos } = i;
        if (bgColor) {
          quillRef.current?.formatText(start, endPos - start, {
            background: bgColor,
          });
        }
        if (color) {
          quillRef.current?.formatText(start, endPos - start, {
            color,
          });
        }
      });
    }
    return quillRef.current?.getContents();
  };

  /**
   * 初始化富文本内容
   *  */
  const initTextContent = (val: string) => {
    const delta = formatTitleText(val);
    quillRef.current?.setContents(delta);
  };

  /** 初始化文字内容 */
  useEffect(() => {
    let text = isTitle ? app.titleContent : content;
    if (!text) {
      text = getDefaultContent(tag);
    }
    setTextValue(text);
  }, [content, app.titleContent]);

  /** 初始化富文本 */
  useEffect(() => {
    initEditor();
  }, []);

  useEffect(() => {
    initTextContent(textValue);
  }, [textValue, model.charAttrs]);

  const textStorkeStyle = getTextStroke(fill);

  /**
   * 获取 点9图
   */
  const getPoint9Url = async () => {
    const { fill, url, style } = model;
    const { width = 0, height = 0 } = style;
    if (url && fill && fill.imageNinePath) {
      const { imageNinePath } = fill;
      const data = await toPoint9(
        url,
        +width,
        +height,
        imageNinePath?.x,
        imageNinePath?.y,
        imageNinePath?.w,
        imageNinePath?.h
      );
      setPoint9Url(data);
    }
  };

  const getPointUrlCB = useCallback(throttle(getPoint9Url, 500), []);

  useEffect(() => {
    getPointUrlCB();
  }, [width, height]);

  return (
    <>
      <div style={{ ...style }} className={cx(cssmodule['text-container'])}>
        {/* 描边 */}
        {textStorkeStyle && (
          <div className={cssmodule['text-storke']} style={textStorkeStyle}>
            {textValue}
          </div>
        )}

        {/* 文字主体 */}
        <div
          spellCheck="false"
          className={cssmodule['text-main']}
          ref={textMainRef}
          style={{
            visibility: model.isEdit && editable ? 'hidden' : 'visible',
          }}
        />
      </div>

      {/* 背景图 */}
      {point9Url && (
        <img
          className={cssmodule['text-sticker-background']}
          src={point9Url}
          alt=""
        />
      )}
    </>
  );
}

export default observer(Text);
