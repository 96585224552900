import { Input } from '@bhb-frontend/lithe-ui';
import React, { cloneElement, ReactElement, useRef, useState } from 'react';
import { useUnactivate } from 'react-activation';
import cs from 'classnames';
import { CustomListResponse, ListQuery } from '@/types/api';
import { SearchParams } from '@/types/material';

import Style from './search.module.less';

interface Props<T> {
  placeholder: string;
  apiFn: (params: SearchParams) => Promise<CustomListResponse<T>>;
  children: ReactElement;
  className?: string;
}

export default function SearchList<T>({
  placeholder,
  apiFn,
  children,
  className,
}: Props<T>) {
  const [keyword, setKeyWord] = useState('');
  const [listVisible, setListVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const pageInfo = useRef<ListQuery>({ sid: '', pageSize: 10 });

  const [list, setList] = useState<T[]>([]);

  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      const { data } = await apiFn({
        ...pageInfo.current,
        keyword,
      });

      pageInfo.current = {
        ...pageInfo.current,
        sid: data.sid,
      };

      setHasMore(!!data.sid);
      return data;
    } finally {
      setLoading(false);
    }
  };

  useUnactivate(() => {
    setKeyWord('');
    handleClear();
  });

  /** 加载更多 */
  const loadMore = async () => {
    const { results } = await getList();
    setList([...list, ...results]);
  };

  const handleEnter = async () => {
    if (!keyword) {
      handleClear();
      return;
    }
    pageInfo.current.sid = '';
    const { results } = await getList();
    setList(results);
    setListVisible(true);
  };

  const handleClear = () => {
    pageInfo.current.sid = '';
    setListVisible(false);
    setList([]);
  };

  if (!children) return null;

  return (
    <div className={cs(Style.search, className)}>
      <Input
        className={Style.searchInput}
        contentClassName={Style.searchInputEl}
        placeholder={placeholder}
        value={keyword}
        allowClear
        onChange={e => {
          setKeyWord(e.target.value);
        }}
        onClear={handleClear}
        onPressEnter={handleEnter}
        prefix={
          <i
            className={`iconfont icon-a-common_icon_search2x ${Style.searchInputIcon}`}
          />
        }
      />

      <div
        className={Style.searchContent}
        style={{ display: listVisible ? 'block' : 'none' }}
      >
        {list.length === 0 && (
          <div className={Style.noData}>暂未搜索到内容</div>
        )}
        {cloneElement(children, { list, loadMore, loading, hasMore })}
      </div>
    </div>
  );
}
