/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/825c7c737894d6c6a0be9b16dcb99c32.png */
  'icon-choose-hover.png': '825c7c737894d6c6a0be9b16dcb99c32.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5b47efb2c92bd111ea169d28093ad029.png */
  'icon-common-tips.png': '5b47efb2c92bd111ea169d28093ad029.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/83a1e4573908b4d72cdec44226035452.png */
  'icon-loading.png': '83a1e4573908b4d72cdec44226035452.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/585029de83533e6825102fe29eda3441.png */
  'icon-question.png': '585029de83533e6825102fe29eda3441.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a47251b8881721360a60c15bbf86740f.png */
  'icon-video-play.png': 'a47251b8881721360a60c15bbf86740f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/0539adc53b1052ed71b6d48f51d7eed8.png */
  'img-empty-box.png': '0539adc53b1052ed71b6d48f51d7eed8.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/77ccba1cd302a2a3c721ae700147fb50.png */
  'dingyue_icon_empty@2x.png': '77ccba1cd302a2a3c721ae700147fb50.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/3f1ed976f1b7f7cb8375dfce8d47de6b.png */
  'img-empty-order.png': '3f1ed976f1b7f7cb8375dfce8d47de6b.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9c633c27941e28d8d09f2fe124e91430.png */
  'img-login-bg.png': '9c633c27941e28d8d09f2fe124e91430.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/943e77d250b71b2ee7ce7d7c0bb0a176.png */
  'img-pay-wx.png': '943e77d250b71b2ee7ce7d7c0bb0a176.png',
};
