import { action, makeObservable, observable } from 'mobx';
import { CmpTypeEnum } from '@/constants/CmpType';
import CreateCmpStruc from '../FactoryStruc/CmpFactory';
import CmpStruc from './CmpStruc';

export default class GroupStruc<
    T extends ComponentModel.Group = ComponentModel.Group
  >
  extends CmpStruc<T>
  implements ComponentModel.Group
{
  cmps!: CmpStruc[];

  constructor(data?: Partial<ComponentModel.Group>) {
    super(data);

    makeObservable(this, {
      cmps: observable,
      removeCmp: action,
    });

    this.cmps = (data?.cmps || []).map(model =>
      CreateCmpStruc(model.type, model, this)
    );
    this.type = CmpTypeEnum.GROUP;
  }

  protected getType(): CmpTypeEnum {
    return CmpTypeEnum.GROUP;
  }

  /**
   * 返回组合数据类型
   */
  model(): ComponentModel.Group {
    const model = super.model();
    return {
      ...model,
      cmps: this.cmps.map(cmp => cmp.model()),
    };
  }

  /**
   * 打包组件
   */
  packUp(cmps: CmpStruc[]) {
    const parent = this.getParent();
    if (!parent) return;
    this.getGroupRect(cmps);

    cmps.forEach(_cmp => {
      // do some
    });
  }

  /**
   * 打散组合
   */
  unPack() {
    //
  }

  /**
   * 获取组合矩形范围
   */
  getGroupRect(_cmps: CmpStruc[]) {
    //
  }

  /**
   * 删除组件
   * @param cmp 选中的组件
   */
  removeCmp(cmp: CmpStruc) {
    const index = this.getCmpIndex(cmp);
    if (index < 0) return;
    this.cmps.splice(index, 1);
    cmp.group = null;
  }

  /**
   * 或许组件的下标
   * @param cmp 选中的组件
   * @returns {number} 组件的位置
   */
  getCmpIndex(cmp: CmpStruc): number {
    return this.cmps.findIndex(item => item.id === cmp.id);
  }

  addCmp(cmp: CmpStruc, index?: number) {
    const i = this.getCmpIndex(cmp);
    if (i >= 0) return;
    cmp.group = this;
    index != null ? this.cmps.splice(index, 0, cmp) : this.cmps.push(cmp);
  }

  getName() {
    return this.name;
  }

  onCopy() {
    //
  }
}
