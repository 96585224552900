/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/c4c3e4cfb883c00e1f33550d2f20ef82.png */
  'dingyuehao.png': 'c4c3e4cfb883c00e1f33550d2f20ef82.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/f6726d9cd77e076f151bf19ab66e0205.png */
  'image-default.png': 'f6726d9cd77e076f151bf19ab66e0205.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/f3ee6450544b4cb59e2d9ade89f16d0c.png */
  'tuwenkuaijian.png': 'f3ee6450544b4cb59e2d9ade89f16d0c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2f9fd30065416dec38803cccafe35faa.png */
  'zhibokuaijian.png': '2f9fd30065416dec38803cccafe35faa.png',
};
