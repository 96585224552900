// import { Material } from '@/types/doc-materials';
import { ThemeDetailItem } from '@/types/ServerData';
import { getFootageMap, getTextStickerImgMap } from '@/utils/formatData';
import { createCmp } from './Cmp';
import { createBackCmp } from './Cmp/Background';

interface BuildCmpsParam {
  materials?: Material[];
  themeInfo: ThemeDetailItem;
}

/** 构造cmps */
export function buildCmps(param: BuildCmpsParam) {
  const { materials, themeInfo } = param;
  let cmps: ComponentModel.Base[] = [];
  const footage = themeInfo.footage;

  // * 这些图片资源只在场景转换一次即可
  // 图片资源
  const resources = getFootageMap(footage);
  // 文字贴纸图片资源
  const textImageResources = getTextStickerImgMap(footage);

  const {
    composition: { layers },
  } = themeInfo;

  // 摘掉水印， 保存的时候也无需保存水印，水印统一由服务端加
  const renderLayers = layers.filter(layer => ![0, 6].includes(layer.usage));
  cmps = renderLayers.reduce((res, layer) => {
    const cmp = createCmp({
      layer,
      resources,
      textImageResources,
      materials,
      themeInfo,
    });
    // 资源错误将不添加
    if (cmp) {
      res.push(cmp);
    }
    return res;
  }, [] as ComponentModel.Base[]);
  // 添加背景图层
  cmps.unshift(createBackCmp(themeInfo, resources));
  console.log('cmps', JSON.parse(JSON.stringify(cmps)));
  return cmps;
}
