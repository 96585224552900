import { Orientation } from '@/constants/Orientation';
import { presetSize, ResolutionEnum } from '@/constants/SceneSize';

/**
 * 获取尺寸
 * @param resolution 分辨率
 * @param direction 视频方向
 * @returns {Size}
 */
export function getSceneSize(
  resolution: ResolutionEnum,
  direction?: Orientation | null
): Size {
  let { width, height } = presetSize[resolution];

  if (width === 0) {
    // TODO - 自定义宽度
  }

  if (height === 0) {
    // TODO - 自定义高度
  }

  if (direction === Orientation.HORIZONTAL) {
    [width, height] = [height, width];
  }

  return { width, height };
}
