import {
  HorizontalAlign,
  tagToUsageMap,
  VerticalAlign,
} from '@/constants/formatData';
import { TextStruc } from '@/models/CmpStruc';
import { Image, TextUsage, ThemeDetailItem } from '@/types/ServerData';
import { pxToNumber, transformTextFillToPatterns } from '@/utils/formatData';

interface TransformTextToDataParam {
  cmp: TextStruc;
  themeInfo: ThemeDetailItem;
  width: number;
  height: number;
  positionVector: number[];
}

export function transformTextToData(param: TransformTextToDataParam) {
  const { cmp, themeInfo, width, height, positionVector } = param;
  const modal = cmp.model();

  const {
    styleId = '',
    content,
    contents,
    charAttrs = [],
    features = 0,
    maxLines,
    fontDesc,
    textExtensionFields,
    fill = {},
    imgExtensionFields,
    textAnimation = {},
    specialeEffect,
    tag,
    sourceId,
    style,
    extra,
    fileName,
    key,
    url,
  } = modal;

  const usage = tagToUsageMap[tag];
  const styles = {
    parentId: '',
    objectId: styleId,
    fileName: `${styleId}.json`,
  };
  themeInfo.footage.styles.push(styles);
  const {
    fontSize = 0,
    letterSpacing = 0,
    lineHeight = 0,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    verticalAlign = 'middle',
    textAlign = 'left',
  } = style || {};
  const textUsage = usage as TextUsage;
  const fontSizeNumber = pxToNumber(fontSize);
  const texts = {
    usage: textUsage,
    width,
    height,
    styleId,
    objectId: sourceId,
    content,
    contents,
    charAttrs,
    fontSize: fontSizeNumber,
    leading: fontSizeNumber * lineHeight,
    features,
    kerning: letterSpacing,
    maxLines,
    position: {
      x: positionVector[0],
      y: positionVector[1],
    },
  };
  const layerOptions = {};
  // 文字图片信息合并
  if (extra?.stickerId) {
    Object.assign(texts, { extra: { stickerId: extra?.stickerId } });
  }
  themeInfo.footage.texts.push(texts);
  // 文字图片添加images
  if (fill?.imageId) {
    const imageRource = {
      ...imgExtensionFields,
      fileName,
      imageKey: key,
      imageUrl: url,
      objectId: fill?.imageId,
      extra,
    } as Image;
    // console.log('imageRource', imageRource);
    themeInfo.footage.images.push(imageRource);
  }
  const {
    position,
    charAttrs: cAttrs,
    styleId: styId,
    ...optionalFields
  } = texts;
  // console.log('optionalFields', optionalFields);
  Object.assign(themeInfo.optionalFields, {
    [styleId]: {
      ...textExtensionFields,
      ...optionalFields,
      fontDesc,
      content,
      contents,
      padding: {
        top: paddingTop,
        left: paddingLeft,
        right: paddingRight,
        bottom: paddingBottom,
      },
      objectId: styleId,
      hAlign: HorizontalAlign[textAlign],
      vAlign: VerticalAlign[verticalAlign],
      patterns: transformTextFillToPatterns(fill),
    },
  });
  // 文字动画
  if (specialeEffect) {
    themeInfo.footage.effects.push(specialeEffect);
  }
  if (textAnimation) {
    Object.assign(layerOptions, {
      textAnimation,
    });
  }
  return layerOptions;
}
