import { LoadMore } from '@bhb-frontend/lithe-ui';
import { useEffect, useState, useRef } from 'react';
import AudioPlayer, { AudioPlayerType } from '@bhb-frontend/audio-player';
import { MusicDetail } from '@/types/material';
import MusicListItem from './MusicListItem';
import Style from './style.module.less';
import { MusicTabTypes } from './const';
import { CmpTypeEnum } from '@/constants/CmpType';
import { app } from '@/store';

interface DubbingListProps {
  /** 资源列表 */
  list?: MusicDetail[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;
  /** 选中 */
  onSelect?: (val: MusicDetail) => void;
  /** 删除 */
  onRemove?: (val: MusicDetail) => void;
  /** 音乐资源类型 */
  type?: MusicTabTypes;
}

function DubbingList(props: DubbingListProps) {
  const { list, loading, hasMore, loadMore, onSelect, type, onRemove } = props;

  const [playingUrl, setPlayingUrl] = useState('');

  const [playTime, setPlayTime] = useState(0);

  const audioPlayer = useRef(AudioPlayer.getInstance());

  useEffect(() => {
    const eventCB = (isPlaying: boolean) => {
      /** 暂停 */
      if (!isPlaying) setPlayingUrl('');
    };

    audioPlayer.current.onPlayEvent(AudioPlayerType.MUSIC_SOURCE, eventCB);
    audioPlayer.current.timeUpdate((time: number) => {
      setPlayTime(time);
    });
    return () => {
      audioPlayer.current.offPlayEvent(AudioPlayerType.MUSIC_SOURCE, eventCB);
    };
  }, []);

  // 切换配音时
  const switchMusic = (item: MusicDetail) => {
    const url = type === MusicTabTypes.My ? item.materialUrl : item.musicUrl;
    console.log(type, url);

    if (playingUrl === url) {
      audioPlayer.current.pause();
      setPlayingUrl('');
      return;
    }
    setPlayingUrl(url);
    audioPlayer.current.play(AudioPlayerType.MUSIC_SOURCE, url);
    app.setActiveMediaCmp(CmpTypeEnum.UNKNOWN);
    audioPlayer.current.setVolume(1);
  };

  const getPlaying = (item: MusicDetail) => {
    if (!item.musicUrl && !item.materialUrl) return false; // 没有音频默认暂停
    if (item.musicUrl || item.materialUrl) {
      return [item.musicUrl, item.materialUrl].includes(playingUrl);
    }
    return false;
  };

  return (
    <div className={Style.musicContent}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        finishedText=""
        className={Style['load-more']}
      >
        {list?.map(item => (
          <MusicListItem
            time={playTime}
            item={item}
            key={item.id}
            isPlaying={getPlaying(item)}
            switchMusic={() => {
              switchMusic(item);
            }}
            onSelect={onSelect}
            onRemove={onRemove}
            type={type}
          />
        ))}
      </LoadMore>
    </div>
  );
}

export default DubbingList;
