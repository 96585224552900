import cookie from 'js-cookie';
import { ENV } from '@/config/hosts';

/**
 * 固定字符串型的api列表；
 */
type StringObject = Record<string, string>;

// 获取当前domain
export function getDomain() {
  const domainArr = window.location.host.split('.').reverse();
  const prod = `${domainArr[1]}.${domainArr[0]}`;
  const testAndGray = `${domainArr[2]}.${domainArr[1]}.${domainArr[0]}`;
  // 根据环境配置cookie设置域名
  const domainMap: StringObject = {
    dev: '',
    test: testAndGray,
    gray: testAndGray,
    prod,
  };
  return domainMap[ENV];
}

const cookieSetOption = () => ({
  path: '/',
  domain: getDomain(),
});

/* 一年时间 */
export const expiresYear = 1000 * 60 * 60 * 24 * 365;

/* 一年到期时间 */
export const expiresDate = new Date(
  new Date(new Date().toLocaleDateString()).getTime() + expiresYear
);

// 获取cookie信息
export function getCookie(name: string) {
  return cookie.get(name);
}

// 设置cookie信息
export function setCookie(name: string, value: string, params = {}) {
  cookie.set(name, value, { ...cookieSetOption(), ...params });
}

// 移除cookie信息
export function removeCookie(name: string) {
  cookie.remove(name, cookieSetOption());
}
