import React, { useState } from 'react';
import { Loading, Toast } from '@bhb-frontend/lithe-ui/lib';

import { observer } from 'mobx-react';
import Uploader from '@/utils/upload';

import CroppedImg from '@/components/CroppedImg';

import { BACKGROUND_CROP, IMG_UPLOAD_MAX } from '../../const';

import Style from './style.module.less';
import { fileToImg, getUploadImage } from '@/utils/file';
import { useStores } from '@/store';
import { BackgroundType } from '@/constants/BackgroundType';

function AddBackground() {
  const { app } = useStores();

  const [loading, setLoading] = useState(false);
  const [croppedModalVisible, setCroppedModalVisible] = useState(false);
  const [imgInfo, setImgInfo] = useState<{
    img: HTMLImageElement;
    imgSrc: string;
    imgName: string;
    imgType: string;
    imgWidth: number;
    imgHeight: number;
    destoryFn: () => void;
  } | null>(null);
  const [croppedInfo, setCroppedInfo] = useState<{
    croppedDefaultWidth: number;
    croppedDefaultHeight: number;
  } | null>(null);

  /** 添加 */
  const add = (url: string, key?: string) => {
    app.activedScene.setBackground(
      BackgroundType.IMAGE,
      {
        id: '',
        name: '',
        percent: '',
        backgroundUrl: url,
        backgroundKey: key || '',
        coverUrl: '',
      },
      undefined,
      'material'
    );
    cancel();
  };

  const uploadImage = async (blob: Blob, name: string) => {
    setLoading(true);
    const file = new File([blob], name);
    const uploader = new Uploader(file);
    uploader.addModule('video');
    uploader.addScene('background');
    uploader
      .upload()
      .then(({ url, key }) => {
        add(url, key);
      })
      .catch(e => console.error(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const orgFile = e.target.files[0];
    // 手动校验类型
    const file = await getUploadImage(orgFile);
    if (!file) return;
    if (file.size > IMG_UPLOAD_MAX) {
      Toast.warning('图片不能超过10M！');
      return;
    }

    const { img, destoryFn } = await fileToImg(file, false);

    setImgInfo({
      img,
      imgSrc: img.src,
      imgType: file.type,
      imgName: file.name,
      imgWidth: img.width,
      imgHeight: img.height,
      destoryFn,
    });

    const { CROP_WIDTH, CROP_HEIGHT } = BACKGROUND_CROP;
    const aspect = app.activedScene.configure.aspect || { num: 9, den: 16 };
    const isV = aspect.den === 16;

    setCroppedInfo({
      croppedDefaultWidth: isV ? CROP_WIDTH : CROP_HEIGHT,
      croppedDefaultHeight: !isV ? CROP_WIDTH : CROP_HEIGHT,
    });

    setCroppedModalVisible(true);
    e.target.value = '';
  };

  const cancel = () => {
    setCroppedModalVisible(false);
    imgInfo?.destoryFn();
    setImgInfo(null);
  };

  return (
    <>
      <div className={Style.addImg}>
        <label className={Style.addImgLabel} htmlFor="pictureFile">
          添加本地图片
        </label>

        <input
          id="pictureFile"
          style={{ display: 'none' }}
          type="file"
          accept=".jpg,.jpeg,.png,.gif,.webp"
          name=""
          onChange={handleFileChange}
        />
      </div>

      {imgInfo && croppedInfo && (
        <CroppedImg
          visible={croppedModalVisible}
          {...imgInfo}
          {...croppedInfo}
          cancel={cancel}
          confirm={uploadImage}
        />
      )}

      <Loading spinning={loading} fullScreen tip="" />
    </>
  );
}
export default observer(AddBackground);
