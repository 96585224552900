import { useRef } from 'react';
import ItemMenu from '@/layout/Material/components/ItemMenu';
import ImageWrapper from '@/components/ImageWrapper';
import { DigitalManDetail, ItemMenuType } from '@/types/material';
import assets from '@/assets';
import Style from './ContentItem.module.less';

const itemMenu = [
  {
    label: '重命名',
    icon: <i className="iconfont icon-a-document_icon_mingming2x" />,
    value: ItemMenuType.rename,
  },
  {
    label: '删除',
    icon: <i className="iconfont icon-dingyue_icon_delete" />,
    value: ItemMenuType.delete,
  },
];

interface ContentItemProps {
  data: DigitalManDetail;
  /** 否展示更多操作 */
  isShowMore: boolean;
  /** 处理操作 */
  onOperation: (value: ItemMenuType, item: DigitalManDetail) => void;

  handleClick: (item: DigitalManDetail) => void;
}

export default function ContentItem(props: ContentItemProps) {
  const { data, isShowMore, onOperation, handleClick } = props;

  const nodeIdRef = useRef(`menu-${data.id}`);

  const isShowMerber = data.memberType && data.memberType !== 'free';

  return (
    <div id={nodeIdRef.current} key={data.id} className={Style['content-item']}>
      <ImageWrapper
        className={Style['image-item']}
        urls={[data.coverUrl || data.thumbnailUrl]}
        onClick={() => handleClick(data)}
      />
      <div className={Style['image-name']}>{data.name}</div>

      {/* vip 标识 */}
      {isShowMerber && (
        <img
          className={Style['member-type']}
          src={
            data.memberType === 'basic'
              ? assets.images.doc['common_icon_vip1@2x.png']
              : assets.images.doc['common_icon_golden.png']
          }
          alt=""
        />
      )}

      {isShowMore && (
        <ItemMenu
          onChange={value => {
            onOperation(value, data);
          }}
          container={() => document.querySelector(`#${nodeIdRef.current}`)!}
          className={Style['item-menu']}
          items={itemMenu}
        />
      )}
    </div>
  );
}
