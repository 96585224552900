// import { Tooltips } from '@bhb-frontend/lithe-ui/lib';
import { useState, useMemo, ReactNode } from 'react';
// import { Member } from '@bhb-frontend/mtv-navbar';
import { PaymentGuidelines } from '@/types/cteateVideo';
import { ConsumeType, guideType } from '@/constants/Createvideo';
// import { useStores } from '@/store';
import ContactBusiness from '../ContactBusiness';
import assets from '@/assets';
import './style.less';
import GoodsItem from './GoodsItem';
import { MEMBER_TYPE, VIP_IMAGE } from '@/layout/Material/const';
// import PayCode from './PayCode';

interface PayContentProps {
  paymentGuidelinesData?: PaymentGuidelines;
  onClose: () => void;
  equityExportVideo: (type: ConsumeType) => void;
  visiable: boolean;
  onOrderChange: (id: string) => void;
}

const PayContent = (props: PayContentProps) => {
  const {
    paymentGuidelinesData,
    // onClose,
    // equityExportVideo,
    // ...otherProps
  } = props;
  if (!paymentGuidelinesData) return null;
  // const { appConfig } = useStores();
  /** 是否显示付费内容 */
  const [isShowPayContent, setIsShowPayContent] = useState<boolean>(false);
  /** 是否去单次付费 */
  // const [isToOncePay, setIsToOncePay] = useState<boolean>(true);

  /** 是否去联系商务 */
  const [isToContact, setIsToContact] = useState(false);

  /** 付款协议 */
  // const getAgreementLink = () => appConfig.config.userPayAgreement;

  /** 付费指引数据 */
  const paidGuides = useMemo(
    () => paymentGuidelinesData.paidGuides,
    [paymentGuidelinesData]
  );

  /** 付费内容数据 */
  const paidContent = useMemo(
    () => paymentGuidelinesData.paidContent,
    [paymentGuidelinesData]
  );

  const payGoodsList = useMemo(() => paidContent.overMemberType, [paidContent]);

  const getOnceGuideType = useMemo(
    () => paidGuides.once.guideType,
    [paidGuides]
  );

  const getMemberRightsGuideType = useMemo(
    () => paidGuides.memberRights.guideType,
    [paidGuides]
  );

  const getVideoPlusPkgGuideType = useMemo(
    () => paidGuides.videoPlusPackages.guideType,
    [paidGuides]
  );

  /** 无法使用 */
  const isAllOutUse = useMemo(
    () =>
      getOnceGuideType === guideType.outUse &&
      getMemberRightsGuideType === guideType.outUse &&
      getVideoPlusPkgGuideType === guideType.outUse,
    [getOnceGuideType, getMemberRightsGuideType, getVideoPlusPkgGuideType]
  );

  /** 关闭弹窗 */
  // const handleClose = () => {
  //   // 关闭弹窗
  //   onClose();
  // };

  // const buyOnce = () => {
  // setIsToOncePay(true);
  // };

  // const useOnce = () => {
  //   equityExportVideo(ConsumeType.once);
  // };

  // const buyMember = () => {
  //   const {
  //     memberRights: { goodsIds },
  //   } = paidGuides;
  //   handleClose();
  // visiable = false;
  //   Member.show({
  //     module: 'video',
  //     vipParam: { goodsIds },
  //   });
  // };

  // const useMember = () => {
  //   equityExportVideo(ConsumeType.memberRights);
  // };

  // const toAgreementLink = () => {
  //   window.open(getAgreementLink());
  // };

  // const onceBtnInfo = useMemo(() => {
  //   if (getOnceGuideType === guideType.outUse) return null;
  //   if (getOnceGuideType === guideType.buy) {
  //     const price = paidGuides.once.goodsInfo.price / 100;
  //     return {
  //       title: `单次购买 ${price}元/次`,
  //       handler: buyOnce,
  //     };
  //   }
  //   return {
  //     title: '消耗制作次数',
  //     handler: useOnce,
  //     tip: '本次导出将消耗1次视频制作次数，请认真检查视频内容，确保内容符合预期',
  //   };
  // }, [getOnceGuideType, paidGuides]);

  // const memberRightsBtnInfo = useMemo(() => {
  //   if (getMemberRightsGuideType === guideType.outUse) return null;
  //   if (getMemberRightsGuideType === guideType.buy) {
  //     return {
  //       title: '购买会员',
  //       handler: buyMember,
  //     };
  //   }
  //   return {
  //     title: '使用会员',
  //     handler: useMember,
  //   };
  // }, [getMemberRightsGuideType]);

  /** 打开加量包购买弹窗 */
  // const openPlusPkg = () => {
  //   handleClose();
  //   // showPlusPkgModal();
  //   // console.log('加量包');
  //   Member.show({
  //     module: 'video',
  //     isOpenAddPlusGoods: true,
  //   });
  // };

  /**
   * 购买加量包按钮显示逻辑
   */
  // const plusPkgBtnInfo = useMemo(() => {
  //   if (getVideoPlusPkgGuideType !== guideType.buy) return null;
  //   return {
  //     title: '购买加量包',
  //     handler: openPlusPkg,
  //   };
  // }, [getVideoPlusPkgGuideType]);

  /** 根据数字人时长和生成次数，返回需要付费的内容 */
  const genGoodsInfoList = useMemo(() => {
    const result: {
      title: ReactNode;
      coverUrl: string;
    }[] = [];
    const {
      figureMoreTime,
      videoMoreTime,
      balanceVideoMergeNum,
      videoMergeDuration,
      generalDurationMoreTime,
    } = paidContent;

    if (figureMoreTime > 0) {
      result.push({
        title: `数字人时长超可用时长${Math.ceil(figureMoreTime)}秒`,
        coverUrl: assets.images.theme['icon_time.png'],
      });
    }

    if (videoMoreTime > 0) {
      /** 转化为分钟 */
      result.push({
        title: `单个视频时长不能超过${Math.ceil(videoMergeDuration / 60)}分钟`,
        coverUrl: assets.images.theme['icon_time.png'],
      });
    }

    if (generalDurationMoreTime > 0) {
      result.push({
        title: `视频时长超可用时长${generalDurationMoreTime}秒`,
        coverUrl: assets.images.theme['icon_time.png'],
      });
    }

    if (balanceVideoMergeNum === 0) {
      result.push({
        title: '生成次数已经用完',
        coverUrl: assets.images.theme['icon_video.png'],
      });
    }
    return result;
  }, [paidContent]);

  /**
   * 会员类型对应的图标
   */
  const getMemberTypeIcon = (memberType: string) => {
    if (memberType === MEMBER_TYPE.BASIC) {
      return VIP_IMAGE.doc.VIP;
    }
    if (memberType === MEMBER_TYPE.PROFESSIONAL) {
      return VIP_IMAGE.doc.SVIP;
    }
    return '';
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  /**
   * 是否展示联系商务按钮
   * 如果不是 OutUse 无法使用，
   * 只要用一种可以购买 buy 就可以联系商务
   *  */
  const isShowContactBtn = useMemo(
    () =>
      !isAllOutUse &&
      [
        getOnceGuideType,
        getMemberRightsGuideType,
        getVideoPlusPkgGuideType,
      ].includes(guideType.buy),
    [
      isAllOutUse,
      getOnceGuideType,
      getMemberRightsGuideType,
      getVideoPlusPkgGuideType,
    ]
  );

  return (
    <div className="pay-content-container" onClick={handleClick}>
      {/* {isToOncePay ? (
        <PayCode
          {...otherProps}
          visiable={isToOncePay}
          goodsInfo={paidGuides.once.goodsInfo}
          onCancel={() => setIsToOncePay(false)}
        />
      ) : ( */}

      {isToContact ? (
        <ContactBusiness
          tips="微信扫码添加商务"
          onClose={() => setIsToContact(false)}
        />
      ) : (
        <>
          <div className="pay-content-header">
            {/* {isAllOutUse ? '无法导出' : '导出视频'} */}
            无法导出
          </div>

          {(payGoodsList.length || genGoodsInfoList.length) && (
            <div
              className="pay-content-title"
              onClick={() => setIsShowPayContent(val => !val)}
            >
              <span>付费内容</span>
              <i
                className={` iconfont ${
                  isShowPayContent
                    ? 'icon-a-live_icon_pullup'
                    : 'icon-live_icon_pull-down'
                }`}
              />
            </div>
          )}

          <div
            className="pay-content-box"
            style={{
              display: isShowPayContent ? 'unset' : 'none',
            }}
          >
            {payGoodsList.map(item => (
              <GoodsItem
                key={item.id}
                className="pay-goods-item"
                goodsCover={item.coverUrl}
                memberIcon={getMemberTypeIcon(item.memberType)}
                title={item.title}
                desc={item.name}
              />
            ))}

            {genGoodsInfoList.map((item, index) => (
              <GoodsItem
                key={index}
                className="pay-goods-item"
                goodsCover={item.coverUrl}
                title={item.title}
              />
            ))}
          </div>

          <div className="pay-content-footer">
            <div>
              {/* {!isAllOutUse && onceBtnInfo && (
                <div className="pay-btn" onClick={onceBtnInfo.handler}>
                  {onceBtnInfo.title}
                  {onceBtnInfo.tip && (
                    <Tooltips
                      className="once-consume-tooltip "
                      tips={onceBtnInfo.tip}
                    >
                      <i className="iconfont icon-a-common_icon_tips2x tips" />
                    </Tooltips>
                  )}
                </div>
              )}

              {!isAllOutUse && memberRightsBtnInfo && (
                <div className="pay-btn" onClick={memberRightsBtnInfo.handler}>
                  {memberRightsBtnInfo.title}
                </div>
              )}
              {!isAllOutUse && plusPkgBtnInfo && (
                <div className="pay-btn" onClick={plusPkgBtnInfo.handler}>
                  {plusPkgBtnInfo.title}
                </div>
              )} */}

              {/*  所有的支付，都去联系商务 */}
              {(isShowContactBtn || isAllOutUse) && (
                <div className="pay-btn" onClick={() => setIsToContact(true)}>
                  联系商务
                </div>
              )}
              {/* 
              {isAllOutUse && (
                <div className="pay-btn" onClick={handleClose}>
                  去调整
                </div>
              )} */}
            </div>

            {/* <div className="agreement">
              支付即视为同意
              <span className="link" onClick={toAgreementLink}>
                《闪剪服务协议》
              </span>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default PayContent;
