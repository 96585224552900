import CmpStruc, { BackgroundStruc } from '@/models/CmpStruc';
import SceneStruc from '@/models/SceneStruc';
import { ThemeDetailItem } from '@/types/ServerData';
import { transformBack } from './Cmp/Background';
import { transformCmp } from './Cmp/CmpToData';

export function transformScene(sceneModel: SceneStruc) {
  const {
    id,
    originalThemeId,
    presetIpImageId,
    ipImageId,
    avatarFigureId,
    cmps,
    configure,
    cover,
    materials,
    allTexts,
    sound,
    captions,
    extensionFields,
    orientation,
    extensionTheme,
  } = sceneModel.model();

  // 从小到大排序
  const newCmps = [...cmps];
  // 背景在数组第一项
  const back = newCmps.shift();
  const themeInfo = transformBack(
    back as BackgroundStruc,
    originalThemeId,
    orientation,
    extensionTheme,
    configure
  ) as ThemeDetailItem;
  const layers = newCmps.map(cmp => transformCmp(cmp as CmpStruc, themeInfo));
  // 修改原layers
  themeInfo.composition.layers = layers;
  // 配音 | 录音
  const audioObj =
    sound?.tag === 'dubbing'
      ? {
          dubId: sound?.id,
          dubSpeed: sound?.speed,
        }
      : {
          recordUrl: sound?.url,
          recordDuration: sound?.duration,
        };

  const scene = {
    ...extensionFields,
    ...audioObj,
    id,
    presetIpImageId,
    ipImageId,
    avatarFigureId,
    coverUrl: cover,
    materials,
    allTexts,
    captions,
    themeInfo,
  };

  return scene;
}
