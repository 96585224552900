import { ChangeLayerType, ScaleModeZh, ScaleMode } from './types';

export const layerOrderList = [
  {
    label: '前移',
    iconfont: 'icon-a-cengji_icon_up2x',
    value: ChangeLayerType.UP,
  },
  {
    label: '移至最前',
    iconfont: 'icon-a-cengji_icon_top2x',
    value: ChangeLayerType.TOP,
  },
  {
    label: '后移',
    iconfont: 'icon-a-cengji_icon_down2x',
    value: ChangeLayerType.DOWN,
  },
  {
    label: '移至最后',
    iconfont: 'icon-a-cengji_icon_bottom2x',
    value: ChangeLayerType.BOTTOM,
  },
];

/** 图片的展示类型映射 */
export const displayMap = {
  [ScaleModeZh.CONTAIN]: ScaleMode.CONTAIN,
  [ScaleModeZh.COVER]: ScaleMode.COVER,
};
/** 图片的展示类型反映射 */
export const displayMapZh = {
  [ScaleMode.CONTAIN]: ScaleModeZh.CONTAIN,
  [ScaleMode.COVER]: ScaleModeZh.COVER,
};

/** 铺平/铺满 */
export const displayTypeList = [ScaleModeZh.CONTAIN, ScaleModeZh.COVER];
