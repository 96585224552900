import { useRef, useEffect } from 'react';
import { Tooltips, Dropdown, Toast, Switch } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { observer } from 'mobx-react';
import { ImageStruc } from '@/models/CmpStruc';
import { cutoutImage } from '@/api/doc';
import { SOCKET_EVENTS } from '@/constants/SocketEvents';
import { SocketResponse } from '@/types/socket';
import { ScaleModeZh } from './types';
import { displayMap, displayMapZh, displayTypeList } from './constants';
import socket from '@/core/socket';
import CmpDelete from '../Base/CmpDelete';
import CmpLayer from '../Base/CmpLayer';
import { LEGAL_PICTURE_SUFFIX } from '@/constants/ImagesSuffix';

import style from './Image.module.less';
import assets from '@/assets';
import { app } from '@/store';

interface ImageSettingProps {
  model: ImageStruc;
}

function ImageSetting(props: ImageSettingProps) {
  const { model } = props;
  const {
    scaleMode,
    isCutout,
    alternateUrl,
    url,
    flip,
    isImage,
    isSticker,
    extra,
    hiddenAnimation,
    id,
  } = model;

  const taskId = useRef<string>('');
  const cmpId = useRef<string>('');

  const setScaleMode = (mode: ScaleModeZh) => {
    model.update({ scaleMode: displayMap[mode] });
  };

  /** 替换图片 */
  const replaceUrl = (imageModel: ImageStruc, alternate?: string) => {
    const alternateUrl = alternate || imageModel.alternateUrl;
    imageModel.setCutoutInfo(imageModel.url);
    imageModel.update({ url: alternateUrl });
  };

  /** 抠图 */
  const onCutout = async () => {
    if (isCutout || alternateUrl) {
      replaceUrl(model, alternateUrl);
      return;
    }
    if (!LEGAL_PICTURE_SUFFIX.includes(url.split('.').pop() || '')) {
      Toast.warning('该图片类型不支持抠像');
      return;
    }

    try {
      model?.replaceFileds({ loading: true });
      const { data } = await cutoutImage(url);
      taskId.current = data.taskId;
      cmpId.current = id;
    } catch (err) {
      console.log(err);
    }
  };

  /** 翻转 */
  const onFlip = () => {
    model.update({ flip: { ...flip, x: flip.x * -1 } });
  };

  /** socket 回调 */
  const sockerCallback = (res: SocketResponse<any>) => {
    const { event, data } = res;
    if (event === SOCKET_EVENTS.IMAGE_SEGMENT_RESULT) {
      const { code, imageUrl, taskId: id, message } = data;
      const cmpModel = app.activedScene.cmps.find(
        cmp => cmp.id === cmpId.current
      ) as ImageStruc;
      cmpModel?.replaceFileds({ loading: false });
      if (taskId.current !== id) return;
      if (code === 0) {
        if (!cmpModel) {
          Toast.success('抠图失败，请勿抠图中切换组件!');
          return;
        }
        replaceUrl(cmpModel, imageUrl);
        Toast.success('已完成抠像!');
        return;
      }
      Toast.error(message);
    }
  };

  /** 更新动画 */
  const animationChange = (val: boolean) => {
    model.update({ hiddenAnimation: !val });
  };

  useEffect(() => {
    socket.on(sockerCallback);

    return () => {
      socket.off(sockerCallback);
    };
  }, []);

  const scaleModeZh = scaleMode ? displayMapZh[scaleMode] : '';
  return (
    <div className={style.image}>
      <div>
        {/* 平铺/铺满, 插图才显示 */}
        {isImage && (
          <Dropdown
            width={100}
            placement="bottomLeft"
            placeholder="请选择"
            value={scaleModeZh}
            className={style['dropdown-component']}
            menuClassName={style['dropdown-menu']}
            onChange={setScaleMode}
          >
            {displayTypeList.map(value => (
              <Dropdown.Option key={value} value={value}>
                {scaleModeZh === value && (
                  <img
                    className={style['dropdown-option-active-icon']}
                    src={assets.images.doc['theme_icon_choose.png']}
                    alt=""
                  />
                )}
                {value}
              </Dropdown.Option>
            ))}
          </Dropdown>
        )}

        {/* 抠图 , 只有用户自己上传的贴纸才能抠图*/}
        {isSticker && extra.source === 'material' && (
          <Tooltips
            tips={isCutout ? '取消抠图' : '抠图'}
            placement="bottomLeft"
          >
            <span className={style.item} onClick={onCutout}>
              <i
                className={cs('iconfont icon-a-kouxiang2x', {
                  [style.cutout]: isCutout,
                })}
              />
            </span>
          </Tooltips>
        )}

        {/* 层级 */}
        <CmpLayer model={model} className={style.item} />

        {/* 翻转 */}
        <Tooltips tips="翻转" placement="bottom">
          <span className={style.item} onClick={onFlip}>
            <i className="iconfont icon-a-tuwen_icon_mirror1x" />
          </span>
        </Tooltips>

        {/** 动画，只有插图才显示 */}
        {isImage && (
          <div className={style.animation}>
            <span className={style.animationText}>动画</span>
            <Switch
              checked={!hiddenAnimation}
              size="small"
              onChange={animationChange}
            />
          </div>
        )}
      </div>

      {/* 删除 */}
      <CmpDelete onClick={() => model.remove()} className={style.item} />
    </div>
  );
}

export default observer(ImageSetting);
