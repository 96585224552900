import { HOST } from '../config';

import common, { CommonKey } from './common';
import material, { MaterialKey } from './material';

import homepage, { HomePageKeys } from './homepage';
import clip, { Clip } from './clip';
import components, { Components } from './components';
import doc, { Doc } from './doc';
import global, { Global } from './global';
import mt, { Mt } from './mt';
import robot, { Robot } from './robot';
import subscription, { Subscription } from './subscription';
import theme, { Theme } from './theme';

export interface Images {
  homepage: {
    [P in HomePageKeys]: string;
  };
  clip: {
    [P in Clip]: string;
  };
  common: {
    [P in CommonKey]: string;
  };
  components: {
    [P in Components]: string;
  };
  doc: {
    [P in Doc]: string;
  };
  global: {
    [P in Global]: string;
  };
  mt: {
    [P in Mt]: string;
  };
  robot: {
    [P in Robot]: string;
  };
  subscription: {
    [P in Subscription]: string;
  };
  theme: {
    [P in Theme]: string;
  };
  material: {
    [p in MaterialKey]: string;
  };
}

/**
 * 处理数据，添加 CDN 的 host 与路径。
 * @param data 数据
 * @returns data
 */
const t = <T extends string>(data: { [P in T]: string }) => {
  Object.keys(data).forEach((k: string) => {
    data[k as T] = `${HOST}/${data[k as T]}`;
  });
  return data;
};

const images: Images = {
  homepage: t(homepage),
  clip: t(clip),
  common: t(common),
  components: t(components),
  doc: t(doc),
  global: t(global),
  mt: t(mt),
  robot: t(robot),
  subscription: t(subscription),
  theme: t(theme),
  material: t(material),
};

export default images;
