import { createElement } from 'react';
import { TMenu, MaterialEnum } from '@/types/material';
import Background from './Content/Background';
import Music from './Content/Music';
import DigitalMan from './Content/DigitalMan';
import Dubbing from './Content/Dubbing';
import Sticker from './Content/Sticker';
import Template from './Content/Template';
import Text from './Content/Text';
import TextAnimation from './Content/TextAnimation';
import WebMaterial from './Content/WebMaterial';

export const options: TMenu = [
  {
    component: Template,
    name: MaterialEnum.THEME,
    label: '模版',
    icon: 'icon-a-doc_icon_theme_def2x',
  },
  {
    component: DigitalMan,
    name: MaterialEnum.DIGITAL_MAN,
    label: '数字人',
    icon: 'icon-a-ip_icon_number_people1x',
  },
  {
    component: Dubbing,
    name: MaterialEnum.DUBBING,
    label: '配音',
    icon: 'icon-doc_icon_voice_pre',
  },
  {
    component: Music,
    name: MaterialEnum.MUSIC,
    label: '音乐',
    icon: 'icon-doc_icon_music_pre',
  },
  {
    component: Background,
    name: MaterialEnum.BACK,
    label: '背景',
    icon: 'icon-doc_icon_bg_pre',
  },
  {
    component: Text,
    name: MaterialEnum.TEXT_STICKER,
    label: '文字',
    icon: 'icon-a-doc_icon_text_pre2x',
  },
  {
    component: Sticker,
    name: MaterialEnum.STICKER,
    label: '贴图',
    icon: 'icon-a-doc_icon_tiezhi_def2x',
  },
  {
    component: WebMaterial,
    name: MaterialEnum.MATERIAL,
    label: '素材',
    icon: '',
    hiddenLabel: true,
    hiddenSidebar: true,
  },
  {
    component: () => createElement('div'),
    name: MaterialEnum.DEFAULT,
    label: '空',
    icon: '',
    hiddenLabel: true,
  },
  {
    component: TextAnimation,
    name: MaterialEnum.TEXT_ANIMATION,
    label: '文字动画',
    icon: '',
    hiddenLabel: true,
    hiddenSidebar: true,
  },
];
