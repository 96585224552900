import { LoadMore } from '@bhb-frontend/lithe-ui';
import { store } from '@bhb-frontend/store';
import { MasonryGrid } from '@egjs/react-grid';

import { randomString } from '@bhb-frontend/utils/lib/random';
import { MateriaResults } from '@/types/doc-materials';
import image from '@/assets/images';
import Style from './MeterialItems.module.less';
import {
  SCENE_MATERIAL_NETWORK_ADD,
  SCENE_NAMESPACE,
} from '@/constants/StoreCacheKeys';

interface MeterialItemsProps {
  /** 资源列表 */
  list?: MateriaResults[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;
}

/** 计算样式 */
const getStyle = item => {
  const { width, height } = item;
  const curWidth = 136;
  const curHeight = (curWidth / width) * height;

  return {
    width: `${curWidth}px`,
    height: `${curHeight}px`,
  };
};

function MeterialItems(props: MeterialItemsProps) {
  const { list, loading, hasMore, loadMore } = props;

  const add = (item: MateriaResults) => {
    // url 添加随机字符串是为了在素材列表可以重复添加。
    store.set(SCENE_NAMESPACE, SCENE_MATERIAL_NETWORK_ADD, {
      ...item,
      url: `${item.url}?random=${randomString()}`,
    });
  };

  return list?.length ? (
    <div className={Style.meterial}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        finishedText="没有更多了~"
        // className={Style['load-more']}
      >
        <MasonryGrid gap={10} defaultDirection="end" align="justify">
          {list.map(item => (
            <div
              key={item.id}
              style={getStyle(item)}
              className={Style.meterialItem}
              onClick={() => add(item)}
            >
              <img
                src={item.thumbnailUrl}
                alt="资源素材"
                className={Style.meterialItemImage}
              />
            </div>
          ))}
        </MasonryGrid>
      </LoadMore>
    </div>
  ) : (
    <div className={Style.meterialEmpty}>
      <img src={image.doc['pic_search.png']} alt="空数据图标" />
      <div>暂时搜不到相关内容，换个词试试</div>
    </div>
  );
}

export default MeterialItems;
