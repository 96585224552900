/** 配音枚举 */
export enum EDubSpeed {
  /** 0.6x */
  slow = 'slow',
  /** 0.8x */
  slower = 'slower',
  /** 1x */
  medium = 'medium',
  /** 1.2x */
  quicker = 'quicker',
  /** 1.5x */
  quick = 'quick',
  /** 4x */
  ultraQuick = 'ultraQuick',
}
