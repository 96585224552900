import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cs from 'classnames';
import { Tooltips } from '@bhb-frontend/lithe-ui';
import { useStores } from '@/store';
import { TextStruc } from '@/models/CmpStruc';
import { FontItem } from '@/types/Font';
import style from './FontFamily.module.less';
import assets from '@/assets';

interface FontFamilyProps {
  model: TextStruc;
  className?: string;
}

function FontFamily(props: FontFamilyProps) {
  const { model, className } = props;
  const { fontDesc } = model;

  const [selectedPreviewUrl, setSelectedPreviewUrl] = useState<string>('');

  const {
    fontStore: { fontList },
  } = useStores();

  useEffect(() => {
    const font = fontList.find(
      item =>
        fontDesc?.faceName === item.faceName ||
        fontDesc?.faceName === item.fontName
    );
    const previewUrl = font ? font.fontPreviewImgUrl : '';
    setSelectedPreviewUrl(previewUrl);
  }, [fontDesc]);

  const selectFamily = (font: FontItem) => {
    const fontInfo = {
      ...fontDesc,
      fontName: font.fontName,
      faceName: font.faceName,
      showName: font.fontName,
      fileName: `${font.faceName}.ttf`,
    };
    model.update({ fontDesc: fontInfo });
  };

  const tips = (
    <div className={style['family-container']}>
      {fontList.map(font => (
        <div
          onClick={() => {
            selectFamily(font);
          }}
          className={style['family-item']}
          key={font.id}
        >
          <img
            className={cs({
              [style['active-icon']]: true,
              [style.show]:
                fontDesc?.faceName === font.faceName ||
                fontDesc?.faceName === font.fontName,
            })}
            src={assets.images.doc['theme_icon_choose.png']}
            alt=""
          />
          <img
            className={style['font-image']}
            src={font.fontPreviewImgUrl}
            alt=""
          />
        </div>
      ))}
    </div>
  );

  return (
    <Tooltips
      clickClosable
      className={style['tooltips-component']}
      trigger="click"
      placement="bottomLeft"
      tips={tips}
    >
      <div className={cs(className, style['selected-family'])}>
        {!selectedPreviewUrl && <span>请选择字体</span>}
        {selectedPreviewUrl && (
          <img
            className={style['family-image']}
            src={selectedPreviewUrl}
            alt=""
          />
        )}

        <i
          className={`iconfont icon-theme_icon_down ${style['icon-down-arrow']}`}
        />
      </div>
    </Tooltips>
  );
}

export default observer(FontFamily);
