import { useState } from 'react';
import { PhotoshopPicker, ColorResult } from 'react-color';

import assets from '@/assets';
import { BACKGROUND_COLOR } from '../../const';
import Style from './style.module.less';
import { useStores } from '@/store';
import { BackgroundType } from '@/constants/BackgroundType';

export default function AddColor() {
  const { app } = useStores();

  const [color, setColor] = useState<ColorResult | null>(null);
  const [pickerVisible, setPickerVisible] = useState(false);

  const selectColor = (clr: string) => {
    app.activedScene.setBackground(
      BackgroundType.COLOR,
      undefined,
      clr,
      'material'
    );
  };
  const handleChange = (color: ColorResult) => {
    setColor(color);
    selectColor(color.hex);
  };

  return (
    <div
      style={{ position: 'relative' }}
      tabIndex={-1}
      onBlur={() => {
        setPickerVisible(false);
      }}
    >
      <div className={Style.colors}>
        {BACKGROUND_COLOR.map((clr, i) => (
          <div
            style={{ backgroundColor: clr }}
            key={`${clr}${i + 1}`}
            onClick={() => {
              selectColor(clr);
              setPickerVisible(false);
            }}
            className={Style.colorsItem}
          />
        ))}
        <div
          className={`${Style.colorsItem} ${Style.colorsItemMore}`}
          onClick={() => {
            setPickerVisible(!pickerVisible);
          }}
        >
          <img
            src={assets.images.doc['color_icon_sepan.png']}
            width={42}
            height={36}
            alt=""
          />
        </div>
      </div>
      <div
        className={`${Style.colorsPicker} ${
          pickerVisible && Style.colorsPickerShow
        }`}
      >
        <PhotoshopPicker
          onChange={handleChange}
          color={color?.hex}
          styles={{
            default: {
              picker: {
                position: 'relative',
                width: 'auto',
                backgroundColor: '#fff',
                boxShadow: 'none',
                // padding: '6px',
              },
              head: { display: 'none' },
              controls: { display: 'none' },
              body: {
                width: 'auto',
                backgroundColor: '#fff',
                padding: '6px',
                borderRadius: '4px',
              },
              // 左侧选择区
              saturation: {
                border: 'none',
                borderRadius: '4px',
                width: 191,
                height: 140,
                cursor: 'pointer',
              },
              // 右侧竖条
              hue: {
                border: 'none',
                borderRadius: '4px',
                width: 12,
                height: 140,
                cursor: 'pointer',
              },
            },
          }}
        />
        <div className={Style.colorsMsg}>
          <span className={Style.colorsMsgText}>{color?.hex}</span>

          <span
            className={Style.colorsMsgIcon}
            style={{ background: color?.hex }}
          />
        </div>
      </div>
    </div>
  );
}
