// import { transformData } from '@/core/FormatData/AppData/AppToData';
import { SOCKET_EMIT, SOCKET_RESPONSE } from '@/constants/Socket';
import socket from '@/core/socket';
// import { DocDetail } from '@/types/ServerData';
/**
 * 保存作品
 */
export async function saveAppData(documentId, data, sceneThemeList) {
  // console.log('数据保存-----', data);
  // 保存文档, 文档成功之后再保存主题
  await socket.request({
    data,
    emitterEventName: SOCKET_EMIT.SAVE_DOC,
    onEnventName: SOCKET_RESPONSE.SAVE_DOC_SUCCESS,
  });

  // 保存主题
  socket.emit({
    event: SOCKET_EMIT.SAVE_THEME,
    data: {
      documentId,
      sceneThemeList,
    },
  });
}

/**
 * 发布作品
 */
export function publishApp() {
  //
}

/**
 * 导出项目
 */
export function exportPorject() {
  //
}

/**
 * 导入项目
 */
export function importPorject() {
  //
}
