import copyText from '@bhb-frontend/utils/lib/copy-text';
import { BHB_PREFIX } from '@/constants/CacheKeys';
import CreateCmpStruc from '@/models/FactoryStruc/CmpFactory';
import AppStore from '@/store/App';

export default class ClipboardManager {
  private static app: AppStore;

  /**
   * 注册剪切板并监听粘贴事件
   * @param app 作品数据
   */
  static register(app: AppStore) {
    this.app = app;

    const handlePaste = (e: ClipboardEvent) => {
      const data = e.clipboardData?.getData('text/plain');
      data && this.parseCmpsFromText(data);
    };
    document.addEventListener('paste', handlePaste, false);
  }

  /**
   * 复制到剪切板
   */
  static copyToClipboard() {
    const cmps = this.app.clipboard;
    if (!cmps || !cmps.length) return;
    const text = JSON.stringify(cmps.map(cmp => cmp.model()));
    copyText(this.formatText(text));
  }

  /**
   * 格式化剪贴板内容，防止系统其他复制动作干扰
   * @param text 当前复制的内容
   */
  private static formatText(text: string) {
    const host = encodeURIComponent(window.location.href);
    return `###${BHB_PREFIX}:COPY###${host}###${text}###`;
  }

  /**
   * 从剪贴板内容解析出cmps
   * @param text 剪贴板内容
   */
  private static parseCmpsFromText(text: string) {
    const { key, models = [] } = this.parseText(text) || {};
    const host = key ? decodeURIComponent(key) : '';
    if (host === window.location.href || !models.length) return;
    const cmps = models.map(model => CreateCmpStruc(model.type, model));
    this.app.handlePasteCmps(cmps);
  }

  /**
   * 字符串转换为CmpModel
   * @param text 剪贴板内容
   */
  private static parseText(text: string) {
    const reg = /###BHB:MTV:DOC:COPY###(.+?)###(.+?)###/;
    const result = text.match(reg);
    if (!result) return null;
    return {
      key: result[1],
      models: JSON.parse(result[2]) as ComponentModel.Base[],
    };
  }
}
