/** socket推送事件 */
export enum SOCKET_EMIT {
  /** 保存文档 */
  SAVE_DOC = 'saveDocuments',
  /** 保存主题 */
  SAVE_THEME = 'saveDocumentsTheme',
  /** 正文拆分 */
  CAPTIONS_SPLIT = 'getSceneCaptionsSplit',
  /** 切换模板  */
  CLICK_THEME = 'clickTheme',
}

/** socket接收事件 */
export enum SOCKET_RESPONSE {
  /** 保存文档成功 */
  SAVE_DOC_SUCCESS = 'saveDocumentsSuccess',
  /** 保存文档失败 */
  SAVE_DOC_FAILED = 'saveDocumentsFailed',
  /** 保存主题成功 */
  SAVE_THEME_SUCCESS = 'saveDocumentsThemeSuccess',
  /** 保存主题失败 */
  SAVE_THEME_FAILED = 'saveDocumentsThemeFailed',
  /** 正文拆分成功 */
  CAPTIONS_SPLIT_RESULT = 'sceneCaptionsSplitResult',
  /** 生成视频成功 */
  CREATE_VIDEO_SUCCESS = 'createVideoSuccess',
  /** 生成视频失败 */
  CREATE_VIDEO_FAILED = 'createVideoFailed',
  /** 生成视频状态变更 */
  VIDEO_STATUS_CHANGE = 'videoStatusChange',
  /** 支付结果 */
  PAY_RESULT = 'pay.result',
}
