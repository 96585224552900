import * as React from 'react';

/**
 * 全局点击事件hooks
 * @param handler 执行钩子
 * @param effective 是否真实有效的交互
 */
export function useGlobalClick(
  handler: (e: MouseEvent) => void,
  effective: boolean
) {
  React.useEffect(() => {
    if (effective) {
      window.addEventListener('click', handler, false);
    }

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [handler, effective]);
}
