import { useState } from 'react';
import Style from './DigitalMaturity.module.less';
import ContactBusiness from '../ContactBusiness';

export default function DigitalMaturity() {
  const [contactBusinessVisible, setContactBusinessVisible] = useState(false);

  return (
    <div className={Style['digital-maturity-container']}>
      {contactBusinessVisible ? (
        <ContactBusiness onClose={() => setContactBusinessVisible(false)} />
      ) : (
        <>
          <div className={Style['digital-maturity-header']}>无法导出</div>

          <div className={Style['digital-maturity-body']}>
            <p>你的数字克隆人使用时间已到期， 请联系商务续费。</p>
            <div
              onClick={() => setContactBusinessVisible(true)}
              className={Style.btn}
            >
              联系商务
            </div>
          </div>
        </>
      )}
    </div>
  );
}
