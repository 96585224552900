import React from 'react';
import ReactDOM from 'react-dom/client';

import { AliveScope } from 'react-activation';
import { initSentry } from './sentry';
import App from './App';
import './reset.less';
import './index.less';
import { initProject } from '@/utils/addEnv';

initProject();
initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AliveScope>
    <App />
  </AliveScope>
);
