import AudioStruc from '@/models/CmpStruc/Audio';
import SceneStruc from '@/models/SceneStruc';
import { DocDetail, ScenesNamespace } from '@/types/ServerData';
import { buildCmps } from '../Cmps';

export function buildScene(orgSence: ScenesNamespace) {
  const {
    themeInfo,
    id,
    presetIpImageId,
    ipImageId,
    avatarFigureId,
    coverUrl,
    materials,
    allTexts,
    dubId,
    dubSpeed,
    recordUrl,
    captions,
    recordDuration,
    ...extensionFields
  } = orgSence;

  const cmps = buildCmps({
    themeInfo,
    materials,
  });
  const sound: AudioStruc = new AudioStruc({
    tag: recordUrl ? 'record' : 'dubbing',
    url: recordUrl ?? '',
    id: dubId,
    speed: dubSpeed,
    duration: recordDuration,
  });

  const scene = {
    id,
    originalThemeId: themeInfo.originalThemeId,
    presetIpImageId,
    ipImageId,
    avatarFigureId,
    cmps,
    configure: themeInfo.configure,
    cover: coverUrl,
    actived: false,
    materials,
    allTexts,
    sound,
    captions,
    extensionFields,
    extensionTheme: themeInfo,
    orientation: themeInfo.orientation,
  };
  return new SceneStruc(scene);
}

/** 构造APPData */
export function buildAppData(data: DocDetail) {
  const {
    title,
    musicKey,
    musicId,
    musicVolume,
    musicSource,
    production,
    id,
    updatedTime,
    labelId,
    labelName,
    durationUS,
    jsonText,
    imageUrl = '',
    ...extensionFields
  } = data;
  const { scenes: originalScenes, title: sceneTitle } = jsonText;
  // console.log('originalScenes', originalScenes);
  /** 构成场景数据 */
  const scenes = originalScenes.map(item => buildScene(item));

  const music = new AudioStruc({
    id: musicId,
    volume: musicVolume,
    source: musicSource,
    key: musicKey,
    tag: 'music',
  });

  const appData = {
    id,
    name: production,
    title,
    music,
    updateTime: updatedTime,
    scenes,
    labelId,
    labelName,
    durationUS,
    titleContent: sceneTitle,
    extensionFields,
    cover: imageUrl,
  };
  return appData;
}
