/** 过滤相同数据 */
export function filterData<T = Record<string, any>>(
  data: T,
  updateData: Partial<T>
): Partial<T> {
  const newUpdateData: Partial<T> = {};
  for (const key in updateData) {
    const val = updateData[key];
    if (data[key] === val) continue;

    newUpdateData[key] = val;
  }
  return newUpdateData;
}
