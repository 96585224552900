/** 公共前缀，用于区分项目缓存 */
export const BHB_PREFIX = 'BHB:MTV:DOC';

/** 画布缩放级别 */
export const CANVAS_ZOOM_LEVEL = `${BHB_PREFIX}:CANVAS:ZOOM-LEVEL`;

/** 标尺 */
export const CANVAS_SHOW_RULER = `${BHB_PREFIX}:CANVAS:SHOW-RULER`;

/** 辅助线 */
export const CANVAS_SHOW_GUIDE = `${BHB_PREFIX}:CANVAS:SHOW-GUIDE`;

/** 磁力线 */
export const CANVAS_SHOW_MAGNETIC = `${BHB_PREFIX}:CANVAS:SHOW-MAGNETIC`;

/** 网格线 */
export const CANVAS_SHOW_GRIDLINES = `${BHB_PREFIX}:CANVAS:SHOW-GRIDLINES`;

export const CANVAS_GUIDE_ARRAY = `${BHB_PREFIX}:CANVAS:GUIDE-ARRAY`;
