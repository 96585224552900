import { observer } from 'mobx-react';
import { BackgroundStruc } from '@/models/CmpStruc';
import { CmpsFactoryProps } from '../Cmp';
import style from './Background.module.less';

export interface BackgroundProps extends CmpsFactoryProps<any> {
  model: BackgroundStruc;
}

function Background(props: BackgroundProps) {
  const { model } = props;
  const { fillType, url, color, extra } = model;
  const getImageUrl = () => {
    if (fillType === 'Image') return url;
    if (fillType === 'Video') return extra?.cover;
    return null;
  };
  const imageUrl = getImageUrl();

  return (
    <div className={style['background-container']}>
      {['Image', 'Video'].includes(fillType) && imageUrl && (
        <img src={imageUrl} alt="" className={style['image-background']} />
      )}

      {fillType === 'Color' && (
        <div
          className={style['color-background']}
          style={{ background: color }}
        />
      )}
    </div>
  );
}

export default observer(Background);
