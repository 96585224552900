import { LoadMore } from '@bhb-frontend/lithe-ui';
import { BackgroundDetail, Percent } from '@/types/material';
import ImageWrapper from '@/components/ImageWrapper';
import Style from './style.module.less';
import { useStores } from '@/store';
import { BackgroundType } from '@/constants/BackgroundType';

interface BackgroundListProps {
  /** 资源列表 */
  list?: BackgroundDetail[];
  /** 请求中 */
  loading?: boolean;
  /** 是否可以加载更多 */
  hasMore?: boolean;
  /** 加载更过回调 */
  loadMore?: () => void;

  /** 模板比例 */
  percent?: Percent;
}

function BackgroundList(props: BackgroundListProps) {
  const {
    list,
    loading,
    hasMore,
    loadMore,
    percent = Percent.Vertical,
  } = props;
  const { app } = useStores();

  const handleClick = (item: BackgroundDetail) => {
    const isMp4 = item.backgroundUrl.endsWith('.mp4');
    const type = isMp4 ? BackgroundType.VIDEO : BackgroundType.IMAGE;
    app.activedScene.setBackground(type, item);
  };

  return (
    <div className={Style['template-content']}>
      <LoadMore
        onReachBottom={() => {
          loadMore?.();
        }}
        loading={!!loading}
        hasMore={!!hasMore}
        finishedText=""
        className={Style['load-more']}
      >
        {list?.map(item => (
          <ImageWrapper
            key={item.id}
            className={`${Style['image-item']} ${
              percent === Percent.Vertical ? Style.vertical : Style.horizontal
            }`}
            urls={[item.coverUrl]}
            onClick={() => handleClick(item)}
          />
        ))}
      </LoadMore>
    </div>
  );
}

export default BackgroundList;
