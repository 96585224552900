/**
 * 尺寸数据类型
 */
export interface Size {
  width: number;
  height: number;
}

export enum ResolutionEnum {
  /** 标清屏 */
  LOW = 'low',

  /** 常规屏 */
  REGULAR = 'regular',

  /** 高清屏 */
  HIGH_DEFINITION = 'high_definition',

  /** 超清屏 */
  ULTRA = 'ultra',

  /** 自定义 */
  CUSTOM = 'custom',
}

/**
 * 预设尺寸
 */
export const presetSize: Record<ResolutionEnum, Size> = {
  [ResolutionEnum.LOW]: { width: 480, height: 840 },
  [ResolutionEnum.REGULAR]: { width: 720, height: 1280 },
  [ResolutionEnum.HIGH_DEFINITION]: { width: 1080, height: 1920 },
  [ResolutionEnum.ULTRA]: { width: 1440, height: 2560 },
  [ResolutionEnum.CUSTOM]: { width: 0, height: 0 },
};

/** 默认场景尺寸配置 */
export const DEFAULT_CONFIGURE: Required<Configure> = {
  aspect: {
    den: 16,
    num: 9,
  },
  width: 720,
  height: 1280,
};

export function getConfigure(configure?: Configure): Configure {
  const config = {
    ...DEFAULT_CONFIGURE,
    ...configure,
    aspect: {
      ...DEFAULT_CONFIGURE.aspect,
      ...configure?.aspect,
    },
  };

  return config;
}
