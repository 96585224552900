import { useRef, useMemo, useEffect } from 'react';
import clssNames from 'classnames';
import assets from '@/assets';
import { useStores } from '@/store';

import './style.less';

export interface Coord {
  x?: number;
  y?: number;
}

interface MenuProps {
  open: boolean;
  /** 坐标 */
  coord: Coord;
  /** 关闭 */
  onClose: () => void;
  /** 新增 */
  onAddScene: () => void;
  /** 复制 */
  onCopyScene: () => void;
  /** 删除 */
  onDeleteScene: () => void;
}

const width = 180;
const height = 150;

const Menu = (props: MenuProps) => {
  const { open, coord, onClose, onAddScene, onCopyScene, onDeleteScene } =
    props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    app: { scenes = [] },
  } = useStores();
  const isOnly = scenes.length < 2;
  const handleClick = (e: MouseEvent) => {
    if (!wrapperRef.current?.contains(e.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClick, true);
    } else {
      document.removeEventListener('mousedown', handleClick, true);
    }
  }, [open]);

  /** 容器位置 */
  const wrapperCoord = useMemo(() => {
    const { x = 0, y = 0 } = coord;
    const top = `${y - height - 10}px`;
    const left = `${x + 10}px`;
    return {
      width: `${width}px`,
      height: `${height}px`,
      top,
      left,
    };
  }, [coord]);

  const addScene = () => {
    onAddScene();
  };

  const copyScene = () => {
    onCopyScene();
  };

  const deleteScene = () => {
    if (isOnly) return;
    onDeleteScene();
  };

  return (
    <div
      ref={wrapperRef}
      className={clssNames({ 'scene-menu': true, 'scene-menu-hidden': !open })}
      style={wrapperCoord}
    >
      <div className="scene-menu-item" onClick={addScene}>
        <img src={assets.images.theme['menu-add.png']} alt="新增" />
        添加页面
      </div>
      <div className="scene-menu-item" onClick={copyScene}>
        <img src={assets.images.theme['menu-copy.png']} alt="新增" />
        复制
      </div>
      <div
        className={clssNames({
          'scene-menu-item': true,
          'scene-menu-item-disabled': isOnly,
        })}
        onClick={deleteScene}
      >
        <i className="iconfont icon-a-document_icon_delete2x" />
        删除
      </div>
    </div>
  );
};
export default Menu;
