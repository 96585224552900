import React from 'react';
import { Toast } from '@bhb-frontend/lithe-ui/lib';

import { handleCompressImage, fileToImg, getUploadImage } from '@/utils/file';

import Style from './style.module.less';
import Uploader from '@/utils/upload';
import { StickerDetail } from '@/types/material';
import { useStores } from '@/store';

export default function AddSticker() {
  const { app } = useStores();
  /** 添加 */
  const add = (item: Partial<StickerDetail>) => {
    app.activedScene.addSticker({
      stickerConfig: {
        ...item,
      },
      source: 'material',
    });
  };

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const orgFile = e.target.files[0];
    // 手动校验类型
    let file = await getUploadImage(orgFile);
    if (!file) return;
    if (file.size > 3 * 1024 * 1024) {
      Toast.warning('图片不能超过3M！');
      return;
    }
    const name = file.name;

    let {
      img: { width: originWidth, height: orignHeight },
    } = await fileToImg(file);
    if (originWidth > 1280 || orignHeight > 1280) {
      const ratio = originWidth / orignHeight;
      if (originWidth > 1280) {
        originWidth = 1280;
        orignHeight = Math.floor(originWidth / ratio);
      }
      if (orignHeight > 1280) {
        orignHeight = 1280;
        originWidth = Math.floor(orignHeight * ratio);
      }
      const blob = await handleCompressImage(file)(
        originWidth,
        orignHeight,
        file.type
      )(1);
      file = new File([blob], name);
    }
    const uploader = new Uploader(file);
    uploader.addModule('video');
    uploader.addScene('sticker');
    uploader
      .upload()
      .then(({ url, key }) => {
        add({
          imageUrl: url,
          imageKey: key,
          name,
          width: originWidth,
          height: orignHeight,
        });
      })
      .catch(e => console.error(e))
      .finally(() => {
        e.target.value = '';
      });
  };

  return (
    <div className={Style.addImg}>
      <label className={Style.addImgLabel} htmlFor="pictureFile">
        添加本地图片
      </label>
      <input
        id="pictureFile"
        style={{ display: 'none' }}
        type="file"
        accept=".jpg,.jpeg,.png,.gif,.webp"
        name=""
        onChange={uploadImage}
      />
    </div>
  );
}
