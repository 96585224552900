// 域名地址
import data from '../../config/config.json';

export enum BASE_URL {}

/** 静态资源CDN域名 */
export const STATIC_CDN_HOST = data.cdn_host;

/** 静态资源CDN路径 */
export const STATIC_CDN_PATH = data.cdn_host;

/** 不需要登录的路由路径 */
export const NO_TOKEN_ROUTES = ['/'];

/** 不需要订阅号id的路由路径 */
export const NO_SUBSCRIPTIONID_ROUTES = [
  '/subscription/list',
  '/subscription/home?type=create',
];

/** 代码环境上下文 */
export enum CONTEXT_ENV {
  dev = 'dev',
  test = 'test',
  gray = 'gray',
  prod = 'prod',
}

/** 接口域名 */
export enum BASE_URL_MAP {
  dev = 'https://mtv-api.test.bhbapp.cn', // 本地开发
  test = 'https://mtv-api.test.bhbapp.cn', // 测试环境
  gray = 'https://mtv-api.bj.gray.bhbapp.cn', // 预发布环境
  prod = 'https://api.8858ai.com', // 正式环境
}

const HREF = window.location.origin.replace('doc-editor', 'doc');
/** 工作台地址 */
export const CHANNEL_WORKBENCH_URL_MAP = {
  dev: 'https://ssss-doc.test.bhbapp.cn',
  test: HREF,
  gray: HREF,
  prod: HREF,
};

export const ENV = (import.meta.env.MODE || CONTEXT_ENV.dev) as CONTEXT_ENV;

export const WORKBENCH_URL: string = CHANNEL_WORKBENCH_URL_MAP[ENV];
