import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Toast, Tooltips } from '@bhb-frontend/lithe-ui/lib';
import { getTags, setTag, addTag, editTag, removeTag } from '@/api/app';
import TagModal from './TagModal';
import { Tag } from './types';
import { useStores } from '@/store';

interface TagProps {
  productionName: string;
}

const TagCom = (props: TagProps) => {
  const { productionName } = props;
  const { app } = useStores();
  const [show, setShow] = useState<boolean>(false);

  const [tagList, setTagList] = useState<Tag[]>([]);

  /** 获取tags */
  const getTagList = async () => {
    const { data } = await getTags();
    setTagList(data.results);
  };

  useEffect(() => {
    getTagList();
  }, []);

  const openTagsManage = () => {
    setShow(true);
  };

  /** 设置 tag */
  const onSetTag = async (tagIds: string[], names: string[]) => {
    const newTagIds = tagIds.filter(tag => !!tag);
    const newNames = names.filter(name => !!name);
    await setTag(app.id, newTagIds);
    Toast.success('标签设置成功！');
    app.setLabel(newTagIds, newNames);
  };

  /** 添加 tag */
  const onAddTag = async (name: string) => {
    const { data } = await addTag(name);
    await getTagList();
    await onSetTag([data.id], [name]);
  };

  /** 编辑 tag */
  const onEditTag = async (id: string, name: string) => {
    await editTag(id, name);
    getTagList();
  };

  /** 删除 tag */
  const onRemoveTag = async (id: string) => {
    await removeTag(id);
    getTagList();
  };

  return (
    <>
      <Tooltips tips="添加标签" placement="bottom">
        <i
          className="iconfont icon-document_icon_tips doc-icon"
          onClick={openTagsManage}
        />
      </Tooltips>

      <TagModal
        productionName={productionName}
        visible={show}
        onClose={() => {
          setShow(false);
        }}
        onSetTag={onSetTag}
        onAddTag={onAddTag}
        onEditTag={onEditTag}
        onRemoveTag={onRemoveTag}
        tagList={tagList}
        labelId={app.labelId?.[0] || ''}
      />
    </>
  );
};

export default observer(TagCom);
