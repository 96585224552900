import { useEffect, useRef, useState } from 'react';
import { Slider, Tooltips, Checkbox } from '@bhb-frontend/lithe-ui';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '@/store';
import { EDubSpeed } from '@/types/dub';
import style from './Canvas.module.less';
import { event } from '@/utils/event';
import { SETTING_ILLUSTRATION_SHOW_KEY } from '@/constants/StoreCacheKeys';

/** 速度和masks 的key映射 */
const speedsMap = {
  0: EDubSpeed.slow,
  1: EDubSpeed.slower,
  2: EDubSpeed.medium,
  3: EDubSpeed.quicker,
  4: EDubSpeed.quick,
  5: EDubSpeed.ultraQuick,
};

/** 速度和masks 的key反映射 */
const speedsInverceMap = {
  [EDubSpeed.slow]: 0,
  [EDubSpeed.slower]: 1,
  [EDubSpeed.medium]: 2,
  [EDubSpeed.quicker]: 3,
  [EDubSpeed.quick]: 4,
  [EDubSpeed.ultraQuick]: 5,
};

interface SwitchItemProps {
  label: string;
  onChange?: (value: boolean) => void;
  status?: boolean;
}

function SwitchItem(props: SwitchItemProps) {
  const { label, status, onChange } = props;
  return (
    <div className={style.item}>
      <Checkbox
        checked={status}
        onChange={(_e, status) => {
          onChange?.(status);
        }}
      >
        <span className={style.label}>{label}</span>
      </Checkbox>
    </div>
  );
}

function CanvasSetting() {
  const {
    speed: { speeds },
    app: { activedScene },
  } = useStores();

  const { titleCmp, captionsCmp, imageCmp, hasDigitalMan, sound } =
    activedScene;

  const { speed = EDubSpeed.medium, isFastestSpeed, isImportRecord } = sound;

  const [illustrationTipVisible, setIllustrationTipVisible] = useState(false);
  const illustrationTipRef = useRef<HTMLDivElement>(null);

  const handleIllustrationChange = (value: boolean) => {
    if (value && !imageCmp) {
      // 新增图层
      activedScene.addFigure();
      return;
    }
    imageCmp?.update({ visible: value });
  };

  const handleTitleChange = (value: boolean) => {
    if (value && !titleCmp) {
      // 新增图层
      activedScene.addTitle();
      return;
    }
    titleCmp?.update({ visible: value });
  };

  const handleCaptionsChange = (value: boolean) => {
    if (value && !captionsCmp) {
      // 新增图层
      activedScene.addCaption();
      return;
    }
    captionsCmp?.update({ visible: value });
  };

  const showIllustrationTip = () => {
    // 聚焦 tip
    illustrationTipRef.current?.focus();
    // 显示 tip
    setIllustrationTipVisible(true);
  };

  useEffect(() => {
    event.on(SETTING_ILLUSTRATION_SHOW_KEY, showIllustrationTip);

    return () => {
      event.off(SETTING_ILLUSTRATION_SHOW_KEY, showIllustrationTip);
    };
  }, []);

  const onChangeSpeed = value => {
    const speed = speedsMap[value];
    /** 4倍速度，删除配音 */
    if (speed === EDubSpeed.ultraQuick) {
      activedScene.removeSound();
    }
    sound.update({ speed });
  };

  const tips = (
    <div className={style['tips-container']}>
      <Slider
        marks={{
          0: `${speeds?.slow}x`,
          1: `${speeds?.slower}x`,
          2: `${speeds?.medium}x`,
          3: `${speeds?.quicker}x`,
          4: `${speeds?.quick}x`,
          5: `${speeds?.ultraQuick}x`,
        }}
        value={speedsInverceMap[speed]}
        onChange={onChangeSpeed}
        max={hasDigitalMan ? 4 : 5}
      />

      {isFastestSpeed && (
        <p className={style.tips}>
          <i className="iconfont icon-a-speed_icon_tips2x" />
          超快速度不支持配音
        </p>
      )}
    </div>
  );

  return (
    <div className={style.canvas}>
      {/* 导入音频，不能设置播放速度 */}
      <div
        className={cx(style.item, {
          [style['item-disabled']]: isImportRecord,
        })}
      >
        <Tooltips
          className={style['tooltips-component']}
          tips={tips}
          trigger="click"
          placement="bottomLeft"
        >
          <div
            className={cx(style.speed, {
              [style.disabled]: isImportRecord,
            })}
          >
            <i className={cx('iconfont', 'icon-a-tuwen_edit_icon_speed1x')} />
            <span className={cx(style.label)}>变速</span>
          </div>
        </Tooltips>
      </div>

      <div className={style['checkbox-container']}>
        <div
          ref={illustrationTipRef}
          tabIndex={-1}
          className={cx(style.item, style.tipContainer)}
          onBlur={() => {
            setIllustrationTipVisible(false);
          }}
        >
          <SwitchItem
            label="插图"
            status={imageCmp?.visible}
            onChange={handleIllustrationChange}
          />
          <div
            className={cx(style.tip, { [style.show]: illustrationTipVisible })}
          >
            插图已隐藏，点击展示
          </div>
        </div>

        <SwitchItem
          label="标题"
          status={titleCmp?.visible}
          onChange={handleTitleChange}
        />
        <SwitchItem
          label="字幕"
          status={captionsCmp?.visible}
          onChange={handleCaptionsChange}
        />
      </div>
    </div>
  );
}

export default observer(CanvasSetting);
