import request from '@bhb-frontend/utils/lib/request';

import { CustomListResponse, DetailResponse } from '@/types/api';
import {
  DocDetail,
  ScenesNamespace,
  ThemeDetailItem,
} from '@/types/ServerData';
import { TagItem } from '@/layout/Header/Tag/types';
import { FontItem } from '@/types/Font';
import {
  DubbingDetail,
  MusicDetail,
  SearchParams,
  DigitalManDetail,
} from '@/types/material';
import { MateriaResults } from '@/types/doc-materials';

export enum TaskType {
  textMapMaterial = 'textMapMaterial',
  keywordPick = 'keywordPick',
  slideFigure = 'slideFigure',
  faceStyle = 'faceStyle',
}

interface TaskIdParams {
  type: TaskType;
  text?: string;
  /** 风格特效id type为faceAnime和pandaFace需要传 */
  effectMaterialId?: string;
  /** 文件url */
  fileUrl?: string;
  customExtra?: {
    /** 下巴点X坐标 type为faceAnime和pandaFace需要传 */
    chinPointX: number;
    /** 下巴点Y坐标 type为faceAnime和pandaFace需要传 */
    chinPointY: number;
  };
}

/** 获取APPData */
export function getAppData(id: string, param) {
  return request.get<DetailResponse<DocDetail>>(`/clip/documents/${id}`, param);
}

/** 新建APPData */
export function createAppData(param) {
  return request.post<DetailResponse<{ documentId: string }>>(
    '/clip/documents',
    param
  );
}

/** 新增场景 */
export function createSceneApi(id: string | null, themeId: string) {
  return request.post<DetailResponse<ScenesNamespace>>(
    `/clip/documents/${id}/scene/new`,
    {
      themeId,
    }
  );
}

/** 复制场景 */
export function copySceneApi(id: string | null, sceneId: string | null) {
  return request.post<DetailResponse<ScenesNamespace>>(
    `/clip/documents/${id}/scene/copy`,
    {
      sceneId,
    }
  );
}

/**
 * 文档设置标签
 */
export function setTag(
  id: string | null,
  tagIds: string[]
): Promise<DetailResponse<void>> {
  return request.post(`/clip/documents/${id}/labels`, {
    labelIds: tagIds,
  });
}

/**
 * 获取标签列表
 */
export function getTags(): Promise<CustomListResponse<TagItem>> {
  return request.get('/clip/labels');
}

/**
 * 添加标签
 */
export function addTag(name: string): Promise<DetailResponse<{ id: string }>> {
  return request.post('/clip/labels', {
    name,
  });
}

/**
 * 编辑标签
 */
export function editTag(
  id: string,
  name: string
): Promise<DetailResponse<void>> {
  return request.put(`/clip/labels/${id}`, {
    name,
  });
}

/**
 * 删除标签
 */
export function removeTag(id: string): Promise<DetailResponse<void>> {
  return request.delete(`/clip/labels/${id}`);
}

/** 获取APPData */
export function getThemeDetail(id: string) {
  return request.get<DetailResponse<ThemeDetailItem>>(`/theme/${id}`);
}

/**
 * 获取字体列表
 */
export function getFontList(params: { pageSize: number }) {
  return request.get<CustomListResponse<FontItem>>('/font/index', params);
}

/** 获取网络背景音乐详情 */
export function getMusicNetwork(id: string) {
  return request.get<DetailResponse<MusicDetail>>(`/clip/music/${id}`);
}

/** 获取用户上传音乐详情 */
export function getMusicMaterial(id: string) {
  return request.get<DetailResponse<MusicDetail>>(
    `/clip/material/detail/${id}`
  );
}

/** 获取网络背景音乐详情 */
export function geDubInfo(id: string) {
  return request.get<DetailResponse<DubbingDetail>>(`/clip/dubbing/${id}`);
}

/** 智能配图、关键词提取 */
export function getTaskId(data: TaskIdParams) {
  return request.post<DetailResponse<{ taskId: string }>>('/v1/effects', data);
}

/** 取消任务 */
export function cancelTaskById(ids: string[]) {
  return request.put('/v1/effects/cancel', { taskIds: ids });
}

/** 获取网络素材 */
export function getMaterial(params: SearchParams & { type: string }) {
  return request.get<CustomListResponse<MateriaResults>>(
    '/clip/network_material',
    params
  );
}

/** 获取预设IP形象详情 */
export function getPresetIpDetail(id: string) {
  return request.get<DetailResponse<DigitalManDetail>>(
    `/preset_ip_image/${id}`
  );
}

/** 获取IP形象详情 */
export function getIpProfileDetail(id: string) {
  return request.get<DetailResponse<DigitalManDetail>>(`/ip_image/${id}`);
}
