export enum SaveStatus {
  /** 未保存 */
  UN_SAVED,
  /** 保存中 */
  SAVING,
  /** 已保存 */
  SAVED,
  /** 保存失败 */
  FAIL_SAVED,
  /** 历史保存 */
  HISTORYSAVE,
}

export const SAVE_STATUS_TEXT: Record<SaveStatus, string> = {
  [SaveStatus.UN_SAVED]: '未保存',
  [SaveStatus.SAVING]: '保存中...',
  [SaveStatus.SAVED]: '已经保存到云端',
  [SaveStatus.FAIL_SAVED]: '保存失败',
  [SaveStatus.HISTORYSAVE]: '历史状态',
};

export enum ResErrType {
  /** 接口数据错误 */
  interfaceError = 'interfaceError',
  /** 前端操作数据错误 */
  webError = 'webError',
  /** 服务端生成视频错误 */
  exportError = 'exportError',
}
