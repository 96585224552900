import { Toast, Popup, Input, Button } from '@bhb-frontend/lithe-ui/lib';
import { cloneDeep } from 'lodash';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { getImageFileFromUrl } from '@/utils/file';
import Style from './style.module.less';

/** 配音别名组件内部数据 */
interface PureTextData extends AllTexts {
  /** 是否为框选区域 */
  isSelect?: boolean;
  /** key */
  index: number;
  /** 配音缺省显示字符（超过7个字符，超出部分显示...） */
  ellipsis?: string;
  /** 是否为空元素 */
  isEmpty?: boolean;
}

/** 持久存在数据结构 */
interface DataRef {
  /** index计数变量 */
  indexSum: number;
  /** 光标偏移量 */
  focusOffset: number;
  /** 解决中文触发多次input事件 */
  inputFlag: boolean;
  /** 鼠标选中的元素index */
  selectIndex: number;
  /** window.getSelection */
  selectObj:
    | (Selection & { modify?: (a: string, b: string, c: string) => void })
    | null;
  /** 恢复选区 */
  rangeTick: boolean;
  /** 恢复光标 */
  cursorTick: boolean;
}

type FileListType =
  | { type: 'file'; file: File }
  | { type: 'cors'; corsUrl: string };

interface PureTextProps {
  /** 更新配音文案数据 */
  update?: (text: AllTexts[]) => void;
  /** 配音文案编辑区获取焦点 */
  onFocus?: () => void;
  /** 粘贴文本 */
  onPaste?: (fileList: FileListType[]) => void;
}
export interface PureTextRefFn {
  /** 渲染配音文本 */
  init: (text: AllTexts[]) => void;
  /** 触发添加配音别名弹窗 */
  addAlias: () => void;
  /** 失焦事件 */
  onBlur: () => void;
}

function PureText(props: PureTextProps, ref: Ref<PureTextRefFn>) {
  const { update, onFocus, onPaste } = props;

  useImperativeHandle(ref, () => ({
    init,
    addAlias,
    onBlur,
  }));

  /** 配音文本编辑区 */
  const pureTextRef = useRef<HTMLDivElement>(null);

  /** 鼠标选中区域 */
  const selectRef = useRef<HTMLSpanElement>(null);

  /** 用于恢复光标 */
  const refObj = useRef<{ [key: string]: HTMLSpanElement }>({});

  /** 持久存在数据 */
  const dataRef = useRef<DataRef>({
    indexSum: 0,
    focusOffset: -1,
    inputFlag: true,
    selectIndex: 0,
    selectObj: window.getSelection(),
    rangeTick: false,
    cursorTick: false,
  });

  /** 配音文案数据 */
  const [puretextData, setPureTextData] = useState<PureTextData[]>([]);

  /** 别名数据 */
  const [aliasText, setAliasText] = useState('');

  /**
   * 配音别名文本超过7个字符显示省略号
   * @param text
   */
  const ellipsis = (text: string | undefined) => {
    let str = '';
    if (text !== undefined && text.length > 7) {
      str = `${text.substring(0, 7)}...`;
    }
    return str;
  };

  /**
   * 获取下标
   * @param idx
   * @param textData
   * @returns
   */
  const getIndexNumber = (idx: number, textData: PureTextData[]) => {
    let index = 0;
    for (let i = 0; i < textData.length; i += 1) {
      if (textData[i].index === idx) {
        index = i;
        break;
      }
    }
    return index;
  };

  /**
   * 处理初始化数据
   * @param textData
   */
  const initData = (textData: PureTextData[]) => {
    let preIsNormal = false;
    if (!textData.length || textData[textData.length - 1].alias !== undefined) {
      // 空数据或者结尾是有配音别名元素
      addEmpty(textData.length, textData);
    }
    if (textData[0].alias !== undefined) {
      // 第一元素有配音别名
      addEmpty(0, textData);
    }

    for (let i = 0; i < textData.length; ) {
      const item = textData[i];
      dataRef.current.indexSum += 1;
      item.index = dataRef.current.indexSum;
      if (item.alias) item.ellipsis = ellipsis(item.alias);
      if (item.alias === undefined) {
        if (preIsNormal) {
          // 前一个元素也是普通配音文本
          item.text = textData.splice(i - 1, 1)[0].text + item.text;
          continue;
        } else {
          preIsNormal = true;
        }
      } else if (!preIsNormal) {
        // 两个有配音别名元素相邻
        addEmpty(i, textData);
        i += 1;
      } else {
        preIsNormal = false;
      }
      if (processEnter(textData, i) > -1) {
        i += 2;
      } else {
        i += 1;
      }
    }
  };

  /**
   * 处理末尾换行情况
   * @param textData
   * @param index
   * @returns
   */
  const processEnter = (textData: PureTextData[], index: number) => {
    const item = textData[index];
    if (item.text.search(/\n$/g) > -1) {
      if (
        textData[index + 1] &&
        textData[index + 1].text === '' &&
        textData[index + 1].isEmpty
      )
        return -1;
      // 末尾是换行符，并且后一个元素有配音别名
      return addEmpty(index + 1, textData);
    }
    return -1;
  };

  /**
   * 在外部初始化组件数据时，调用该方法
   * @param texts
   */
  const init = (texts: AllTexts[]) => {
    const newTextData = cloneDeep(texts);
    dataRef.current.indexSum = newTextData.length;
    initData(newTextData);
    if (
      newTextData.length === 1 &&
      newTextData[0].text === '' &&
      pureTextRef.current
    ) {
      // 空数据情况自动失去焦点，显示输入文本提示信息
      pureTextRef.current.blur();
    }
    setPureTextData(newTextData);
  };

  /**
   * 失焦
   *  */
  const onBlur = () => {
    refObj.current[`focus${dataRef.current.selectIndex}`]?.blur();
  };

  /**
   * 插入空元素
   * @param idx
   * @param textData
   */
  const addEmpty = (idx: number, textData: PureTextData[]) => {
    dataRef.current.indexSum += 1;
    textData.splice(idx, 0, {
      text: '',
      index: dataRef.current.indexSum,
      isEmpty: true,
    });
    return dataRef.current.indexSum;
  };

  /**
   * 处理数据，通知外部更新配音数据
   * @param textData
   */
  const updatePure = (textData: PureTextData[]) => {
    const pureData: AllTexts[] = [];
    for (let i = 0; i < textData.length; i += 1) {
      const item = textData[i];
      if (item.text === '') continue; // 过滤空元素数据
      const pureItem: AllTexts = { text: item.text };
      if (item.alias !== undefined) {
        pureItem.alias = item.alias;
      }
      pureData.push(pureItem);
    }
    update && update(pureData);
  };

  /**
   * 合并指定元素前后普通元素
   * @param idx
   * @param textData
   */
  const mergeData = (idx: number, textData: PureTextData[]) => {
    const nowItem = textData[idx];
    /** 往前查找 */
    let i = idx - 1;
    let preItem = textData[i];
    while (
      i > -1 &&
      preItem &&
      preItem.alias === undefined &&
      !preItem.isEmpty
    ) {
      preItem = textData.splice(i, 1)[0];
      nowItem.text = preItem.text + nowItem.text;
      if (dataRef.current.focusOffset !== -1) {
        // 由于合并了前面元素光标位置相应改变
        dataRef.current.focusOffset += preItem.text.length;
      }
      i -= 1;
      preItem = textData[i];
    }
    /** 往后查找 */
    const t = i + 2;
    let nextItem = textData[t];
    if (t < textData.length) {
      while (nextItem && nextItem.alias === undefined && !nextItem.isEmpty) {
        nextItem = textData.splice(t, 1)[0];
        nowItem.text += nextItem.text;
        nextItem = textData[t];
      }
    }
    if (nowItem.alias) {
      nowItem.ellipsis = ellipsis(nowItem.alias);
    }
    const emptyIndex = processEnter(textData, t - 1);
    if (
      getIndexNumber(emptyIndex, textData) - 1 ===
      getIndexNumber(nowItem.index, textData)
    ) {
      dataRef.current.selectIndex = emptyIndex;
    } else {
      dataRef.current.selectIndex = nowItem.index;
    }
  };

  /**
   * 配音文本删除操作
   * @param idx
   * @param ev
   */
  const keyDelete = (
    idx: number | undefined,
    ev: React.KeyboardEvent<HTMLSpanElement>
  ) => {
    if (idx === undefined) return;
    if (!dataRef.current.selectObj?.isCollapsed) return;
    const newTextData = cloneDeep(puretextData);
    const index = getIndexNumber(idx, newTextData);
    let changeIdx = 0;
    const offset = dataRef.current.selectObj?.focusOffset as number;
    dataRef.current.focusOffset = offset - 1;
    const key = ev.key;
    const item = newTextData[index];
    if (key === 'Backspace') {
      if (offset === 0 && index !== 0) {
        /** 文本起始位置往前删除 */
        changeIdx = index - 1;
        dataRef.current.focusOffset = offset;
        if (newTextData[changeIdx].alias === undefined) {
          const text = newTextData[changeIdx].text;
          newTextData[changeIdx].text = text.substring(0, text.length - 1);
        }
        dataRef.current.indexSum += 1;
        newTextData.splice(changeIdx, 1, {
          text: newTextData[changeIdx].text,
          index: dataRef.current.indexSum,
        });
        if (changeIdx - 1 > -1) delete newTextData[changeIdx - 1].isEmpty;
        mergeData(index, newTextData);
        updatePure(newTextData);
        dataRef.current.cursorTick = true;
        ev.preventDefault();
        setPureTextData(newTextData);
      } else if (
        offset === item.text.length &&
        index !== puretextData.length - 1
      ) {
        /** 文本结束位置往前删除 */
        dataRef.current.focusOffset = offset;
        dataRef.current.indexSum += 1;
        newTextData.splice(index, 1, {
          text: newTextData[index].text.substring(
            0,
            newTextData[index].text.length - 1
          ),
          index: dataRef.current.indexSum,
        });
        mergeData(index, newTextData);
        updatePure(newTextData);
        dataRef.current.cursorTick = true;
        ev.preventDefault();
        setPureTextData(newTextData);
      }
    } else if (key === 'Delete') {
      if (offset === item.text.length && index !== newTextData.length - 1) {
        /** 文本末尾位置往后删除 */
        changeIdx = index + 1;
        dataRef.current.focusOffset = offset;
        if (newTextData[changeIdx].alias === undefined) {
          const text = newTextData[changeIdx].text;
          newTextData[changeIdx].text = text.substring(1, text.length);
        }
        dataRef.current.indexSum += 1;
        newTextData.splice(changeIdx, 1, {
          text: newTextData[changeIdx].text,
          index: dataRef.current.indexSum,
        });
        if (changeIdx + 1 < newTextData.length + 1)
          delete newTextData[changeIdx + 1].isEmpty;
        mergeData(index, newTextData);
        updatePure(newTextData);
        dataRef.current.cursorTick = true;
        ev.preventDefault();
        setPureTextData(newTextData);
      } else if (offset === item.text.length - 1) {
        /** 最后一个换行符往后删除 */
        const preText = item.text.substring(
          item.text.length - 3,
          item.text.length - 2
        );
        const nextText = item.text.substring(
          item.text.length - 1,
          item.text.length
        );
        if (preText !== '\n' && nextText === '\n') {
          changeIdx = index + 1;
          dataRef.current.focusOffset = offset;
          item.text = item.text.substring(0, item.text.length - 1);
          dataRef.current.indexSum += 1;
          newTextData.splice(changeIdx, 1, {
            text: newTextData[changeIdx].text,
            index: dataRef.current.indexSum,
          });
          mergeData(index, newTextData);
          updatePure(newTextData);
          dataRef.current.cursorTick = true;
          ev.preventDefault();
          setPureTextData(newTextData);
        }
      }
    }
  };

  /**
   * 配音文本换行操作
   * @param idx
   * @param ev
   */
  const keyEnter = (
    idx: number | undefined,
    ev: React.KeyboardEvent<HTMLSpanElement>
  ) => {
    if (idx === undefined) return;
    const index = getIndexNumber(idx, puretextData);
    let startOffset = 0;
    let endOffset = 0;
    if (
      dataRef.current.selectObj?.anchorOffset !== undefined &&
      dataRef.current.selectObj?.focusOffset !== undefined
    ) {
      startOffset = Math.min(
        dataRef.current.selectObj?.anchorOffset,
        dataRef.current.selectObj?.focusOffset
      );
      endOffset = Math.max(
        dataRef.current.selectObj?.anchorOffset,
        dataRef.current.selectObj?.focusOffset
      );
    }
    dataRef.current.focusOffset = startOffset + 1;
    if (endOffset === puretextData[index].text.length) {
      /** 在元素末尾换行 */
      const newTextData = cloneDeep(puretextData);
      dataRef.current.indexSum += 1;
      const text = newTextData[index].text;
      newTextData.splice(index, 1, {
        text: `${
          text.substring(0, startOffset) +
          text.substring(endOffset, text.length)
        }\n`,
        index: dataRef.current.indexSum,
      });
      mergeData(index, newTextData);
      updatePure(newTextData);
      dataRef.current.cursorTick = true;
      ev.preventDefault();
      setPureTextData(newTextData);
    }
  };

  /** 键盘按下 */
  const keydown = (
    idx: number | undefined,
    ev: React.KeyboardEvent<HTMLSpanElement>
  ) => {
    if (ev.key === 'Delete' || ev.key === 'Backspace') {
      keyDelete(idx, ev);
    } else if (ev.key === 'Enter') {
      keyEnter(idx, ev);
    }
  };

  /**
   * 配音文本修改
   * @param event
   * @param idx
   * @returns
   */
  const onInput = (event: any, idx?: number) => {
    const ev = event as Event & { target?: { textContent: string } };
    if (idx === undefined) return;
    /** 定时器为了兼容input中文一些浏览器触发顺序不同 */
    setTimeout(() => {
      const index = getIndexNumber(idx, puretextData);
      if (!dataRef.current.inputFlag) return;
      // 替换掉所有的制表符，避免在其他地方的渲染异常
      const textStr = ev.target.textContent.replaceAll('\t', '');
      const offset = dataRef.current.selectObj?.focusOffset
        ? dataRef.current.selectObj?.focusOffset
        : 0;
      dataRef.current.focusOffset = Math.max(
        dataRef.current.focusOffset,
        offset
      ); // 拿到光标位置
      const newTextData = cloneDeep(puretextData);
      newTextData[index].text = textStr;
      dataRef.current.indexSum += 1;
      newTextData.splice(index, 1, {
        text: newTextData[index].text,
        index: dataRef.current.indexSum,
      });
      mergeData(index, newTextData);
      updatePure(newTextData);
      dataRef.current.cursorTick = true;
      setPureTextData(newTextData);
    }, 0);
  };

  /**
   * 打开添加配音弹窗
   */
  const addAlias = () => {
    if (!dataRef.current.selectObj) {
      console.error('window.getSelection is null');
      return;
    }
    if (dataRef.current.selectIndex === undefined) {
      Toast.warning('请选择文本内容');
      return;
    }
    const index = getIndexNumber(dataRef.current.selectIndex, puretextData);
    if (puretextData[index].isSelect) return;
    const textStr = dataRef.current.selectObj?.toString();
    if (
      textStr === '' ||
      textStr === undefined ||
      !puretextData[index].text.includes(textStr)
    ) {
      Toast.warning('请选择文本内容');
      return;
    }
    if (puretextData[index].alias !== undefined) {
      Toast.warning('暂不支持重复别名');
      return;
    }
    if (textStr && textStr.length > 10) {
      Toast.warning('添加别名的文本不能超过10个字符');
      return;
    }
    if (textStr.search(/\n/g) > -1) {
      Toast.warning('换行不支持别名');
      return;
    }
    const range = dataRef.current.selectObj.getRangeAt(0);
    const startOffset = range.startOffset; // 光标开始
    const endOffset = range.endOffset; // 光标结束
    const newTextData = cloneDeep(puretextData);
    const itemStr = newTextData[index].text;
    newTextData[index].text = itemStr.substring(0, startOffset);
    dataRef.current.indexSum += 1;
    const addSelect = {
      text: itemStr.substring(startOffset, endOffset),
      isSelect: true,
      index: dataRef.current.indexSum,
    };
    const nextStr = itemStr.substring(endOffset);
    if (index === newTextData.length - 1) {
      // 最后一个元素直接追加
      newTextData.push(addSelect);
      dataRef.current.indexSum += 1;
      newTextData.push({
        text: nextStr,
        index: dataRef.current.indexSum,
      });
    } else {
      newTextData.splice(index + 1, 0, addSelect);
      dataRef.current.indexSum += 1;
      newTextData.splice(index + 2, 0, {
        text: nextStr,
        index: dataRef.current.indexSum,
      });
    }
    dataRef.current.rangeTick = true;
    setPureTextData(newTextData);
  };

  /**
   * 关闭提交别名弹窗
   * @param idx
   */
  const closeAlias = (idx?: number) => {
    if (idx === undefined) return;
    const index = getIndexNumber(idx, puretextData);
    if (puretextData[index].isSelect) {
      const newTextData = cloneDeep(puretextData);
      dataRef.current.indexSum += 1;
      newTextData.splice(index, 1, {
        text: newTextData[index].text,
        index: dataRef.current.indexSum,
      });
      mergeData(index, newTextData);
      setPureTextData(newTextData);
    }
  };

  /**
   * 配音别名增、删、改
   * @param idx
   * @param item
   * @param type
   * @returns
   */
  const aliasChange = (
    idx: number | undefined,
    item: PureTextData,
    type: string
  ) => {
    if (idx === undefined) return;
    const newTextData = cloneDeep(puretextData);
    const index = getIndexNumber(idx, newTextData);
    if (newTextData[index] === undefined) return;
    if (['update', 'add'].includes(type) && aliasText === '') {
      Toast.warning('请输入别名');
      return;
    }
    dataRef.current.indexSum += 1;
    newTextData[index].index = dataRef.current.indexSum; // 为了关闭弹窗
    if (type === 'add') {
      // 添加配音别名
      if (selectRef.current) {
        // 关闭新增别名弹窗
        selectRef.current.blur();
      }
      dataRef.current.indexSum += 1;
      newTextData.splice(index, 1, {
        text: item.text,
        alias: aliasText,
        index: dataRef.current.indexSum,
        ellipsis: ellipsis(aliasText),
      });
      mergeData(index - 1, newTextData);
    } else if (type === 'update') {
      // 更新别名
      newTextData[index].alias = aliasText;
      newTextData[index].ellipsis = ellipsis(aliasText);
    } else if (type === 'remove') {
      // 删除别名
      newTextData.splice(index, 1, {
        text: newTextData[index].text,
        index: newTextData[index].index,
      });
      if (index + 1 < newTextData.length + 1)
        delete newTextData[index + 1].isEmpty;
      if (index - 1 > -1) delete newTextData[index - 1].isEmpty;
      mergeData(index, newTextData);
    }
    updatePure(newTextData);
    setPureTextData(newTextData);
  };

  /**
   * 粘贴事件，获取数据中的所有图片地址
   * @param event
   * @returns
   */
  const handlePaste = async (event: ClipboardEvent) => {
    if (event.clipboardData !== null) {
      const pasteText = event.clipboardData.getData('text');
      dataRef.current.focusOffset = pasteText.length;
    }
    const paste = event?.clipboardData?.getData('text/html');
    if (!paste) {
      return;
    }
    /** url数组 */
    const parse = new DOMParser();
    const doc = parse.parseFromString(paste, 'text/html');
    const imgList = doc.querySelectorAll('img');
    // 特殊处理一下bmp后缀的图片
    const imgUrlList = Array.from(imgList)
      .map(item => item.src)
      .filter(str => str.startsWith('http') && !str.includes('.bmp'));

    if (!imgUrlList.length) return;
    /** 批量下载 */
    const promiseArr = imgUrlList.reduce((res: Promise<File>[], url, index) => {
      const filePromise = getImageFileFromUrl(url, `${index}-custom`);
      res.push(filePromise);
      return res;
    }, []);

    const res = await Promise.allSettled(promiseArr);
    /** 拿到 FileList 数组 */
    const fileArr = res.reduce((res: FileListType[], item) => {
      const { status } = item;
      if (status === 'fulfilled') {
        res.push({
          type: 'file',
          file: item.value,
        });
      } else if (status === 'rejected') {
        res.push({
          type: 'cors',
          corsUrl: item.reason,
        });
      }
      return res;
    }, []);
    onPaste && onPaste(fileArr);
  };

  /**
   * set inputFlag
   * @param val
   */
  const setInputFlag = val => {
    dataRef.current.inputFlag = val;
  };

  /**
   * set selectIndex
   * @param index
   */
  const setSelectIndex = index => {
    dataRef.current.selectIndex = index !== undefined ? index : 0;
  };

  /**
   * 生成添加、编辑配音别名弹窗
   * @param item
   * @param type
   * @returns
   */
  const getPopupContent = (item, type: 'update' | 'add') => {
    const content = (
      <div className={Style.purePopover}>
        <Input
          value={aliasText}
          placeholder="请输入别名"
          maxLength={40}
          className={Style.purePopoverInput}
          allowClear
          onChange={(_, value) => {
            setAliasText(value);
          }}
        />
        <div className={Style.purePopoverLabel}>
          例如：设置「YYDS」为「永远的神」
        </div>
        <div className={Style.purePopoverBtn}>
          {type === 'update' && (
            <Button
              className={Style.purePopoverBtnSmall}
              onClick={() => aliasChange(item.index, item, 'remove')}
            >
              移除
            </Button>
          )}
          <Button
            type="primary"
            className={
              type === 'update'
                ? Style.purePopoverBtnSmall
                : Style.purePopoverBtnBig
            }
            onClick={() => aliasChange(item.index, item, type)}
          >
            确定
          </Button>
        </div>
      </div>
    );
    return content;
  };

  // fix: 兼容火狐文档编辑
  // const onPaste = (e: ClipboardEvent) => {
  //   e.preventDefault();
  //   const text = e.clipboardData?.getData('text/plain');
  //   if (!text) return;
  //   document.execCommand('insertText', false, text);
  // };

  useEffect(() => {
    pureTextRef.current?.addEventListener('paste', handlePaste);
    dataRef.current.selectObj = window.getSelection();

    return () => {
      pureTextRef.current?.removeEventListener('paste', handlePaste);
    };
  });

  useEffect(() => {
    if (
      dataRef.current.rangeTick &&
      selectRef.current &&
      selectRef.current.click
    ) {
      /** 需要恢复选区 */
      dataRef.current.rangeTick = false;
      setTimeout(() => {
        if (selectRef && selectRef.current) {
          const range = document.createRange();
          range.selectNode(selectRef.current);
          dataRef.current.selectObj?.removeAllRanges();
          dataRef.current.selectObj?.addRange(range);
          /** 打开添加配音别名弹窗 */
          selectRef && selectRef.current && selectRef.current.click();
        }
      }, 0);
    }

    if (dataRef.current.cursorTick) {
      /** 需要恢复光标 */
      dataRef.current.cursorTick = false;
      if (!dataRef.current.selectObj || !dataRef.current.selectObj.modify)
        return;
      if (
        dataRef.current.selectIndex === undefined ||
        dataRef.current.focusOffset === undefined
      )
        return;
      dataRef.current.selectObj.collapse(
        refObj.current[`focus${dataRef.current.selectIndex}`],
        0
      );
      while (dataRef.current.focusOffset > 0) {
        dataRef.current.selectObj.modify('move', 'forward', 'character');
        dataRef.current.focusOffset -= 1;
      }
    }
  }, [puretextData]);

  return (
    <div ref={pureTextRef} className={Style.pure} onFocus={onFocus}>
      {puretextData.map((item, index) => (
        <span key={item.index}>
          {/* 框选文本并且打开别名添加弹窗 */}
          {item.isSelect && (
            <Popup
              placement="bottomLeft"
              overlay={getPopupContent(item, 'add')}
              trigger="click"
              onOpen={() => setAliasText('')}
              onExited={() => closeAlias(item.index)}
            >
              <span
                id="selectRef"
                ref={selectRef}
                className={Style.pureSelectText}
              >
                {item.text}
              </span>
            </Popup>
          )}
          {/* 正常文本 */}
          {!item.isSelect && item.alias === undefined && (
            <span
              ref={r => {
                if (r) refObj.current[`focus${item.index}`] = r;
              }}
              tabIndex={index + 1}
              suppressContentEditableWarning
              contentEditable
              className={
                puretextData.length === 1 && item.text === ''
                  ? Style.pureEmptyText
                  : Style.pureNormalText
              }
              onKeyDown={event => keydown(item.index, event)}
              onCompositionStart={() => setInputFlag(false)}
              onCompositionEnd={() => setInputFlag(true)}
              onInput={event => onInput(event, item.index)}
              onFocus={() => setSelectIndex(item.index)}
            >
              {item.text}
            </span>
          )}
          {/* 有配音别名 */}
          {item.alias !== undefined && (
            <Popup
              placement="bottomLeft"
              overlay={getPopupContent(item, 'update')}
              trigger="click"
              onOpen={() => setAliasText(item.alias as string)}
            >
              <span>
                <span
                  ref={r => {
                    if (r) refObj.current[`focus${item.index}`] = r;
                  }}
                  className={Style.pureAliasText}
                >
                  {item.text}
                </span>
                {item.ellipsis !== '' ? (
                  <span title={item.alias} className={Style.pureAlias}>
                    {item.ellipsis}
                  </span>
                ) : (
                  <span className={Style.pureAlias}>{item.alias}</span>
                )}
              </span>
            </Popup>
          )}
        </span>
      ))}
    </div>
  );
}

export default forwardRef(PureText);
