import { observer } from 'mobx-react';
import cs from 'classnames';
import { useStores } from '@/store';
import { AxleDirection } from '@/types/OSModel';
import style from './Magnetic.module.less';

function Magnetic() {
  const { OS } = useStores();
  const { magneticLines } = OS;

  if (!magneticLines) return null;

  const getLineStyle = (length: number, direction: AxleDirection) => {
    if (direction === AxleDirection.x) {
      return {
        width: length,
      };
    }
    return {
      height: length,
    };
  };

  return (
    <>
      {magneticLines.map((item, _key) => {
        const { axis, length, direction } = item;
        return (
          <div
            className={style['magnetic-line']}
            style={{
              left: axis.x,
              top: axis.y,
            }}
            key={`${direction}-${Date.now()}`}
          >
            <div
              style={getLineStyle(length, direction)}
              className={cs(style.line, {
                [style.horizontal]: direction === AxleDirection.x,
                [style.vertical]: direction === AxleDirection.y,
              })}
            />
          </div>
        );
      })}
    </>
  );
}

export default observer(Magnetic);
