import Style from './WeChatQRCode.module.less';
import { channelApp } from '@/store';

export interface WeChatQRCodeProps {
  tips?: string;
}

export default function WeChatQRCode(props: WeChatQRCodeProps) {
  const { tips = '权益不足，微信扫码联系商务' } = props;

  const codeUrl = channelApp.config.contactImageUrl;
  const title = codeUrl ? tips : '暂无商务微信，请主动联系';

  return (
    <div className={Style['wechat-qr-code']}>
      <div className={Style['border-box']}>
        <div className={Style['content-box']}>
          {codeUrl && <img src={codeUrl} alt="" />}
        </div>
      </div>

      {title && <p className={Style.tips}>{title}</p>}
    </div>
  );
}
