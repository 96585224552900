import { TType } from '@bhb-frontend/mtv-navbar/lib/types/member';
import { Member } from '@bhb-frontend/mtv-navbar';
import { Toast } from '@bhb-frontend/lithe-ui/lib';
import { TMembers } from '@/types/user';
import { user } from '@/store';
import VipDialog from '@/components/VipDialog';

const CONSUMPTION_TIP_KEY = 'isConsumptionTip';
const CONSUMPTION_TIP_VALUE = 'disable';
export function getNoConsumptionTips(): boolean {
  return !!localStorage.getItem(CONSUMPTION_TIP_KEY);
}

export function setNoConsumptionTips() {
  localStorage.setItem(CONSUMPTION_TIP_KEY, CONSUMPTION_TIP_VALUE);
}

/**
 * 其他类别的公用付费引导函数
 */
export async function commonGuide(
  getHigherMemberParam: {
    fields: keyof TMembers;
    value: number;
    type: TType;
  },
  showVipDialogParams: {
    title: string;
    content: string;
  },
  fallbackMessage?: string
) {
  const {
    isHave,
    memberId,
    type: tab,
    goodsIds,
  } = await Member.getHigherMember({
    ...getHigherMemberParam,
    module: 'video',
  });
  if (isHave) {
    Member.show({
      ...showVipDialogParams,
      module: 'video',
      vipParam: {
        tab,
        memberId,
        goodsIds,
      },
    });
  } else {
    fallbackMessage && Toast.error(fallbackMessage);
  }
}

/**
 * 图文快剪存储空间付费引导
 */
export async function storagePaidGuidance() {
  const { videoClipRights } = user;
  if (!videoClipRights) {
    return;
  }
  const { type = 'free', totalStorage = 0 } = videoClipRights;

  const {
    memberId,
    type: tab,
    isHave,
    goodsIds,
  } = await Member.getHigherMember({
    fields: 'storage',
    value: totalStorage,
    type,
    module: 'video',
  });

  if (!isHave) {
    Toast.error('空间已不足，请释放空间');
    return;
  }
  VipDialog?.show?.({
    title: '可用云空间已不足',
    content: '购买会员获取更多空间',
    module: 'video',
    vipParam: {
      tab,
      memberId,
      goodsIds,
    },
  });
}

const TIME = 60;

export function formerTime(s: number) {
  if (s === 0) {
    return `${s}秒`;
  }
  const m = Math.floor(s / TIME);
  const seconds = s % TIME;
  if (seconds === 0) {
    return `${m}分钟`;
  }
  return m > 0 ? `${m}分${seconds}秒` : `${seconds}秒`;
}
