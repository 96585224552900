import { Modal } from '@bhb-frontend/lithe-ui';
import { IModaleText } from '@/types/videos';
import style from './hintModal.module.less';

interface HintModalProps {
  modaleText: Partial<IModaleText>;
  onOk: () => void;
  visible: boolean;
}

function HintModal(props: HintModalProps) {
  const { modaleText, onOk, visible } = props;

  return (
    <Modal
      width="283px"
      height="220px"
      className={style['mt-hint-modal']}
      append-to-body
      visible={visible}
    >
      <div className={style.content}>
        <div className={style.title}>{modaleText.title}</div>
        <div className={style.text}>{modaleText.content}</div>
        <div className={style.footer}>
          <div className={`${style.but} ${style.ok}`} onClick={onOk}>
            {modaleText.butText}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HintModal;
