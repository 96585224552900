import { ReactNode } from 'react';
import classnames from 'classnames';
import Style from './style.module.less';

interface Category<T> {
  key: T;
  label: ReactNode;
}

interface CategoryWrapperProps<T> {
  /** 分类 */
  categorys: Category<T>[];
  /** 活动key */
  activeKey: string;
  /** 切换事件 */
  onChange: (key: T) => void;
}

function CategoryWrapper<T extends React.Key = string>(
  props: CategoryWrapperProps<T>
) {
  const { categorys, activeKey, onChange } = props;
  return (
    <div className={Style['category-wrapper']}>
      {categorys.map(({ key, label }) => (
        <div
          key={key}
          onClick={() => {
            onChange(key);
          }}
          className={classnames(Style.category, {
            category: true,
            [Style.activeCategory]: key === activeKey,
          })}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

export default CategoryWrapper;
