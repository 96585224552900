import { Tooltips } from '@bhb-frontend/lithe-ui';
import { TooltipsProps } from '@bhb-frontend/lithe-ui/lib/Tooltips';
import { PhotoshopPicker, ColorResult } from 'react-color';
import style from './ColorPicker.module.less';

interface ColorPickerProps extends Omit<TooltipsProps, 'tips'> {
  children: React.ReactNode;
  changeColor?: (color: string) => void;
  color: string;
}
export default function ColorPicker(props: ColorPickerProps) {
  const {
    children,
    changeColor,
    trigger = 'click',
    placement = 'bottomLeft',
    color,
  } = props;

  const handleChange = (color: ColorResult) => {
    changeColor?.(color.hex);
  };
  const tips = (
    <div>
      <PhotoshopPicker
        onChange={handleChange}
        color="#fff"
        styles={{
          default: {
            picker: {
              position: 'relative',
              width: 'auto',
              backgroundColor: '#fff',
              boxShadow: 'none',
              // padding: '6px',
            },
            head: { display: 'none' },
            controls: { display: 'none' },
            body: {
              width: 'auto',
              backgroundColor: '#fff',
              padding: '6px',
              borderRadius: '4px',
            },
            // 左侧选择区
            saturation: {
              border: 'none',
              borderRadius: '4px',
              width: 191,
              height: 140,
              cursor: 'pointer',
            },
            // 右侧竖条
            hue: {
              border: 'none',
              borderRadius: '4px',
              width: 12,
              height: 140,
              cursor: 'pointer',
            },
          },
        }}
      />
      <div className={style.colorsMsg}>
        <span className={style.colorsMsgText}>{color}</span>
        <span className={style.colorsMsgIcon} style={{ background: 'red' }} />
      </div>
    </div>
  );

  return (
    <Tooltips
      className={style['tooltips-component']}
      tips={tips}
      trigger={trigger}
      placement={placement}
    >
      {children}
    </Tooltips>
  );
}
