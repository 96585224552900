import classNames from 'classnames';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/store';
import './style.less';
import { SaveStatus, SAVE_STATUS_TEXT } from '@/constants/SaveStatus';
import Tag from './Tag';
import View from './View';
import ExportVideo from './ExportVideo';
import { goToWorkbench } from '@/utils/workbench';

interface HeaderProps {
  className: string;
}

function Header(props: HeaderProps) {
  const { className } = props;

  const { app } = useStores();
  const { saveStatus, durationUS, name, scenes } = app;
  /** 返回 */
  const goBack = () => {
    goToWorkbench('/doc');
  };

  const getTitle = () => {
    if (name && name !== '未命名文档') return name;
    if (!scenes.length) return '未命名文档';
    const contents = scenes[0]?.allTexts.map(item => item.text);
    if (!Array.isArray(contents) || contents.length === 0) return '未命名文档';
    const text = contents.join('');
    return text.substring(0, 15);
  };

  const filterTitle = () => {
    const text = getTitle();
    const title = text?.replaceAll?.(/\n/g, '') || '未命名文档';
    return title;
  };

  const title = filterTitle();

  /** 视频时长转换分秒 */
  const videoTime = useMemo(() => {
    const timer = Math.ceil(durationUS / 1000 / 1000);
    return `${Math.floor(timer / 60)}分${timer % 60}秒`;
  }, [durationUS]);

  return (
    <div className={`options-head ${className}`}>
      <div className="left-option">
        <div className="back-btn">
          <i className="iconfont icon-doc_icon_back" onClick={goBack} />
          <span className="doc-title">{title}</span>
        </div>

        <Tag productionName={title} />
        <span
          className={classNames({
            'save-tips': true,
            iconfont: true,
            'save-icon-saving icon-a-doc_icon_loading2x':
              saveStatus === SaveStatus.SAVING,
            'save-icon-success icon-a-doc_icon_correct2x':
              saveStatus === SaveStatus.SAVED,
            'save-icon-fail icon-a-doc_icon_fault2x':
              saveStatus === SaveStatus.FAIL_SAVED,
          })}
        >
          <span>{SAVE_STATUS_TEXT[saveStatus]}</span>
        </span>
      </div>
      <div className="right-option">
        <span className="doc-info">预计消耗视频时长{videoTime}</span>
        <View />
        <ExportVideo />
      </div>
    </div>
  );
}

export default observer(Header);
