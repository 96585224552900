import { useState } from 'react';
import { Tooltips, Menu } from '@bhb-frontend/lithe-ui';
import { PopupTrigger } from '@bhb-frontend/lithe-ui/lib/Popup';
import cs from 'classnames';
import style from './ItemMenu.module.less';

interface Item<T = any> {
  label: string;
  icon: React.ReactNode;
  value: T;
}

interface ItemMenuProps<T = any> {
  className?: string;

  /** 选项 */
  items: Item<T>[];
  /**
   * 弹层挂载容器节点，默认`document.body`
   */
  container?: HTMLElement | ((node: HTMLElement) => HTMLElement);

  trigger?: PopupTrigger;

  onChange?: (value: T) => void;
}

export default function ItemMenu<T>(props: ItemMenuProps<T>) {
  const { container, items, onChange, trigger = 'click', className } = props;

  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const renderPoint = new Array(3).fill(null).map((_, index) => {
    const id = String(index);
    return <i className={style.point} key={id} />;
  });

  const overlay = (
    <Menu
      onItemClick={() => {
        setPopupVisible(false);
      }}
      width={100}
      onChange={val => {
        onChange?.(val);
      }}
      className={style['overlay-container']}
    >
      {items.map(({ value, label, icon }) => (
        <Menu.Item key={label} value={value}>
          <span>{icon}</span>
          <span className={style['menu-label']}>{label}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handlePopupOpen = () => {
    setPopupVisible(true);
  };

  return (
    <Tooltips
      className={style['tooltips-component']}
      placement="bottomRight"
      visible={popupVisible}
      container={container}
      tips={overlay}
      trigger={trigger}
      onOpen={handlePopupOpen}
    >
      <div className={cs(className, style['menu-container'])}>
        {renderPoint}
      </div>
    </Tooltips>
  );
}
