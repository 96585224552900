import cs from 'classnames';

import type { AudioProps } from '../AudioBox';
import { AudioEnums } from '../AudioBox';

import Style from './AudioRender.module.less';

interface AudioRenderProps extends AudioProps {
  isPlay: boolean;
  /** 是否存在数字人 */
  isHaveDigitalMan?: boolean;
}

/**
 * 带音频内容渲染
 */
function AudioRender(props: AudioRenderProps) {
  const {
    cover,
    name,
    removeMusic,
    type,
    isPlay,
    isHaveDigitalMan = false,
    id,
    isImportRecord,
  } = props;

  /* 本地配音上传 */
  const handleImportRecord = () => (
    <>
      <div
        className={cs(Style.audioWrapImg, {
          [Style.playing]: isPlay,
        })}
      >
        <i
          className={cs('iconfont', 'icon-a-tuwen_icon_local_video1x')}
          style={{ color: '#02b203', fontSize: 36 }}
        />
      </div>

      <span className={Style.audioWrapText}>本地音频使用中</span>
      <i
        className={cs('iconfont', 'icon-common_icon_close', Style.audioClear)}
        onClick={e => {
          e.stopPropagation();
          removeMusic();
        }}
      />
    </>
  );

  const handleNoImportRecord = () => (
    <>
      {id && cover ? (
        <img
          src={cover}
          alt="音乐封面"
          className={cs(Style.audioWrapImg, {
            [Style.playing]: isPlay,
          })}
        />
      ) : (
        <div
          className={cs(Style.audioEmptyImg, {
            [Style.playing]: isPlay,
          })}
        >
          <i
            className={cs(
              'iconfont',
              type === AudioEnums.MUSIC
                ? 'icon-theme_icon_muscic'
                : 'icon-theme_icon_voice'
            )}
          />
        </div>
      )}
      <span className={Style.audioWrapText}>{name}</span>
      {/* 没有数字人配音才可以删除，有数字人时，配音不可以删除 */}
      {((type === AudioEnums.DUBBING && !isHaveDigitalMan) ||
        type === AudioEnums.MUSIC) && (
        <i
          className={cs('iconfont', 'icon-common_icon_close', Style.audioClear)}
          onClick={e => {
            e.stopPropagation();
            removeMusic();
          }}
        />
      )}
    </>
  );

  return (
    <div className={Style.audioWrap}>
      {isImportRecord ? handleImportRecord() : handleNoImportRecord()}
    </div>
  );
}

export default AudioRender;
