import { FontItem } from '@/types/Font';
import {
  FontStyle,
  Layer,
  TextDetail,
  TextUsage,
  Image,
} from '@/types/ServerData';

/**
 * 获取默认文本样式
 */
export function getDefaultOptionalField(font: FontItem): FontStyle {
  const data = {
    usage: 9 as TextUsage,
    width: 600,
    height: 100,
    degree: 0,
    hAlign: 1,
    vAlign: 1,
    format: '',
    justify: 1,
    leading: 72,
    kerning: 0,
    content: '编辑文字',
    contents: ['编辑文字'],
    features: 0,
    objectId: 'style-1-1',
    fontSize: 60,
    boxShift: -26,
    padding: {
      top: 20,
      left: 10,
      right: 10,
      bottom: 20,
    },
    fontDesc: {
      style: '',
      family: '',
      faceName: '',
      fontName: 'NotoSansMonoCJKtc-Regular',
      showName: '',
      fileName: '',
    },
    patterns: [
      {
        style: 0,
        alpha: 100,
        color: '#ffffff',
      },
    ],
  };

  if (font) {
    data.fontDesc.fontName = font.fontName;
    data.fontDesc.faceName = font.fontName;
    data.fontDesc.showName = font.fontName;
    data.fontDesc.fileName = `${font.fontName}.ttf`;
  }

  return data;
}

/**
 * 获取FootageText
 */
export function getFootageText(param: {
  styleId: string;
  objectId: string;
  content: string;
  optionalField: FontStyle;
  stickerId?: string;
}) {
  const { styleId, objectId, content, optionalField, stickerId } = param;
  const data: TextDetail = {
    ...optionalField,
    objectId,
    styleId,
    content,
    position: {
      x: 0,
      y: 0,
    },
  };
  if (stickerId) {
    data.extra = {
      stickerId,
    };
  }
  return data;
}

/**
 * 获取图片资源对象
 */
export function getImage(
  name: string,
  key: string,
  url: string,
  id: string
): Image {
  return {
    features: 0,
    fileName: name,
    imageKey: key,
    imageUrl: url,
    maskName: '',
    maskType: 0,
    objectId: id,
  };
}

// 获取默认贴图layer配置
export function getStickerLayer(
  id: string,
  width: number,
  height: number,
  usage: 3 | 4 | 8 | 9 | 10 | 11,
  configure: Configure,
  objectId = ''
): Layer {
  // 默认贴图layer配置
  const defaultStickerLayer: any = {
    animName: '',
    blendMode: 1,
    clipRange: [0, 30000000],
    drawable: true,
    editFlags: 0,
    features: 0,
    height,
    maskComb: 0,
    maskName: '',
    maskType: 0,
    matteMode: 0,
    mimeType: 7,
    objectId,
    opacity: 100,
    parentId: '',
    scaleMode: 0,
    showRange: [0, 30000000],
    sourceId: id,
    transform: {
      anchor: [360, 640, 0],
      orientation: [0, 0, 0],
      position: [360, 640, 0],
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      scalar: [100, 100, 100],
    },
    usage,
    usageName: '文字贴纸',
    velocity: 1,
    videoMute: false,
    volume: 1,
    width,
    zIndex: 1,
  };

  const maxWidth = configure.width || 720;
  const maxHeight = configure.height || 1280;

  const ratio = width / height;

  if (usage === 8) {
    defaultStickerLayer.originImageWidth = width;
    defaultStickerLayer.originImageHeight = height;

    if (width > maxWidth * 0.8) {
      width = maxWidth;
      height = width / ratio;
    }
    if (height > maxHeight * 0.8) {
      height = maxHeight;
      width = height * ratio;
    }

    if (width === maxWidth || height === maxHeight) {
      defaultStickerLayer.transform.scalar[0] =
        ((maxWidth * 0.8) / defaultStickerLayer.originImageWidth) * 100;
      defaultStickerLayer.transform.scalar[1] =
        ((maxWidth * 0.8) / defaultStickerLayer.originImageWidth) * 100;
      defaultStickerLayer.width = width * 0.8;
      defaultStickerLayer.height = height * 0.8;
    } else {
      defaultStickerLayer.transform.scalar[0] = 100;
      defaultStickerLayer.transform.scalar[1] = 100;
      defaultStickerLayer.width = width;
      defaultStickerLayer.height = height;
    }
  } else {
    if (width > maxWidth) {
      width = maxWidth * 0.8;
      height = width / ratio;
    }
    if (height > maxHeight) {
      height = maxHeight * 0.8;
      width = height * ratio;
    }
    defaultStickerLayer.width = width;
    defaultStickerLayer.height = height;
  }

  const anchor = [
    (defaultStickerLayer.width as number) / 2,
    (defaultStickerLayer.height as number) / 2,
    0,
  ];
  const position = [maxWidth / 2, maxHeight / 2, 0];
  defaultStickerLayer.transform.anchor = anchor;
  defaultStickerLayer.transform.position = position;

  if (usage !== 8) {
    defaultStickerLayer.mimeType = 4;
  }
  return defaultStickerLayer;
}
