export const COLORS = [
  '#ffffff',
  '#000000',
  '#F80701',
  '#FA9904',
  '#FFFE00',
  '#00FF00',
  '#6CDEFF',
  '#403ED6',
  '#ED41FD',
  '#E9E9E9',
  '#7B7B7B',
  '#FCC8B8',
  '#FDE1B2',
  '#FEF2CC',
  '#D9EAD3',
  '#DFF8FF',
  '#CFC7F4',
  '#FDE4FF',
  '#D9D9D9',
  '#5C5C5C',
  '#E99899',
  '#FCB84D',
  '#FEE59A',
  '#ACDB7D',
  '#85D4E6',
  '#8C7BE8',
  '#EE93F6',
  '#C4C4C4',
  '#333333',
  '#E06666',
  '#FA8D02',
  '#FDD967',
  '#87C120',
  '#4CC2EE',
  '#3F49B9',
  '#D041E1',
  '#9D9D9D',
  '#262626',
  '#CC0500',
  '#E75200',
  '#FCB702',
  '#678F01',
  '#1F88FB',
  '#2741B1',
  '#A41AD3',
];
