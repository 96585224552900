import { useState, useEffect } from 'react';
import cs from 'classnames';
import { observer } from 'mobx-react';
import { Tooltips, Input } from '@bhb-frontend/lithe-ui';
import { TextStruc } from '@/models/CmpStruc';
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from '@/constants/FontSize';
import style from './FontSize.module.less';
import assets from '@/assets';

interface FontSizeProps {
  model: TextStruc;
  className?: string;
}

const FONT_SIZE_LIST = [12, 14, 18, 24, 36, 48, 50, 62, 74, 86, 98, 100];

function FontSize(props: FontSizeProps) {
  const { model, className } = props;

  const {
    style: { fontSize = 12 },
  } = model;

  const [size, setSize] = useState<number | string>(0);

  useEffect(() => {
    setSize(Math.floor(+fontSize));
  }, [fontSize]);

  /** 改变字体 */
  const changeSize = (value: number | string = size) => {
    const fontSize = Math.min(MAX_FONT_SIZE, Math.max(MIN_FONT_SIZE, +value));
    setSize(fontSize);
    model.updateStyle({ fontSize });
  };

  /** 输入改变 */
  const onChange = (e, value: string) => {
    setSize(value);
  };

  const tips = (
    <div className={style['size-container']}>
      {FONT_SIZE_LIST.map(sizeItem => (
        <div
          className={style['size-item']}
          key={sizeItem}
          onClick={() => {
            changeSize(sizeItem);
          }}
        >
          <img
            className={cs({
              [style['active-icon']]: true,
              [style.show]: size === sizeItem,
            })}
            src={assets.images.doc['theme_icon_choose.png']}
            alt=""
          />
          {sizeItem}
        </div>
      ))}
    </div>
  );

  return (
    <Tooltips
      tips={tips}
      className={style['tooltips-component']}
      placement="bottomLeft"
      trigger="click"
      clickClosable
    >
      <Input
        type="number"
        value={size}
        className={cs(className, style['input-component'])}
        onChange={onChange}
        onPressEnter={() => {
          changeSize();
        }}
        onBlur={() => {
          changeSize();
        }}
      />
    </Tooltips>
  );
}

export default observer(FontSize);
