export enum AnchorPoint {
  /** 上 */
  TOP,

  /** 右 */
  RIGHT,

  /** 下 */
  BOTTOM,

  /** 左 */
  LEFT,

  /** 左上 */
  TOP_LEFT,

  /** 右上 */
  TOP_RIGHT,

  /** 左下 */
  BOTTOM_LEFT,

  /** 右下 */
  BOTTOM_RIGHT,
}

/**
 * 所有的锚点
 */
export const ALL_ANCHORS: AnchorPoint[] = [
  AnchorPoint.TOP_LEFT,
  AnchorPoint.TOP,
  AnchorPoint.TOP_RIGHT,
  AnchorPoint.RIGHT,
  AnchorPoint.BOTTOM_RIGHT,
  AnchorPoint.BOTTOM,
  AnchorPoint.BOTTOM_LEFT,
  AnchorPoint.LEFT,
];

/**
 * 锚点鼠标样式
 */
export const ANCHOR_CURSORS = ['nwse', 'ns', 'nesw', 'ew'];
