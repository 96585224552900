import { ThemeDetailItem } from '@/types/ServerData';

/** 检验单一场景资源 */
export function checkSingleSecene({ composition, footage }: ThemeDetailItem) {
  const { layers } = composition;
  const resourceMap = new Map<string, boolean>();
  const values = Object.values(footage);
  for (const resourceArr of values) {
    if (Array.isArray(resourceArr)) {
      resourceArr.forEach(item => {
        resourceMap.set(item.objectId, true);
      });
    }
  }
  const checkRes = layers
    .filter(item => item.usage !== 2)
    .some(layer => !resourceMap.has(layer.sourceId || ''));
  // 校验通过
  if (!checkRes) return true;
  // 资源丢失
  return false;
}
