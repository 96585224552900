/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/fae4989b31d93b91642cfc0051ac457c.png */
  'icon-common-play.png': 'fae4989b31d93b91642cfc0051ac457c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e37bb221fbab22f8a72752377c230a4a.png */
  'icon-fabu-bufen.png': 'e37bb221fbab22f8a72752377c230a4a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2df0e3cc1b99719aff2994b879151bdf.png */
  'icon-fabu-fail.png': '2df0e3cc1b99719aff2994b879151bdf.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/24a9cfdef29be8f699e6d303eb5af0a8.png */
  'icon-fabu-loading.png': '24a9cfdef29be8f699e6d303eb5af0a8.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c85a47569c323e93182754c3cc8b4982.png */
  'icon-fabu-success.png': 'c85a47569c323e93182754c3cc8b4982.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5802e06f5e4fd080afb4e81b6a81fa0c.png */
  'img-theme-cover.png': '5802e06f5e4fd080afb4e81b6a81fa0c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a89827356694a6f8d8d529af0f6647c0.png */
  common_icon_complete: 'a89827356694a6f8d8d529af0f6647c0.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/868c5771c6cab8e9427867cbeedf1177.png */
  'background-mosaic': '868c5771c6cab8e9427867cbeedf1177.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/66fb45338596f5be2652da553a2daded.png */
  'menu-boltcut': '66fb45338596f5be2652da553a2daded.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d8c3ce5f06216f2b7dc025831940fc77.png */
  'menu-live': 'd8c3ce5f06216f2b7dc025831940fc77.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c0933c8b34d0b9f6a186235c4d77f490.png */
  'menu-doc': 'c0933c8b34d0b9f6a186235c4d77f490.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/58f572dedb1284928aa5b8613acaa369.png */
  'menu-subscription': '58f572dedb1284928aa5b8613acaa369.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a84fca78125f9745510f6270851262f3.png */
  'common-loading': 'a84fca78125f9745510f6270851262f3.png',
};
