import { Modal } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import cs from 'classnames';
import style from './CreateBigHeadModal.module.less';

interface CreateDigitalManModalProps extends ModalProps {
  /** 打开录制 */
  openRecording: () => void;
  /** 打开文件 */
  openFileInput: () => void;
}

export default function CreateDigitalManModal(
  props: CreateDigitalManModalProps
) {
  const { openFileInput, openRecording, onClose, ...otherProps } = props;
  return (
    <Modal
      {...otherProps}
      closable={false}
      contentClassName={style['create-big-head-modal']}
      width={488}
      height={230}
    >
      <div className={style.header}>
        <span className={style.title}>选择添加方式</span>
        <i
          onClick={onClose}
          className={cs('iconfont icon-a-common_icon_close_grey2x1')}
        />
      </div>

      <div className={style.body}>
        <div
          onClick={openRecording}
          className={cs(style.box, style['record-self'])}
        >
          <i
            className={cs(
              'iconfont icon-a-number_icon_luzhi2x',
              style['box-icon']
            )}
          />
          <span>录制自己</span>
        </div>
        <div
          onClick={openFileInput}
          className={cs(style.box, style['select-image'])}
        >
          <i
            className={cs(
              'iconfont icon-a-number_icon_ablum2x',
              style['box-icon']
            )}
          />
          <span>选择照片</span>
        </div>
      </div>
    </Modal>
  );
}
