/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/322a71627e7721559523c3f3b7644359.png */
  'icon-arrow-def.png': '322a71627e7721559523c3f3b7644359.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2945dd4d7e60aba2507fd68c49cef9e6.png */
  'icon-arrow-pre.png': '2945dd4d7e60aba2507fd68c49cef9e6.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d9ab942eea0ce34c960cdc117fda6ee7.png */
  'icon-choice.png': 'd9ab942eea0ce34c960cdc117fda6ee7.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/4e8902d17f63c0909092d694cbcd882f.png */
  'icon-choice2.png': '4e8902d17f63c0909092d694cbcd882f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/82e820b115237993eadf7dc9e039c9f7.png */
  'icon-more.png': '82e820b115237993eadf7dc9e039c9f7.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/496d042119d03f99764f3772911e7614.png */
  'icon-nochoice.png': '496d042119d03f99764f3772911e7614.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a71a6e866b744056908cceda7d02ae66.png */
  'icon-robot.png': 'a71a6e866b744056908cceda7d02ae66.png',
};
