import { useMemo, createElement, useState, useEffect } from 'react';
import { flushSync } from 'react-dom';
import { observer } from 'mobx-react';
import KeepAlive from 'react-activation';
import { Panel, Tabs } from '@bhb-frontend/lithe-ui';
import { event } from '@/utils/event';
import { MATERIAL_CLOSE_MEUN } from '@/constants/StoreCacheKeys';

import MaterialStore from '@/store/Material';
import assets from '@/assets';

import Style from './style.module.less';

import { MaterialEnum, TMenu } from '@/types/material';

interface SidabarMenuPorps {
  /** 侧边栏store */
  MaterialStore: MaterialStore;
  /** 菜单配置项 */
  options: TMenu;
}

function SidabarMenu(props: SidabarMenuPorps) {
  const { MaterialStore, options = [] } = props;
  const { activeMenu } = MaterialStore;
  const [penalVisible, setPenalVisible] = useState(true);

  useEffect(() => {
    if (!activeMenu) return;
    setPenalVisible(true);
  }, [activeMenu]);

  /** 当前激活的菜单配置 */
  const option = useMemo(
    () => options.find(item => item.name === activeMenu),
    [activeMenu, options]
  );

  /* 文字动画隐藏侧边栏把手 */
  const hiddenSidebar = useMemo(() => option?.hiddenSidebar, [option]);

  /** 需要展示的菜单 */
  const showOptions = useMemo(
    () => options.filter(item => !item.hiddenLabel),
    [options]
  );

  /** 切换菜单 */
  const handleSwitchMenu = menuKey => {
    MaterialStore.changeMenu(menuKey);
    setPenalVisible(true);
  };

  /** 关闭资源面板 */
  const handleClosePanel = () => {
    flushSync(() => {
      setPenalVisible(false);
    });
    MaterialStore.changeMenu(MaterialEnum.DEFAULT);
  };

  useEffect(() => {
    event.on(MATERIAL_CLOSE_MEUN, handleClosePanel);

    return () => {
      event.off(MATERIAL_CLOSE_MEUN, handleClosePanel);
    };
  }, []);

  const items = useMemo(
    () =>
      showOptions.map(item => ({
        key: item.name,
        label: (
          <div>
            <i className={`iconfont icon ${item.icon}`} />
            {item.label}
          </div>
        ),
        children: null,
      })),
    [showOptions]
  );

  return (
    <div className={Style.material}>
      {/* 菜单区域 */}
      <div className={Style.menu}>
        <Tabs
          items={items}
          tabPosition="left"
          activeKey={activeMenu}
          onChange={handleSwitchMenu}
        />
      </div>

      {/* 内容区域 */}
      <Panel
        className={Style['material-panel']}
        visible={penalVisible}
        showSwitchTips={false}
      >
        <div className={Style.content}>
          {option && (
            <KeepAlive id={`material_${option.name}`}>
              {createElement(option.component)}
            </KeepAlive>
          )}
        </div>

        {/* 关闭侧边栏把手 */}
        {!hiddenSidebar && (
          <div
            className={Style.materialContentClose}
            onClick={handleClosePanel}
          >
            <img
              src={assets.images.material['material_content-close-icon_png']}
              alt=""
            />
          </div>
        )}
      </Panel>
    </div>
  );
}

export default observer(SidabarMenu);
