import cx from 'classnames';

import { observer } from 'mobx-react-lite';
import { DubbingDetail } from '@/types/material';
import { MEMBER_TYPE, VIP_IMAGE } from '../../const';
import Style from './style.module.less';
import image from '@/assets/images';
import { useStores } from '@/store';

interface DubbingListItemProps {
  item: DubbingDetail;
  switchMusic: (itme: DubbingDetail) => void;
  onSelect?: (itme: DubbingDetail) => void;
  isPlaying: boolean;
}

function DubbingListItem(props: DubbingListItemProps) {
  const { item, switchMusic, onSelect, isPlaying } = props;

  const {
    app: {
      activedScene: {
        sound: { id },
      },
    },
  } = useStores();

  const memberTypeIcon = (() => {
    switch (item.memberType) {
      case MEMBER_TYPE.BASIC:
        return VIP_IMAGE.doc.VIP;
      case MEMBER_TYPE.PROFESSIONAL:
        return VIP_IMAGE.doc.SVIP;

      default:
        return '';
    }
  })();

  return (
    <div
      className={`${Style.dubbingItem} ${isPlaying && 'hh'}`}
      onClick={() => {
        onSelect?.(item);
      }}
    >
      <div
        className={Style.dubbingItemCover}
        onClick={e => {
          e.stopPropagation();
          switchMusic?.(item);
        }}
      >
        <img className={Style.dubbingItemCoverImg} src={item.coverUrl} alt="" />
        <img
          alt=""
          src={
            isPlaying
              ? image.doc['music_icon_stop.png']
              : image.doc['music_icon_play.png']
          }
          className={cx(Style.dubbingItemCoverPlayBtn, {
            pause: !isPlaying,
          })}
        />
      </div>
      <div className={Style.dubbingItemNameWrapper}>
        <div className={Style.dubbingItemNameWrapperName}>{item.name}</div>
      </div>
      <div className={Style.dubbingItemSelectWrapper}>
        <div
          className={cx(Style.dubbingItemSelectWrapperIcon, {
            [Style.dubbingItemSelectWrapperSelect]: id === item.id,
          })}
        >
          <i className="iconfont icon-a-music_icon_choose_succeed2x" />
        </div>
      </div>
      {memberTypeIcon && (
        <img
          src={memberTypeIcon}
          className={Style.dubbingItemMemberType}
          alt=""
        />
      )}
    </div>
  );
}

export default observer(DubbingListItem);
