import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { CmpTypeEnum } from '@/constants/CmpType';
import CmpStruc from '@/models/CmpStruc';
import { useStores } from '@/store';
import CanvasSetting from './Canvas';
import Video from './Video';
import Music from './Music';
import Background from './Background';
import Group from './Group';
import Image from './Image';
import Text from './Text';
// import OSSetting from './OSSetting';

interface SettingsProps {
  model: CmpStruc;
}

function getSettingByType(type: CmpType) {
  const settingByType: Record<CmpType, FC<any> | null> = {
    // 其他的都未实现
    [CmpTypeEnum.UNKNOWN]: null,
    [CmpTypeEnum.DUBBING]: null,
    [CmpTypeEnum.VIDEO]: Video,
    [CmpTypeEnum.MUSIC]: Music,
    [CmpTypeEnum.BACKGROUND]: Background,
    [CmpTypeEnum.GROUP]: Group,
    [CmpTypeEnum.IMAGE]: Image,
    [CmpTypeEnum.TEXT]: Text,
  };

  return settingByType[type] as React.ComponentType<SettingsProps>;
}

function Settings() {
  const { app } = useStores();
  const { activedCmps, activeMediaCmp } = app;

  // if (showGlobalSetting) return <OSSetting />;

  // 未选中任何组件
  if (activedCmps.length === 0 && activeMediaCmp === CmpTypeEnum.UNKNOWN) {
    return <CanvasSetting />;
  }

  // 选中多个组件
  if (activedCmps.length > 1) {
    return <div>选择多个组件</div>;
  }

  const cmp = activedCmps[0];
  /** 选中的媒体组件优先级高 */
  const type =
    activeMediaCmp === CmpTypeEnum.UNKNOWN ? cmp.type : activeMediaCmp;

  const CmpSetting = getSettingByType(type);
  if (!CmpSetting) return null;

  return <CmpSetting model={cmp} />;
}

export default observer(Settings);
