import { useState, useEffect, useMemo } from 'react';
import CategoryWrapper from '@/components/Material/CategoryWrapper';
import MaterialContent from '@/components/Material/MaterialContent';
import GetListWrapper from '@/components/Material/GetListWrapper';
import TempalteContent from './TemplateContent';
import {
  getThemeCategory,
  getCategoryThemeById,
  getSelfTheme,
} from '@/api/material';
import {
  Percent,
  GetThemeParmas,
  ThemeDetail,
  Category,
} from '@/types/material';

import { PERCENT_TYPES } from './const';
import { SELF_CATEGORY } from '../../const';

import Style from './style.module.less';

function Template() {
  /** 主题模板比例 */
  const [activePercent, setActivePercent] = useState<Percent>(Percent.Vertical);
  /** 模板分类 */
  const [tempCategorys, setTempCategorys] = useState<Category[]>([]);
  /** 默认选择的分类 */
  const [activeCategory, setActiveCategory] = useState<string>('');

  /** 获取分类 */
  const getCategory = async () => {
    const { data } = await getThemeCategory();
    const results = [{ ...SELF_CATEGORY }, ...data.results];
    setActiveCategory(results[1]?.id || '');
    setTempCategorys(results);
  };

  useEffect(() => {
    getCategory();
  }, []);

  const changePercent = (key: Percent) => {
    setActivePercent(key);
  };

  const typePayload = useMemo(
    () => ({
      percent: activePercent,
      type: SELF_CATEGORY.id,
    }),
    [activePercent]
  );

  const payload = useMemo(
    () => ({
      percent: activePercent,
    }),
    [activePercent]
  );

  return (
    <>
      <div className={Style['percent-container']}>
        <CategoryWrapper<Percent>
          categorys={PERCENT_TYPES}
          activeKey={activePercent}
          onChange={changePercent}
        />
      </div>

      <MaterialContent
        categorys={tempCategorys.map(({ id, name }) => ({
          key: id,
          label: name,
          apiFn: id === SELF_CATEGORY.id ? getSelfTheme : getCategoryThemeById,
          payload: id === SELF_CATEGORY.id ? typePayload : payload,
        }))}
        activeTab={activeCategory}
      >
        <GetListWrapper<GetThemeParmas, ThemeDetail>>
          <TempalteContent
            percent={activePercent}
            setPercent={setActivePercent}
          />
        </GetListWrapper>
      </MaterialContent>
    </>
  );
}

export default Template;
