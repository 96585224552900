import {
  ContextItem,
  ContextMenuProps,
} from '@bhb-frontend/lithe-ui/lib/ContextMenu';
// import CmdEnum from '@/constants/CmdEnum';
// import { getHotKeyByCmd, getHotKeyCmdOfOS } from '@/helpers/HotKey';
import React from 'react';
import { getCmpsByPoint } from '@/helpers/Cmps';
import { toCanvasPoint } from '@/helpers/Node';
import CmpStruc, { TextStruc, ImageStruc } from '@/models/CmpStruc';
import { TAGS_MAP } from '@/constants/Tags';
import CoverLabel from '@/layout/Stage/ContextMenuCover';
import style from '@/layout/Stage/ContextMenuCover/ContextMenuCover.module.less';
import { ExtraType } from '@/constants/Extra';
import { Stores } from '@/store';

/**
 * 复制
 */
// const copyHotKey = getHotKeyByCmd(CmdEnum.COPY);

/**
 * 剪切
 */
// const cutHotKey = getHotKeyByCmd(CmdEnum.CUT);

/**
 * 粘贴
 */
// const pasteHotKey = getHotKeyByCmd(CmdEnum.PASTE);

/**
 * 删除
 */
// const deleteHotKey = getHotKeyByCmd(CmdEnum.DELETE);

const getStickerDesc = (cmp: CmpStruc) => {
  if ((cmp as ImageStruc).extra?.scene?.type === ExtraType.DIGITAL_MAN)
    return '数字人';
  return '贴图';
};

const getSourceDesc = (cmp: CmpStruc, titleContent: string) => {
  switch (cmp.tag) {
    case TAGS_MAP.Captions:
    case TAGS_MAP.DateTextSticker:
    case TAGS_MAP.MapTextSticker:
    case TAGS_MAP.TextSticker:
      return (cmp as TextStruc).content;
    case TAGS_MAP.Title:
      return titleContent;
    case TAGS_MAP.Image:
      return '插图';
    case TAGS_MAP.Sticker:
      return getStickerDesc(cmp);
    default:
      return cmp.tag;
  }
};

function getSceneContextSource(store: Stores, e: MouseEvent): ContextItem[] {
  const {
    app: { activedScene, titleContent },
    OS: { zoomLevel },
  } = store;
  const { x, y } = toCanvasPoint({
    x: e.clientX,
    y: e.clientY,
  });
  const cmps = getCmpsByPoint(activedScene.cmps, {
    x: x / zoomLevel,
    y: y / zoomLevel,
  });

  const children: ContextItem[] = [];

  cmps.forEach(cmp => {
    children.push({
      label: React.createElement(CoverLabel, { model: cmp }),
      value: cmp,
      desc: getSourceDesc(cmp, titleContent),
    });
  });

  return [
    {
      label: '选择重叠图层',
      value: 'layer',
      desc: '>',
      children,
    },
  ];
}

// function getCmpContextSource(_store: Stores): ContextItem[] {
//   const source: ContextItem[] = [
//     {
//       label: copyHotKey?.label || '',
//       value: CmdEnum.COPY,
//       desc: getHotKeyCmdOfOS(copyHotKey),
//     },
//     {
//       label: cutHotKey?.label || '',
//       value: CmdEnum.CUT,
//       desc: getHotKeyCmdOfOS(cutHotKey),
//     },
//     {
//       label: pasteHotKey?.label || '',
//       value: CmdEnum.PASTE,
//       desc: getHotKeyCmdOfOS(pasteHotKey),
//     },
//     {
//       label: deleteHotKey?.label || '',
//       value: CmdEnum.DELETE,
//       desc: getHotKeyCmdOfOS(deleteHotKey),
//     },
//   ];
//   return source;
// }

export function getContextSource(
  store: Stores,
  e: MouseEvent
): ContextMenuProps<CmpStruc> {
  const { app } = store;

  return {
    soruce: getSceneContextSource(store, e),
    className: style['context-menu-container'],
    style: { width: 180, left: e.pageX, top: e.pageY },
    onChange: model => app.activeCmp(model),
  };
}
